import { apiClient } from "./apiClient";

export default {
  getAll(params, cancelToken) {
    return apiClient.get("/rest/area", {
      params: params,
      cancelToken: cancelToken,
    });
  },

  delete(id) {
    return apiClient.delete(`/rest/area/${id}`);
  },

  checkDelete(id) {
    return apiClient.get(`/rest/area/${id}/check-delete`);
  },

  getBasicGeozonesByOrg(orgId) {
    return apiClient.get(`/rest/area/basic?orgId=${orgId}`);
  },
};
