import wifiCellularMismatchClient from "@/apis/wifiCellularMismatchClient";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SntDialog from "../../components/SntDialog/SntDialog";
import MapUpdateWifiCellularMismatch from "./MapUpdateWifiCellularMismatch";

const UpdateMismatchPopup = ({ selectedItem, onClose, isShow, setIsShow }) => {
  const language = useSelector((state) => state.language);
  const [actualLocation, setActualLocation] = useState(null);
  const [item, setItem] = useState();
  const [isShowResponse, setShowResponse] = useState(false);
  const [responseText, setResponseText] = useState("");

  useEffect(() => {
    wifiCellularMismatchClient.getById(selectedItem.id).then((response) => {
      setItem(response.data);
    });
  }, [selectedItem, isShow]);

  const onSave = () => {
    if (actualLocation && item) {
      wifiCellularMismatchClient
        .createPrivateCellularTower(item.id, actualLocation)
        .then(() => {
          setResponseText(language.update_success_key);
        })
        .catch((e) => {
          setResponseText(language.save_fail + ". " + e.response.data.message);
        })
        .finally(() => {
          setShowResponse(true);
        });
    }
    setIsShow(false);
  };

  return (
    <>
      {item && (
        <SntDialog
          isAllowClickOutside={true}
          isShow={isShow}
          onSave={onSave}
          onClose={onClose}
          title={language.resolve_wifi_cellular_mismatch_key.replace(
            "{0}",
            selectedItem?.cellId
          )}
          closeTxt={language.cancel_key}
        >
          {() => (
            <MapUpdateWifiCellularMismatch
              item={item}
              onClick={(data) =>
                setActualLocation({ lat: data.latitude, lng: data.longitude })
              }
            />
          )}
        </SntDialog>
      )}
      <SntDialog
        isShow={isShowResponse}
        onSave={() => {
          setShowResponse(false);
        }}
        onClose={() => {
          setShowResponse(false);
        }}
        title={language.notifications_key}
        saveTxt={"OK"}
        isShowCloseButton={false}
        btnSaveClassName="submit btn-sensolus"
      >
        {() => <div>{responseText}</div>}
      </SntDialog>
    </>
  );
};

export default UpdateMismatchPopup;
