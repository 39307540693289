import { useEffect, useState } from "react";
import { FormFullScreenLayout } from "@/components/ReactBootstrap/FormValidation";
import SntItemSelectorField from "@/cleanup/widgets/formiks/SntItemSelectorField";
import alertManageClient from "@/apis/alertManageClient";

const AlertRuleConditionControl = ({ name, orgId, disabled, language }) => {
  const [alertRuleOptions, setAlertRuleOptions] = useState([]);

  useEffect(() => {
    alertManageClient.getAllAlertRules({ orgId: orgId }).then(
      ({ data }) => {
        setAlertRuleOptions(
          data.map((item) => {
            return {
              value: item.id,
              label: item.title,
            };
          })
        );
      },
      (err) => {}
    );
  }, [orgId]);

  return (
    <>
      <FormFullScreenLayout
        label={language.select_alert_rule_key}
        isRequired={true}
        rightRender={() => (
          <>
            <SntItemSelectorField
              options={alertRuleOptions}
              name={`${name}.alertRuleId`}
              disabled={disabled}
            />
          </>
        )}
      />
    </>
  );
};

export default AlertRuleConditionControl;
