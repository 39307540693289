import { useHistory } from "react-router-dom";

import sigfoxDeviceClient from "@/apis/sigfoxDeviceClient";
import SntLink from "@/components/ReactBootstrap/SntLink";
import {
  BleSensorRoute,
  DeviceAdminRoute,
  InfraRoute,
} from "@/cleanup/utils/routesUtil/routesUtil";

const SntAssetLinkContainer = ({ text, id }) => {
  const history = useHistory();

  const onClick = () => {
    sigfoxDeviceClient
      .findOne({ id: id })
      .then(({ data }) => {
        if (data.deviceCategory === "TRACKER") {
          history.push(
            DeviceAdminRoute.getUpdatePage({
              orgId: data.ownedByOrg.id,
              id: id,
            })
          );
        } else if (data.deviceCategory === "INFRA") {
          history.push(
            InfraRoute.getUpdatePage({
              orgId: data.ownedByOrg.id,
              id: id,
            })
          );
        } else {
          history.push(
            BleSensorRoute.getUpdatePage({
              orgId: data.ownedByOrg.id,
              id: id,
            })
          );
        }
      })
      .catch((error) => {});
  };

  return (
    <SntLink onClick={onClick} to={"/asset_link?id=" + id}>
      {text}
    </SntLink>
  );
};

export default SntAssetLinkContainer;
