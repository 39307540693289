import { useEffect, useMemo, useState } from "react";

import { useSelector } from "react-redux";

import { useLocation } from "react-router-dom";

import queryString from "query-string";

import PageHeader from "@/containers/PageHeader/PageHeader";
import ChangeModeHeaderIcon from "@/containers/PageHeader/ChangeModeHeaderIcon";

import { useOrg } from "@/contexts/OrgContext";
import LoaderSpinner from "@/components/ReactBootstrap/LoaderSpinner/LoaderSpinner";

import automationRuleClient from "@/apis/automationRuleClient";
import SntTabRouteContainer from "@/components/SntTab/SntTabRouteContainer";
import { useRoutes } from "@/components/SntTab/useRoutes";
import EventTab from "./EventTab";
import InfoTab from "./InfoTab";
import SntLabIcon from "@/components/Icons/SntLabIcon";

const AutomationRuleUpdate = ({ reload }) => {
  const language = useSelector((state) => state.language);
  const { orgId } = useOrg();

  const location = useLocation();
  let { id, type } = queryString.parse(location.search);

  const [count, setCount] = useState(0);
  const [data, setData] = useState(null);

  useEffect(() => {
    setCount((prevCount) => {
      const newCount = prevCount + 1;
      return newCount;
    });
  }, [reload, orgId]);

  useEffect(() => {
    setData(null);
    automationRuleClient.findOne(id).then(({ data }) => {
      setData(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (!data) return <LoaderSpinner />;

  return (
    <>
      <PageHeader
        title={
          <>
            {language.automation_rule_update_key}
            <SntLabIcon className={"ms-2"} title={language.lab_feature_key} />
          </>
        }
        tabTitle={language.automation_rule_update_key}
        renderHeaderIcon={() => (
          <>
            <ChangeModeHeaderIcon />
          </>
        )}
      />

      <AutomationRuleTabs
        key={count}
        orgId={orgId}
        id={id}
        type={type}
        data={data}
      />
    </>
  );
};

const AutomationRuleTabs = ({ orgId, id, type, data }) => {
  const language = useSelector((state) => state.language);
  const loginInfo = useSelector((state) => state.user);

  const route = {
    route: "automation_rule_update?",
    tabActive: "info_tab",
  };
  const { routeParams } = useRoutes(route);

  const allTabs = useMemo(() => {
    return [
      {
        id: "info_tab",
        label: language.v43_settings_key,
        render: true,
        component: () => (
          <InfoTab orgId={orgId} id={id} type={type} data={data} />
        ),
      },
      {
        id: "event_tab",
        label: language.events_key,
        render: true,
        component: () => (
          <EventTab orgId={orgId} id={id} type={type} data={data} />
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, loginInfo, type]);

  return (
    <div>
      <SntTabRouteContainer
        allTabs={allTabs}
        tabGroupInfo={{
          route: route.route,
          tabActive: routeParams.tabActive,
        }}
        browserTabTitle={language.automation_rule_update_key}
      />
    </div>
  );
};

export default AutomationRuleUpdate;
