import { useCallback } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import SntDeleteIcon from "../../components/Icons/SntDeleteIcon";
import SntSettingsIcon from "../../components/Icons/SntSettingsIcon";
import SntActionButton from "../../components/ReactBootstrap/SntActionButton";
import SntCheckBox from "../../components/SntCheckBox/SntCheckBox";

const ServiceConfigTable = ({
  mode,
  allService,
  services,
  onActivate,
  onEdit,
  onDelete,
}) => {
  const language = useSelector((state) => state.language);

  const canConfiguration = useCallback((serviceKey) => {
    return (
      [
        "BLE_SENSOR",
        "GENERIC_LOCATION",
        "ORIENTATION_EVENT",
        "DIAGNOSTIC",
        "ORIENTATION_STATE",
        "TIMESYNC",
        "ACCELERO_BASED_ACTIVITY",
        "BATTERY",
        "IBEACON_SCAN",
        "INFRASTRUCTURE",
        "SIGFOX",
      ].indexOf(serviceKey) >= 0
    );
  }, []);

  function renderRow(s) {
    let service =
      allService &&
      allService.length &&
      allService.find((p) => p.serviceKey.name === s.type);
    return (
      service && (
        <tr key={service.serviceKey.name}>
          <td>{language[service.serviceKey.fullName]}</td>
          <td>
            <SntCheckBox
              checked={s.active !== undefined ? s.active : true}
              disabled={mode === "view"}
              onChange={(el) => onActivate(el, service.serviceKey.name)}
            />
          </td>
          {mode !== "view" && (
            <td className="text-center">
              {canConfiguration(service.serviceKey.name) && (
                <SntActionButton
                  name={service.serviceKey.name}
                  onClick={onEdit}
                  title="Edit configuration"
                >
                  <SntSettingsIcon />
                </SntActionButton>
              )}
              <SntActionButton
                name={service.serviceKey.name}
                onClick={onDelete}
                title={language.delete_key}
              >
                <SntDeleteIcon />
              </SntActionButton>
            </td>
          )}
        </tr>
      )
    );
  }

  return (
    <Table striped bordered responsive>
      <thead>
        <tr>
          <th>{language.service_key}</th>
          <th>{language.v40_enabled_col_key}</th>
          {mode !== "view" && <th>{language.action_key}</th>}
        </tr>
      </thead>
      <tbody>{services.map((service) => renderRow(service))}</tbody>
    </Table>
  );
};
export default ServiceConfigTable;
