import { useEffect, useState } from "react";
import visibilityFilterClient from "@/apis/visibilityFilterClient";
import SntMultipleIetemSelectorField from "@/cleanup/widgets/formiks/SntMultipleIetemSelectorField";
import { FormFullScreenLayout } from "@/components/ReactBootstrap/FormValidation";

const VisibilityFilterActionControl = ({ name, orgId, disabled, language }) => {
  const [visibilityFilterOptions, setVisibilityFilterOptions] = useState([]);

  useEffect(() => {
    visibilityFilterClient.findAll({ orgId: orgId }).then(
      ({ data }) => {
        setVisibilityFilterOptions(
          data.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          })
        );
      },
      (err) => {}
    );
  }, [orgId]);

  return (
    <>
      <FormFullScreenLayout
        label={language.select_visibility_filters_key}
        rightRender={() => (
          <SntMultipleIetemSelectorField
            name={`${name}.selectedIds`}
            options={visibilityFilterOptions}
            disabled={disabled}
          />
        )}
      />
    </>
  );
};

export default VisibilityFilterActionControl;
