import SntIcon from "./SntIcon";

const SntFormulaIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 7.46667L13.7118 6.55238L11.4598 9.39657L9.44828 6.85714H6.09874L7.27184 1.58324C7.28713 1.51467 7.43678 1.51619 7.43678 1.6V1.90476H9.04598V1.6C9.04598 0.717714 8.28805 0 7.34988 0C6.55494 0 5.85977 0.534095 5.69644 1.2701L4.45494 6.85714H2.6092V8.38095H4.11621L2.77414 14.4168C2.75805 14.4853 2.6092 14.4838 2.6092 14.4008V14.0952H1V14.4008C1 15.283 1.75713 16 2.69529 16C3.49023 16 4.18621 15.4659 4.34874 14.7299L5.76 8.38095H8.64368L10.3325 10.5143L10.454 10.6667L7.91874 13.8667L9.2069 14.7817L11.4598 11.9368L13.7118 14.7817L15 13.8667L12.4655 10.6667L15 7.46667Z"
      ></path>
    </SntIcon>
  );
};

export default SntFormulaIcon;
