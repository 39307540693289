import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import {
  FormGroupLayout,
  InputCheck,
} from "../../../components/ReactBootstrap/FormValidation/index.js";
import SntDialog from "../../../components/SntDialog/SntDialog.js";
import ServiceInput from "../Utils/ServiceInput.js";

const GpsLocationServiceConfig = ({
  isShow,
  setIsShow,
  selectedService,
  onSave,
}) => {
  const language = useSelector((state) => state.language);
  const formikRefs = useRef();

  const [service, setService] = useState({
    type: "GENERIC_LOCATION",
    active: true,
    configuration: {
      minDistanceForOverridePreciseInMeter: 25000,
      carryTrackers: false,
      carryAttachDistance: null,
      carryBreakupDistance: null,
      ignoreDeviceGpsMessages: false,
    },
  });

  useEffect(() => {
    if (selectedService && isShow) {
      setService({ ...service, ...selectedService });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService, isShow]);

  const onSubmit = (values) => {
    onSave({
      ...service,
      configuration: {
        minDistanceForOverridePreciseInMeter:
          values.minDistanceForOverridePreciseInMeter,
        carryTrackers: values.carryTrackers,
        ignoreDeviceGpsMessages: values.ignoreDeviceGpsMessages,
        carryAttachDistance: values.carryAttachDistance,
        carryBreakupDistance: values.carryBreakupDistance,
      },
    });
    setIsShow(false);
  };

  const validationSchema = Yup.object().shape({
    minDistanceForOverridePreciseInMeter: Yup.number()
      .required(language.valid_required_key)
      .test(
        "Is positive?",
        "ERROR: The number must be greater than 0!",
        (value) => value > 0
      ),
  });

  return (
    <SntDialog
      isShow={isShow}
      onSave={() => formikRefs.current.handleSubmit()}
      onClose={() => setIsShow(false)}
      saveTxt={language.save_key}
      title={language.v40_define_services_to_enable_disable_key}
    >
      {() => (
        <Formik
          initialValues={service.configuration}
          innerRef={formikRefs}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form className="form-horizontal">
            <FormGroupLayout
              xl="7"
              rightRender={() => (
                <InputCheck
                  name="ignoreDeviceGpsMessages"
                  label={language.ignoreDeviceGpsMessages}
                />
              )}
            />

            <FormGroupLayout
              xl="7"
              label={language.min_distance_key}
              rightRender={() => (
                <ServiceInput
                  name="minDistanceForOverridePreciseInMeter"
                  placeholder={language.min_distance_key}
                />
              )}
            />

            <FormGroupLayout
              xl="7"
              rightRender={() => (
                <InputCheck
                  name="carryTrackers"
                  label={language.carry_trackers}
                />
              )}
            />

            <FormGroupLayout
              xl="7"
              label={language.carry_attach_distance}
              rightRender={() => (
                <ServiceInput
                  name="carryAttachDistance"
                  placeholder={language.carry_attach_distance}
                />
              )}
            />

            <FormGroupLayout
              xl="7"
              label={language.carry_breakup_distance}
              rightRender={() => (
                <ServiceInput
                  name="carryBreakupDistance"
                  placeholder={language.carry_breakup_distance}
                />
              )}
            />
          </Form>
        </Formik>
      )}
    </SntDialog>
  );
};

export default GpsLocationServiceConfig;
