const _bleSensorRoute = {
  page: "/ble_sensor_list",
  updatePage: "/ble_sensor_update",
  createPage: "",
  getPage: (data) => {
    return `/ble_sensor_list?org=${data.orgId}`;
  },
  getUpdatePage: (data) => {
    return `/ble_sensor_update?id=${data.id}&tabActive=${
      data.tabActive || ""
    }&type=${data.type || "view"}&org=${data.orgId}`;
  },
  getCreatePage: (data) => {
    if (data?.orgId) return `/ble_sensor_update?org=${data.orgId}`;
    return `/ble_sensor_update?`;
  },
};

export default _bleSensorRoute;
