import MonitoredAssetContainer from "@/cleanup/containers/formiks/MonitoredAssetContainer/MonitoredAssetContainer";
import SntPanel from "@/components/Panel/SntPanel";
import { FormFullScreenLayout } from "@/components/ReactBootstrap/FormValidation";

const MonitoredAssetPanel = ({ name, orgId, disabled, language }) => {
  return (
    <>
      <SntPanel title={language.monitored_assets_key}>
        <FormFullScreenLayout
          label={language.select_trackers_key}
          rightRender={() => (
            <MonitoredAssetContainer
              language={language}
              name={name}
              disabled={disabled}
              orgId={orgId}
            />
          )}
        />
      </SntPanel>
    </>
  );
};

export default MonitoredAssetPanel;
