import { useEffect } from "react";

// 3rd parties
import { useField } from "formik";

// owner
import SntRadio from "@/components/SntRadio/SntRadio";
import { VerticalLayout } from "@/components/ReactBootstrap/FormValidation";

/**
 *
 * @param {options} array list {value, label }
 * @returns
 */
const SntRadioField = ({ name, options, onChange, disabled }) => {
  // eslint-disable-next-line
  const [field, meta, helper] = useField(name);

  useEffect(() => {
    onChange && onChange(field.value);
    // eslint-disable-next-line
  }, [field.value]);

  const handleChange = (selectedValue) => {
    helper.setValue(selectedValue);
  };

  return (
    <VerticalLayout>
      {options.map((item, key) => {
        return (
          <SntRadio
            key={key}
            selectedValue={field.value}
            value={item.value}
            disabled={disabled}
            onChange={(e) => handleChange(item.value)}
            label={item.label}
          />
        );
      })}
    </VerticalLayout>
  );
};

export default SntRadioField;
