import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { useEffect, useState } from "react";
import {
  FormFullScreenLayout,
  InputText,
} from "../../components/ReactBootstrap/FormValidation";
import SntDialog from "../../components/SntDialog/SntDialog";

const AddNumericVariablePopup = ({
  selectedVariable,
  isShow,
  setIsShow,
  onSave,
}) => {
  const language = useSelector((state) => state.language);

  const [variable, setVariable] = useState(selectedVariable);

  useEffect(() => {
    setVariable(selectedVariable);
  }, [selectedVariable]);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^\w+$/, "invalid format")
      .required(language.valid_required_key),
    min: Yup.number().integer(),
    max: Yup.number().integer().moreThan(Yup.ref("min")),
    step: Yup.number().positive(),
  });

  return (
    <div>
      <>
        <SntDialog
          isShow={isShow}
          onSave={() => {
            onSave(variable);
            setIsShow(false);
          }}
          onClose={() => setIsShow(false)}
          saveTxt={language.save_key}
          title={language.add_variable}
          customModalClassname={"snt-config-column-modal"}
        >
          {() => (
            <Formik
              initialValues={selectedVariable}
              enableReinitialize
              validationSchema={validationSchema}
            >
              {() => (
                <Form className="form-horizontal">
                  <FormFullScreenLayout
                    label={language.name_key}
                    rightRender={() => (
                      <InputText
                        name="name"
                        placeholder={language.name_key}
                        isRequired={true}
                        handleChange={(value) =>
                          setVariable({ ...variable, name: value })
                        }
                      />
                    )}
                  />
                  <FormFullScreenLayout
                    label={language.min_key}
                    rightRender={() => (
                      <InputText
                        name="min"
                        type="number"
                        placeholder={language.min_key}
                        handleChange={(value) =>
                          setVariable({ ...variable, min: value })
                        }
                      />
                    )}
                  />
                  <FormFullScreenLayout
                    label={language.max_key}
                    rightRender={() => (
                      <InputText
                        name="max"
                        type="number"
                        placeholder={language.max_key}
                        handleChange={(value) =>
                          setVariable({ ...variable, max: value })
                        }
                      />
                    )}
                  />
                  <FormFullScreenLayout
                    label={language.step_key}
                    rightRender={() => (
                      <InputText
                        name="step"
                        type="number"
                        placeholder={language.step_key}
                        handleChange={(value) =>
                          setVariable({ ...variable, step: value })
                        }
                      />
                    )}
                  />
                </Form>
              )}
            </Formik>
          )}
        </SntDialog>
      </>
    </div>
  );
};

export default AddNumericVariablePopup;
