import SntSelect from "@/cleanup/wrappers/ReactSelect/SntSelect";
import SntDropdownListIcon from "@/components/Icons/SntDropdownListIcon";
import SntRadioListIcon from "@/components/Icons/SntRadioListIcon";
import SntInfoField from "@/components/SntInfo/SntInfoField";
import SntRadio from "@/components/SntRadio/SntRadio";
import GlobalUtils from "@/utils/GlobalUtils";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

const ProfileCategoryLayout = ({
  numericVariables,
  category,
  results,
  onChange,
}) => {
  const language = useSelector((state) => state.language);
  const [selectedCategory, setSelectedCategory] = useState({ options: [] });
  const [selectedOption, setSelectedOption] = useState({});

  const [isDropdown, setDropdown] = useState(true);

  useEffect(() => {
    setSelectedCategory(category);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    let opt =
      results.find((r) => r.name === selectedCategory.name)?.options[0] || {};
    opt = selectedCategory.options.find((o) => o.name === opt.name);
    if (!opt) {
      opt = selectedCategory.options.find((o) => o.isDefault);
    }
    setSelectedOption(opt || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results, selectedCategory]);

  const onSelectOptionChange = (option) => {
    let opt = selectedCategory.options.find((o) => o.name === option.value);
    setSelectedOption(opt);
    if (option.name !== "No select") {
      onChange(selectedCategory, opt);
    }
  };

  const getSelectedOptionForCategory = (category, option) => {
    let selectedCategory = results.find((r) => r.name === category.name);
    if (selectedCategory) {
      if (selectedCategory.options[0].name === option.name)
        return selectedCategory.options[0].name;
    } else {
      return option.isDefault ? option.name : "";
    }
  };

  const isExpression = (value) => {
    return value && (value + "").includes("$");
  };

  const renderNumericVariableCategory = (category) => {
    let definedOpt = category.options[0];
    let value = definedOpt.deviceFwParameterSettings[0].value;
    if (isExpression(value)) {
      value = value.substring(value.indexOf("${") + 2, value.indexOf("}"));
    }
    let variable = numericVariables.find((v) => v.name === value);
    if (variable) {
      let opts = [];
      for (let i = variable.min; i < variable.max; i = i + variable.step) {
        let opt = {
          name: i,
          description: definedOpt.description,
          deviceFwParameterSettings: [
            {
              fwParameterKey:
                definedOpt.deviceFwParameterSettings[0].fwParameterKey,
              value: i,
            },
          ],
          isDefault: definedOpt.isDefault,
          profileServiceUpdates: definedOpt.profileServiceUpdates,
        };
        opts.push(opt);
      }
      opts.unshift({ ...opts[0], name: "No select" });
      setSelectedOption(opts[0]);
      category.options = opts;
    }
    return (
      <Row className="mb-2">
        <Col xs="7" lg="4">
          <SntSelect
            value={{
              label: selectedOption.uiLabel,
              value: selectedOption.name,
            }}
            onChange={(item) => {
              onSelectOptionChange(item);
            }}
            options={category.options.map((option) => {
              return { label: option.uiLabel, value: option.name };
            })}
          />
        </Col>
        <Col xs="12" lg="6">
          {selectedOption.description && (
            <SntInfoField>{selectedOption.description}</SntInfoField>
          )}
        </Col>
      </Row>
    );
  };
  return (
    <>
      <Row>
        <Form.Label>
          {selectedCategory.uiLabel}
          {selectedCategory.visualization !== "NUMERIC_VALUE" && (
            <span
              className="ms-3"
              role="button"
              onClick={() => {
                setDropdown(!isDropdown);
              }}
            >
              {isDropdown ? <SntRadioListIcon /> : <SntDropdownListIcon />}
            </span>
          )}
        </Form.Label>
      </Row>
      <Row className="mb-2">
        <Col>{selectedCategory.description}</Col>
      </Row>

      {selectedCategory.visualization === "NUMERIC_VALUE" ? (
        renderNumericVariableCategory(selectedCategory)
      ) : isDropdown ? (
        <Row className="mb-2">
          <Col xs="7" lg="4">
            <SntSelect
              value={{
                label: selectedOption.uiLabel,
                value: selectedOption.name,
              }}
              onChange={(item) => {
                onSelectOptionChange(item);
              }}
              options={selectedCategory.options.map((option) => {
                return { label: option.uiLabel, value: option.name };
              })}
            />
          </Col>
          <Col xs="5" lg="2">
            <span>
              {GlobalUtils.renderBatteryImpact(
                selectedOption,
                selectedOption.batteryImpact?.name
                  ? selectedOption.batteryImpact.name
                  : selectedOption.batteryImpact
              )}
            </span>
          </Col>
          <Col xs="12" lg="6">
            {selectedOption.description && (
              <SntInfoField>{selectedOption.description}</SntInfoField>
            )}
          </Col>
        </Row>
      ) : (
        <Row className="mb-2">
          {selectedCategory.options.map((option, idx) => {
            return (
              <div key={idx}>
                <Row className="mb-2">
                  <Col xs="7" lg="4">
                    <SntRadio
                      value={option.name}
                      selectedValue={getSelectedOptionForCategory(
                        selectedCategory,
                        option
                      )}
                      onChange={() => onChange(selectedCategory, option)}
                      label={
                        <>
                          {option.isDefault
                            ? option.uiLabel + " (" + language.default_key + ")"
                            : option.uiLabel}
                        </>
                      }
                    />
                  </Col>
                  <Col xs="5" lg="2">
                    <span>
                      {GlobalUtils.renderBatteryImpact(
                        option,
                        option.batteryImpact.name
                          ? option.batteryImpact.name
                          : option.batteryImpact
                      )}
                    </span>
                  </Col>
                  <Col xs="12" lg="6">
                    {option.description && (
                      <SntInfoField>{option.description}</SntInfoField>
                    )}
                  </Col>
                </Row>
              </div>
            );
          })}{" "}
        </Row>
      )}
      <hr className="m-0 mb-2" />
    </>
  );
};

export default ProfileCategoryLayout;
