import { FormFullScreenLayout } from "@/components/ReactBootstrap/FormValidation";
import SntColorField from "@widgets/formiks/SntColorField";

const DecoratorActionControl = ({ name, disabled, language }) => {
  return (
    <>
      <FormFullScreenLayout
        label={language.select_decorator_key}
        rightRender={() => (
          <SntColorField
            name={`${name}.decorator`}
            language={language}
            disabled={disabled}
            isHideResetAction={true}
          />
        )}
      />
    </>
  );
};

export default DecoratorActionControl;
