import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import sntServiceClient from "@apis/sntServiceClient.js";
import { FormGroupLayout } from "../../../components/ReactBootstrap/FormValidation/index.js";
import SntDialog from "../../../components/SntDialog/SntDialog.js";
import SntTooltip from "../../../components/Tooltip/SntTooltip.js";
import { SensolusDatetimeReact } from "../../../constants/Config.js";
import moment from "../../../constants/SensolusMoment.js";
import ServiceInput from "../Utils/ServiceInput.js";

const TimeSyncServiceConfig = ({
  device,
  isShow,
  setIsShow,
  selectedService,
  onSave,
}) => {
  const language = useSelector((state) => state.language);
  const formikRefs = useRef();
  const [service, setService] = useState({
    type: "TIMESYNC",
    active: true,
    configuration: {
      updateFrequencyInHours: "",
      nextTimeSync: "",
      timeSyncPending: false,
    },
  });

  useEffect(() => {
    if (selectedService && isShow) {
      let config = {};
      config.updateFrequencyInHours = selectedService.configuration
        ? selectedService.configuration.updateFrequencyInHours
        : "";
      config.timeSyncPending = selectedService.timeSyncPending;
      if (config.timeSyncPending) {
        config.nextTimeSync = SensolusDatetimeReact.format(
          moment(selectedService.lastTimeSync).add(
            config.updateFrequencyInHours,
            "h"
          ),
          "server"
        );
      } else {
        config.nextTimeSync = SensolusDatetimeReact.format(moment(), "server");
      }
      setService({ ...service, configuration: { ...config } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService, isShow]);

  function onServiceSave() {
    let data = {
      ...service,
      configuration: {
        updateFrequencyInHours:
          formikRefs.current.values.updateFrequencyInHours,
      },
    };

    validationSchema.isValid(data.configuration).then(function (valid) {
      if (valid) {
        onSave(data);
        setIsShow(false);
      }
    });
  }

  function onDisengageClicked() {
    sntServiceClient
      .disengageTimeSync(device.id)
      .then(() => {
        service.configuration.nextTimeSync = SensolusDatetimeReact.format(
          moment(),
          "server"
        );
        setService({ ...service });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const validationSchema = Yup.object().shape({
    updateFrequencyInHours: Yup.number().min(0).label("Time"),
  });

  return (
    <SntDialog
      isShow={isShow}
      onSave={() => onServiceSave(service)}
      onClose={() => setIsShow(false)}
      saveTxt={language.save_key}
      title={language.timesync_service_name}
    >
      {() => (
        <Formik
          initialValues={service.configuration}
          innerRef={formikRefs}
          enableReinitialize={true}
          validationSchema={validationSchema}
        >
          <Form className="form-horizontal">
            <FormGroupLayout
              xl="5"
              label={language.timesync_update_frequency_in_hours_label}
              rightRender={() => (
                <ServiceInput
                  name="updateFrequencyInHours"
                  placeholder={
                    language.timesync_update_frequency_in_hours_label
                  }
                  inputType={"number"}
                  tooltip={
                    language.timesync_update_frequency_in_hours_description
                  }
                  min={0}
                />
              )}
            />

            {device && (
              <>
                <FormGroupLayout
                  xl="5"
                  label={language.timesync_next_at_label}
                  rightRender={() => (
                    <ServiceInput
                      name="nextTimeSync"
                      tooltip={language.timesync_next_at_description}
                      readOnly={true}
                    />
                  )}
                />

                <FormGroupLayout
                  xl="5"
                  label={language.timesync_pending_key}
                  rightRender={() => (
                    <ServiceInput
                      name="timeSyncPending"
                      tooltip={language.timesync_pending_description_key}
                      readOnly={true}
                    />
                  )}
                />

                <FormGroupLayout
                  xl="5"
                  rightRender={() => (
                    <div className="align-self-center">
                      <Button
                        variant="sensolus"
                        onClick={(e) => {
                          e.preventDefault();
                          onDisengageClicked();
                        }}
                      >
                        {language.timesync_disengage_button}
                      </Button>
                      <span className="ms-2">
                        <SntTooltip
                          text={language.timesync_disengage_description}
                        />
                      </span>
                    </div>
                  )}
                />
              </>
            )}
          </Form>
        </Formik>
      )}
    </SntDialog>
  );
};

export default TimeSyncServiceConfig;
