import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { Button, Col, Row, Table } from "react-bootstrap";
import Select, { createFilter } from "react-select";
import * as Yup from "yup";
import { Form, Formik } from "formik";

import ErrorLabel from "@/components/ReactBootstrap/FormValidation/ErrorLabel.js";
import {
  FormGroupLayout,
  ItemSelector,
  VerticalLayout,
} from "@/components/ReactBootstrap/FormValidation/index.js";
import SntInfoPanel from "@/components/SntInfo/SntInfoPanel.js";
import SntCheckBox from "@/components/SntCheckBox/SntCheckBox.js";
import SntDialog from "@/components/SntDialog/SntDialog.js";
import SntRadio from "@/components/SntRadio/SntRadio.js";
import config from "@/constants/Config.js";

import OrientationStateService from "./OrientationStateService.js";
import ScriptInput from "../Utils/ScriptInput.js";
import ServiceConfigPanel from "../Utils/ServiceConfigPanel.js";
import ServiceInput from "../Utils/ServiceInput.js";
import SntDeleteIcon from "@icons/SntDeleteIcon.js";

import imageLibraryClient, { IMAGE_TYPE } from "@apis/imageLibraryClient.js";
import orientationState from "@apis/orientationState.js";
import { useOrg } from "@/contexts/OrgContext.js";

const OrientationStateServiceConfig = ({
  isShow,
  setIsShow,
  selectedService,
  onSave,
}) => {
  const language = useSelector((state) => state.language);
  const formikRefs = useRef();
  const loginInfo = useSelector((state) => state.user);
  const { orgId } = useOrg();

  const [testData, setTestData] = useState();

  const [images, setImages] = useState([]);
  const [error, setError] = useState("");

  const states = [
    { value: "top_up", label: language.state_top_up_key },
    { value: "bottom_up", label: language.state_bottom_up_key },
    { value: "left_up", label: language.state_left_up_key },
    { value: "right_up", label: language.state_right_up_key },
    { value: "back_up", label: language.state_back_up_key },
    { value: "front_up", label: language.state_front_up_key },
    { value: "unknown", label: language.unknown_key },
    { value: "state1", label: language.state1_key },
    { value: "state2", label: language.state2_key },
  ];

  const [service, setService] = useState({
    type: "ORIENTATION_STATE",
    active: true,
    configuration: {
      showYaw: false,
      showRoll: false,
      showPitch: false,
      allowCustomMapping: false,
      script: "",
      name: "",
      description: "",
      customStates: [],
      state: states[0],
    },
  });

  useEffect(() => {
    imageLibraryClient
      .getByVisibility({
        orgId: orgId,
        imageLibraryType: IMAGE_TYPE.ORIENTATION,
      })
      .then(({ data }) => {
        let images = [];
        for (var i = 0; i < data.length; i++) {
          var full = data[i];
          var _system = "";
          if (loginInfo.organisation.organisationType === "SYSTEM") {
            if (full.organisation.organisationType === "SYSTEM") {
              _system = " (SYSTEM)";
            } else {
              _system = " (" + full.organisation.name + ")";
            }
          } else if (full.organisation.organisationType === "SYSTEM") {
            _system = " (SYSTEM)";
          }
          images.push({
            value: full.pathName,
            label: (
              <span>
                <img
                  className="me-2"
                  alt={full.name}
                  src={config.API_URL + full.pathName}
                  width="24px"
                  height="24px"
                />{" "}
                {full.name + "." + full.type + _system}{" "}
              </span>
            ),
            name: full.name,
          });
        }
        setImages(images);
      })
      .then((error) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [type, setType] = useState();

  useEffect(() => {
    if (selectedService && isShow) {
      let configuration = {
        ...service.configuration,
        ...selectedService.configuration,
      };
      setService({ ...service, configuration: configuration });
      setType(
        selectedService.configuration &&
          selectedService.configuration.allowCustomMapping
          ? "custom"
          : "default"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService, isShow]);

  function onServiceSave() {
    let configuration = { ...getFormData() };
    let data = {};
    if (type === "custom") {
      data = {
        showYaw: configuration.showYaw,
        showRoll: configuration.showRoll,
        showPitch: configuration.showPitch,
        allowCustomMapping: true,
        script: configuration.script,
        name: configuration.name,
        description: configuration.description,
        customStates: configuration.customStates,
      };
    } else {
      data = {
        showYaw: configuration.showYaw,
        showRoll: configuration.showRoll,
        showPitch: configuration.showPitch,
        allowCustomMapping: false,
        script: "",
        name: "",
        description: "",
        customStates: [],
      };
    }
    onSave({ ...service, configuration: data });
    setIsShow(false);
  }

  function onDecomposedConfigChange(showType) {
    let config = { ...getFormData() };
    config[showType] = !config[showType];
    setService({ ...service, configuration: config });
  }

  function renderCustomStates() {
    if (!service.configuration.customStates) return;
    let customStates = service.configuration.customStates;

    return customStates.map((state, idx) => (
      <tr key={idx}>
        <td style={{ width: "25%" }}>
          <input
            name="key"
            type="text"
            className="form-control"
            value={state.key}
            onChange={(el) => onCustomKeyChanged(el, idx)}
          />
        </td>
        <td style={{ width: "25%" }}>
          <input
            name="name"
            type="text"
            className="form-control"
            value={state.name}
            onChange={(el) => onCustomNameChanged(el, idx)}
          />
        </td>
        <td style={{ width: "40%" }}>
          <Select
            name="image"
            options={images}
            filterOption={createFilter({
              matchFrom: "any",
              stringify: (option) => `${option.data?.name}`,
            })}
            onChange={(el) => onImageChanged(el, idx)}
            value={images.filter((i) => i.value === state.image) || images[0]}
            menuPlacement="auto"
          />
        </td>
        <td style={{ width: "10%" }}>
          <span
            className="input-group-addon"
            style={{ padding: "0px", paddingLeft: "5px" }}
          >
            <button
              type="button"
              onClick={removeCustomStateClicked.bind(idx)}
              className="btn removeItemButton"
              title={language.delete_key}
            >
              <SntDeleteIcon />
            </button>
          </span>
        </td>
      </tr>
    ));
  }

  function onCustomKeyChanged(el, idx) {
    let states = [...service.configuration.customStates];
    states[idx] = { ...states[idx], key: el.target.value };
    let configuration = { ...getFormData(), customStates: states };
    setService({ ...service, configuration: configuration });
  }

  function onCustomNameChanged(el, idx) {
    let states = [...service.configuration.customStates];
    states[idx] = { ...states[idx], name: el.target.value };
    let configuration = { ...getFormData(), customStates: states };
    setService({ ...service, configuration: configuration });
  }

  function onImageChanged(el, idx) {
    let states = [...service.configuration.customStates];
    states[idx] = { ...states[idx], image: el.value };
    let configuration = { ...getFormData(), customStates: states };
    setService({ ...service, configuration: configuration });
  }

  function addCustomStateClicked(el) {
    let states = [
      ...service.configuration.customStates,
      { key: "", name: "", image: "" },
    ];
    let configuration = { ...getFormData(), customStates: states };
    setService({ ...service, configuration: configuration });
    el.preventDefault();
  }

  function removeCustomStateClicked() {
    let states = [...service.configuration.customStates];
    states.splice(this, 1);
    let configuration = { ...getFormData(), customStates: states };
    setService({ ...service, configuration: configuration });
  }

  function getFormData() {
    let params = formikRefs.current.values;
    let configuration = {
      allowCustomMapping: type === "custom",
      script: params.script,
      name: params.name,
      description: params.description,
      state: states.filter((s) => s.value === params.state.value),
      yaw: params.yaw,
      roll: params.roll,
      pitch: params.pitch,
    };
    return { ...service.configuration, ...configuration };
  }

  function testButtonClicked() {
    let params = formikRefs.current.values;
    var testData = {
      orientation: params.state.value,
      yaw: params.yaw,
      roll: params.roll,
      pitch: params.pitch,
      script: params.script,
    };

    validationSchema.isValid(testData).then(function (valid) {
      if (valid) {
        orientationState
          .test(testData)
          .then(({ data }) => {
            let configuration = {
              showRoll: params.showRoll,
              showYaw: params.showYaw,
              showPitch: params.showPitch,
              allowCustomMapping: type === "custom",
              customStates: params.customStates,
              name: params.name,
            };
            setTestData({
              name: params.name,
              currentOrientation: data.result || "unknown",
              currentYaw: params.yaw,
              currentRoll: params.roll,
              currentPitch: params.pitch,
              configuration: configuration,
            });
          })
          .catch((error) => {
            setError(error.response.data.message);
          });
      }
    });
  }

  const validationSchema = Yup.object({
    yaw: Yup.number().max(180).min(-180).label("Yaw"),
    roll: Yup.number().max(180).min(-180).label("Roll"),
    pitch: Yup.number().max(180).min(-180).label("Pitch"),
  });

  return (
    <SntDialog
      isShow={isShow}
      onSave={() => onServiceSave(service)}
      onClose={() => setIsShow(false)}
      saveTxt={language.save_key}
      title={language.v40_define_services_to_enable_disable_key}
    >
      {() => (
        <div>
          <Formik
            initialValues={service.configuration}
            innerRef={formikRefs}
            enableReinitialize={true}
            validationSchema={validationSchema}
          >
            <Form className="form-horizontal">
              <ServiceConfigPanel title={language.orientation_states_key}>
                {() => (
                  <VerticalLayout>
                    <SntRadio
                      value="default"
                      selectedValue={type}
                      onChange={() => setType("default")}
                      label={language.default_orientation_states_key}
                    />
                    <SntRadio
                      value="custom"
                      selectedValue={type}
                      onChange={() => setType("custom")}
                      label={language.custom_orientation_states_key}
                    />
                  </VerticalLayout>
                )}
              </ServiceConfigPanel>

              {type === "custom" && (
                <ServiceConfigPanel title={language.orientation_name_key}>
                  {() => (
                    <>
                      <FormGroupLayout
                        label={language.name_key}
                        rightRender={() => (
                          <ServiceInput
                            name="name"
                            placeholder={language.name_key}
                            tooltip={language.orientation_name_tooltip_key}
                          />
                        )}
                      />
                      <FormGroupLayout
                        label={language.description_key}
                        rightRender={() => (
                          <ServiceInput
                            name="description"
                            placeholder={language.description_key}
                            tooltip={
                              language.orientation_description_tooltip_key
                            }
                          />
                        )}
                      />
                    </>
                  )}
                </ServiceConfigPanel>
              )}

              {type === "custom" && (
                <ServiceConfigPanel
                  title={language.definition_orientation_states_key}
                  tooltip={
                    language.definition_orientation_states_description_key
                  }
                >
                  {() => (
                    <div>
                      <Table>
                        <thead>
                          <tr>
                            <th>{language.key_key}</th>
                            <th>{language.name_key}</th>
                            <th>{language.image_key}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody className="custom-state-container">
                          {renderCustomStates()}
                        </tbody>
                      </Table>
                      <Button
                        id="add-other-state-btn"
                        variant="sensolus-greylight"
                        onClick={addCustomStateClicked}
                      >
                        {language.add_other_state_key}
                      </Button>
                    </div>
                  )}
                </ServiceConfigPanel>
              )}

              {type === "custom" && (
                <ServiceConfigPanel title={language.script_key}>
                  {() => (
                    <Row>
                      <SntInfoPanel>
                        {language.connect_orientations_key}
                      </SntInfoPanel>

                      <ScriptInput
                        name="script"
                        rows={5}
                        value={service.configuration.script}
                      >
                        {" "}
                      </ScriptInput>
                    </Row>
                  )}
                </ServiceConfigPanel>
              )}

              <ServiceConfigPanel
                title={language.show_decomposed_orientations_key}
              >
                {() => (
                  <VerticalLayout>
                    <SntCheckBox
                      checked={service.configuration.showYaw}
                      disabled={false}
                      onChange={() => {
                        onDecomposedConfigChange("showYaw");
                      }}
                      label={language.show_yaw_key}
                    />

                    <SntCheckBox
                      checked={service.configuration.showRoll}
                      disabled={false}
                      onChange={() => {
                        onDecomposedConfigChange("showRoll");
                      }}
                      label={language.show_roll_key}
                    />

                    <SntCheckBox
                      checked={service.configuration.showPitch}
                      disabled={false}
                      onChange={() => {
                        onDecomposedConfigChange("showPitch");
                      }}
                      label={language.show_pitch_key}
                    />
                  </VerticalLayout>
                )}
              </ServiceConfigPanel>

              {type === "custom" && (
                <ServiceConfigPanel title={language.test_key}>
                  {() => (
                    <Row>
                      <Col md="5" xs="12">
                        <FormGroupLayout
                          xl="6"
                          label={language.orientation_key}
                          rightRender={() => (
                            <ItemSelector
                              name="state"
                              placeholder={language.orientation_key}
                              options={states}
                            />
                          )}
                        />

                        <FormGroupLayout
                          xl="6"
                          label={language.yaw_key}
                          rightRender={() => (
                            <ServiceInput
                              name="yaw"
                              inputType={"number"}
                              placeholder={language.yaw_key}
                              min={-180}
                              max={180}
                              step={1}
                            />
                          )}
                        />
                        <FormGroupLayout
                          xl="6"
                          label={language.roll_key}
                          rightRender={() => (
                            <ServiceInput
                              name="roll"
                              inputType={"number"}
                              placeholder={language.roll_key}
                              min={-180}
                              max={180}
                            />
                          )}
                        />
                        <FormGroupLayout
                          xl="6"
                          label={language.pitch_key}
                          rightRender={() => (
                            <ServiceInput
                              name="pitch"
                              inputType={"number"}
                              placeholder={language.pitch_key}
                              min={-180}
                              max={180}
                            />
                          )}
                        />

                        <FormGroupLayout
                          xl="6"
                          label={language.pitch_key}
                          rightRender={() => (
                            <>
                              <Button
                                onClick={testButtonClicked}
                                variant="sensolus"
                              >
                                {language.test_key}
                              </Button>
                              <ErrorLabel error={error} />
                            </>
                          )}
                        />
                      </Col>

                      <Col md="7" xs="12">
                        <OrientationStateService
                          testData={testData}
                        ></OrientationStateService>
                      </Col>
                    </Row>
                  )}
                </ServiceConfigPanel>
              )}
            </Form>
          </Formik>
        </div>
      )}
    </SntDialog>
  );
};

export default OrientationStateServiceConfig;
