import SntIcon from "./SntIcon";

const SntIntegerSelectIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 13H16V15H6V13ZM6 7H16V9H6V7ZM6 1H16V3H6V1ZM3 0V4H2V1H1V0H3ZM2 8.219V9H4V10H1V7.719L3 6.781V6H1V5H4V7.281L2 8.219ZM4 11V16H1V15H3V14H1V13H3V12H1V11H4Z"
      ></path>
    </SntIcon>
  );
};

export default SntIntegerSelectIcon;
