import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import SntDialog from "../../../components/SntDialog/SntDialog.js";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import ServiceConfigPanel from "../Utils/ServiceConfigPanel.js";
import DiagnosticServiceInput from "./DiagnosticServiceInput.js";
import diagnosticClient from "@apis/diagnosticClient.js";

const DiagnosticServiceConfig = ({
  isShow,
  setIsShow,
  selectedService,
  onSave,
}) => {
  const language = useSelector((state) => state.language);
  const formikRefs = useRef();
  const [mapScalarDefinition, setMapScalarDefinition] = useState(null);
  const [service, setService] = useState({
    type: "DIAGNOSTIC",
    active: true,
    configuration: {
      scalarToWarningValue: {
        messageCompleteness: {
          minor: null,
          major: null,
        },
        networkQuality: {
          minor: null,
          major: null,
        },
        liveness: {
          minor: null,
          major: null,
        },
        gpsFixResult: {
          minor: null,
          major: null,
        },
        sensorCompletenessRatio: {
          minor: null,
          major: null,
        },
        journeyCompletenessRatio: {
          minor: null,
          major: null,
        },
        wifiResult: {
          minor: null,
          major: null,
        },
        shortStopRatio: {
          minor: null,
          major: null,
        },
        totalWithoutThrottlingEvent: {
          minor: null,
          major: null,
        },
        hoursWithoutOfflineRatio: {
          minor: null,
          major: null,
        },
        averageBatteryConsumptionInADay: {
          minor: null,
          major: null,
        },
        averageNumberOfSendMessageInADay: {
          minor: null,
          major: null,
        },
        averageNumberOfRebootsADay: {
          minor: null,
          major: null,
        },
      },
    },
  });
  const [values, setValues] = useState({});

  useEffect(() => {
    if (selectedService && isShow) {
      let scalarToWarningValue =
        selectedService.configuration &&
        Object.keys(selectedService.configuration).length
          ? selectedService.configuration.scalarToWarningValue
          : service.configuration.scalarToWarningValue;
      let config = {
        scalarToWarningValue: {
          ...service.configuration.scalarToWarningValue,
          ...scalarToWarningValue,
        },
      };
      setService({ ...service, configuration: config });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService, isShow]);

  useEffect(() => {
    diagnosticClient.getScalarDefinition().then(
      (res) => {
        let data = res.data;
        setMapScalarDefinition(data);
      },
      (err) => {}
    );
  }, []);

  function onServiceSave() {
    onSave({
      ...service,
      configuration: {
        scalarToWarningValue: {
          messageCompleteness: {
            minor:
              values.messageCompleteness.minor !== ""
                ? values.messageCompleteness.minor
                : null,
            major:
              values.messageCompleteness.major !== ""
                ? values.messageCompleteness.major
                : null,
          },
          networkQuality: {
            minor:
              values.networkQuality.minor !== ""
                ? values.networkQuality.minor
                : null,
            major:
              values.networkQuality.major !== ""
                ? values.networkQuality.major
                : null,
          },
          liveness: {
            minor: values.liveness.minor !== "" ? values.liveness.minor : null,
            major: values.liveness.major !== "" ? values.liveness.major : null,
          },
          gpsFixResult: {
            minor:
              values.gpsFixResult.minor !== ""
                ? values.gpsFixResult.minor
                : null,
            major:
              values.gpsFixResult.major !== ""
                ? values.gpsFixResult.major
                : null,
          },
          sensorCompletenessRatio: {
            minor:
              values.sensorCompletenessRatio.minor !== ""
                ? values.sensorCompletenessRatio.minor
                : null,
            major:
              values.sensorCompletenessRatio.major !== ""
                ? values.sensorCompletenessRatio.major
                : null,
          },
          journeyCompletenessRatio: {
            minor:
              values.journeyCompletenessRatio.minor !== ""
                ? values.journeyCompletenessRatio.minor
                : null,
            major:
              values.journeyCompletenessRatio.major !== ""
                ? values.journeyCompletenessRatio.major
                : null,
          },
          wifiResult: {
            minor:
              values.wifiResult.minor !== "" ? values.wifiResult.minor : null,
            major:
              values.wifiResult.major !== "" ? values.wifiResult.major : null,
          },
          shortStopRatio: {
            minor:
              values.shortStopRatio.minor !== ""
                ? values.shortStopRatio.minor
                : null,
            major:
              values.shortStopRatio.major !== ""
                ? values.shortStopRatio.major
                : null,
          },
          totalWithoutThrottlingEvent: {
            minor:
              values.totalWithoutThrottlingEvent.minor !== ""
                ? values.totalWithoutThrottlingEvent.minor
                : null,
            major:
              values.totalWithoutThrottlingEvent.major !== ""
                ? values.totalWithoutThrottlingEvent.major
                : null,
          },
          hoursWithoutOfflineRatio: {
            minor:
              values.hoursWithoutOfflineRatio.minor !== ""
                ? values.hoursWithoutOfflineRatio.minor
                : null,
            major:
              values.hoursWithoutOfflineRatio.major !== ""
                ? values.hoursWithoutOfflineRatio.major
                : null,
          },
          averageBatteryConsumptionInADay: {
            minor:
              values.averageBatteryConsumptionInADay.minor !== ""
                ? values.averageBatteryConsumptionInADay.minor
                : null,
            major:
              values.averageBatteryConsumptionInADay.major !== ""
                ? values.averageBatteryConsumptionInADay.major
                : null,
          },
          averageNumberOfSendMessageInADay: {
            minor:
              values.averageNumberOfSendMessageInADay.minor !== ""
                ? values.averageNumberOfSendMessageInADay.minor
                : null,
            major:
              values.averageNumberOfSendMessageInADay.major !== ""
                ? values.averageNumberOfSendMessageInADay.major
                : null,
          },
          averageNumberOfRebootsADay: {
            minor:
              values.averageNumberOfRebootsADay.minor !== ""
                ? values.averageNumberOfRebootsADay.minor
                : null,
            major:
              values.averageNumberOfRebootsADay.major !== ""
                ? values.averageNumberOfRebootsADay.major
                : null,
          },
        },
      },
    });
    setIsShow(false);
  }

  const validationSchema = Yup.object().shape({
    //TODO
  });

  return (
    <SntDialog
      isShow={isShow}
      onSave={() => onServiceSave(service)}
      onClose={() => setIsShow(false)}
      saveTxt={language.save_key}
      title={language.v40_define_services_to_enable_disable_key}
    >
      {() => (
        <ServiceConfigPanel title={language.diagnostic_configuration_key}>
          {() => (
            <Formik
              initialValues={service.configuration.scalarToWarningValue}
              innerRef={formikRefs}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {({ values, setFieldValue }) => {
                setTimeout(() => {
                  setValues(values);
                }, 0);
                return (
                  <Form className="form-horizontal">
                    <DiagnosticServiceInput
                      name="messageCompleteness"
                      minorName="messageCompletenessMinor"
                      majorName="messageCompletenessMajor"
                      label={language.message_transmission_success}
                      onChange={(e) => {
                        setFieldValue("messageCompleteness", e);
                      }}
                      tooltip={
                        language.message_transmission_success_description_key
                      }
                      isHightIsBetter={
                        mapScalarDefinition &&
                        mapScalarDefinition.messageCompleteness
                          ? mapScalarDefinition.messageCompleteness
                              .higherIsBetter
                          : true
                      }
                    />
                    <DiagnosticServiceInput
                      name="networkQuality"
                      minorName="networkQualityMinor"
                      majorName="networkQualityMajor"
                      label={language.network_quality_key}
                      onChange={(e) => {
                        setFieldValue("networkQuality", e);
                      }}
                      tooltip={language.network_quality_description_key}
                      isHightIsBetter={
                        mapScalarDefinition &&
                        mapScalarDefinition.networkQuality
                          ? mapScalarDefinition.networkQuality.higherIsBetter
                          : true
                      }
                    />
                    <DiagnosticServiceInput
                      name="liveness"
                      minorName="livenessMinor"
                      majorName="livenessMajor"
                      label={language.liveness_key}
                      onChange={(e) => {
                        setFieldValue("liveness", e);
                      }}
                      tooltip={language.liveness_description_key}
                      isHightIsBetter={
                        mapScalarDefinition && mapScalarDefinition.liveness
                          ? mapScalarDefinition.liveness.higherIsBetter
                          : true
                      }
                    />
                    <DiagnosticServiceInput
                      name="gpsFixResult"
                      minorName="gpsFixResultMinor"
                      majorName="gpsFixResultMajor"
                      label={language.gps_fix_success_key}
                      onChange={(e) => {
                        setFieldValue("gpsFixResult", e);
                      }}
                      tooltip={language.gps_fix_success_description_key}
                      isHightIsBetter={
                        mapScalarDefinition && mapScalarDefinition.gpsFixResult
                          ? mapScalarDefinition.gpsFixResult.higherIsBetter
                          : true
                      }
                    />
                    <DiagnosticServiceInput
                      name="sensorCompletenessRatio"
                      minorName="sensorCompletenessRatioMinor"
                      majorName="sensorCompletenessRatioMajor"
                      label={language.sensor_data_completeness_key}
                      onChange={(e) => {
                        setFieldValue("sensorCompletenessRatio", e);
                      }}
                      tooltip={
                        language.sensor_data_completeness_description_key
                      }
                      isHightIsBetter={
                        mapScalarDefinition &&
                        mapScalarDefinition.sensorCompletenessRatio
                          ? mapScalarDefinition.sensorCompletenessRatio
                              .higherIsBetter
                          : true
                      }
                    />
                    <DiagnosticServiceInput
                      name="journeyCompletenessRatio"
                      minorName="journeyCompletenessRatioMinor"
                      majorName="journeyCompletenessRatioMajor"
                      label={language.journey_completeness_ratio_key}
                      onChange={(e) => {
                        setFieldValue("journeyCompletenessRatio", e);
                      }}
                      tooltip={
                        language.journey_completeness_ratio_description_key
                      }
                      isHightIsBetter={
                        mapScalarDefinition &&
                        mapScalarDefinition.journeyCompletenessRatio
                          ? mapScalarDefinition.journeyCompletenessRatio
                              .higherIsBetter
                          : true
                      }
                    />
                    <DiagnosticServiceInput
                      name="wifiResult"
                      minorName="wifiResultMinor"
                      majorName="wifiResultMajor"
                      label={language.wifi_resolution_success_key}
                      onChange={(e) => {
                        setFieldValue("wifiResult", e);
                      }}
                      tooltip={language.wifi_resolution_success_description_key}
                      isHightIsBetter={
                        mapScalarDefinition && mapScalarDefinition.wifiResult
                          ? mapScalarDefinition.wifiResult.higherIsBetter
                          : true
                      }
                    />
                    <DiagnosticServiceInput
                      name="totalWithoutThrottlingEvent"
                      minorName="totalWithoutThrottlingEventMinor"
                      majorName="totalWithoutThrottlingEventMajor"
                      label={language.days_without_throttling_ratio_key}
                      onChange={(e) => {
                        setFieldValue("totalWithoutThrottlingEvent", e);
                      }}
                      tooltip={
                        language.days_without_throttling_ratio_description_key
                      }
                      isHightIsBetter={
                        mapScalarDefinition &&
                        mapScalarDefinition.totalWithoutThrottlingEvent
                          ? mapScalarDefinition.totalWithoutThrottlingEvent
                              .higherIsBetter
                          : true
                      }
                    />
                    <DiagnosticServiceInput
                      name="hoursWithoutOfflineRatio"
                      minorName="hoursWithoutOfflineRatioMinor"
                      majorName="hoursWithoutOfflineRatioMajor"
                      label={language.time_online_key}
                      onChange={(e) => {
                        setFieldValue("hoursWithoutOfflineRatio", e);
                      }}
                      tooltip={language.time_online_description_key}
                      isHightIsBetter={
                        mapScalarDefinition &&
                        mapScalarDefinition.hoursWithoutOfflineRatio
                          ? mapScalarDefinition.hoursWithoutOfflineRatio
                              .higherIsBetter
                          : true
                      }
                    />
                    <DiagnosticServiceInput
                      name="shortStopRatio"
                      minorName="shortStopRatioMinor"
                      majorName="shortStopRatioMajor"
                      label={language.short_stop_key}
                      onChange={(e) => {
                        setFieldValue("shortStopRatio", e);
                      }}
                      tooltip={language.short_stop_description_key}
                      isHightIsBetter={
                        mapScalarDefinition &&
                        mapScalarDefinition.shortStopRatio
                          ? mapScalarDefinition.shortStopRatio.higherIsBetter
                          : false
                      }
                    />
                    <DiagnosticServiceInput
                      name="averageBatteryConsumptionInADay"
                      minorName="averageBatteryConsumptionInADayMinor"
                      majorName="averageBatteryConsumptionInADayMajor"
                      label={language.average_battery_consumption_in_a_day_key}
                      onChange={(e) => {
                        setFieldValue("averageBatteryConsumptionInADay", e);
                      }}
                      tooltip={
                        language.average_battery_consumption_in_a_day_description_key
                      }
                      isHightIsBetter={
                        mapScalarDefinition &&
                        mapScalarDefinition.averageBatteryConsumptionInADay
                          ? mapScalarDefinition.averageBatteryConsumptionInADay
                              .higherIsBetter
                          : false
                      }
                    />
                    <DiagnosticServiceInput
                      name="averageNumberOfSendMessageInADay"
                      minorName="averageNumberOfSendMessageInADayMinor"
                      majorName="averageNumberOfSendMessageInADayMajor"
                      label={language.average_number_of_send_message_day_key}
                      onChange={(e) => {
                        setFieldValue("averageNumberOfSendMessageInADay", e);
                      }}
                      tooltip={
                        language.average_number_of_send_message_day_description_key
                      }
                      isHightIsBetter={
                        mapScalarDefinition &&
                        mapScalarDefinition.averageNumberOfSendMessageInADay
                          ? mapScalarDefinition.averageNumberOfSendMessageInADay
                              .higherIsBetter
                          : false
                      }
                    />
                    <DiagnosticServiceInput
                      name="averageNumberOfRebootsADay"
                      minorName="averageNumberOfRebootsADayMinor"
                      majorName="averageNumberOfRebootsADayMajor"
                      label={language.average_number_of_reboots_day_key}
                      onChange={(e) => {
                        setFieldValue("averageNumberOfRebootsADay", e);
                      }}
                      tooltip={
                        language.average_number_of_reboots_day_description_key
                      }
                      isHightIsBetter={
                        mapScalarDefinition &&
                        mapScalarDefinition.averageNumberOfRebootsADay
                          ? mapScalarDefinition.averageNumberOfRebootsADay
                              .higherIsBetter
                          : false
                      }
                    />
                  </Form>
                );
              }}
            </Formik>
          )}
        </ServiceConfigPanel>
      )}
    </SntDialog>
  );
};

export default DiagnosticServiceConfig;
