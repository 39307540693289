import {
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from "react-table";
import { useEffect, useState } from "react";
import { getGroupColumnData } from "@/cleanup/utils/tableUtils";

export const useDataFilterTable = (dataFilter, data, tableProps) => {
  const { filter, setPaging, pageCount, setSortByKey, totalItems } = dataFilter;

  const [columns, setColumns] = useState(filter.columns);

  const [isGroupedMode, setGroupedMode] = useState(false);

  useEffect(() => {
    let { groupColumn, allGroupColumns } = getGroupColumnData(filter.columns);

    let filterColumns = filter.columns;
    if (groupColumn) {
      filterColumns = [...allGroupColumns];
      setGroupedMode(true);
    } else {
      setGroupedMode(false);
    }
    setColumns([...filterColumns]);
  }, [filter.columns]);

  const table = useTable(
    {
      columns: columns,
      data,
      manualPagination: true,
      pageCount: pageCount,
      totalItems: totalItems,
      manualSortBy: true,
      autoResetPage: false,
      initialState: {
        pageIndex: filter.pageIndex,
        pageSize: filter.pageSize,
        sortBy:
          filter.sortBy && filter.sortBy.visible
            ? [
                {
                  id: filter.sortBy.column.columnKey,
                  desc: filter.sortBy.desc,
                },
              ]
            : [],
      },
      ...tableProps,
    },
    useSortBy,
    usePagination,
    useResizeColumns,
    useFlexLayout
  );

  const {
    setPageSize,
    toggleSortBy,
    gotoPage,
    // Get the state from the instance
    state: { pageIndex, pageSize, sortBy },
  } = table;

  useEffect(() => {
    if (
      filter.sortBy &&
      filter.sortBy.visible &&
      columns.find((col) => col.columnKey === filter.sortBy.column.columnKey)
    ) {
      if (
        !sortBy[0] ||
        sortBy[0].id !== filter.sortBy.column.columnKey ||
        sortBy[0].desc !== filter.sortBy.desc
      ) {
        toggleSortBy(filter.sortBy.column.columnKey, filter.sortBy.desc);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.sortBy, columns]);

  // // Listen for changes in sorting
  useEffect(() => {
    if (sortBy.length === 0) {
      setSortByKey(filter.columns[0].columnKey, false);
    } else {
      setSortByKey(sortBy[0].id, sortBy[0].desc);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  useEffect(() => {
    if (filter.pageSize !== pageSize) setPageSize(filter.pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.pageSize]);

  useEffect(() => {
    if (filter.pageIndex !== pageIndex) gotoPage(filter.pageIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.pageIndex]);

  // // Listen for changes in pagination and pass on to
  useEffect(() => {
    setPaging(pageIndex, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize]);

  return { isGroupedMode, ...table };
};
