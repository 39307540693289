import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import {
  FormGroupLayout,
  ItemSelector,
} from "../../../components/ReactBootstrap/FormValidation/index.js";
import SntDialog from "../../../components/SntDialog/SntDialog.js";
import { Col } from "react-bootstrap";
import InputNumber from "../../../components/ReactBootstrap/FormValidation/InputNumber.js";

const NetworkServiceConfig = ({
  isShow,
  setIsShow,
  selectedService,
  onSave,
}) => {
  const language = useSelector((state) => state.language);
  const formikRefs = useRef();
  const offlineBehaviorList = [
    { value: "PRODUCT_DEFAULT", label: language.product_default },
    { value: "SUPPORT_OFFLINE", label: language.support_offline },
    { value: "NEVER_OFFLINE", label: language.never_offline },
  ];

  const [service, setService] = useState({
    type: "SIGFOX",
    active: true,
    configuration: {
      offlineBehavior: { value: "PRODUCT_DEFAULT" },
      offlineTimeoutInMinutes: 60,
    },
  });

  useEffect(() => {
    if (selectedService && isShow) {
      setService({
        ...service,
        configuration: {
          offlineBehavior: {
            value: selectedService?.configuration?.offlineBehavior,
          },
          offlineTimeoutInMinutes:
            selectedService?.configuration?.offlineTimeoutInMinutes,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService, isShow]);

  const onSubmit = (values) => {
    onSave({
      ...service,
      configuration: {
        offlineBehavior: values.offlineBehavior.value,
        offlineTimeoutInMinutes: values.offlineTimeoutInMinutes,
      },
    });
    setIsShow(false);
  };

  const validationSchema = Yup.object().shape({
    offlineTimeoutInMinutes: Yup.number()
      .required(language.valid_required_key)
      .test(
        "Is positive?",
        "ERROR: The number must be greater than or equal to 0!",
        (value) => value >= 0
      ),
  });

  return (
    <SntDialog
      isShow={isShow}
      onSave={() => formikRefs.current.handleSubmit()}
      onClose={() => setIsShow(false)}
      saveTxt={language.save_key}
      title={language.v40_define_services_to_enable_disable_key}
    >
      {() => (
        <Formik
          initialValues={service.configuration}
          innerRef={formikRefs}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values }) => (
            <Form className="form-horizontal">
              <FormGroupLayout
                xl="5"
                label={language.offline_behavior}
                rightRender={() => (
                  <Col xs="12" lg="6">
                    <ItemSelector
                      name="offlineBehavior"
                      options={offlineBehaviorList}
                    />
                  </Col>
                )}
              />

              {values.offlineBehavior.value === "SUPPORT_OFFLINE" && (
                <FormGroupLayout
                  xl="5"
                  label={language.offline_timout_in_minutes}
                  rightRender={() => (
                    <Col xs="12" lg="6">
                      <InputNumber
                        name="offlineTimeoutInMinutes"
                        placeholder={language.offline_timout_in_minutes}
                      />
                    </Col>
                  )}
                />
              )}
            </Form>
          )}
        </Formik>
      )}
    </SntDialog>
  );
};

export default NetworkServiceConfig;
