import SntIcon from "./SntIcon";

const SntCloneIcon = (props) => {
  return (
    <SntIcon {...props}>
      <g clipPath="url(#clip0_3748_661)">
        <rect width="16" height="16" fill="transparent"></rect>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 0C4.89543 0 4 0.895431 4 2V3H10C11.6569 3 13 4.34315 13 6V12H14C15.1046 12 16 11.1046 16 10V2C16 0.895431 15.1046 0 14 0H6ZM12 6C12 4.89543 11.1046 4 10 4H2C0.895431 4 0 4.89543 0 6V14C0 15.1046 0.895431 16 2 16H10C11.1046 16 12 15.1046 12 14V6ZM5 9V6H7V9H10V11H7V14H5V11H2V9H5Z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_3748_661">
          <rect width="16" height="16" fill="transparent"></rect>
        </clipPath>
      </defs>
    </SntIcon>
  );
};

export default SntCloneIcon;
