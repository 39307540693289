import { Form, Formik } from "formik";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import {
  FormGroupLayout,
  VerticalLayout,
} from "../../../components/ReactBootstrap/FormValidation/index.js";
import SntRadio from "../../../components/SntRadio/SntRadio.js";
import ConfigMappingSensor from "./ConfigMappingSensor.js";
import ServiceConfigPanel from "../Utils/ServiceConfigPanel.js";
import ServiceInput from "../Utils/ServiceInput.js";

const SensorConfig = ({ title = "", sensorConfig, shared }) => {
  const language = useSelector((state) => state.language);
  const mappingRef = useRef();
  const formRef = useRef();

  const [useRawValue, setUseRawValue] = useState("useRaw"); // or "translate"

  const [sensorInfo, setSensorInfo] = useState({
    valueVisibility: "ALL",
    stateVisibility: "ALL",
  });
  const [sensor, setSensor] = useState({
    name: "",
    description: "",
    unit: "",
    lowerBound: null,
    upperBound: null,
    useRawValue: true,
    handleAsDeviceOrientation: false,
  });
  const visibilityTypes = ["ALL", "PARTNER", "NONE"].map((item) => ({
    value: item,
    label: item,
  }));

  useEffect(() => {
    setSensor({
      ...sensor,
      ...sensorConfig,
    });

    setSensorInfo({
      ...sensorInfo,
      valueVisibility: sensorConfig
        ? sensorConfig.valueVisibility?.name ||
          sensorConfig.valueVisibility ||
          "ALL"
        : "ALL",
      stateVisibility: sensorConfig
        ? sensorConfig.stateVisibility?.name ||
          sensorConfig.stateVisibility ||
          "ALL"
        : "ALL",
    });

    if (sensorConfig && sensorConfig.useRawValue === false) {
      setUseRawValue("translate");
    }

    if (sensorConfig && sensorConfig.handleAsDeviceOrientation) {
      setUseRawValue("handleAsDeviceOrientation");
    }
    // eslint-disable-next-line
  }, [sensorConfig]);

  function getFormData() {
    let params = formRef.current.values;
    if (useRawValue === "translate") {
      params.useRawValue = false;
      params.handleAsDeviceOrientation = false;
      params = {
        ...params,
        ...mappingRef.current?.getFormData(),
      };
    } else if (useRawValue === "useRaw") {
      params.useRawValue = true;
      params.handleAsDeviceOrientation = false;
    } else if (useRawValue === "handleAsDeviceOrientation") {
      params.useRawValue = false;
      params.handleAsDeviceOrientation = true;
    }
    params.valueVisibility = sensorInfo.valueVisibility;
    params.stateVisibility = sensorInfo.stateVisibility;
    params.upperBound = params.upperBound !== "" ? params.upperBound : null;
    params.lowerBound = params.lowerBound !== "" ? params.lowerBound : null;

    return params;
  }

  useImperativeHandle(shared, () => ({
    getFormData,
  }));

  function getSensorInfo() {
    if (formRef.current.values) {
      let info = formRef.current.values;
      return {
        description: info.description,
        name: info.name,
        unit: info.unit,
      };
    }
    return {};
  }

  return (
    <ServiceConfigPanel title={title}>
      {() => (
        <Formik
          initialValues={sensor}
          innerRef={formRef}
          enableReinitialize={true}
        >
          <Form className="form-horizontal">
            {useRawValue !== "handleAsDeviceOrientation" && (
              <>
                <FormGroupLayout
                  label={language.sensor_index_name_key}
                  rightRender={() => (
                    <ServiceInput
                      name="name"
                      tooltip={language.ble_sensor_name_tooltip_key}
                    />
                  )}
                />
                <FormGroupLayout
                  label={language.sensor_index_description_key}
                  rightRender={() => (
                    <ServiceInput
                      name="description"
                      tooltip={language.ble_sensor_description_tooltip_key}
                    />
                  )}
                />
                <FormGroupLayout
                  label={language.units_key}
                  rightRender={() => (
                    <ServiceInput name="unit" tooltip={language.unit_tooltip} />
                  )}
                />
                <FormGroupLayout
                  label={language.value_visibility}
                  rightRender={() => (
                    <Select
                      options={visibilityTypes}
                      onChange={(option) => {
                        setSensorInfo({
                          ...sensorInfo,
                          valueVisibility: option.value,
                        });
                      }}
                      value={visibilityTypes.filter(
                        (option) => option.value === sensorInfo.valueVisibility
                      )}
                      menuPlacement="auto"
                    />
                  )}
                />
                <FormGroupLayout
                  label={language.state_visibility}
                  rightRender={() => (
                    <Select
                      options={visibilityTypes}
                      onChange={(option) => {
                        setSensorInfo({
                          ...sensorInfo,
                          stateVisibility: option.value,
                        });
                      }}
                      value={visibilityTypes.filter(
                        (option) => option.value === sensorInfo.stateVisibility
                      )}
                      menuPlacement="auto"
                    />
                  )}
                />
                <FormGroupLayout
                  label={language.v44_bound_key}
                  rightRender={() => (
                    <div className="d-flex">
                      <FormGroupLayout
                        xs="1"
                        label={language.min_key}
                        rightRender={() => (
                          <ServiceInput
                            name="lowerBound"
                            inputType={"number"}
                          />
                        )}
                      />

                      <FormGroupLayout
                        xs="1"
                        label={language.max_key}
                        rightRender={() => (
                          <ServiceInput
                            name="upperBound"
                            inputType={"number"}
                          />
                        )}
                      />
                    </div>
                  )}
                />
              </>
            )}
            <FormGroupLayout
              label={language.sensor_values}
              rightRender={() => (
                <VerticalLayout>
                  <SntRadio
                    className=" me-3"
                    value="useRaw"
                    selectedValue={useRawValue}
                    onChange={() => setUseRawValue("useRaw")}
                    label={language.use_raw_values}
                  />
                  <SntRadio
                    value="translate"
                    selectedValue={useRawValue}
                    onChange={() => setUseRawValue("translate")}
                    label={language.translate_values}
                  />
                  <SntRadio
                    value="handleAsDeviceOrientation"
                    selectedValue={useRawValue}
                    onChange={() => setUseRawValue("handleAsDeviceOrientation")}
                    label={language.handle_as_orientation_value}
                  />
                </VerticalLayout>
              )}
            />

            {useRawValue === "translate" && (
              <ConfigMappingSensor
                serviceConfiguration={{
                  ...sensor,
                  ...sensorInfo,
                }}
                getSensorInfo={getSensorInfo}
                shared={(child) => (mappingRef.current = child)}
              ></ConfigMappingSensor>
            )}
          </Form>
        </Formik>
      )}
    </ServiceConfigPanel>
  );
};
export default SensorConfig;
