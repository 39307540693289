const ObjectUtils = {
  sort(obj) {
    return Object.keys(obj)
      .sort()
      .reduce((arr, v) => {
        arr[v] = obj[v];
        return arr;
      }, {});
  },

  sortDeep(obj) {
    obj = this.sort(obj);
    for (let key in obj) {
      if (typeof obj[key] === "object") {
        obj[key] = this.sortDeep(obj[key]);
      }
    }
    return obj;
  },

  deleteProperties(obj, props) {
    props.forEach((prop) => {
      delete obj[prop];
    });
  },

  deletePropertiesDeep(obj, props) {
    this.deleteProperties(obj, props);
    for (let key in obj) {
      if (typeof obj[key] === "object") {
        this.deletePropertiesDeep(obj[key], props);
      }
    }
    return obj;
  },
  isNullOrEmpty(obj) {
    return obj === undefined || obj === null || JSON.stringify(obj) === "{}";
  },
};

export default ObjectUtils;
