import { useEffect, useMemo, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import SntDeleteIcon from "../../components/Icons/SntDeleteIcon";
import SntCheckBox from "../../components/SntCheckBox/SntCheckBox";
//import StringUtils from "../../utils/StringUtils";
import SntSortAZ from "@/components/Icons/SntSortAZ";
import SntSortZA from "@/components/Icons/SntSortZA";
import FunctionSelect from "@pages/DeviceUsageProfile/FunctionSelect.js";
import SntSelect from "@wrappers/ReactSelect/SntSelect";

const RemoteSetting = ({
  option,
  fwParams,
  supportedFWPresets,
  enumTypes,
  bitmaskTypes,
  onOptionChange,
  configurationStyle = "SNT",
  numericVariables,
}) => {
  const language = useSelector((state) => state.language);
  const [settings, setSettings] = useState({ params: [] });
  const [chooseParam, setChooseParam] = useState({});
  const [sortOption, setSortOption] = useState("asc");

  const booleanOptions = [
    { value: "TRUE", label: "TRUE" },
    { value: "FALSE", label: "FALSE" },
  ];

  const presets = useMemo(() => {
    return (
      supportedFWPresets &&
      supportedFWPresets.map((preset) => {
        return { value: preset.presetNumber, label: preset.key };
      })
    );
  }, [supportedFWPresets]);

  const allParam = useMemo(() => {
    let params = [];
    let selectedKey = settings.params
      ? settings.params.map((d) => d.fwParameterKey)
      : [];

    fwParams &&
      fwParams.forEach((e) => {
        selectedKey.indexOf(e.key) < 0 &&
          params.push({
            value: e.key,
            label:
              configurationStyle === "SNT"
                ? e.key +
                  " (" +
                  e.bidirectionalSigfoxFormatInfo.totalLength +
                  " " +
                  language.bits_key +
                  ")"
                : e.key,
          });
      });
    params.length > 0 && setChooseParam(params[0]);
    return params;
  }, [fwParams, settings, language, configurationStyle]);

  useEffect(() => {
    option.deviceFwParameterSettings &&
      setSettings({ params: option.deviceFwParameterSettings });
  }, [option]);

  function onInputChange(el) {
    let vals = [...settings.params];
    vals = vals.map((v) =>
      v.fwParameterKey === el.target.name ? { ...v, value: el.target.value } : v
    );
    setSettings({ params: vals });
    onOptionChange({ deviceFwParameterSettings: vals });
  }

  function onSelectChange(selected, el) {
    let vals = [...settings.params];
    vals = vals.map((v) =>
      v.fwParameterKey === el.name ? { ...v, value: selected.value } : v
    );
    setSettings({ params: vals });
    onOptionChange({ deviceFwParameterSettings: vals });
  }

  function onBitMaskChanged(el, fwParameterKey, i) {
    let vals = [...settings.params];

    let bitMasks = vals.find((v) => v.fwParameterKey === fwParameterKey).value;
    if (bitMasks) {
      bitMasks = bitMasks.split("").reverse();
    } else {
      let setting = fwParams.find((p) => p.key === fwParameterKey);
      bitMasks = bitmaskTypes[
        setting.dataType.description
      ].listBitMaskDescr.map(() => "0");
    }

    bitMasks[i] = bitMasks[i] === "0" ? "1" : "0";
    vals = vals.map((v) =>
      v.fwParameterKey === fwParameterKey
        ? { ...v, value: bitMasks.reverse().join("") }
        : v
    );
    setSettings({ params: vals });
    onOptionChange({ deviceFwParameterSettings: vals });
  }

  function removeClick(event) {
    let vals = [...settings.params];
    vals.splice(
      vals.findIndex((obj) => obj.fwParameterKey === event.currentTarget.name),
      1
    );
    setSettings({ params: vals });
    onOptionChange({ deviceFwParameterSettings: vals });
    event.preventDefault();
  }

  const addClick = (event) => {
    let defaultValue = getDefaultValue(chooseParam.value);
    let vals = [
      ...settings.params,
      { fwParameterKey: chooseParam.value, value: defaultValue.value },
    ];
    setSettings({ params: vals });
    onOptionChange({ deviceFwParameterSettings: vals });
    event.preventDefault();
  };

  function getDefaultValue(fwParameterKey, selectedValue) {
    let setting = fwParams.find((p) => p.key === fwParameterKey);
    if (setting.dataType.dataType === "BITMASK") {
      return selectedValue
        ? selectedValue.split("").reverse()
        : bitmaskTypes[setting.dataType.description].listBitMaskDescr.map(
            () => "0"
          );
    } else if (setting.dataType.dataType === "ENUM") {
      let options = [];
      enumTypes[setting.dataType.description].listEnumTypeDescr.forEach((e) => {
        options.push({ value: e.value, label: e.description });
      });
      return options.find((o) => o.value === selectedValue) || options[0];
    } else if (setting.dataType.dataType === "BOOLEAN") {
      return (
        booleanOptions.find((o) => o.value === selectedValue) ||
        booleanOptions[0]
      );
    } else if (setting.dataType.dataType === "FIRMWARE_PRESET") {
      return presets.find((o) => o.value === selectedValue) || presets[0];
    } else if (setting.dataType.dataType === "STRING") {
      return { value: selectedValue };
    } else {
      return { value: selectedValue || setting.dataType.minValue };
    }
  }

  function renderRow(fwParameterKey, value) {
    if (!fwParams) return <></>;
    let setting = fwParams.find((p) => p.key === fwParameterKey);
    return (
      setting && (
        <tr key={setting.key}>
          <td>
            {setting.key}
            <p>{setting.description}</p>
          </td>
          {configurationStyle === "SNT" && (
            <td>{setting.bidirectionalSigfoxFormatInfo.totalLength}</td>
          )}
          <td style={{ width: "150px" }}>
            {renderValue(
              setting,
              value,
              bitmaskTypes,
              enumTypes,
              onSelectChange
            )}
          </td>

          <td className="text-center">
            <Button
              variant="sensolus-greylight"
              name={setting.key}
              onClick={removeClick}
              title="Delete this option"
            >
              <SntDeleteIcon />
            </Button>
          </td>
        </tr>
      )
    );
  }

  function renderValue(setting, selectedValue, bitmaskTypes, enumTypes) {
    let defaultValue = getDefaultValue(setting.key, selectedValue);
    if (setting.dataType.dataType === "BITMASK") {
      return (
        <div className="form-group" style={{ paddingLeft: "15px" }}>
          {bitmaskTypes[setting.dataType.description].listBitMaskDescr.map(
            (obj, i) => (
              <SntCheckBox
                key={i}
                checked={defaultValue[i] === "1"}
                onChange={(el) => onBitMaskChanged(el, setting.key, i)}
                label={obj.description}
              />
            )
          )}
        </div>
      );
    } else if (setting.dataType.dataType === "ENUM") {
      let options = [];
      enumTypes[setting.dataType.description].listEnumTypeDescr.forEach((e) => {
        options.push({ value: e.value, label: e.description });
      });
      return (
        <SntSelect
          name={setting.key}
          value={defaultValue}
          options={options}
          onChange={(selected, el) => onSelectChange(selected, el)}
          menuPlacement="auto"
        />
      );
    } else if (setting.dataType.dataType === "BOOLEAN") {
      return (
        <SntSelect
          name={setting.key}
          value={defaultValue}
          options={booleanOptions}
          onChange={(selected, el) => onSelectChange(selected, el)}
          menuPlacement="auto"
        />
      );
    } else if (setting.dataType.dataType === "FIRMWARE_PRESET") {
      return (
        <SntSelect
          name={setting.key}
          value={defaultValue}
          options={presets}
          onChange={(selected, el) => onSelectChange(selected, el)}
          menuPlacement="auto"
        />
      );
    } else if (setting.dataType.dataType === "VOID") {
      return <center>-</center>;
    } else if (setting.dataType.dataType === "STRING") {
      return (
        <input
          name={setting.key}
          className="form-control"
          type="text"
          value={defaultValue.value}
          onChange={(e) => onInputChange(e)}
        />
      );
    } else {
      return (
        <FunctionSelect
          setting={setting}
          defaultValue={defaultValue}
          language={language}
          onChange={(e) => onInputChange(e)}
          numericVariables={numericVariables}
        />
      );
    }
  }

  useEffect(() => {
    if (sortOption === "asc") {
      settings.params.sort((a, b) =>
        a?.fwParameterKey.localeCompare(b?.fwParameterKey)
      );
    } else {
      settings.params.sort((a, b) =>
        b?.fwParameterKey.localeCompare(a?.fwParameterKey)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOption]);

  return (
    <div>
      <Row>
        <Col xs="12">
          <Table striped bordered>
            <thead>
              <tr>
                <th style={{ verticalAlign: "top" }}>
                  {language.modal_fw_parameter_key}
                  {sortOption === "desc" ? (
                    <SntSortZA
                      className="ms-2"
                      onClick={() => {
                        setSortOption("asc");
                      }}
                    />
                  ) : (
                    <SntSortAZ
                      className="ms-2"
                      onClick={() => {
                        setSortOption("desc");
                      }}
                    />
                  )}
                </th>
                {configurationStyle === "SNT" && (
                  <th style={{ verticalAlign: "top" }}>
                    {language.required_bits_key}
                  </th>
                )}
                <th style={{ verticalAlign: "top", width: 250 }}>
                  {language.value_to_set_key}
                </th>
                <th style={{ verticalAlign: "top" }}>{language.action_key}</th>
              </tr>
            </thead>
            <tbody>
              {settings.params.map((setting) =>
                renderRow(setting.fwParameterKey, setting.value)
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col className="m-1" xs="12" sm="6">
          <SntSelect
            value={chooseParam}
            options={allParam}
            onChange={(selectedOption) => {
              setChooseParam(selectedOption);
            }}
            menuPlacement="auto"
          />
        </Col>
        <Col className="m-1" xs="12" sm="5">
          <Button variant="sensolus-greylight" onClick={addClick}>
            {language.add_this_setting_key}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default RemoteSetting;
