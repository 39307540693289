import { useState } from "react";

import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import * as Yup from "yup";

import SntPanel from "@/components/Panel/SntPanel";
import {
  FormFullScreenLayout,
  InputText,
} from "@/components/ReactBootstrap/FormValidation";

import SntRadioField from "@widgets/formiks/SntRadioField";

import ProcessStepConditionControl from "./ProcessStepConditionControl";
import DeviceTagsActionControl from "./DeviceTagsActionControl";
import DecoratorActionControl from "./DecoratorActionControl";
import VisibilityFilterActionControl from "./VisibilityFilterActionControl";
import MonitoredAssetPanel from "./MonitoredAssetPanel";
import GeozoneConditionControl from "./GeozoneConditionControl";
import AlertRuleConditionControl from "./AlertRuleConditionControl";
import {
  ACTION_MAPPING,
  CONDITION_MAPPING,
  convertMonitorComponent2RequestDto,
  convertMonitorServerData2Component,
} from "./automationRuleUtils";
import PushTrackerUsageProfielActionControl from "./PushTrackerUsageProfielActionControl";
import AssignDeviceTagsActionControl from "./AssignDeviceTagsActionControl";

function AutomationRuleForm({ disabled, data: serverData, onSubmit, orgId }) {
  const language = useSelector((state) => state.language);

  const convertForm2RequestDto = (values) => {
    let data = {
      name: values.name,
      description: values.description,
      monitoredItem: convertMonitorComponent2RequestDto(
        values.monitoredAssets,
        "DEVICE"
      ),
      conditionType: values.conditionType,
      condition: CONDITION_MAPPING[
        values.conditionType
      ]?.convertForm2RequestDto(values[values.conditionType]),
      automationActionType: values.automationActionType,
      automationAction: ACTION_MAPPING[
        values.automationActionType
      ]?.convertForm2RequestDto(values[values.automationActionType]),
      orgId: orgId,
    };

    return data;
  };

  const onSubmitForm = (values) => {
    let data = convertForm2RequestDto(values);
    onSubmit && onSubmit(data);
  };

  const [formValue] = useState(() => {
    const defaultState = {
      name: "",
      description: "",
      monitoredAssets: {
        selectType: "ALL",
        selectedIds: [],
        selectedTags: [],
      },
      conditionType: CONDITION_MAPPING.IN_PROCESS_STEP.key,
      IN_PROCESS_STEP: { selectedProcessId: null, stepId: null },
      IN_GEOZONE: {
        selectType: "ALL",
        selectedIds: [],
        selectedTags: [],
      },
      OUT_GEOZONE: {
        selectType: "ALL",
        selectedIds: [],
        selectedTags: [],
      },
      ALERT_RULE: { alertRuleId: null },
      automationActionType: ACTION_MAPPING.ASSIGN_ASSET_TAGS.key,
      ASSIGN_ASSET_TAGS: { selectedIds: [] },
      REMOVE_ASSET_TAGS: { selectedIds: [] },
      SET_DECORATOR: { decorator: "" },
      REMOVE_DECORATOR: { decorator: "" },
      APPLY_VISIBILITY_FILTER: { selectedIds: [] },
      REMOVE_VISIBILITY_FILTER: { selectedIds: [] },
      PUSH_USAGE_PROFILE: [],
    };

    // update initialValues from server 2 components
    let state = serverData || {};
    if (serverData) {
      state.monitoredAssets =
        convertMonitorServerData2Component(serverData.monitoredItem) ||
        defaultState.monitoredAssets;

      let conditionKey = serverData.conditionType;

      // convert dataServer to component data
      state[conditionKey] =
        CONDITION_MAPPING[conditionKey]?.convertServerData2Component(
          serverData.condition
        ) || defaultState[conditionKey];

      let actionKey = serverData.automationActionType;
      state[actionKey] =
        ACTION_MAPPING[actionKey]?.convertServerData2Component(
          serverData.automationAction
        ) || defaultState[conditionKey];
    }

    return { ...defaultState, ...state };
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(language.valid_required_key),
    conditionType: Yup.string().required(language.valid_required_key),
    monitoredAssets: Yup.object({
      selectType: Yup.string().required(language.valid_required_key), // Ensure selectType is valid
      selectedIds: Yup.array().when("selectType", {
        is: "NAME",
        then: Yup.array()
          .of(
            Yup.object({
              value: Yup.string().required(language.valid_required_key), // Validate each object in the array
            })
          )
          .nullable()
          .required(language.valid_required_key) // Ensure array is not null or undefined
          .min(1, language.valid_required_key), // Ensure array has at least one object
        otherwise: Yup.array().notRequired(), // No validation when selectType is not "NAME"
      }),
      selectedTags: Yup.array().when("selectType", {
        is: "TAG",
        then: Yup.array()
          .of(
            Yup.object({
              value: Yup.string().required(language.valid_required_key), // Validate each object in the array
            })
          )
          .nullable()
          .required(language.valid_required_key) // Ensure array is not null or undefined
          .min(1, language.valid_required_key), // Ensure array has at least one object
        otherwise: Yup.array().notRequired(), // No validation when selectType is not "NAME"
      }),
    }),
    // condition data
    IN_PROCESS_STEP: Yup.object().when("conditionType", {
      is: CONDITION_MAPPING.IN_PROCESS_STEP.key,
      then: Yup.object({
        selectedProcessId: Yup.object({
          value: Yup.string().required(language.valid_required_key),
        })
          .nullable()
          .required(language.valid_required_key),
        stepId: Yup.object({
          value: Yup.string().required(language.valid_required_key),
        })
          .nullable()
          .required(language.valid_required_key),
      }).required(language.valid_required_key),
      otherwise: Yup.object().notRequired(),
    }),
    IN_GEOZONE: Yup.object().when("conditionType", {
      is: CONDITION_MAPPING.IN_GEOZONE.key,
      then: Yup.object({
        selectType: Yup.string().required(language.valid_required_key), // Ensure selectType is valid
        selectedIds: Yup.array().when("selectType", {
          is: "NAME",
          then: Yup.array()
            .of(
              Yup.object({
                value: Yup.string().required(language.valid_required_key), // Validate each object in the array
              })
            )
            .nullable()
            .required(language.valid_required_key) // Ensure array is not null or undefined
            .min(1, language.valid_required_key), // Ensure array has at least one object
          otherwise: Yup.array().notRequired(), // No validation when selectType is not "NAME"
        }),
        selectedTags: Yup.array().when("selectType", {
          is: "TAG",
          then: Yup.array()
            .of(
              Yup.object({
                value: Yup.string().required(language.valid_required_key), // Validate each object in the array
              })
            )
            .nullable()
            .required(language.valid_required_key) // Ensure array is not null or undefined
            .min(1, language.valid_required_key), // Ensure array has at least one object
          otherwise: Yup.array().notRequired(), // No validation when selectType is not "NAME"
        }),
      }).required(language.valid_required_key),
      otherwise: Yup.object().notRequired(),
    }),
    OUT_GEOZONE: Yup.object().when("conditionType", {
      is: CONDITION_MAPPING.OUT_GEOZONE.key,
      then: Yup.object({
        selectType: Yup.string().required(language.valid_required_key), // Ensure selectType is valid
        selectedIds: Yup.array().when("selectType", {
          is: "NAME",
          then: Yup.array()
            .of(
              Yup.object({
                value: Yup.string().required(language.valid_required_key), // Validate each object in the array
              })
            )
            .nullable()
            .required(language.valid_required_key) // Ensure array is not null or undefined
            .min(1, language.valid_required_key), // Ensure array has at least one object
          otherwise: Yup.array().notRequired(), // No validation when selectType is not "NAME"
        }),
        selectedTags: Yup.array().when("selectType", {
          is: "TAG",
          then: Yup.array()
            .of(
              Yup.object({
                value: Yup.string().required(language.valid_required_key), // Validate each object in the array
              })
            )
            .nullable()
            .required(language.valid_required_key) // Ensure array is not null or undefined
            .min(1, language.valid_required_key), // Ensure array has at least one object
          otherwise: Yup.array().notRequired(), // No validation when selectType is not "NAME"
        }),
      }).required(language.valid_required_key),
      otherwise: Yup.object().notRequired(),
    }),
    ALERT_RULE: Yup.object().when("conditionType", {
      is: CONDITION_MAPPING.ALERT_RULE.key,
      then: Yup.object({
        alertRuleId: Yup.object({
          value: Yup.string().required(language.valid_required_key),
        })
          .nullable()
          .required(language.valid_required_key),
      }).required(language.valid_required_key),
      otherwise: Yup.object().notRequired(),
    }),
    // automationActionType data
    ASSIGN_ASSET_TAGS: Yup.object().when("automationActionType", {
      is: ACTION_MAPPING.ASSIGN_ASSET_TAGS.key,
      then: Yup.object({
        selectedIds: Yup.array()
          .of(
            Yup.object({
              value: Yup.string().required(language.valid_required_key), // Validate each object in the array
            })
          )
          .nullable()
          .required(language.valid_required_key) // Ensure array is not null or undefined
          .min(1, language.valid_required_key), // Ensure array has at least one object
      }),
      otherwise: Yup.object().notRequired(),
    }),
    REMOVE_ASSET_TAGS: Yup.object().when("automationActionType", {
      is: ACTION_MAPPING.REMOVE_ASSET_TAGS.key,
      then: Yup.object({
        selectedIds: Yup.array()
          .of(
            Yup.object({
              value: Yup.string().required(language.valid_required_key), // Validate each object in the array
            })
          )
          .nullable()
          .required(language.valid_required_key) // Ensure array is not null or undefined
          .min(1, language.valid_required_key), // Ensure array has at least one object
      }),
      otherwise: Yup.object().notRequired(),
    }),
    SET_DECORATOR: Yup.object().when("automationActionType", {
      is: ACTION_MAPPING.SET_DECORATOR.key,
      then: Yup.object({
        decorator: Yup.string()
          .nullable()
          .required(language.valid_required_key), // Ensure array is not null or undefined
      }),
      otherwise: Yup.object().notRequired(),
    }),
    APPLY_VISIBILITY_FILTER: Yup.object().when("automationActionType", {
      is: ACTION_MAPPING.APPLY_VISIBILITY_FILTER.key,
      then: Yup.object({
        selectedIds: Yup.array()
          .of(
            Yup.object({
              value: Yup.string().required(language.valid_required_key), // Validate each object in the array
            })
          )
          .nullable()
          .required(language.valid_required_key) // Ensure array is not null or undefined
          .min(1, language.valid_required_key), // Ensure array has at least one object
      }),
      otherwise: Yup.object().notRequired(),
    }),
    REMOVE_VISIBILITY_FILTER: Yup.object().when("automationActionType", {
      is: ACTION_MAPPING.REMOVE_VISIBILITY_FILTER.key,
      then: Yup.object({
        selectedIds: Yup.array()
          .of(
            Yup.object({
              value: Yup.string().required(language.valid_required_key), // Validate each object in the array
            })
          )
          .nullable()
          .required(language.valid_required_key) // Ensure array is not null or undefined
          .min(1, language.valid_required_key), // Ensure array has at least one object
      }),
      otherwise: Yup.object().notRequired(),
    }),
    PUSH_USAGE_PROFILE: Yup.array().when("automationActionType", {
      is: ACTION_MAPPING.PUSH_USAGE_PROFILE.key,
      then: Yup.array()
        .required(language.valid_required_key)
        .min(1, language.valid_required_key),
      otherwise: Yup.array().notRequired(),
    }),
  });

  const [conditionOptions] = useState([
    {
      value: CONDITION_MAPPING.IN_PROCESS_STEP.key,
      label: CONDITION_MAPPING.IN_PROCESS_STEP.getLabel(language),
    },
    {
      value: CONDITION_MAPPING.IN_GEOZONE.key,
      label: CONDITION_MAPPING.IN_GEOZONE.getLabel(language),
    },
    {
      value: CONDITION_MAPPING.OUT_GEOZONE.key,
      label: CONDITION_MAPPING.OUT_GEOZONE.getLabel(language),
    },
    {
      value: CONDITION_MAPPING.ALERT_RULE.key,
      label: CONDITION_MAPPING.ALERT_RULE.getLabel(language),
    },
  ]);
  const [actionOptions] = useState([
    {
      value: ACTION_MAPPING.ASSIGN_ASSET_TAGS.key,
      label: ACTION_MAPPING.ASSIGN_ASSET_TAGS.getLabel(language),
    },
    {
      value: ACTION_MAPPING.REMOVE_ASSET_TAGS.key,
      label: ACTION_MAPPING.REMOVE_ASSET_TAGS.getLabel(language),
    },
    {
      value: ACTION_MAPPING.SET_DECORATOR.key,
      label: ACTION_MAPPING.SET_DECORATOR.getLabel(language),
    },
    {
      value: ACTION_MAPPING.REMOVE_DECORATOR.key,
      label: ACTION_MAPPING.REMOVE_DECORATOR.getLabel(language),
    },
    {
      value: ACTION_MAPPING.APPLY_VISIBILITY_FILTER.key,
      label: ACTION_MAPPING.APPLY_VISIBILITY_FILTER.getLabel(language),
    },
    {
      value: ACTION_MAPPING.REMOVE_VISIBILITY_FILTER.key,
      label: ACTION_MAPPING.REMOVE_VISIBILITY_FILTER.getLabel(language),
    },
    {
      value: ACTION_MAPPING.PUSH_USAGE_PROFILE.key,
      label: ACTION_MAPPING.PUSH_USAGE_PROFILE.getLabel(language),
    },
  ]);

  return (
    <Formik
      initialValues={formValue}
      onSubmit={onSubmitForm}
      enableReinitialize={true}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, errors }) => {
        return (
          <Form>
            <SntPanel title={language.general_info_key}>
              <FormFullScreenLayout
                label={language.name_key}
                isRequired={true}
                rightRender={() => (
                  <InputText name="name" disabled={disabled} />
                )}
              />

              <FormFullScreenLayout
                label={language.description_key}
                rightRender={() => (
                  <InputText name="description" disabled={disabled} />
                )}
              />
            </SntPanel>

            <MonitoredAssetPanel
              name={"monitoredAssets"}
              language={language}
              orgId={orgId}
              disabled={disabled}
            />

            <SntPanel title={language.condition_key}>
              <FormFullScreenLayout
                label={language.chose_condition_key}
                rightRender={() => (
                  <SntRadioField
                    name={"conditionType"}
                    options={conditionOptions}
                    disabled={disabled}
                  />
                )}
              />

              {values.conditionType === "IN_PROCESS_STEP" && (
                <ProcessStepConditionControl
                  language={language}
                  disabled={disabled}
                  value={values.IN_PROCESS_STEP}
                  orgId={orgId}
                  name="IN_PROCESS_STEP"
                />
              )}

              {values.conditionType === "IN_GEOZONE" && (
                <>
                  <GeozoneConditionControl
                    language={language}
                    disabled={disabled}
                    orgId={orgId}
                    name="IN_GEOZONE"
                  />
                </>
              )}

              {values.conditionType === "OUT_GEOZONE" && (
                <>
                  <GeozoneConditionControl
                    language={language}
                    disabled={disabled}
                    orgId={orgId}
                    name="OUT_GEOZONE"
                  />
                </>
              )}

              {values.conditionType === "ALERT_RULE" && (
                <>
                  <AlertRuleConditionControl
                    language={language}
                    disabled={disabled}
                    orgId={orgId}
                    name="ALERT_RULE"
                  />
                </>
              )}
            </SntPanel>

            <SntPanel title={language.action_key}>
              <FormFullScreenLayout
                label={language.chose_type_of_action_key}
                rightRender={() => (
                  <SntRadioField
                    name={"automationActionType"}
                    options={actionOptions}
                    disabled={disabled}
                  />
                )}
              />
              {values.automationActionType === "ASSIGN_ASSET_TAGS" && (
                <>
                  <AssignDeviceTagsActionControl
                    name={"ASSIGN_ASSET_TAGS"}
                    disabled={disabled}
                    language={language}
                    orgId={orgId}
                  />
                </>
              )}
              {values.automationActionType === "REMOVE_ASSET_TAGS" && (
                <>
                  <DeviceTagsActionControl
                    name={"REMOVE_ASSET_TAGS"}
                    disabled={disabled}
                    language={language}
                    orgId={orgId}
                  />
                </>
              )}

              {values.automationActionType === "SET_DECORATOR" && (
                <>
                  <DecoratorActionControl
                    name={"SET_DECORATOR"}
                    disabled={disabled}
                    language={language}
                  />
                </>
              )}

              {values.automationActionType === "APPLY_VISIBILITY_FILTER" && (
                <>
                  <VisibilityFilterActionControl
                    name={"APPLY_VISIBILITY_FILTER"}
                    disabled={disabled}
                    language={language}
                    orgId={orgId}
                  />
                </>
              )}

              {values.automationActionType ===
                ACTION_MAPPING.REMOVE_VISIBILITY_FILTER.key && (
                <>
                  <VisibilityFilterActionControl
                    name={"REMOVE_VISIBILITY_FILTER"}
                    disabled={disabled}
                    language={language}
                    orgId={orgId}
                  />
                </>
              )}
              {values.automationActionType ===
                ACTION_MAPPING.PUSH_USAGE_PROFILE.key && (
                <>
                  <PushTrackerUsageProfielActionControl
                    name={"PUSH_USAGE_PROFILE"}
                    disabled={disabled}
                    language={language}
                    orgId={orgId}
                  />
                </>
              )}
            </SntPanel>

            <Button variant="sensolus" type="submit">
              {language.save_key}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}

export default AutomationRuleForm;
