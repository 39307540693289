const _automationRuleRoute = {
  page: "/automation_rule",
  updatePage: "/automation_rule_update",
  createPage: "/automation_rule_create",
  getPage: (data) => {
    return `/automation_rule?org=${data.orgId}`;
  },
  getUpdatePage: (data) => {
    return `/automation_rule_update?id=${data.id}&type=${
      data.type || "view"
    }&org=${data.orgId}`;
  },
  getCreatePage: (data) => {
    if (data?.orgId) return `/automation_rule_create?org=${data.orgId}`;
    return `/automation_rule_create?`;
  },
};

export default _automationRuleRoute;
