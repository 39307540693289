import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import SntDialog from "../../../components/SntDialog/SntDialog.js";
import ServiceConfigPanel from "../Utils/ServiceConfigPanel.js";
import { Form, Formik } from "formik";
import ServiceInput from "../Utils/ServiceInput.js";
import {
  FormGroupLayout,
  ItemSelector,
} from "../../../components/ReactBootstrap/FormValidation/index.js";
import SntCheckBox from "@/components/SntCheckBox/SntCheckBox.js";

const AcceleroBaseActivityServiceConfig = ({
  isShow,
  setIsShow,
  selectedService,
  onSave,
}) => {
  const language = useSelector((state) => state.language);
  const form0 = useRef();
  const form1 = useRef();
  const form2 = useRef();
  const form3 = useRef();
  const form4 = useRef();
  const form5 = useRef();
  const enableOTMDetection = [
    { value: true, label: language.yes_key },
    { value: false, label: language.no_key },
  ];
  const [service, setService] = useState({
    type: "ACCELERO_BASED_ACTIVITY",
    active: true,
    configuration: {
      detailsActivityConfig: {
        exposeDetailedActivity: false,
        tripsToExclude: "",
      },
      algoSettings: {
        forceBasicJourneyAlgorihm: false,
        speedThresholdForStop: "",
        conditionalOTMEliminationSettings: {
          maxGpsLocationsInOTMJourneyPart: "",
          maxMissedGpsLocationsInOTMJourneyPart: "",
          maxDistance: "",
          maxOTMDuration: "",
          convertUnrealisticMovementToUnrecorded: enableOTMDetection[0],
          unrealisticMovementMinimumSpeed: "",
          unrealisticMovementMinimumDistance: "",
          unrealisticMovementTooLongMinimumDurationMinutes: "",
          unrealisticMovementTooLongTripMaxDistance: "",
        },
        conditionalStopEliminationSettings: {
          maximumStopToEliminate: "",
          maxDistanceBetweenStopAndStartLocationToDropStart: "",
        },
      },
    },
  });

  useEffect(() => {
    if (selectedService && isShow) {
      let algo =
        selectedService.configuration &&
        selectedService.configuration.algoSettings
          ? selectedService.configuration.algoSettings
          : service.configuration.algoSettings;
      let detailsActivityConfig =
        selectedService.configuration &&
        selectedService.configuration.detailsActivityConfig
          ? selectedService.configuration.detailsActivityConfig
          : service.configuration.detailsActivityConfig;

      let currentConfig = {
        type: selectedService.type,
        active: selectedService.active,
        configuration: {
          detailsActivityConfig: {
            exposeDetailedActivity:
              detailsActivityConfig.exposeDetailedActivity,
            tripsToExclude: detailsActivityConfig.tripsToExclude,
          },
          algoSettings: {
            forceBasicJourneyAlgorihm: algo.forceBasicJourneyAlgorihm,
            speedThresholdForStop: algo.speedThresholdForStop,
            conditionalOTMEliminationSettings: {
              maxGpsLocationsInOTMJourneyPart:
                algo.conditionalOTMEliminationSettings
                  .maxGpsLocationsInOTMJourneyPart,
              maxMissedGpsLocationsInOTMJourneyPart:
                algo.conditionalOTMEliminationSettings
                  .maxMissedGpsLocationsInOTMJourneyPart,
              maxDistance: algo.conditionalOTMEliminationSettings.maxDistance,
              maxOTMDuration:
                algo.conditionalOTMEliminationSettings.maxOTMDuration,
              convertUnrealisticMovementToUnrecorded:
                enableOTMDetection.find(
                  (e) =>
                    e.value ===
                    algo.conditionalOTMEliminationSettings
                      .convertUnrealisticMovementToUnrecorded
                ) || enableOTMDetection[0],
              unrealisticMovementMinimumSpeed:
                algo.conditionalOTMEliminationSettings
                  .unrealisticMovementMinimumSpeed,
              unrealisticMovementMinimumDistance:
                algo.conditionalOTMEliminationSettings
                  .unrealisticMovementMinimumDistance,
              unrealisticMovementTooLongMinimumDurationMinutes:
                algo.conditionalOTMEliminationSettings
                  .unrealisticMovementTooLongMinimumDurationMinutes,
              unrealisticMovementTooLongTripMaxDistance:
                algo.conditionalOTMEliminationSettings
                  .unrealisticMovementTooLongTripMaxDistance,
            },
            conditionalStopEliminationSettings: {
              maximumStopToEliminate:
                algo.conditionalStopEliminationSettings.maximumStopToEliminate,
              maxDistanceBetweenStopAndStartLocationToDropStart:
                algo.conditionalStopEliminationSettings
                  .maxDistanceBetweenStopAndStartLocationToDropStart,
            },
          },
        },
      };
      setService({ ...service, ...currentConfig });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService, isShow]);

  function onServiceSave() {
    let zero = form0.current.values;
    let first = form1.current.values;
    let second = form2.current.values;
    let third = form3.current.values;
    let fourth = form4.current.values;
    let fifth = form5.current.values;
    let configuration = {
      detailsActivityConfig: {
        exposeDetailedActivity: fourth.exposeDetailedActivity,
        tripsToExclude:
          fourth.tripsToExclude !== "" ? fourth.tripsToExclude : null,
      },
      algoSettings: {
        forceBasicJourneyAlgorihm: zero.forceBasicJourneyAlgorihm,
        speedThresholdForStop:
          fifth.speedThresholdForStop !== ""
            ? fifth.speedThresholdForStop
            : null,
        conditionalOTMEliminationSettings: {
          maxGpsLocationsInOTMJourneyPart:
            first.maxGpsLocationsInOTMJourneyPart !== ""
              ? first.maxGpsLocationsInOTMJourneyPart
              : null,
          maxMissedGpsLocationsInOTMJourneyPart:
            first.maxMissedGpsLocationsInOTMJourneyPart !== ""
              ? first.maxMissedGpsLocationsInOTMJourneyPart
              : null,
          maxDistance: first.maxDistance !== "" ? first.maxDistance : null,
          maxOTMDuration:
            first.maxOTMDuration !== "" ? first.maxOTMDuration : null,
          convertUnrealisticMovementToUnrecorded:
            second.convertUnrealisticMovementToUnrecorded.value !== ""
              ? second.convertUnrealisticMovementToUnrecorded.value
              : null,
          unrealisticMovementMinimumSpeed:
            second.unrealisticMovementMinimumSpeed !== ""
              ? second.unrealisticMovementMinimumSpeed
              : null,
          unrealisticMovementMinimumDistance:
            second.unrealisticMovementMinimumDistance !== ""
              ? second.unrealisticMovementMinimumDistance
              : null,
          unrealisticMovementTooLongMinimumDurationMinutes:
            second.unrealisticMovementTooLongMinimumDurationMinutes !== ""
              ? second.unrealisticMovementTooLongMinimumDurationMinutes
              : null,
          unrealisticMovementTooLongTripMaxDistance:
            second.unrealisticMovementTooLongTripMaxDistance !== ""
              ? second.unrealisticMovementTooLongTripMaxDistance
              : null,
        },
        conditionalStopEliminationSettings: {
          maximumStopToEliminate:
            third.maximumStopToEliminate !== ""
              ? third.maximumStopToEliminate
              : null,
          maxDistanceBetweenStopAndStartLocationToDropStart:
            third.maxDistanceBetweenStopAndStartLocationToDropStart !== ""
              ? third.maxDistanceBetweenStopAndStartLocationToDropStart
              : null,
        },
      },
    };
    onSave({ ...service, configuration: configuration });
    setIsShow(false);
  }
  return (
    <SntDialog
      isShow={isShow}
      onSave={() => onServiceSave(service)}
      onClose={() => setIsShow(false)}
      saveTxt={language.save_key}
      title={language.v40_define_services_to_enable_disable_key}
    >
      {() => (
        <>
          <ServiceConfigPanel
            title={language.journey_algo_choice_key}
            description={language.journey_algo_choice_description_key}
          >
            {() => (
              <Formik
                initialValues={service.configuration.algoSettings}
                innerRef={form0}
                enableReinitialize={true}
              >
                {({ values, setFieldValue }) => {
                  return (
                    <Form className="form-horizontal">
                      <FormGroupLayout
                        xs="12"
                        xl="8"
                        label={language.use_basic_journey_algo_key}
                        rightRender={() => (
                          <SntCheckBox
                            checked={values.forceBasicJourneyAlgorihm}
                            onChange={(value) => {
                              setFieldValue("forceBasicJourneyAlgorihm", value);
                            }}
                          />
                        )}
                      />
                    </Form>
                  );
                }}
              </Formik>
            )}
          </ServiceConfigPanel>
          <ServiceConfigPanel title={language.basic_journey_algo_settings_key}>
            {() => (
              <Formik
                initialValues={service.configuration.algoSettings}
                innerRef={form5}
                enableReinitialize={true}
              >
                <Form className="form-horizontal">
                  <FormGroupLayout
                    xs="12"
                    xl="8"
                    label={language.basic_journey_speed_treshold_stop_key}
                    rightRender={() => (
                      <ServiceInput
                        name="speedThresholdForStop"
                        inputType={"number"}
                        unit={language.kmh_key}
                      />
                    )}
                  />
                </Form>
              </Formik>
            )}
          </ServiceConfigPanel>
          <ServiceConfigPanel
            title={language.short_otm_elimination_key}
            description={language.short_otm_elimination_description_key}
          >
            {() => (
              <Formik
                initialValues={
                  service.configuration.algoSettings
                    .conditionalOTMEliminationSettings
                }
                innerRef={form1}
                enableReinitialize={true}
              >
                <Form className="form-horizontal">
                  <FormGroupLayout
                    xs="12"
                    xl="8"
                    label={language.max_distance_description_key}
                    rightRender={() => (
                      <ServiceInput
                        name="maxDistance"
                        inputType={"number"}
                        unit={language.meters_key}
                      />
                    )}
                  />

                  <FormGroupLayout
                    xs="12"
                    xl="8"
                    label={language.max_duration_description_key}
                    rightRender={() => (
                      <ServiceInput
                        name="maxOTMDuration"
                        inputType={"number"}
                        unit={language.seconds_key}
                      />
                    )}
                  />
                  <FormGroupLayout
                    xs="12"
                    xl="8"
                    label={language.max_omt_in_segment_key}
                    rightRender={() => (
                      <ServiceInput
                        name="maxGpsLocationsInOTMJourneyPart"
                        inputType={"number"}
                      />
                    )}
                  />
                  <FormGroupLayout
                    xs="12"
                    xl="8"
                    label={language.max_missed_location_key}
                    rightRender={() => (
                      <ServiceInput
                        name="maxMissedGpsLocationsInOTMJourneyPart"
                        inputType={"number"}
                      />
                    )}
                  />
                </Form>
              </Formik>
            )}
          </ServiceConfigPanel>

          <ServiceConfigPanel
            title={language.unrealistic_otm_detect_key}
            description={language.unrealistic_otm_description_key}
          >
            {() => (
              <Formik
                initialValues={
                  service.configuration.algoSettings
                    .conditionalOTMEliminationSettings
                }
                innerRef={form2}
                enableReinitialize={true}
              >
                <Form className="form-horizontal">
                  <FormGroupLayout
                    label={language.enable_unrealistic_otm_key}
                    xs="12"
                    xl="8"
                    rightRender={() => (
                      <ItemSelector
                        name="convertUnrealisticMovementToUnrecorded"
                        options={enableOTMDetection}
                      />
                    )}
                  />

                  <FormGroupLayout
                    xs="12"
                    xl="8"
                    label={language.otm_speed_over_key}
                    rightRender={() => (
                      <ServiceInput
                        name="unrealisticMovementMinimumSpeed"
                        inputType={"number"}
                        unit="km/h"
                      />
                    )}
                  />

                  <FormGroupLayout
                    xs="12"
                    xl="8"
                    label={language.otm_distance_over_key}
                    rightRender={() => (
                      <ServiceInput
                        name="unrealisticMovementMinimumDistance"
                        inputType={"number"}
                        unit={language.meters_key}
                      />
                    )}
                  />

                  <FormGroupLayout
                    xs="12"
                    xl="8"
                    label={language.otm_short_duration_key}
                    rightRender={() => (
                      <ServiceInput
                        name="unrealisticMovementTooLongMinimumDurationMinutes"
                        inputType={"number"}
                        unit={language.minutes_key}
                      />
                    )}
                  />

                  <FormGroupLayout
                    xs="12"
                    xl="8"
                    label={language.otm_short_distance_key}
                    rightRender={() => (
                      <ServiceInput
                        name="unrealisticMovementTooLongTripMaxDistance"
                        inputType={"number"}
                        unit={language.meters_key}
                      />
                    )}
                  />
                </Form>
              </Formik>
            )}
          </ServiceConfigPanel>

          <ServiceConfigPanel
            title={language.short_stop_elimination_key}
            description={language.short_stop_elimination_description_key}
          >
            {() => (
              <Formik
                initialValues={
                  service.configuration.algoSettings
                    .conditionalStopEliminationSettings
                }
                innerRef={form3}
                enableReinitialize={true}
              >
                <Form className="form-horizontal">
                  <FormGroupLayout
                    xs="12"
                    xl="8"
                    label={language.max_duration_description_key}
                    rightRender={() => (
                      <ServiceInput
                        name="maximumStopToEliminate"
                        inputType={"number"}
                        unit={language.seconds_key}
                      />
                    )}
                  />
                  <FormGroupLayout
                    xs="12"
                    xl="8"
                    label={language.max_distance_to_drop_start_location_key}
                    rightRender={() => (
                      <ServiceInput
                        name="maxDistanceBetweenStopAndStartLocationToDropStart"
                        inputType={"number"}
                        unit={language.meters_key}
                      />
                    )}
                  />
                </Form>
              </Formik>
            )}
          </ServiceConfigPanel>

          {/* Details trip configuration */}
          <ServiceConfigPanel
            title={language.detailed_activity_key}
            description={language.detailed_activity_description_key}
          >
            {() => (
              <Formik
                initialValues={service.configuration.detailsActivityConfig}
                innerRef={form4}
                enableReinitialize={true}
              >
                {({ values, setFieldValue }) => {
                  return (
                    <Form className="form-horizontal">
                      <FormGroupLayout
                        xs="12"
                        xl="8"
                        label={language.expose_detailed_activity_key}
                        rightRender={() => (
                          <SntCheckBox
                            checked={values.exposeDetailedActivity}
                            onChange={(value) => {
                              setFieldValue("exposeDetailedActivity", value);
                            }}
                          />
                        )}
                      />
                      <FormGroupLayout
                        xs="12"
                        xl="8"
                        label={language.trips_to_exclude_key}
                        rightRender={() => (
                          <ServiceInput
                            name="tripsToExclude"
                            inputType={"number"}
                            unit={language.km_key}
                            min={0}
                          />
                        )}
                      />
                    </Form>
                  );
                }}
              </Formik>
            )}
          </ServiceConfigPanel>
        </>
      )}
    </SntDialog>
  );
};

export default AcceleroBaseActivityServiceConfig;
