import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SntDialog from "../../components/SntDialog/SntDialog";
import RemoteSetting from "../DeviceUsageProfile/RemoteSetting";

const RemoteSettings = ({
  fwParams,
  isShow,
  settings,
  onSave,
  onCancel,
  configurationStyle = "SNT",
}) => {
  const language = useSelector((state) => state.language);
  const [option, setOption] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!isShow) return;
    if (settings.length) {
      let data = { deviceFwParameterSettings: settings };
      setOption(data);
      setData(settings);
    } else {
      setOption({ deviceFwParameterSettings: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  function settingChange(setting) {
    setData(setting.deviceFwParameterSettings);
  }

  return (
    <SntDialog
      isShow={isShow}
      onSave={() => onSave(data)}
      onClose={() => onCancel()}
      saveTxt={language.save_key}
      title="Add remote settings"
      minBodyHeight="300px"
    >
      {() => (
        <RemoteSetting
          option={option}
          fwParams={fwParams.data}
          supportedFWPresets={fwParams.supportedFWPresets}
          enumTypes={fwParams.enumTypes}
          bitmaskTypes={fwParams.bitmaskTypes}
          onOptionChange={(settings) => settingChange(settings)}
          configurationStyle={configurationStyle}
        />
      )}
    </SntDialog>
  );
};

export default RemoteSettings;
