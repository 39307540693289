import { useEffect, useState } from "react";
import { apiClient } from "./apiClient";
import geozoneTagClient from "./geozoneTagClient";

export default {
  getGeozoneTagsByOrg(orgId) {
    return apiClient.get(`/rest/geozone_tag/getByOrg?orgId=${orgId}`);
  },

  getGeozoneTags() {
    return apiClient.get(`/rest/geozone_tag/getByLoginUser`);
  },

  search(data, orgId) {
    return apiClient.post(`/rest/geozone_tag/search/${orgId}`, data, {
      headers: { "content-type": "application/json" },
    });
  },

  checkTag(id) {
    return apiClient.get(`/rest/geozone_tag/checkTag/${id}`);
  },

  delete(id) {
    return apiClient.delete(`/rest/geozone_tag/${id}`);
  },

  getById(id) {
    return apiClient.get(`/rest/geozone_tag/${id}`);
  },

  create(orgId, body) {
    return apiClient.post(`/rest/geozone_tag/${orgId}`, body, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  update(id, body) {
    return apiClient.put(`/rest/geozone_tag/${id}`, body, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
};
export const getGeozoneTags = {
  getAll(params) {
    return apiClient.get("/rest/geozone_tag/", params);
  },
};
export const useGetGeozoneTags = (orgId) => {
  const [tags, setTags] = useState([]);
  useEffect(() => {
    orgId &&
      geozoneTagClient.getGeozoneTagsByOrg(orgId).then(({ data }) => {
        let permissionList = [];
        for (let i = 0; i < data.length; i++) {
          permissionList.push({
            label: data[i].name,
            value: data[i].id,
          });
        }
        setTags(permissionList);
      });
  }, [orgId]);
  return tags;
};
