import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import { useOutsideClick } from "rooks";
import styled from "styled-components";
import SntCheckBox from "@/components/SntCheckBox/SntCheckBox";
import IcomoonIcon from "@/components/Icons/IcomoonIcon";
import SntCloseIcon from "@/components/Icons/SntCloseIcon";
import SntDialog from "@/components/SntDialog/SntDialog";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import SntFullScreenDialog from "@/components/SntDialog/SntFullScreenDialog";

const ConfigLayout = styled.table`
  width: 100%;
  margin-bottom: 0px;

  tr td:first-child {
    width: 30%;
    padding-right: 1rem;
  }

  .list-group {
    width: 100%;
  }

  .list-group-item-selected {
    background: #eff3f4;
    padding: 0.3rem 0.5rem;
    margin-bottom: 0.25rem;
    border-radius: 4px;
    &.group {
      background: white;
      border: 1px solid var(--bs-list-group-border-color);
    }
  }
  .list-group-item-unselected {
    border-radius: 0;
    border-bottom: 1px solid var(--bs-list-group-border-color);
  }

  .list-group-item-selected .sortIconHover {
    opacity: 0;
  }

  .list-group-item-selected:hover {
    .sortIconHover {
      opacity: 1;
    }
  }

  .column-scroll {
    overflow: hidden auto;
  }
`;

export const SelectorDialog = ({
  title,
  data,
  setData,
  isShowConfigDialog,
  onDone,
  onCancel,
  hideAvailable = false,
  isLayerSelector,
  isFullScreenMode,
  isSingle,
  children,
}) => {
  const { filter, allItems } = data;

  const [searchColumn, setSearchColumn] = useState("");
  const [selectedSearchItem, setSelectedSearchItem] = useState("");
  const [columnCategories, setColumnCategories] = useState([]);
  const [columnsByKey, setColumnsByKey] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const ref = useRef();
  const [sortBy, setSortBy] = useState();

  const language = useSelector((state) => state.language);
  const { isMobile } = useSelector((state) => state.all);
  const initializeCategories = useCallback((allItems) => {
    let groupByKey = {},
      groupList = [],
      columnMap = {};

    if (allItems) {
      for (let i = 0; i < allItems.length; i++) {
        let obj = allItems[i];
        // skip immovables
        if (obj.immovable) {
          continue;
        }
        columnMap[obj.columnKey] = obj;

        let key = obj.categoryId || obj.category;

        let groupItem = groupByKey[key];
        if (!groupItem) {
          groupItem = {
            key: key,
            items: [],
            groupColumns: [],
            category: obj.category,
          };
          groupByKey[key] = groupItem;
          groupList.push(groupItem);
        }
        groupItem.items.push(obj);
        if (!obj.disabledGroupColumn) {
          groupItem.groupColumns.push(obj);
        }
      }
    }

    setColumnCategories(
      groupList.filter((item) => {
        return item.items.length > 0;
      })
    );

    setColumnsByKey(columnMap);
  }, []);

  const initializeSelection = useCallback((filterColumns, columnsByKey) => {
    let selectedList = [];

    // dont show items in group
    if (filterColumns) {
      for (let i = 0; i < filterColumns.length; i++) {
        let columnKey = filterColumns[i].columnKey;
        let obj = columnsByKey[columnKey];

        // should not happen !
        if (!obj) continue;
        obj.opacity = filterColumns[i].opacity;
        selectedList.push(transformItemByKey(columnKey, obj));
      }
    }

    setSelectedColumns(selectedList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transformItemByKey = (columnKey, obj) => {
    return {
      ...obj,
      key: columnKey,
      Header: obj.Header || obj.title || obj.description,
      disabledGroupColumn: obj.disabledGroupColumn,
      description: obj.description,
    };
  };

  const onSaveSettingsPopup = () => {
    setData({
      ...data,
      filter: {
        items: selectedColumns,
      },
    });
    onDone();
  };

  useOutsideClick(ref, onSaveSettingsPopup);

  useEffect(() => {
    initializeCategories(allItems);
  }, [allItems, initializeCategories]);

  useEffect(() => {
    initializeSelection(filter.items, columnsByKey);
  }, [filter.items, columnsByKey, initializeSelection]);

  useEffect(() => {
    setSearchColumn("");
    setSelectedSearchItem("");
    if (filter && filter.sortBy) {
      setSortBy({
        key: filter.sortBy.column.sortKey,
        desc: filter.sortBy.desc,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowConfigDialog]);

  const onCloseDialog = () => {
    onCancel();
  };

  const onSelectedColumn = (key) => {
    let obj = columnsByKey[key];
    if (selectedColumns.length === 0) {
      setSortBy({ ...sortBy, key: key });
    }

    if (isSingle) {
      setSelectedColumns([transformItemByKey(key, obj)]);
    } else {
      setSelectedColumns([...selectedColumns, transformItemByKey(key, obj)]);
    }
  };
  const onUnSelectedColumn = (key) => {
    const list = selectedColumns.filter((_key) => _key.key !== key);
    setSelectedColumns(list);

    // can remove all items, so list can be empty
    if (sortBy && sortBy.key === key && list.length) {
      setSortBy({ ...sortBy, key: list[0].key });
    }
  };

  const renderAvailableSection = () => {
    return availableSection(
      columnCategories,
      selectedColumns,
      onSelectedColumn
    );
  };

  const availableSection = (categories, selected, onClick) => {
    categories.sort((a, b) => a?.category?.localeCompare(b?.category));

    return (
      <div
        className="column-scroll snt-scroll"
        style={{ maxHeight: isMobile ? "40vh" : "65vh" }}
      >
        {categories.map((item, index) => {
          let _showGroup = false;

          const _listItems = item.items
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((obj, idx) => {
              let _title = obj.settingTitle || obj.title || "";
              let isShowItem = false;
              if (
                _title.toLowerCase().indexOf(searchColumn.toLowerCase()) > -1 &&
                selected.filter((item) => item.key === obj.columnKey).length ===
                  0
              ) {
                _showGroup = true;
                isShowItem = true;
              }

              if (!isShowItem) return <span key={idx}></span>;
              return (
                <li
                  key={idx}
                  className="list-group-item list-group-item-unselected"
                >
                  <Row>
                    <Col xs={6} xl={6}>
                      <SntCheckBox
                        checked={false}
                        onChange={() => {
                          onClick(obj.columnKey);
                        }}
                        label={
                          <>
                            <span className="ps-1">
                              {obj.settingTitle || obj.title}
                            </span>
                          </>
                        }
                      />
                    </Col>
                    <Col xs={6} xl={6}>
                      {obj.description && obj.description && (
                        <div
                          className="d-flex"
                          style={{
                            color: "#424B59",
                          }}
                        >
                          <div style={{ minWidth: 24 }}>
                            <IcomoonIcon icon="info" size={18} />
                          </div>
                          <div className="flex-grow-1">{obj.description}</div>
                        </div>
                      )}
                    </Col>
                  </Row>
                </li>
              );
            });

          return (
            <div
              key={index}
              className="mb-3"
              style={{ display: _showGroup ? "block" : "none" }}
            >
              {item.category && (
                <label className="fw-bold">{item.category}</label>
              )}
              <ul className="list-group caterogy-group">{_listItems}</ul>
            </div>
          );
        })}
      </div>
    );
  };

  const renderSelectedSection = () => {
    return columnsSelectedSection();
  };

  const columnsSelectedSection = () => {
    return (
      <>
        {selectedSection(
          selectedColumns,
          setSelectedColumns,
          onUnSelectedColumn
        )}
      </>
    );
  };

  const selectedSection = (selected, setSelected, onClick) => {
    let groupByKey = {},
      groupList = [],
      columnMap = {};
    for (let i = 0; i < selected.length; i++) {
      let obj = selected[i];
      // skip immovables
      if (obj.immovable) {
        continue;
      }
      columnMap[obj.columnKey] = obj;

      let key = obj.categoryId || obj.category;

      let groupItem = groupByKey[key];
      if (!groupItem) {
        groupItem = {
          key: key,
          items: [],
          groupColumns: [],
          category: obj.category,
        };
        groupByKey[key] = groupItem;
        groupList.push(groupItem);
      }
      groupItem.items.push(obj);
      if (!obj.disabledGroupColumn) {
        groupItem.groupColumns.push(obj);
      }
    }
    groupList.filter((item) => {
      return item.items.length > 0;
    });

    groupList.sort((a, b) => a?.category?.localeCompare(b?.category));

    return (
      <div
        className="column-scroll snt-scroll"
        style={{ maxHeight: isMobile ? "40vh" : "65vh" }}
      >
        {groupList.map((item, index) => {
          let _showGroup = false;
          const _listItems = item.items
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((item, index) => {
              let displayClass = "";
              let _title = item.settingTitle || item.title || item.Header || "";

              let isShowItem = false;
              if (
                _title.toLowerCase().indexOf(selectedSearchItem.toLowerCase()) >
                -1
              ) {
                isShowItem = true;
                _showGroup = true;
              }
              if (!isShowItem) return <span key={index}></span>;

              return (
                <>
                  <li
                    key={index}
                    className={`list-group-item list-group-item-selected ${displayClass}`}
                  >
                    <Row>
                      <Col xs={6} md={7}>
                        <div>{item.Header}</div>
                      </Col>
                      <Col xs={6} md={5}>
                        {!hideAvailable ? (
                          <div className="text-end">
                            <>
                              <IcomoonIcon
                                style={{
                                  visibility: !item.description
                                    ? "hidden"
                                    : "unset",
                                }}
                                icon="info"
                                className={"me-2"}
                                size={18}
                                title={item.description}
                              />
                              <SntCloseIcon
                                className={"me-2"}
                                onClick={() => {
                                  onClick(item.key);
                                }}
                              />
                            </>
                          </div>
                        ) : (
                          <>
                            {item.description && item.description && (
                              <div
                                className="d-flex"
                                style={{
                                  color: "#424B59",
                                }}
                              >
                                <div style={{ minWidth: 24 }}>
                                  <IcomoonIcon icon="info" size={18} />
                                </div>
                                <div className="flex-grow-1">
                                  {item.description}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  </li>
                  {isLayerSelector && (
                    <li
                      style={{
                        border: "1px solid #dee2e6",
                        borderRadius: 4,
                        padding: "0.3rem 0.5rem",
                        marginBottom: "0.25rem",
                      }}
                    >
                      <Row>
                        <Col xs={12}>{language.opacity_key}</Col>
                        <Col xs={12} className="ms-1">
                          <Slider
                            allowCross={false}
                            style={{ width: "98%" }}
                            min={0.1}
                            max={1}
                            step={0.1}
                            value={item.opacity || 1}
                            onChange={(e) => {
                              let _selectedLayers = selectedColumns.map((s) =>
                                s.key === item.key
                                  ? {
                                      ...item,
                                      opacity: e,
                                    }
                                  : s
                              );
                              setData({
                                allItems: [...allItems],
                                filter: {
                                  items: _selectedLayers,
                                },
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    </li>
                  )}
                </>
              );
            });

          return (
            <div
              key={index}
              className="mb-3"
              style={{ display: _showGroup ? "block" : "none" }}
            >
              {item.category && (
                <label className="fw-bold">{item.category}</label>
              )}
              <ul className="list-group caterogy-group">{_listItems}</ul>
            </div>
          );
        })}
      </div>
    );
  };

  const bodyRender = () => {
    return (
      <>
        {children && children()}
        <ConfigLayout className="table table-bordered">
          {isMobile ? (
            <tbody>
              <tr>
                <td className="fw-bold">{language.selected_key}</td>
              </tr>
              <tr>
                <td>
                  <div className="mb-2">
                    <b>{selectedColumns?.length || 0}</b>{" "}
                    {language.items_selected_key}
                  </div>
                  <Form.Control
                    placeholder={language.search_key}
                    value={selectedSearchItem}
                    onChange={(e) => setSelectedSearchItem(e.target.value)}
                    size="md"
                    className="mb-2"
                  />
                  {renderSelectedSection()}
                </td>
              </tr>
              <tr>
                {!hideAvailable && (
                  <td className="fw-bold">{language.available_key}</td>
                )}
              </tr>
              <tr>
                {!hideAvailable && (
                  <td>
                    <div className="mb-2">
                      <b>{allItems?.length - selectedColumns?.length || 0}</b>{" "}
                      {language.available_items_key}
                    </div>

                    <Form.Control
                      placeholder={language.search_key}
                      value={searchColumn}
                      onChange={(e) => setSearchColumn(e.target.value)}
                      size="md"
                      className="mb-2"
                    />
                    {renderAvailableSection()}
                  </td>
                )}
              </tr>
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td className="fw-bold">{language.selected_key}</td>
                {!hideAvailable && (
                  <td className="fw-bold">{language.available_key}</td>
                )}
              </tr>
              <tr>
                <td style={{ verticalAlign: "top" }}>
                  <div className="mb-2">
                    <b>{selectedColumns?.length || 0}</b>{" "}
                    {language.items_selected_key}
                  </div>
                  <Form.Control
                    placeholder={language.search_key}
                    value={selectedSearchItem}
                    onChange={(e) => setSelectedSearchItem(e.target.value)}
                    size="md"
                    className="mb-2"
                  />
                  {renderSelectedSection()}
                </td>
                {!hideAvailable && (
                  <td style={{ verticalAlign: "top" }}>
                    <div className="mb-2">
                      <b>{allItems?.length - selectedColumns?.length || 0}</b>{" "}
                      {language.available_items_key}
                    </div>

                    <Form.Control
                      placeholder={language.search_key}
                      value={searchColumn}
                      onChange={(e) => setSearchColumn(e.target.value)}
                      size="md"
                      className="mb-2"
                    />
                    {renderAvailableSection()}
                  </td>
                )}
              </tr>
            </tbody>
          )}
        </ConfigLayout>
      </>
    );
  };

  if (isFullScreenMode) {
    return (
      <SntFullScreenDialog
        isShow={isShowConfigDialog}
        onSave={onSaveSettingsPopup}
        onClose={onCloseDialog}
        title={title || language.select_items_key}
        saveTxt={language.ok_key}
        isShowSaveButton={!hideAvailable}
        closeTxt={!hideAvailable ? language.cancel_key : language.close_key}
        refWrapper={ref}
        customModalClassname={!hideAvailable && "snt-config-column-modal"}
        bodyClass={"p-0"}
      >
        {bodyRender}
      </SntFullScreenDialog>
    );
  }
  return (
    <SntDialog
      isShow={isShowConfigDialog}
      onSave={onSaveSettingsPopup}
      onClose={onCloseDialog}
      title={title || language.select_items_key}
      saveTxt={language.ok_key}
      isShowSaveButton={!hideAvailable}
      closeTxt={!hideAvailable ? language.cancel_key : language.close_key}
      refWrapper={ref}
      customModalClassname={!hideAvailable && "snt-config-column-modal"}
      bodyClass={"p-0"}
    >
      {bodyRender}
    </SntDialog>
  );
};

export default SelectorDialog;
