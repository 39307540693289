import * as types from "./ActionTypes";

export const login = (username, password, history) => {
  return {
    type: types.LOG_IN,
    username,
    password,
    history,
  };
};

export const logout = (callBack) => {
  return {
    type: types.LOG_OUT,
    callBack,
  };
};

export const loginSuccess = (data) => {
  return {
    type: types.LOG_IN_SUCCESS,
    data,
  };
};

export const updateProfile = (data) => {
  return {
    type: types.UPDATE_PROFILE,
    data,
  };
};

export const updateLanguage = (data) => {
  return {
    type: types.LANGUAGE,
    data,
  };
};

export const addHistoryCount = () => {
  return {
    type: types.ADD_HISTORY,
  };
};
export const backHistory = () => {
  return {
    type: types.BACK_HISTORY,
  };
};
export const setIsMobile = (data) => {
  return {
    type: types.SET_IS_MOBILE,
    data,
  };
};

export const updateCustumizationUserInfo = (data) => {
  return {
    type: types.INTERFACE,
    data,
  };
};

export const updateMessage = (data) => {
  return {
    type: types.MESSAGE,
    data,
  };
};

export const setShowCanvasMenu = (data) => {
  return {
    type: types.SHOW_CANVAS_MENU,
    data,
  };
};

export const setLogoPath = (data) => {
  return {
    type: types.LOGO_PATH,
    data,
  };
};

export const setMenuType = (data) => {
  return {
    type: types.MENU_TYPE,
    data,
  };
};
export const storeLayer = (data) => {
  return {
    type: types.STORE_LAYERS,
    data,
  };
};
export const reloadSidebar = () => {
  return {
    type: types.RELOAD_SIDEBAR,
  };
};

export const setDashboardNotification = (data) => {
  return {
    type: types.DASHBOARD_NOTIFICATION,
    data: data || null,
  };
};

export const setGlobalNotification = (notifications) => {
  return {
    type: types.GLOBAL_NOTIFICATION,
    data: notifications || [],
  };
};

export const setGlobalNotificationCount = (count) => {
  return {
    type: types.GLOBAL_NOTIFICATION_COUNT,
    data: count,
  };
};

export const setShowGlobalNotification = (data) => {
  return {
    type: types.SHOW_NOTIFICATION,
    data: data,
  };
};

export const preventSidebar = (data) => {
  return {
    type: types.PREVENT_SIDEBAR,
    data: data,
  };
};
