const getGroupColumnData = (visisbledColumns) => {
  // find any count/avg/min/max columns are visible
  let allGroupColumns = [],
    groupColumn = null,
    groups = [],
    metricData = [];

  groupColumn = visisbledColumns.find((item) => item.isGrouped);
  if (groupColumn) {
    let metricColumns = visisbledColumns.filter((item) => item.aggregationType);

    allGroupColumns = [groupColumn, ...metricColumns];

    metricData = metricColumns.map((item) => {
      return {
        columnKey: item.columnKey,
        aggregationType: item.aggregationType,
        aggregateColumn: item.aggregateColumn,
        columnGroupKey: item.groupKey, // columnKey of group Column
      };
    });

    groups = [
      {
        columnKey: groupColumn.columnKey,
        aggregateColumn: groupColumn.aggregateColumn,
        bucketType: groupColumn.bucketType,
        bucketConfig: groupColumn.bucketConfig,
        columnGroupKey: groupColumn.columnKey,
      },
      ...metricData,
    ];
  }

  return {
    groupColumn, // the groupColumn
    metricData, // count/min/max/avg,..
    groups, // data store in backend
    allGroupColumns, // contain group column, and metric columns
  };
};
export { getGroupColumnData };
