import { useEffect } from "react";

// 3rd parties
import { Form } from "react-bootstrap";
import { useField } from "formik";

// owner
import SntSelect from "@wrappers/ReactSelect/SntSelect";

const SntItemSelectorField = ({
  name,
  placeholder = "",
  disabled = false,
  options = [],
  handleChange,
  filterOption = undefined,
  isClearable,
}) => {
  const [field, meta, helper] = useField(name);

  useEffect(() => {
    handleChange && handleChange(field.value);
    // eslint-disable-next-line
  }, [field.value]);

  useEffect(() => {
    if (
      field.value &&
      options.length &&
      !options.find((ops) => ops.value === field.value.value)
    ) {
      helper.setValue(null);
    }
    // eslint-disable-next-line
  }, [options]);

  let formGroupClass = "";
  if (meta && meta.touched) {
    if (meta.error) {
      formGroupClass = "is-invalid";
    } else {
      formGroupClass = "is-valid";
    }
  }

  const getValue = () => {
    let itemSelected = null;
    if (options.length > 0) {
      if (field.value) {
        itemSelected = options.find((ops) => ops.value === field.value.value);
      }
    }
    return itemSelected;
  };

  const setValue = (option) => {
    if (option === null) {
      helper.setValue(null);
    } else {
      helper.setValue(option);
    }
  };

  return (
    <div>
      <SntSelect
        className={`${formGroupClass}`}
        name={name}
        placeholder={placeholder}
        options={options}
        isDisabled={disabled}
        value={getValue()}
        onChange={setValue}
        filterOption={filterOption}
        isClearable={isClearable}
      />
      <Form.Control.Feedback
        type="invalid"
        className={`${formGroupClass} ${
          formGroupClass === "is-invalid" ? "d-block" : "d-none"
        }`}
      >
        {meta.error}
      </Form.Control.Feedback>
    </div>
  );
};

export default SntItemSelectorField;
