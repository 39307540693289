import { apiClient } from "./apiClient";
import { useEffect, useState } from "react";

export const product = {
  getAllProducts(params) {
    return apiClient.get("/rest/device_usage_profile/allProduct", {
      params: params,
    });
  },
};

export const useProducts = (category) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    product.getAllProducts({ category: category }).then(({ data }) => {
      setProducts(data);
    });
  }, [category]);

  return products;
};
