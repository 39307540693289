import { useEffect } from "react";

import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import queryString from "query-string";
import LoaderSpinner from "@/components/ReactBootstrap/LoaderSpinner/LoaderSpinner";

import sigfoxDeviceClient from "@/apis/sigfoxDeviceClient";
import {
  BleSensorRoute,
  DeviceAdminRoute,
  InfraRoute,
} from "@/cleanup/utils/routesUtil/routesUtil";

const AssetLinkPage = ({ reload }) => {
  const history = useHistory();

  const location = useLocation();
  let { id } = queryString.parse(location.search);

  useEffect(() => {
    sigfoxDeviceClient
      .findOne({ id: id })
      .then(({ data }) => {
        if (data.deviceCategory === "TRACKER") {
          history.push(
            DeviceAdminRoute.getUpdatePage({
              orgId: data.ownedByOrg.id,
              id: id,
            })
          );
        } else if (data.deviceCategory === "INFRA") {
          history.push(
            InfraRoute.getUpdatePage({
              orgId: data.ownedByOrg.id,
              id: id,
            })
          );
        } else {
          history.push(
            BleSensorRoute.getUpdatePage({
              orgId: data.ownedByOrg.id,
              id: id,
            })
          );
        }
      })
      .catch((error) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return <LoaderSpinner />;
};

export default AssetLinkPage;
