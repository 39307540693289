const _deviceAdminRoute = {
  page: "/trackers_admin",
  updatePage: "/trackers_admin_update",
  createPage: "",
  getPage: (data) => {
    return `/trackers_admin?org=${data.orgId}`;
  },
  getUpdatePage: (data) => {
    return `/trackers_admin_update?id=${data.id}&tabActive=${
      data.tabActive || ""
    }&type=${data.type || "view"}&org=${data.orgId}`;
  },
  getCreatePage: (data) => {
    if (data?.orgId) return `/trackers_admin_update?org=${data.orgId}`;
    return `/trackers_admin_update?`;
  },
};

export default _deviceAdminRoute;
