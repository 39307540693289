import { useEffect } from "react";

import Creatable from "react-select/creatable";
import { useField, useFormikContext } from "formik";
import { Form } from "react-bootstrap";

const ItemCreatable = ({
  name,
  multi = false,
  placeholder = "",
  disabled = false,
  options = [],
  handleChange,
}) => {
  const [field, meta] = useField(name);

  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    handleChange && handleChange(field.value);
    // eslint-disable-next-line
  }, [field.value]);

  let formGroupClass = "";
  if (meta && meta.touched) {
    if (meta.error) {
      formGroupClass = "is-invalid";
    } else {
      formGroupClass = "is-valid";
    }
  }

  useEffect(() => {
    if (
      !options.find((ops) => field.value && ops.value === field.value.value) &&
      options[0] &&
      !multi
    ) {
      setFieldValue(
        name,
        options.find((ops) => ops.value === meta.initialValue.value) ||
          options[0]
      );
    }
    // eslint-disable-next-line
  }, [options]);

  const getValue = () => {
    if (options.length > 0) {
      if (field.value) {
        if (multi) {
          let value = options.filter(
            (ops) => field.value.filter((f) => f.value === ops.value).length > 0
          );
          let new_values = field.value.filter((i) => i.__isNew__);
          new_values.forEach((v) => value.push(v));
          return value;
        } else {
          return options.find((ops) => ops.value === field.value.value);
        }
      } else {
        return multi ? [] : null;
      }
    } else {
      if (field.value) {
        // show the current values, will be replaced when we get the options choice
        return field.value;
      } else {
        return multi ? [] : null;
      }
    }
  };

  const setValue = (option) => {
    if (option === null) {
      setFieldValue(name, multi ? [] : null);
    } else {
      setFieldValue(name, option);
    }
  };

  return (
    <>
      <Creatable
        className={`${formGroupClass}`}
        name={name}
        placeholder={placeholder}
        isMulti={multi}
        options={options}
        isDisabled={disabled}
        value={getValue()}
        onChange={setValue}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
      />
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </>
  );
};

export default ItemCreatable;
