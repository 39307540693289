import SntIcon from "./SntIcon";

const SntClipboardIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 1H5V13H13V1ZM4 0V14H14V0H4Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 13V2H2V16H12V13H5ZM11 14H4V3H3V15H11V14Z"
      />
      <path d="M6 3H12V4H6V3Z" />
      <path d="M6 9H12V10H6V9Z" />
      <path d="M6 7H12V8H6V7Z" />
      <path d="M6 5H12V6H6V5Z" />
    </SntIcon>
  );
};

export default SntClipboardIcon;
