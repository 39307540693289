import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import searchUIClient from "@/apis/searchUIClient";
import SntAdvancedSearchTableViewCard from "@/components/SntTableViewCard/SntAdvancedSearchTableViewCard";

import AdminLayout from "@/containers/AdminLayout/AdminLayout";
import ActionToolEnum from "@/containers/AdminLayout/Toolbar/ActionToolEnum";
import ActionToolType from "@/containers/AdminLayout/Toolbar/ActionToolType";

import SntDateView from "@/components/ReactBootstrap/SntDateView";
import {
  ACTION_MAPPING,
  CONDITION_MAPPING,
} from "../components/automationRuleUtils";
import { DangerTag, SuccessTag } from "@/components/ReactBootstrap/TagMore";
import DateRangeContainer from "@/cleanup/containers/dates/DateRangeContainer";
import DateModeView from "@/cleanup/widgets/dates/DateModeView";
import moment from "moment";
import { SensolusDatetimeReact } from "@/constants/Config";
import SntAssetLinkContainer from "@/cleanup/containers/layouts/SntAssetLinkContainer";

const PAGE_NAME = "AUTOMATION_EVENTS";
const DEFAULT_OPTION = {
  pageName: PAGE_NAME,
  queryObjectType: "AUTOMATION_EVENT",
  filtersVisibleAtInit: [
    "automationResultIdentifier",
    "automationName",
    "currentActionType",
    "currentConditionType",
  ],
  optionsSettings: {},
};
const AUTOMATION_EVENT_SESSION = "AUTOMATION_EVENT_SESSION";

const EventTab = ({ orgId, id, data }) => {
  //redux state
  const language = useSelector((state) => state.language);
  const table = useRef();
  const loginInfo = useSelector((state) => state.user);
  const { weekStart } = loginInfo;

  const getSessionState = () => {
    let cachedDateTimePicker = {
      viewMode: DateModeView.day,
      start: weekStart
        ? moment().startOf("isoWeek")
        : moment().startOf("isoWeek").subtract(1, "days"),
      end: weekStart
        ? moment().endOf("isoWeek")
        : moment().endOf("isoWeek").subtract(1, "days"),
    };
    let cached = sessionStorage.getItem(AUTOMATION_EVENT_SESSION);

    if (cached) {
      let json = JSON.parse(cached);
      cachedDateTimePicker = {
        start: moment(json.startDate),
        end: moment(json.endDate),
        viewMode: json.defaultViewMode || DateModeView.week,
      };
    }
    return cachedDateTimePicker;
  };

  const setSessionState = (data) => {
    let _data = {
      ...data,
      defaultDates: {
        startDate: data.startDate,
        endDate: data.endDate,
        viewMode: data.viewMode,
      },
    };
    sessionStorage.setItem(AUTOMATION_EVENT_SESSION, JSON.stringify(_data));
  };

  const handleTimeRangeChange = (dates) => {
    const timeFilter = {
      defaultViewMode: dates.viewMode,
      viewMode: dates.viewMode,
      startDate: dates.start,
      endDate: dates.end,
    };
    setSessionState({
      startDate: dates.start,
      endDate: dates.end,
      viewMode: dates.viewMode,
      defaultViewMode: dates.viewMode,
    });
    setTimeRange({ ...timeFilter });
    table.current && table.current.refresh();
  };

  const [timeRange, setTimeRange] = useState();

  const [initialDefaultValue, setInitialDefaultValue] = useState(
    () => getSessionState(),
    []
  );

  const [forceFilterAction, setForceFilterAction] = useState(null);
  const [filterMap, setFilterMap] = useState(null);

  const filterList = [
    {
      id: ActionToolEnum.save_filter,
      title: language.save_filter,
      type: ActionToolType.filter,
    },
    {
      id: ActionToolEnum.load_filter,
      title: language.load_filter,
      type: ActionToolType.filter,
    },
  ];

  const allColumns = useMemo(() => {
    const allColumns = [
      {
        key: "deviceName",
        Cell: ({ cell, value }) => {
          return (
            <SntAssetLinkContainer
              text={value}
              id={cell.row.original.deviceId}
            />
          );
        },
      },
      {
        key: "deviceSerial",
        Cell: ({ cell, value }) => {
          return (
            <SntAssetLinkContainer
              text={value}
              id={cell.row.original.deviceId}
            />
          );
        },
      },
      {
        key: "recordedAt",
        Cell: ({ cell, value }) => {
          return <SntDateView value={value} other={""} format="lll" />;
        },
      },
      {
        key: "currentActionType",
        Cell: ({ cell, value }) => {
          return <>{ACTION_MAPPING[value]?.getLabel(language) || ""}</>;
        },
      },
      {
        key: "executedAction",
        Cell: ({ cell, value }) => {
          if (!value) return <></>;
          return (
            <>
              {ACTION_MAPPING[cell.row.original.currentActionType]?.getCell(
                value,
                language
              ) || ""}
            </>
          );
        },
      },
      {
        key: "currentConditionType",
        Cell: ({ cell, value }) => {
          return <>{CONDITION_MAPPING[value]?.getLabel(language) || ""}</>;
        },
      },
      {
        key: "status",
        Cell: ({ cell, value }) => {
          if (value === "SUCCESS") {
            return <SuccessTag tagName={value} />;
          }
          return <DangerTag tagName={value} />;
        },
      },
      {
        key: "error",
        Cell: ({ cell, value }) => {
          return <>{value}</>;
        },
      },
    ];
    return allColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultColumnInit = useMemo(
    () => [
      "automationName",
      "deviceName",
      "deviceSerial",
      "recordedAt",
      "error",
      "status",
    ],
    []
  );

  const onDataRequestChange = (data, callback) => {
    const { params, dataOptions } = data;
    if (table.current && id !== null && id !== undefined) {
      table.current.setLoading(true);

      params.query = params.query.filter(function (param) {
        return param.filterKey !== "recoredAt" && param.filterKey !== "automationIdentifier";
      });

      params.query.push({
        categoryId: "automation_event",
        filterKey: "recordedAt",
        filterType: "DATE",
        searchType: "STATIC",
        notFilter: false,
        filterValue: {
          dateFilterType: "BETWEEN_ABSOLUTE",
          lastValue: null,
          lastUnit: null,
          intervalSign: null,
          absoluteStartDate: timeRange.startDate.format(
            SensolusDatetimeReact.ISO_8601_PATTERN
          ),
          absoluteEndDate: timeRange.endDate.format(
            SensolusDatetimeReact.ISO_8601_PATTERN
          ),
          relativeStartDate: null,
          relativeEndDate: null,
        },
      });

      params.query.push({
        categoryId: "automation_event",
        filterKey: "automationIdentifier",
        filterType: "MULTI_STRING",
        searchType: "STATIC",
        notFilter: false,
        filterValue: {
          searchText: id+""
        }
      });
      

      let _params = {
        ...params,
        selectedOrgId: orgId,
      };

      searchUIClient.search({ ..._params }).then(
        ({ data }) => {
          if (table.current) {
            table.current.updateDataTable({
              ...data,
              pageCount: data.countByFilter,
              totalItem: {
                countAll: data.countAll,
                countByFilter: data.countByFilter,
              },
            });
            table.current.setLoading(false);
            const dataCallback = table.current.getDataCallBack(
              params,
              dataOptions,
              data
            );
            callback(dataCallback[0], dataCallback[1]);
          }
        },
        (err) => {
          table.current.setLoading(false);
        }
      );
    }
  };

  return (
    <div>
      <AdminLayout
        pageName={PAGE_NAME}
        defaultOption={DEFAULT_OPTION}
        isAdvanceFilter={true}
        filterList={filterList}
        isShowHeader={false}
        restrictedFilters={["recordedAt", "automationIdentifier"]}
        topContent={() => (
          <DateRangeContainer
            value={initialDefaultValue}
            onChange={(value) => {
              setInitialDefaultValue({
                ...initialDefaultValue,
                ...value,
              });
              setTimeout(() => {
                handleTimeRangeChange(value);
              }, 100);
            }}
            overrideDisplays={{ year: false }}
            custom={{ hideRanges: { today: true, yesterday: true } }}
            enableTime={true}
          />
        )}
        body={({ isDisabled, dataOptions, onChangeChildren }) =>
          dataOptions && (
            <>
              {timeRange && (
                <SntAdvancedSearchTableViewCard
                  shared={(child) => (table.current = child)}
                  allColumns={allColumns}
                  visibleCols={defaultColumnInit}
                  disabled={isDisabled}
                  onChange={(data) => {
                    onDataRequestChange(data, onChangeChildren);
                  }}
                  dataOptions={dataOptions}
                  isDisabledCopyData={false}
                  isDisabbledAddFilter={false}
                  onFilterClicked={(action, key, filterKeys, query) => {
                    setForceFilterAction({ action, key, filterKeys, query });
                  }}
                  filterMap={filterMap}
                />
              )}
            </>
          )
        }
        forceFilterAction={forceFilterAction}
        setForceFilterAction={setForceFilterAction}
        onLoadFilter={(data) => setFilterMap(data)}
      ></AdminLayout>
    </div>
  );
};

export default EventTab;
