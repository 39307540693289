const _infraRoute = {
  page: "/infrastructures_admin",
  updatePage: "/infrastructures_update",
  createPage: "",
  getPage: (data) => {
    return `/infrastructures_admin?org=${data.orgId}`;
  },
  getUpdatePage: (data) => {
    return `/infrastructures_update?id=${data.id}&tabActive=${
      data.tabActive || ""
    }&type=${data.type || "view"}&org=${data.orgId}`;
  },
  getCreatePage: (data) => {
    if (data?.orgId) return `/infrastructures_update?org=${data.orgId}`;
    return `/infrastructures_update?`;
  },
};

export default _infraRoute;
