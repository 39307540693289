import { apiClient } from "./apiClient";

export default {
  getScalarDefinition() {
    return apiClient.get(`/rest/diagnostic_time_series_data/scalar_definition`);
  },
  getDiagnosticTimeSeriesDataFromLatestSnapshotByDevice(deviceId) {
    return apiClient.get(
      `/rest/diagnostic_time_series_data/latest_data_from_snapshot_by_device/${deviceId}`
    );
  },
  getSupportedScalars(deviceId) {
    return apiClient.get(
      `/rest/diagnostic_time_series_data/supported-scalars/${deviceId}`
    );
  },
  getSupportedDiagnosticMemberData(deviceId) {
    return apiClient.get(
      `/rest/diagnostic_time_series_data/supported-diagnostic-member-data/${deviceId}`
    );
  },
  getDiagnosticTimeSeriesDataBySnapshot(params) {
    return apiClient.post(
      "/rest/diagnostic_time_series_data/data_by_snapshot",
      params
    );
  },
  getOverview(orgId) {
    return apiClient.get(`/rest/diagnostic_time_series_data/overview/${orgId}`);
  },
  getSnapshotsByOrg(orgId) {
    return apiClient.get(
      `/rest/diagnostic_time_series_data/snapshots/${orgId}`
    );
  },
};
