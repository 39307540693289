import { useEffect, useState } from "react";
import { apiClient } from "./apiClient";
import apiUltils from "./apiUltils";

const processDefinitionClient = {
  findAll() {
    return apiClient.get(`/rest/processdefinition`);
  },

  delete(id) {
    return apiClient.delete(`/rest/processdefinition/${id}`);
  },

  getByOrg(orgId, cancelToken) {
    return apiClient.get("/rest/processdefinition/org/" + orgId, {
      cancelToken: cancelToken,
    });
  },
  getById(id) {
    return apiClient.get(`/rest/processdefinition/${id}`);
  },
  getDownloadUrl(id) {
    return (
      apiClient.defaults.baseURL + `/rest/processdefinition/descriptor/${id}`
    );
  },
  create(params) {
    return apiClient.post(`/rest/processdefinition/`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
  update(id, params) {
    return apiClient.put(`/rest/processdefinition/${id}`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
  uploadDesciptor(id, data) {
    return apiClient.post(
      apiUltils.buildParamsListURI(`/rest/processdefinition/descriptor/${id}`),
      data,
      {
        headers: { "content-type": "multipart/form-data" },
      }
    );
  },
  changeImage(id, params) {
    return apiClient.put(
      apiUltils.buildParamsListURI(
        `/rest/processdefinition/changeImage/${id}`,
        params
      ),
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },
  validateDescriptor(id, value) {
    return apiClient.post(
      `/rest/processdefinition/validateDescriptor/${id}`,
      value,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  getByDevice(deviceId) {
    return apiClient.get("/rest/processdefinition/device/" + deviceId);
  },
  searchProcess(params) {
    return apiClient.post("/rest/processdefinition/search", params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
};

export default processDefinitionClient;

export const useProcessDefinition = (deviceId) => {
  const [processDefinitions, setProcessDefinitions] = useState([]);
  useEffect(() => {
    processDefinitionClient
      .getByDevice(deviceId)
      .then(({ data }) => {
        let list = [];
        for (let i = 0; i < data.length; i++) {
          list.push({
            label: data[i].name,
            value: data[i].id,
          });
        }
        setProcessDefinitions(
          list.sort((a, b) => (a.value > b.value ? 1 : -1))
        );
      })
      .catch(() => {
        console.log("Failed to load process definition of device");
      });
  }, [deviceId]);
  return processDefinitions;
};
