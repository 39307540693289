import areaClient from "@/apis/areaClient";
import geozoneClient from "@/apis/geozoneClient";
import geozoneTagClient from "@/apis/geozoneTagClient";
import SntNameAndTagSelectorField from "@/cleanup/widgets/formiks/SntNameAndTagSelectorField";

const MonitoredGeozoneContainer = ({ name, orgId, disabled, language }) => {
  const loadGeozonesOptions = async (search, loadedOptions) => {
    let _data = [];
    let _hasMore = false;
    const { data } = await geozoneClient.getBasicGeozonesByOrg(orgId);
    data.forEach((item) => {
      _data.push({ value: item.id, label: item.text });
    });

    if (search === "") {
      _data = _data.slice(loadedOptions.length, loadedOptions.length + 50);
    } else {
      let filtered = _data.filter(
        (option) =>
          option.label.toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
      _data = filtered.slice(loadedOptions.length, loadedOptions.length + 50);
    }
    _hasMore = loadedOptions.length + 50 < _data.length;
    return {
      options: _data,
      hasMore: _hasMore,
    };
  };

  const loadGeozoneTagsOptions = async (search, loadedOptions) => {
    let _data = [];
    let _hasMore = false;
    const { data } = await geozoneTagClient.getGeozoneTagsByOrg(orgId);

    data.forEach((item) => {
      _data.push({ value: item.id, label: item.name });
    });

    if (search === "") {
      _data = _data.slice(loadedOptions.length, loadedOptions.length + 50);
    } else {
      let filtered = _data.filter(
        (option) =>
          option.label.toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
      _data = filtered.slice(loadedOptions.length, loadedOptions.length + 50);
    }
    _hasMore = loadedOptions.length + 50 < _data.length;
    return {
      options: _data,
      hasMore: _hasMore,
    };
  };

  const getGeozoneLengthByType = async (monitorItem) => {
    if (monitorItem.selectType === "ALL") {
      const { data } = await geozoneClient.getBasicGeozonesByOrg(orgId);
      return data.length;
    } else if (monitorItem.selectType === "TAG") {
      const { data } = await areaClient.countGeozonesByTagIds({
        geozoneTagId: monitorItem.selectedIds || [],
      });
      return data;
    }

    return monitorItem.selectedIds.length;
  };

  return (
    <>
      <SntNameAndTagSelectorField
        language={language}
        name={name}
        disabled={disabled}
        orgId={orgId}
        loadDataByIds={loadGeozonesOptions}
        loadDataByTags={loadGeozoneTagsOptions}
        getLengthByType={getGeozoneLengthByType}
        lengthText={language.geozone_s_key}
      />
    </>
  );
};

export default MonitoredGeozoneContainer;
