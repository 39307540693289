import { useField } from "formik";

const ScriptInput = ({ name, className = "", rows = "", disabled = false }) => {
  const [field] = useField(name);
  return (
    <textarea
      name={name}
      disabled={disabled}
      className={`${className} form-control`}
      rows={rows}
      {...field}
    />
  );
};
export default ScriptInput;
