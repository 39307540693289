import { Button } from "react-bootstrap";
/**
 *
 * @param {*} param0
 * @returns
 */
const SntFullScreenDialog = ({
  isShow,
  onSave,
  onClose,
  children,
  title,
  saveTxt,
  closeTxt,
  minBodyHeight,
  btnSaveClassName = "sensolus",
  isShowCloseButton = true,
  isShowSaveButton = true,
  refWrapper,
  backdrop = true,
  customModalClassname = "",
  bodyClass,
}) => {
  function onSaveClicked() {
    onSave && onSave();
  }

  function onCloseClicked() {
    onClose && onClose();
  }

  if (!isShow) return <></>;

  return (
    <div>
      <div
        className="fade modal-backdrop show"
        onClick={(e) => {
          console.log("Clicked");
          if (e.currentTarget === e.target) {
            onClose();
          }
        }}
      ></div>
      <div
        role="dialog"
        style={{ display: "block" }}
        ref={refWrapper}
        className={`fade snt-config-column-modal modal show ${customModalClassname}`}
      >
        <div className="modal-dialog modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title h4">{title}</div>
                {isShowCloseButton && (
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={onCloseClicked}
                  ></button>
                )}
              </div>
              <div
                className={`${bodyClass || ""} snt-scroll modal-body`}
                style={{ minHeight: minBodyHeight }}
              >
                {children && children()}
              </div>

              {(isShowSaveButton || isShowCloseButton) && (
                <div className="d-flex justify-content-start modal-footer">
                  {isShowSaveButton && (
                    <Button variant={btnSaveClassName} onClick={onSaveClicked}>
                      {saveTxt || "Save"}
                    </Button>
                  )}
                  {isShowCloseButton && (
                    <Button variant="outline-sensolus" onClick={onCloseClicked}>
                      {closeTxt || "Close"}
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SntFullScreenDialog;
