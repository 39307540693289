import { useState } from "react";
import SntDialog from "../../../components/SntDialog/SntDialog";

const useErrorDialog = ({ language = {}, title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState("");

  const [errorTitle, setTitle] = useState(title);

  const handleOpen = (content, errorTitleText) => {
    setContent(content || language.error_key);
    setTitle(errorTitleText || title);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const ErrorDialog = () => {
    return (
      <SntDialog
        isShow={isOpen}
        onSave={handleClose}
        onClose={handleClose}
        saveTxt={language.ok_key}
        btnSaveClassName="danger"
        isShowCloseButton={false}
        title={errorTitle || language.error_key}
      >
        {() => <div>{content}</div>}
      </SntDialog>
    );
  };

  return { handleOpen, handleClose, ErrorDialog };
};

export default useErrorDialog;
