import { useField } from "formik";

import { Form, Row } from "react-bootstrap";
import SntTooltip from "../../../components/Tooltip/SntTooltip.js";
import { useEffect } from "react";

const ServiceInput = ({
  name,
  placeholder = "",
  tooltip = "",
  unit,
  readOnly = false,
  inputType,
  min = null,
  max = null,
  handleChange,
}) => {
  const [field, meta] = useField(name);
  let formGroupClass = "";

  useEffect(() => {
    handleChange && handleChange(field.value);
    // eslint-disable-next-line
  }, [field.value]);

  if (meta && meta.touched) {
    if (meta.error) {
      formGroupClass = "is-invalid";
    } else {
      formGroupClass = "is-valid";
    }
  }

  return (
    <Row>
      <div className="d-flex">
        <div className="align-self-center">
          <Form.Control
            className={`${formGroupClass}`}
            type={inputType ? inputType : "text"}
            placeholder={placeholder}
            readOnly={readOnly}
            min={min !== null ? min : undefined}
            max={max !== null ? max : undefined}
            {...field}
            value={
              field.value === undefined || field.value === null
                ? ""
                : field.value
            }
          />
        </div>
        <div className="align-self-center">
          {tooltip && (
            <span className="ms-2">
              <SntTooltip text={tooltip} />
            </span>
          )}
          {unit && <div className="float-start ms-2">{unit}</div>}
        </div>
      </div>

      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </Row>
  );
};
export default ServiceInput;
