import SntIcon from "./SntIcon";

const SntLabIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6853 5.44L14.6665 12.3116C15.0897 13.0695 15.121 13.9789 14.6978 14.7537C14.306 15.5284 13.5693 16 12.7386 16H3.24012C2.4094 16 1.68839 15.5284 1.28087 14.7537C0.889016 13.9789 0.90469 13.0695 1.34356 12.3116L5.34044 5.44C5.45016 5.2379 5.51286 5.00211 5.51286 4.76632V1.14526H5.08966C4.79185 1.14526 4.55674 0.892632 4.55674 0.572632C4.55674 0.252632 4.79185 0 5.08966 0H10.9361C11.2339 0 11.469 0.252632 11.469 0.572632C11.469 0.892632 11.2339 1.14526 10.9361 1.14526H10.5129V4.76632C10.5129 5.00211 10.5756 5.2379 10.6853 5.44ZM4.63846 8.79999H11.4029L9.80754 6.04632C9.57243 5.65895 9.46271 5.22105 9.46271 4.76632V1.14526H6.57869V4.76632C6.57869 5.22105 6.4533 5.65895 6.23386 6.04632L4.63846 8.79999Z"
      />
    </SntIcon>
  );
};

export default SntLabIcon;
