import { apiClient } from "./apiClient";
import UTILS from "./apiUltils";

export default {
  getAllAlertRules: function (params, cancelToken) {
    var uri = encodeURI(UTILS.buildParamsListURI("", params));
    return apiClient.get("/rest/alertManagement" + uri, {
      cancelToken: cancelToken,
    });
  },

  delete(id) {
    return apiClient.delete(`/rest/alertManagement/${id}`);
  },

  active(params) {
    // have no idea in the back-end API, why is it only use the path param instead of the body?
    return apiClient.post(
      `/rest/alertManagement/active/${params.id}/${params.active}`,
      params,
      {
        headers: { "content-type": "application/json" },
      }
    );
  },

  getAllAlertRuleTypeCategoryDescriptor(viewMode, orgId) {
    return apiClient.get(
      `/rest/alertManagement/alertRule/types/catalog/${viewMode}?orgId=${orgId}`
    );
  },

  countDevicesForAlertRule(filterType, params) {
    let uri = encodeURI(
      UTILS.buildParamsListURI("countDevicesForAlertRule", params)
    );
    return apiClient.get("/rest/alertManagement/" + filterType + "/" + uri);
  },

  getNotificationTemplatesByOrg(orgId) {
    let uri = "/rest/alertManagement/notification/template";
    if (orgId) {
      uri += "/byOrg/" + orgId;
    }
    return apiClient.get(uri);
  },

  create(orgId, params) {
    return apiClient.post(`/rest/alertManagement?orgId=${orgId}`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  update(ruleId, orgId, params) {
    return apiClient.put(
      `/rest/alertManagement/${ruleId}?orgId=${orgId}`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },

  findOne: function (ruleId) {
    return apiClient.get(`/rest/alertManagement/${ruleId}`);
  },

  search(params) {
    return apiClient.post(`/rest/alertManagement/search`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
};
