import { Card } from "react-bootstrap";

import SntTooltip from "@/components/Tooltip/SntTooltip";

/**
 * Detail panel with general styles.
 *
 * @param {componentElement} icon - The image to be displayed.
 * @param {string} title - The title of the detail panel.
 * @param {componentElement} tooltip - Optional tooltip element.
 * @param {children} children - Child components passed into SntDetailPanel.
 * @param {string} rightTitle - The rightTitle header.
 *
 */
const SntDetailPanel = ({
  icon,
  title,
  tooltip,
  rightTitle,
  showBottomLine = true,
  children,
  className = "",
}) => {
  return (
    <Card className={`border-0 ${className}`}>
      <Card.Body style={{ padding: "16px 0px" }}>
        {title && (
          <Card.Title>
            <div className="d-flex justify-content-between">
              <div className="align-self-center">
                {icon ? (
                  <>
                    {icon} <span className="me-2"></span>
                  </>
                ) : (
                  ""
                )}
                {title ? (
                  <span className="align-middle me-2">{title}</span>
                ) : (
                  ""
                )}
                {tooltip ? <SntTooltip text={tooltip} /> : ""}
              </div>
              <div className="align-self-center">
                <div className="float-end">
                  {rightTitle ? rightTitle : <></>}
                </div>
              </div>
            </div>
          </Card.Title>
        )}
        <div>{children}</div>
      </Card.Body>
      {showBottomLine && <hr className="m-0" />}
    </Card>
  );
};

export default SntDetailPanel;
