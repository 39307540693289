import { useEffect, useState } from "react";
import { FormFullScreenLayout } from "@/components/ReactBootstrap/FormValidation";
import SntSortList from "./SntSortList";
import SntButton from "@/cleanup/widgets/forms/SntButton";
import SelectUsageProfile from "@/pages/TrackersAdminUpdate/TrackerConfiguration/ProfileConfig/SelectUsageProfile";
import { useField } from "formik";
import { Form } from "react-bootstrap";
import deviceUsageProfile from "@/apis/deviceUsageProfile";

const PushTrackerUsageProfielActionControl = ({
  name,
  orgId,
  disabled,
  language,
}) => {
  const [field, meta, helper] = useField(name);

  const [profiles, setProfiles] = useState([]);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [filterProfiles, setFilterProfiles] = useState([]);

  const [isShowPopup, setShowPopup] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    deviceUsageProfile
      .getByOrg(orgId)
      .then(({ data }) => {
        setProfiles(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [orgId]);

  useEffect(() => {
    if (field.value?.length && profiles.length) {
      setSelectedProfiles(
        field.value.map((item) => {
          let { profile, categories } = item;

          let findProfile = profiles.find((p) => p.id === profile.id);
          let text = "";

          if (findProfile) {
            text = findProfile.name; // profile can only has id, don't have name. So, use findProfile
            let notDefaultList = [];
            categories.forEach((category) => {
              let findCategory = findProfile.categories.find(
                (c) => c.name === category.categoryName
              );
              if (findCategory) {
                let findOption = findCategory.options.find(
                  (option) => option.name === category.optionName
                );
                if (findOption && !findOption.isDefault) {
                  notDefaultList.push(
                    category.categoryName + ": " + findOption.uiLabel
                  );
                }
              }
            });

            if (notDefaultList.length) {
              text += " (" + notDefaultList.join(", ") + ")";
            }
          }

          return {
            id: profile.id,
            label: text,
            deviceSettings: {
              appliedDeviceUsageProfile: profile,
              appliedProfileCategories: categories
                .map((c) => {
                  return c.categoryName + ":" + c.optionName;
                })
                .join(";"),
            },
          };
        })
      );
    } else {
      setSelectedProfiles([]);
    }
    // eslint-disable-next-line
  }, [field.value, profiles]);

  useEffect(() => {
    let selectedIds = selectedProfiles.map((item) => item.id);
    let filterList = profiles.filter((p) => !selectedIds.includes(p.id));
    setFilterProfiles(filterList);
  }, [profiles, selectedProfiles]);

  const onSelectProfileSave = (data) => {
    // convert data to form value
    let categories = data.categories.map((item) => {
      let option = item.options[0];
      return {
        categoryName: item.name,
        optionName: option.name,
      };
    });

    let list = field.value || [];

    if (selectedItem) {
      let updateItem = list.find(
        (value) => value.profile.id === selectedItem.id
      );
      updateItem.profile = data.profile;
      updateItem.categories = categories;
    } else {
      list.push({
        profile: data.profile,
        categories: categories,
      });
    }

    helper.setValue([...list]);
  };

  const onRemoveProfile = (item, idx) => {
    let list = field.value.filter(
      (fieldValue) => fieldValue.profile.id !== item.id
    );
    helper.setValue(list);
  };

  const onEditProfile = (item, idx) => {
    let selectedItem = selectedProfiles.find((value) => value.id === item.id);
    if (selectedItem) {
      setSelectedItem(selectedItem);
      setShowPopup(true);
    }
  };

  const onOrderList = (data) => {
    if (data) {
      let list = [];
      data.forEach((item) => {
        let findOne = field.value?.find((val) => val.profile.id === item.id);
        if (findOne) {
          list.push(findOne);
        }
      });
      helper.setValue(list);
    }
  };

  let formGroupClass = "";
  if (meta && meta.touched) {
    if (meta.error) {
      formGroupClass = "is-invalid";
    } else {
      formGroupClass = "is-valid";
    }
  }

  return (
    <>
      <SelectUsageProfile
        profiles={filterProfiles}
        isShow={isShowPopup}
        setIsShow={setShowPopup}
        onSave={onSelectProfileSave}
        deviceSettings={selectedItem?.deviceSettings}
      />

      <FormFullScreenLayout
        label={language.select_tracker_usage_profile_key}
        rightRender={() => (
          <>
            {selectedProfiles.length ? (
              <SntSortList
                value={selectedProfiles}
                setValue={onOrderList}
                onEdit={onEditProfile}
                onRemove={onRemoveProfile}
                disabled={disabled}
              />
            ) : (
              <></>
            )}
            <div>
              {!disabled && (
                <SntButton
                  text={language.add_tracker_usage_profile_key}
                  onClick={() => {
                    setSelectedItem(null);
                    setShowPopup(true);
                  }}
                />
              )}
            </div>
            <Form.Control.Feedback
              type="invalid"
              className={`${formGroupClass} ${
                formGroupClass === "is-invalid" ? "d-block" : "d-none"
              }`}
            >
              {meta.error}
            </Form.Control.Feedback>
          </>
        )}
      />
    </>
  );
};

export default PushTrackerUsageProfielActionControl;
