import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import {
  FormGroupLayout,
  InputCheck,
  ItemSelector,
} from "../../../components/ReactBootstrap/FormValidation/index.js";
import SntDialog from "../../../components/SntDialog/SntDialog.js";
import ServiceInput from "../Utils/ServiceInput.js";

const InfrastructureServiceConfig = ({
  isShow,
  setIsShow,
  selectedService,
  onSave,
  parentComponent,
}) => {
  const language = useSelector((state) => state.language);
  const formikRefs = useRef();

  const LOCATION_RESOLUTION_ALLOWANCE = [
    { value: "GLOBAL", label: language.ble_resolution_global_key },
    { value: "PARTNER", label: language.ble_resolution_partner_key },
    { value: "OWNER_ORGANISATION", label: language.ble_resolution_org_key },
  ];
  const [showGeobeaconSerial, setShowGeobeaconSerial] = useState(true);

  const [service, setService] = useState({
    type: "INFRASTRUCTURE",
    active: true,
    configuration: {
      locationResolutionAllowance: { value: "GLOBAL" },
      secret: null,
      wifiPassword: null,
      scanIntervalSeconds: 600,
      autoLocated: true,
      geobeaconSerial: null,
    },
  });

  useEffect(() => {
    if (selectedService && isShow) {
      if (parentComponent === "services") {
        setShowGeobeaconSerial(true);
      } else {
        setShowGeobeaconSerial(false);
      }
      let config = selectedService?.configuration
        ? { ...selectedService?.configuration }
        : { ...service.configuration };
      config.locationResolutionAllowance = {
        value:
          selectedService?.configuration?.locationResolutionAllowance ||
          "GLOBAL",
      };
      config.geobeaconSerial = selectedService.geobeaconSerial;
      setService({ ...service, configuration: { ...config } });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService, isShow]);

  const onSubmit = (values) => {
    onSave({
      ...service,
      configuration: {
        locationResolutionAllowance: values.locationResolutionAllowance.value,
        secret: values.secret,
        wifiPassword: values.wifiPassword,
        scanIntervalSeconds: values.scanIntervalSeconds,
        autoLocated: values.autoLocated,
        geobeaconSerial: values.geobeaconSerial,
      },
    });
    setIsShow(false);
  };

  const validationSchema = Yup.object().shape({
    scanIntervalSeconds: Yup.number()
      .required(language.valid_required_key)
      .test(
        "Is positive?",
        "ERROR: The number must be greater than 0!",
        (value) => value > 0
      ),
  });

  return (
    <SntDialog
      isShow={isShow}
      onSave={() => formikRefs.current.handleSubmit()}
      onClose={() => setIsShow(false)}
      saveTxt={language.save_key}
      title={language.v40_define_services_to_enable_disable_key}
    >
      {() => (
        <Formik
          initialValues={service.configuration}
          innerRef={formikRefs}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form className="form-horizontal">
            {showGeobeaconSerial ? (
              <FormGroupLayout
                xl="6"
                label={language.geobeacon_serial_key}
                rightRender={() => <ServiceInput name="geobeaconSerial" />}
              />
            ) : (
              ""
            )}

            <FormGroupLayout
              xl="6"
              label={language.location_resolution_allowance_key}
              rightRender={() => (
                <ItemSelector
                  name="locationResolutionAllowance"
                  options={LOCATION_RESOLUTION_ALLOWANCE}
                />
              )}
            />

            <FormGroupLayout
              xl="6"
              label={language.secret}
              rightRender={() => (
                <ServiceInput name="secret" placeholder={language.secret} />
              )}
            />

            <FormGroupLayout
              xl="6"
              label={language.wifi_password_key}
              rightRender={() => (
                <ServiceInput
                  name="wifiPassword"
                  placeholder={language.wifi_password_key}
                />
              )}
            />

            <FormGroupLayout
              xl="6"
              label={language.scan_interval_key}
              rightRender={() => (
                <ServiceInput
                  name="scanIntervalSeconds"
                  inputType="number"
                  placeholder={language.scan_interval_key}
                />
              )}
            />

            <FormGroupLayout
              xl="6"
              rightRender={() => (
                <InputCheck name="autoLocated" label={language.autoLocated} />
              )}
            />
          </Form>
        </Formik>
      )}
    </SntDialog>
  );
};

export default InfrastructureServiceConfig;
