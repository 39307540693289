import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";

import PageHeader from "@/containers/PageHeader/PageHeader";
import { useOrg } from "@/contexts/OrgContext";

import AutomationRuleForm from "../components/AutomationRuleForm";
import automationRuleClient from "@/apis/automationRuleClient";
import { AutomationRuleRoute } from "@/cleanup/utils/routesUtil/routesUtil";

const AutomationRuleCreate = ({ reload }) => {
  const language = useSelector((state) => state.language);
  const { orgId } = useOrg();

  const [count, setCount] = useState(0);
  useEffect(() => {
    setCount((prevCount) => {
      const newCount = prevCount + 1;
      return newCount;
    });
  }, [reload, orgId]);

  if (!orgId || !count) return <></>;

  return (
    <>
      <PageHeader title={language.automation_rule_create_key} />
      <AutomationBody key={count} orgId={orgId} />
    </>
  );
};

const AutomationBody = ({ orgId }) => {
  const history = useHistory();

  const onSubmit = (values) => {
    automationRuleClient
      .createAutomationRule(values)
      .then((result) => {
        history.push(AutomationRuleRoute.getPage({ orgId: orgId }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <AutomationRuleForm disabled={false} onSubmit={onSubmit} orgId={orgId} />
  );
};

export default AutomationRuleCreate;
