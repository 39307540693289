import { Form as Formk, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { Col, Form, Row } from "react-bootstrap";
import ErrorLabel from "../../components/ReactBootstrap/FormValidation/ErrorLabel";
import {
  InputCheck,
  ItemSelector,
} from "../../components/ReactBootstrap/FormValidation";
import SntDialog from "../../components/SntDialog/SntDialog";
import ServiceConfig from "../ServicesConfiguration/ServiceConfig";
import RemoteSetting from "./RemoteSetting";
import SntPanel from "@/components/Panel/SntPanel";
import InputTextAlwaysShowIfError from "./InputTextAlwaysShowWarnIfError";

const AddOptionDetails = ({
  category,
  fwParams,
  mode,
  services,
  isShowOptionDetails,
  selectedOption,
  onSave,
  onCancel,
  numericVariables,
  isCloneAction,
  allowEditName,
}) => {
  const language = useSelector((state) => state.language);
  const optionDetailRef = useRef();
  const formikRef = useRef();

  const [isShowConfirm, setShowConfirm] = useState(false);
  const [selectedService, setSelectedService] = useState({});
  const [nameEditable, setNameEditable] = useState(false);

  const batteryImpacts = [
    { value: "WORST", label: "WORST" },
    { value: "WORSE", label: "WORSE" },
    { value: "NEUTRAL", label: "NEUTRAL" },
    { value: "BETTER", label: "BETTER" },
    { value: "BEST", label: "BEST" },
  ];

  const [option, setOption] = useState({
    name: "",
    uiLabel: "",
    description: "",
    isDefault: false,
    batteryImpact: batteryImpacts[2],
    deviceFwParameterSettings: [],
    profileServiceUpdates: [],
  });

  const [error, setError] = useState("");

  useEffect(() => {
    if (selectedOption && isShowOptionDetails) {
      let option = { ...selectedOption };
      option.batteryImpact =
        batteryImpacts.find((b) => b.value === option.batteryImpact) ||
        batteryImpacts[2];
      setOption(option);
      setSelectedService(selectedOption.profileServiceUpdates);

      if (selectedOption.name === "" || allowEditName || isCloneAction) {
        setNameEditable(true);
      } else {
        setNameEditable(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, isCloneAction, allowEditName]);

  function settingChange(settings) {
    setOption({
      ...option,
      deviceFwParameterSettings: settings.deviceFwParameterSettings,
    });
    setError("");
    forceFormikValidate();
  }

  function forceFormikValidate() {
    formikRef.current.validateForm();
    formikRef.current.setFieldTouched("name", true, true);
  }

  function serviceChange(serviceConfig) {
    setOption({ ...option, profileServiceUpdates: serviceConfig });
    forceFormikValidate();
  }

  function onSettingsSave(name) {
    validationSchema.isValid(option).then(function (valid) {
      if (valid) {
        onSave(name, {
          ...option,
          batteryImpact: option.batteryImpact.value || "NEUTRAL",
        });
        setShowConfirm(false);
      }
    });
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().test({
      test: function (name) {
        if (!name) {
          return this.createError({
            message: language.valid_required_key,
            path: "name",
          });
        } else if (name.includes(";") || name.includes(":")) {
          return this.createError({
            message: `Not allowed special character(";" or ":")`,
            path: "name",
          });
        } else if (
          category?.options.filter((o) => o.name === name).length &&
          (isCloneAction ? true : selectedOption.name !== name)
        ) {
          return this.createError({
            message: `Duplicate ID in the category`,
            path: "name",
          });
        }
        return true;
      },
    }),
    uiLabel: Yup.string().test({
      test: function (uiLabel) {
        if (!uiLabel) {
          return this.createError({
            message: language.valid_required_key,
            path: "uiLabel",
          });
        } else if (uiLabel.includes(";") || uiLabel.includes(":")) {
          return this.createError({
            message: `Not allowed special character(";" or ":")`,
            path: "uiLabel",
          });
        } else if (
          category?.options.filter((o) => o.uiLabel === uiLabel).length &&
          (isCloneAction ? true : selectedOption.uiLabel !== uiLabel)
        ) {
          return this.createError({
            message: `Duplicate UI label in the category`,
            path: "uiLabel",
          });
        }
        return true;
      },
    }),
  });

  function onClose() {
    let changed = {
      ...option,
      batteryImpact: option.batteryImpact.value || "NEUTRAL",
    };
    validationSchema.isValid(changed).then(function (valid) {
      if (valid) {
        if (JSON.stringify(selectedOption) !== JSON.stringify(changed)) {
          setShowConfirm(true);
        }
      }
      onCancel();
    });
  }

  function onConfirmYes() {
    onSettingsSave(category.name);
    setShowConfirm(false);
  }

  function onConfirmNo() {
    setShowConfirm(false);
  }

  return (
    <div ref={optionDetailRef}>
      <SntDialog
        isShow={isShowConfirm}
        onSave={() => onConfirmYes()}
        onClose={() => onConfirmNo()}
        saveTxt={language.yes_key}
        closeTxt={language.no_key}
        title={language.add_option_key}
      >
        {() => language.you_have_unsaved_changes_key}
      </SntDialog>

      <SntDialog
        isShow={isShowOptionDetails}
        onSave={() => onSettingsSave(category.name)}
        onClose={() => onClose()}
        saveTxt={language.save_key}
        title={language.add_option_key}
        customModalClassname={"snt-config-column-modal"}
      >
        {() => (
          <>
            <Formik
              initialValues={option}
              enableReinitialize={true}
              validationSchema={validationSchema}
              innerRef={formikRef}
              validateOnChange
            >
              {({ setFieldTouched }) => (
                <Formk className="form-horizontal">
                  <SntPanel title={language.tracker_admin_general_setting}>
                    <Row className="mb-3">
                      <Col xs="12">
                        <Form.Label>{language.id_upper}*</Form.Label>
                      </Col>
                      <Col xs="12">
                        <InputTextAlwaysShowIfError
                          name="name"
                          required
                          handleChange={(name) => {
                            setOption({ ...option, name: name });
                            setFieldTouched("name", true, true);
                          }}
                          disabled={mode !== "add" && !nameEditable}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs="12">
                        <Form.Label>{language.ui_label}*</Form.Label>
                      </Col>
                      <Col xs="12">
                        <InputTextAlwaysShowIfError
                          name="uiLabel"
                          required
                          handleChange={(uiLabel) =>
                            setOption({ ...option, uiLabel: uiLabel })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs="12">
                        <Form.Label>{language.description_key}</Form.Label>
                      </Col>
                      <Col xs="12">
                        <InputTextAlwaysShowIfError
                          name="description"
                          placeholder={language.description_key}
                          handleChange={(description) =>
                            setOption({ ...option, description: description })
                          }
                        />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col xs="12">
                        <Form.Label>{language.battery_impact_key}</Form.Label>
                      </Col>
                      <Col xs="12">
                        <ItemSelector
                          name="batteryImpact"
                          options={batteryImpacts}
                          handleChange={(selected) =>
                            setOption({
                              ...option,
                              batteryImpact: batteryImpacts.find(
                                (b) => b.value === selected.value
                              ),
                            })
                          }
                        />
                      </Col>
                    </Row>
                    {category.visualization === "NUMERIC_VALUE" ? (
                      ""
                    ) : (
                      <Row className="mb-3">
                        <Col xs="12">
                          <InputCheck
                            name="isDefault"
                            label={language.mark_option_as_default_key}
                            handleChange={(value) =>
                              setOption({ ...option, isDefault: value })
                            }
                          />
                        </Col>
                      </Row>
                    )}
                  </SntPanel>
                </Formk>
              )}
            </Formik>

            <SntPanel title={language.defined_remote_settings}>
              <Col xs="12" className="mb-3">
                <RemoteSetting
                  option={option}
                  fwParams={fwParams.data}
                  supportedFWPresets={fwParams.supportedFWPresets}
                  enumTypes={fwParams.enumTypes}
                  bitmaskTypes={fwParams.bitmaskTypes}
                  onOptionChange={(settings) => settingChange(settings)}
                  numericVariables={numericVariables}
                />
              </Col>
            </SntPanel>

            <SntPanel title={language.defined_service}>
              <ServiceConfig
                parentEl={optionDetailRef}
                mode={mode}
                services={services}
                selectedService={selectedService}
                onSave={(serviceConfig) => serviceChange(serviceConfig)}
              />
            </SntPanel>
            <ErrorLabel error={error} />
          </>
        )}
      </SntDialog>
    </div>
  );
};

export default AddOptionDetails;
