import SntIcon from "./SntIcon";

const SntSensorIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.97647 1.18182C9.47343 1.18182 10.8872 1.75455 11.9499 2.8L12.8 1.96364C11.5156 0.7 9.79685 0 7.97647 0C6.1561 0 4.48443 0.653247 3.2 1.91688L4.05012 2.75325C5.11278 1.70779 6.47951 1.18182 7.97647 1.18182ZM7.97647 1.7C9.33482 1.7 10.6192 2.21818 11.5803 3.16364L10.7301 4C9.9909 3.28182 9.02065 2.88182 7.97647 2.88182C6.9323 2.88182 5.96205 3.27273 5.22281 4L4.37269 3.16364C5.33369 2.21818 6.61812 1.7 7.97647 1.7ZM9.6 5.6C9.6 6.19223 9.27824 6.7093 8.8 6.98595V8L11.6 8C12.7046 8 13.6 8.89543 13.6 10V14C13.6 15.1046 12.7046 16 11.6 16H4.4C3.29543 16 2.4 15.1046 2.4 14V10C2.4 8.89543 3.29543 8 4.4 8L7.2 8V6.98595C6.72176 6.7093 6.4 6.19223 6.4 5.6C6.4 4.71634 7.11635 4 8 4C8.88366 4 9.6 4.71634 9.6 5.6ZM9.6 12C9.6 12.8837 8.88366 13.6 8 13.6C7.11635 13.6 6.4 12.8837 6.4 12C6.4 11.1163 7.11635 10.4 8 10.4C8.88366 10.4 9.6 11.1163 9.6 12Z"
      />
    </SntIcon>
  );
};

export default SntSensorIcon;
