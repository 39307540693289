import { useEffect } from "react";

import { useField } from "formik";
import { Form, Row } from "react-bootstrap";

// owner component
import SntLink from "@/components/ReactBootstrap/SntLink";
import styled from "styled-components";

const Styles = styled.div`
  input.form-control-color-custom {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: transparent;
  }
  input.form-control-color-custom::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to top left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgba(255, 17, 0, 1) 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    );
  }
`;

const SntColorField = ({
  name = "backgroundColor",
  language,
  onChange,
  disabled = false,
  placeholder = "",
  isHideResetAction = false,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helper] = useField(name);

  useEffect(() => {
    onChange && onChange(field.value);
    // eslint-disable-next-line
  }, [field.value]);

  let formGroupClass = "";
  if (meta && meta.touched) {
    if (meta.error) {
      formGroupClass = "is-invalid";
    } else {
      formGroupClass = "is-valid";
    }
  }

  const customClss = field.value ? "" : "form-control-color-custom";

  return (
    <>
      <Row className="d-flex align-items-center">
        <div style={{ width: "fit-content" }}>
          <Styles>
            <Form.Control
              type="color"
              className={`${formGroupClass} ${customClss}`}
              title={placeholder}
              disabled={disabled}
              {...field}
              value={field.value || "#FFFFFF"}
              onChange={(e) => {
                field.onChange(e);
                onChange && onChange(e.target);
              }}
            />
          </Styles>
        </div>
        {!isHideResetAction && (
          <div style={{ width: "fit-content" }}>
            <SntLink onClick={() => helper.setValue(null)}>
              {language.reset_to_default_key}
            </SntLink>
          </div>
        )}
      </Row>
      <div>
        <Form.Control.Feedback
          type="invalid"
          className={`${formGroupClass} ${
            formGroupClass === "is-invalid" ? "d-block" : "d-none"
          }`}
        >
          {meta.error}
        </Form.Control.Feedback>
      </div>
    </>
  );
};

export default SntColorField;
