const DeviceProfileConfigurationAnchorMenu = ({ items, onItemClick }) => {
  return items?.length ? (
    <ul className="list-unstyled">
      {items.map((item) => (
        <li
          key={item.name}
          className={`${item.isHeader ? " " : "ps-4"} mt-2 text-truncate`}
        >
          <a
            href="##"
            onClick={(e) => {
              if (onItemClick) {
                e.preventDefault();
                e.stopPropagation();
                onItemClick(item);
              }
            }}
            title={item.name}
          >
            {item.name}
          </a>
        </li>
      ))}
    </ul>
  ) : (
    ""
  );
};

export default DeviceProfileConfigurationAnchorMenu;
