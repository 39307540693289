import { useEffect } from "react";

// 3rd parties
import { Form } from "react-bootstrap";
import { useField } from "formik";

// owner
import SntSelect from "@wrappers/ReactSelect/SntSelect";

const SntMultipleIetemSelectorField = ({
  name,
  placeholder = "",
  disabled = false,
  options = [],
  handleChange,
  filterOption = undefined,
}) => {
  const [field, meta, helper] = useField(name);

  useEffect(() => {
    handleChange && handleChange(field.value);
    // eslint-disable-next-line
  }, [field.value]);

  let formGroupClass = "";
  if (meta && meta.touched) {
    if (meta.error) {
      formGroupClass = "is-invalid";
    } else {
      formGroupClass = "is-valid";
    }
  }

  const getValue = () => {
    let itemsSelected = [];
    if (options.length > 0) {
      if (field.value) {
        itemsSelected = options.filter((ops) =>
          field.value.find((f) => f.value === ops.value) ? true : false
        );
      }
    }
    return itemsSelected;
  };

  const setValue = (option) => {
    if (option === null) {
      helper.setValue([]);
    } else {
      helper.setValue(option);
    }
  };

  return (
    <div>
      <SntSelect
        className={`${formGroupClass}`}
        name={name}
        placeholder={placeholder}
        options={options}
        isDisabled={disabled}
        value={getValue()}
        onChange={setValue}
        filterOption={filterOption}
        isMulti={true}
      />
      <Form.Control.Feedback
        type="invalid"
        className={`${formGroupClass} ${
          formGroupClass === "is-invalid" ? "d-block" : "d-none"
        }`}
      >
        {meta.error}
      </Form.Control.Feedback>
    </div>
  );
};

export default SntMultipleIetemSelectorField;
