import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import BleSensorServiceConfig from "./Config/BleSensorServiceConfig.js";
import GpsLocationServiceConfig from "./Config/GpsLocationServiceConfig.js";
import IBeaconScanServiceConfig from "./Config/IBeaconScanServiceConfig.js";
import OrientationEventServiceConfig from "./Config/OrientationEventServiceConfig.js";
import DiagnosticServiceConfig from "./Config/DiagnosticServiceConfig.js";
import OrientationStateServiceConfig from "./Config/OrientationStateServiceConfig.js";
import TimeSyncServiceConfig from "./Config/TimeSyncServiceConfig.js";
import AcceleroBaseActivityServiceConfig from "./Config/AcceleroBaseActivityServiceConfig.js";
import BatteryServiceConfig from "./Config/BatteryServiceConfig.js";
import NetworkServiceConfig from "./Config/NetworkServiceConfig.js";
import InfrastructureServiceConfig from "./Config/InfrastructureServiceConfig.js";

const ServiceConfigPopup = ({
  device,
  selectedService,
  onServiceConfigSave,
  parentEl,
  parentComponent,
}) => {
  const [serviceConfig, setServiceConfig] = useState();
  const [showBleConfig, setShowBleConfig] = useState(false);
  const [showGpsLocationConfig, setShowGpsLocationConfig] = useState(false);
  const [showIBeaconScanConfig, setShowIBeaconScanConfig] = useState(false);
  const [
    showOrientationEventServiceConfig,
    setShowOrientationEventServiceConfig,
  ] = useState(false);
  const [
    showOrientationStateServiceConfig,
    setShowOrientationStateServiceConfig,
  ] = useState(false);
  const [
    showDiagnosticServiceConfig,
    setShowDiagnosticServiceConfig,
  ] = useState(false);
  const [showTimesyncServiceConfig, setShowTimesyncServiceConfig] = useState(
    false
  );
  const [
    showAcceleroBaseActivityServiceConfig,
    setShowAcceleroBaseActivityServiceConfig,
  ] = useState(false);
  const [showBatteryServiceConfig, setShowBatteryServiceConfig] = useState(
    false
  );
  const [showNetworkServiceConfig, setShowNetworkServiceConfig] = useState(
    false
  );
  const [
    showInfrastructureServiceConfig,
    setShowInfrastructureServiceConfig,
  ] = useState(false);

  useEffect(() => {
    if (selectedService) {
      setServiceConfig(selectedService);
      let serviceKey = selectedService.type;
      if (serviceKey === "BLE_SENSOR") {
        setShowBleConfig(true);
      } else if (serviceKey === "GENERIC_LOCATION") {
        setShowGpsLocationConfig(true);
      } else if (serviceKey === "ORIENTATION_EVENT") {
        setShowOrientationEventServiceConfig(true);
      } else if (serviceKey === "DIAGNOSTIC") {
        setShowDiagnosticServiceConfig(true);
      } else if (serviceKey === "ORIENTATION_STATE") {
        setShowOrientationStateServiceConfig(true);
      } else if (serviceKey === "TIMESYNC") {
        setShowTimesyncServiceConfig(true);
      } else if (serviceKey === "ACCELERO_BASED_ACTIVITY") {
        setShowAcceleroBaseActivityServiceConfig(true);
      } else if (serviceKey === "BATTERY") {
        setShowBatteryServiceConfig(true);
      } else if (serviceKey === "IBEACON_SCAN") {
        setShowIBeaconScanConfig(true);
      } else if (serviceKey === "SIGFOX") {
        setShowNetworkServiceConfig(true);
      } else if (serviceKey === "INFRASTRUCTURE") {
        setShowInfrastructureServiceConfig(true);
      }
    }
  }, [selectedService]);

  return (
    <>
      {parentEl &&
        parentEl.current &&
        ReactDOM.createPortal(
          <>
            <BleSensorServiceConfig
              isShow={showBleConfig}
              setIsShow={setShowBleConfig}
              selectedService={serviceConfig}
              onSave={(data) => onServiceConfigSave(data)}
            ></BleSensorServiceConfig>

            <GpsLocationServiceConfig
              isShow={showGpsLocationConfig}
              setIsShow={setShowGpsLocationConfig}
              selectedService={serviceConfig}
              onSave={(data) => onServiceConfigSave(data)}
            ></GpsLocationServiceConfig>

            <IBeaconScanServiceConfig
              isShow={showIBeaconScanConfig}
              setIsShow={setShowIBeaconScanConfig}
              selectedService={serviceConfig}
              onSave={(data) => onServiceConfigSave(data)}
            ></IBeaconScanServiceConfig>

            <OrientationEventServiceConfig
              isShow={showOrientationEventServiceConfig}
              setIsShow={setShowOrientationEventServiceConfig}
              selectedService={serviceConfig}
              onSave={(data) => onServiceConfigSave(data)}
            ></OrientationEventServiceConfig>

            <OrientationStateServiceConfig
              isShow={showOrientationStateServiceConfig}
              setIsShow={setShowOrientationStateServiceConfig}
              selectedService={serviceConfig}
              onSave={(data) => onServiceConfigSave(data)}
            ></OrientationStateServiceConfig>

            <DiagnosticServiceConfig
              isShow={showDiagnosticServiceConfig}
              setIsShow={setShowDiagnosticServiceConfig}
              selectedService={serviceConfig}
              onSave={(data) => onServiceConfigSave(data)}
            ></DiagnosticServiceConfig>

            <TimeSyncServiceConfig
              device={device}
              isShow={showTimesyncServiceConfig}
              setIsShow={setShowTimesyncServiceConfig}
              selectedService={serviceConfig}
              onSave={(data) => onServiceConfigSave(data)}
            ></TimeSyncServiceConfig>

            <AcceleroBaseActivityServiceConfig
              isShow={showAcceleroBaseActivityServiceConfig}
              setIsShow={setShowAcceleroBaseActivityServiceConfig}
              selectedService={serviceConfig}
              onSave={(data) => onServiceConfigSave(data)}
            ></AcceleroBaseActivityServiceConfig>

            <BatteryServiceConfig
              isShow={showBatteryServiceConfig}
              setIsShow={setShowBatteryServiceConfig}
              selectedService={serviceConfig}
              onSave={(data) => onServiceConfigSave(data)}
            ></BatteryServiceConfig>

            <NetworkServiceConfig
              isShow={showNetworkServiceConfig}
              setIsShow={setShowNetworkServiceConfig}
              selectedService={serviceConfig}
              onSave={(data) => onServiceConfigSave(data)}
            ></NetworkServiceConfig>

            <InfrastructureServiceConfig
              isShow={showInfrastructureServiceConfig}
              setIsShow={setShowInfrastructureServiceConfig}
              selectedService={serviceConfig}
              onSave={(data) => onServiceConfigSave(data)}
              parentComponent={parentComponent}
            ></InfrastructureServiceConfig>
          </>,
          parentEl.current
        )}
    </>
  );
};

export default ServiceConfigPopup;
