import { FormGroupLayout } from "@/components/ReactBootstrap/FormValidation/index.js";

const TestConfigSensorInputLayout = ({
  label,
  leftRender,
  rightRender,
  isRequired,
}) => {
  return (
    <FormGroupLayout
      xl="4"
      lg="4"
      label={label}
      isRequired={isRequired}
      leftRender={leftRender}
      rightRender={rightRender}
    />
  );
};
export default TestConfigSensorInputLayout;
