import { apiClient } from "./apiClient";

export default {
  logError(params) {
    return apiClient.post(`/rest/error_boundary`, params);
  },
  logErrorCsp(params) {
    return apiClient.post(`/rest/csp_report`, params);
  },
};
