import L from "leaflet";

// owner
import searchUIClient from "@/apis/searchUIClient";
import { forceMapFilters } from "@/components/AdvancedFilters/FilterConstants";
import { CircleGroup } from "@/components/Leaflet/Map/Marker/CircleGroup";

L.NetworkLayer = L.LayerGroup.extend({
  options: {},
  bounds: L.latLngBounds(),
  pruneCluster: null,

  updateLayer: function (layer) {
    var self = this;

    this.clearLayers();
    if (layer) {
      self.addLayer(layer);
    }
  },

  updateData: function (orgId) {
    var self = this;
    this.clearLayers();

    let basic = {
      searchQueryType: "BASIC",
      queryObjectType: "SIGFOX_DEVICE",
      query: [
        {
          categoryId: "locationInfo",
          filterKey: "locationService_lastLocationSource",
          filterType: "MULTI_SELECT_CHECKBOX",
          searchType: "STATIC",
          notFilter: false,
          filterValue: {
            includeEmpty: false,
            includeNoEmpty: false,
            selectedValues: ["network"],
          },
        },
      ],
      requestedTableColumns: [
        {
          columnKey: "lastNetworkLocationRadius",
          order: null,
        },
        {
          columnKey: "currentLat",
          order: null,
        },
        {
          columnKey: "currentLng",
          order: null,
        },
        {
          columnKey: "name",
          order: null,
        },
        {
          columnKey: "decoratorColor",
          order: null,
        },
        {
          columnKey: "location",
          order: null,
        },
        {
          columnKey: "lastBeaconName",
          order: null,
        },
        {
          columnKey: "lastActivity",
          order: null,
        },
      ],
    };

    if (orgId)
      basic.query.push({
        categoryId: "userInfo",
        filterKey: "organizations",
        filterType: "MULTI_SELECT_AUTO_COMPLETE",
        searchType: "STATIC",
        notFilter: false,
        filterValue: {
          includeEmpty: false,
          includeNoEmpty: false,
          selectedValues: [orgId],
        },
      });
    forceMapFilters(basic);

    searchUIClient.search(basic).then(({ data }) => {
      self.processData(data.data);
    });
  },
  transformData: function (beacon) {
    beacon.coordinate = {
      lat: beacon.currentLat,
      lon: beacon.currentLng,
      lastSeen: beacon.lastSeenAlive,
    };
  },
  isValidCoordinate: function (coordinate) {
    return !!(coordinate && (coordinate.lat || coordinate.lon));
  },
  processData: function (data) {
    let self = this;
    let lastMarkers = [];
    data.forEach((obj) => {
      self.transformData(obj);
      let coordinate = obj.coordinate,
        isValid = self.isValidCoordinate(coordinate);
      if (isValid) {
        lastMarkers.push(obj);
      }
    });
    self.processMarkers(lastMarkers);
  },
  processMarkers: function (lastMarkers) {
    let self = this;

    let circles = [];
    lastMarkers.forEach((item) => {
      let _radius = item.lastNetworkLocationRadius;
      circles.push({
        center: L.latLng(item.coordinate.lat, item.coordinate.lon),
        radius: _radius,
      });
    });

    if (circles.length) {
      let layer = CircleGroup(circles, {
        color: "#5094BE",
        fillOpacity: 0.5,
        opacity: 0,
      });
      self.addLayer(layer);
    }
  },
  getLayer: function () {
    return this;
  },
  getBounds: function () {
    return this.bounds;
  },
});

L.networkLayer = function (opts) {
  return new L.NetworkLayer(opts);
};
export const layerNetwork = L.networkLayer;
