import deviceTagClient from "@/apis/deviceTagClient";
import sigfoxDeviceClient from "@/apis/sigfoxDeviceClient";
import SntNameAndTagSelectorField from "@/cleanup/widgets/formiks/SntNameAndTagSelectorField";

const MonitoredAssetContainer = ({ name, orgId, disabled, language }) => {
  const loadDevices = async (search, loadedOptions) => {
    const { data } = await sigfoxDeviceClient.getBasicInfo({
      search: search,
      offset: loadedOptions.length,
      limit: 50,
      orgId: orgId,
      serviceTypes: [],
      deviceCategories: [],
      alertRuleType: "",
    });
    return data;
  };

  const loadDeviceTags = async (search, loadedOptions) => {
    const { data } = await deviceTagClient.getBasicInfo({
      search: search,
      offset: loadedOptions.length,
      limit: 50,
      orgId: orgId,
    });
    return {
      options: data.results,
      hasMore: data.hasMore,
    };
  };

  const getDeviceLengthByType = async (monitorItem) => {
    let monitoredAssets = {
      ...monitorItem,
      monitoredType: null,
    };
    const { data } = await sigfoxDeviceClient.countMonitoredAssets(
      orgId,
      monitoredAssets
    );
    return data;
  };

  return (
    <>
      <SntNameAndTagSelectorField
        language={language}
        name={name}
        disabled={disabled}
        orgId={orgId}
        loadDataByIds={loadDevices}
        loadDataByTags={loadDeviceTags}
        getLengthByType={getDeviceLengthByType}
        isCustomLabel={true}
        lengthText={language.tracker_s_key}
      />
    </>
  );
};

export default MonitoredAssetContainer;
