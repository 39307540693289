import { useEffect, useState } from "react";
import ProfileCategoryLayout from "./ProfileCategoryLayout";

const ProfileConfig = ({ profile, results, onChange }) => {
  const [selectedProfile, setSelectedProfile] = useState();
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    setSelectedProfile(profile);
    setSelectedCategories(profile.categories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    if (selectedProfile) {
      let categories = selectedProfile.categories.filter((item) => {
        if (item.dependOnCategories) {
          return !item.dependOnCategories.find((dependOn) => {
            return !results.find((rs) => {
              if (dependOn.operator === "NOT_EQUAL") {
                return (
                  dependOn.categoryName === rs.name &&
                  dependOn.optionName === rs.options[0].name
                );
              } else {
                return (
                  dependOn.categoryName === rs.name &&
                  dependOn.optionName !== rs.options[0].name
                );
              }
            });
          });
        } else {
          return true;
        }
      });
      setSelectedCategories(categories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results, selectedProfile]);

  return (
    <>
      {selectedProfile ? (
        <>
          {selectedCategories.map((category, idx) => (
            <ProfileCategoryLayout
              key={idx}
              numericVariables={profile?.numericVariables}
              category={category}
              results={results}
              onChange={onChange}
            />
          ))}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ProfileConfig;
