import { apiClient } from "./apiClient";
import { useEffect, useState } from "react";

export const organisation = {
  getBasicOrgs() {
    return apiClient.get("/rest/org/basicOrganisation");
  },
};

export const useOrganisations = () => {
  const [organisations, setOrganisation] = useState([]);

  useEffect(() => {
    organisation.getBasicOrgs().then(({ data }) => {
      let organisations = [];
      for (let i = 0; i < data.length; i++) {
        organisations.push({
          label: data[i].name,
          value: data[i].id,
        });
      }
      setOrganisation(organisations);
    });
  }, []);

  return organisations;
};
