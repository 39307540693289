import { apiClient } from "./apiClient";

export default {
  findOne(id) {
    return apiClient.get(`/rest/automation/${id}`);
  },
  updateAutomationRule(id, params) {
    return apiClient.put(`/rest/automation/${id}`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
  createAutomationRule(params) {
    return apiClient.post(`/rest/automation`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
  delete(id) {
    return apiClient.delete(`/rest/automation/${id}`);
  },
  active(id, state) {
    return apiClient.put(`/rest/automation/active/${id}`, state, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
};
