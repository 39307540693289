import { useEffect, useMemo, useRef, useState } from "react";

// 3rd parties
import styled from "styled-components";
import { Button, ButtonGroup } from "react-bootstrap";

// owner
import SntArrowLeftIcon from "../../../components/Icons/SntArrowLeftIcon";
import SntArrowRightIcon from "../../../components/Icons/SntArrowRightIcon";
import DateWrapper from "../../wrappers/dates/DateWrapper";
import DateRangeWrapper from "../../wrappers/dates/DateRangeWrapper";
import DateModeView from "./DateModeView";
import moment from "../../../constants/SensolusMoment";

// style
const Styles = styled.div`
  .react-datepicker-popper {
    z-index: 1001;
  }
 
  .react-datepicker-wrapper {
    width: ${(props) => (props.enableTime ? "255px" : "220px")};
  }
  
  input {
    width: ${(props) => (props.enableTime ? "255px" : "220px")};
    color: #212851;
    text-align: center;
    border-radius: 0px;
    border-color: $b8bfca;
    height: 35px;
    border-left: none;
    border-right: none;
  }
}
`;
/**
 * A DateRangeWidget component for selecting date ranges.
 *
 * @param {object} value - An object containing date range information.
 * @param {string} value.viewMode - The view mode for the date range ('day', 'week', 'month', 'custom').
 * @param {moment} value.start - The start of the date range.
 * @param {moment} value.end - The end of the date range.
 *
 * @param {object} overrideDisplays - Override default display modes. (default: {day: true, week: true, month: true, year: true, custom: true, all: false, last90d: false, lastHour: false})
 * @param {boolean} overrideDisplays.day - Display day mode.
 * @param {boolean} overrideDisplays.week - Display week mode.
 * @param {boolean} overrideDisplays.month - Display month mode.
 * @param {boolean} overrideDisplays.year - Display year mode.
 * @param {boolean} overrideDisplays.custom - Display custom mode.
 * @param {boolean} overrideDisplays.last90d - Display last90d mode.
 * @param {boolean} overrideDisplays.all - Display all mode.
 *
 * @param {object} custom - An object for custom mode options.
 * @param {object} custom.hideRanges - Buttons to hide in custom mode.
 * @param {boolean} custom.hideRanges.today - Hide 'Today' button.
 * @param {boolean} custom.hideRanges.yesterday - Hide 'Yesterday' button.
 * @param {boolean} custom.hideRanges.last7Day - Hide 'Last 7 Days' button.
 * @param {boolean} custom.hideRanges.last30Day - Hide 'Last 30 Days' button.
 * @param {boolean} custom.hideRanges.last90Day - Hide 'Last 90 Days' button.
 * @param {boolean} custom.hideRanges.thisMonth - Hide 'This Month' button.
 * @param {boolean} custom.hideRanges.lastMonth - Hide 'Last Month' button.
 *
 *
 * @param {object} all - An object for all mode options.
 * @param {moment} all.start - The start date of all mode. (default: last 10 years)
 *
 * @param {moment} options.minDate - The minimum date for the date range.
 * @param {moment} options.maxDate - The maximum date for the date range. (default: now())
 *
 * @param {boolean} disabled - Disable action buttons.
 * @param {object} language - An object for i18n.
 * @param {number} weekStart - 0 is Sunday, 1 is Monday.
 *
 * @returns {JSX.Element} The rendered DateRangeWidget component.
 *
 * @example
 * // Usage with custom mode and hidden buttons:
 * <DateRangeWidget
 *   value={{
 *     viewMode: 'custom',
 *     start: moment('2023-10-01'),
 *     end: moment('2023-10-15'),
 *   }}
 *   overrideDisplays={{ custom: true, year: false, all: false }}
 *   custom={{ hideRanges: { last7Day: true, last30Day: true } }}
 *   options={{ maxDate: moment('2023-12-31') }}
 * />
 */
const DateRangeWidget = ({
  value,
  onChange,
  overrideDisplays,
  custom,
  all,
  minDate,
  maxDate = moment().endOf("day"),
  disabled = false,
  language,
  weekStart,
  enableTime = false,
}) => {
  const dateRef = useRef();
  const dateRangeRef = useRef();
  const [isButtonClicked, setButtonClicked] = useState(false);

  const [buttons, setButtons] = useState([
    {
      key: DateModeView.hour,
      display: false,
      label: language.hour_key,
    },
    {
      key: DateModeView.day,
      display: true,
      label: language.v41_day_key,
    },
    {
      key: DateModeView.week,
      display: true,
      label: language.v41_week_key,
    },
    {
      key: DateModeView.month,
      display: true,
      label: language.v41_month_key,
    },
    {
      key: DateModeView.year,
      display: true,
      label: language.v41_year_key,
    },
    {
      key: DateModeView.last90Day,
      display: false,
      label: language.last_90_day_key,
    },
    {
      key: DateModeView.custom,
      display: true,
      label: language.v41_custom_key,
    },
    {
      key: DateModeView.all,
      display: false,
      label: language.all_key,
    },
  ]);

  const firstInit = useRef(true);

  // this use for updating start, end dates correctly with the time
  useEffect(() => {
    if (!value) return;

    if (firstInit.current) {
      updateRefDate(value);
    }

    const { viewMode, start = moment(), end = moment() } = value;

    const dates = tranform2CorrectRanges(value);
    if (
      firstInit.current ||
      !value.end ||
      (dates.start.valueOf() !== start.valueOf() &&
        dates.end.valueOf() !== end.valueOf())
    ) {
      onChange({ viewMode: viewMode, ...dates });
    }

    firstInit.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (overrideDisplays) {
      const keys = Object.keys(overrideDisplays);
      keys.forEach((key) => {
        let btn = buttons.find((btn) => btn.key === key);
        if (btn) {
          btn.display = overrideDisplays[key];
        }
      });
      setButtons([...buttons]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overrideDisplays]);

  const tranform2CorrectRanges = (value) => {
    let { viewMode } = value;
    let start = value.start ? value.start.clone() : moment(),
      end = value.end ? value.end.clone() : moment();

    if (viewMode === DateModeView.hour && value.oldViewmode) {
      // check the initial case : last hour
      end = moment().endOf("minute");
      start = end.clone().subtract(1, "hour");
    } else if (viewMode === DateModeView.day) {
      // start and end dates will be same a day, use start date for calculating end date
      start = start.startOf("day");
      end = start.clone().endOf("day");
    } else if (viewMode === DateModeView.datetime) {
      end = start.clone();
    } else if (viewMode === DateModeView.week) {
      const dayOfWeek = start.isoWeekday();
      // if the dayOfWeek of start range = weekStart config. Using start for start of range. Else need to check isoweek
      if (dayOfWeek % 7 === weekStart) {
        start = start.startOf("day");
      } else {
        // Determine the start of the week
        start = moment(start).startOf("isoWeek");
        // If Sunday is the start of the week, subtract one day (to shift to Sunday)
        if (weekStart === 0) {
          start.subtract(1, "day");
        }
      }

      end = start.clone().add(7, "day").subtract(1, "second");
    } else if (viewMode === DateModeView.month) {
      // start and end dates will be same a week, use start date for calculating end date
      start = start.startOf("month");
      end = start.clone().endOf("month");
    } else if (viewMode === DateModeView.year) {
      // start and end dates will be same a week, use start date for calculating end date
      start = start.startOf("year");
      end = start.clone().endOf("year");
    } else if (viewMode === DateModeView.last90Day) {
      start = moment().subtract(90, "day").startOf("day");
      end = moment().endOf("day");
    } else if (viewMode === DateModeView.all) {
      start = all && all.start ? all.start : moment().subtract(10, "years");
      end = moment();
    }

    // prevent the range by min/max values
    if (minDate && minDate.isAfter(start)) {
      start = minDate.startOf("day");
    }

    if (maxDate && maxDate.endOf("day").isBefore(end)) {
      end = maxDate.endOf("day");
    }

    return { start, end };
  };

  const onButtonClick = (key) => {
    const dates = tranform2CorrectRanges({
      ...value,
      oldViewmode: value.viewMode,
      viewMode: key,
      start: dateRef.current,
    });
    onChange && onChange({ ...dates, viewMode: key });
  };

  const onPrevClick = () => {
    const dates = getNextPrevRanges(true);
    handleValueChange({ ...value, ...dates });
  };
  const onNextClick = () => {
    const dates = getNextPrevRanges();
    handleValueChange({ ...value, ...dates });
  };

  const onDateChange = (date) => {
    handleValueChange({ ...value, start: moment(date) });
  };

  const onDateRangeChange = (ranges) => {
    handleValueChange({
      ...value,
      start: enableTime
        ? moment(ranges.start)
        : moment(ranges.start.startOf("day")),
      end: enableTime ? moment(ranges.end) : moment(ranges.end.endOf("day")),
    });
  };

  const handleValueChange = (rangesWithMode) => {
    const dates = tranform2CorrectRanges(rangesWithMode);
    updateRefDate(dates);
    onChange && onChange({ ...rangesWithMode, ...dates });
  };

  const getNextPrevRanges = (isPrev) => {
    let d = 1;
    if (isPrev) {
      d *= -1;
    }
    const { viewMode } = value;
    let start = value.start ? value.start.clone() : moment(),
      end = value.end ? value.end.clone() : moment();

    if (viewMode === DateModeView.hour) {
      let duration = moment.duration(end.diff(start));
      let hours = duration.asHours();
      hours += isPrev ? 1 : -1;
      end = moment().endOf("minute");
      start = end.clone().subtract(hours, "hour");
    } else if (
      viewMode === DateModeView.day ||
      viewMode === DateModeView.datetime
    ) {
      start = start.add(1 * d, "day");
      end = end.add(1, "day");
    } else if (viewMode === DateModeView.week) {
      start = start.add(7 * d, "day");
      end = end.add(7 * d, "day");
    } else if (viewMode === DateModeView.month) {
      start = start.add(1 * d, "month");
      end = end.add(1 * d, "month");
    } else if (viewMode === DateModeView.year) {
      start = start.add(1 * d, "year");
      end = end.add(1 * d, "year");
    } else if (viewMode === DateModeView.custom) {
      let diff = end.diff(start, "day") + 1;
      start = start.add(diff * d, "day");
      end = end.add(diff * d, "day");
      dateRangeRef.current.setStartDate(start.toDate());
      dateRangeRef.current.setEndDate(end.toDate());
    }
    return { start, end };
  };

  const updateRefDate = (ranges) => {
    dateRef.current = ranges.start;
    const now = moment();
    if (ranges.start && ranges.end) {
      if (ranges.start.isSameOrBefore(now) && ranges.end.isSameOrAfter(now)) {
        dateRef.current = now;
      }
    }
  };

  const limitRange = useMemo(() => {
    if (!value) return { prevDisabled: false, nextDisabled: false };

    if (
      DateModeView.all === value.viewMode ||
      DateModeView.last90Day === value.viewMode ||
      disabled
    ) {
      return { prevDisabled: true, nextDisabled: true };
    }

    if (DateModeView.hour === value.viewMode) {
      return {
        prevDisabled: false,
        nextDisabled:
          moment.duration(value.end.diff(value.start)).asHours() <= 1,
      };
    }

    const start = value.start;
    const end = value.end;

    let prevDisabled = false,
      nextDisabled = false;
    if (minDate && minDate.startOf("day").isSameOrAfter(start)) {
      prevDisabled = true;
    }
    if (maxDate && maxDate.startOf("day").isSameOrBefore(end)) {
      nextDisabled = true;
    }
    return { prevDisabled: prevDisabled, nextDisabled: nextDisabled };
  }, [value, minDate, maxDate, disabled]);

  // TODO: return the loading like the filter
  if (!value) return <></>;

  const { viewMode, start = moment(), end = moment() } = value;

  const textRender = () => {
    let text = "";
    if (viewMode === DateModeView.hour) {
      let hours = moment.duration(end.diff(start)).asHours();
      text =
        hours === 1
          ? language.last_hour_key
          : language.last_x_hours_key.replace("{}", hours);
    } else if (viewMode === DateModeView.day) {
      text = start.format("ll");
    } else if (viewMode === DateModeView.week) {
      text = start.format("ll") + " - " + end.format("ll");
    } else if (viewMode === DateModeView.month) {
      text = start.format("MMM yyyy");
    } else if (viewMode === DateModeView.year) {
      text = start.format("yyyy");
    } else if (viewMode === DateModeView.datetime) {
      text = start.format("LLL");
    } else if (viewMode === DateModeView.all) {
      text = "> " + start.format("LLL");
    } else if (viewMode === DateModeView.last90Day) {
      text = language.last_90_day_key;
    } else if (viewMode === DateModeView.lastHour) {
      text = language.last_1hour_key;
    }
    return text;
  };

  const getCustomRange = () => {
    let range = {};

    let hideCustomRanges = custom && custom.hideRanges ? custom.hideRanges : {};
    if (!hideCustomRanges.today) {
      range[language.today_key] = [moment().toDate(), moment().toDate()];
    }

    if (!hideCustomRanges.yesterday) {
      range[language.yesterday_key] = [
        moment().subtract(1, "days").toDate(),
        moment().subtract(1, "days").toDate(),
      ];
    }

    if (!hideCustomRanges.last7Day) {
      range[language.last_7_day_key] = [
        moment().subtract(6, "days").toDate(),
        moment().toDate(),
      ];
    }

    if (!hideCustomRanges.last30Day) {
      range[language.last_30_day_key] = [
        moment().subtract(29, "days").toDate(),
        moment().toDate(),
      ];
    }

    if (!hideCustomRanges.last90Day) {
      range[language.last_90_day_key] = [
        moment().subtract(89, "days").toDate(),
        moment().toDate(),
      ];
    }

    if (!hideCustomRanges.thisMonth) {
      range[language.this_month_key] = [
        moment().startOf("month").toDate(),
        moment().endOf("month").toDate(),
      ];
    }

    if (!hideCustomRanges.lastMonth) {
      range[language.last_month_key] = [
        moment().subtract(1, "month").startOf("month").toDate(),
        moment().subtract(1, "month").endOf("month").toDate(),
      ];
    }

    return range;
  };

  return (
    <div className="date-range">
      <div className="d-lg-inline-block mb-2 me-lg-2">
        <ButtonGroup>
          <Button
            variant="sensolus-greylight"
            disabled={limitRange.prevDisabled}
            onClick={onPrevClick}
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          >
            <SntArrowLeftIcon />
          </Button>
          <Styles enableTime={enableTime}>
            {viewMode === DateModeView.custom ? (
              <DateRangeWrapper
                ref={dateRangeRef}
                isShowDirrectPopupAtInit={isButtonClicked}
                start={start}
                end={end}
                enableTime={enableTime}
                onChange={onDateRangeChange}
                maxDate={maxDate}
                minDate={minDate}
                disabled={disabled}
                ranges={getCustomRange()}
                locale={{
                  fromLabel: language.from_key,
                  toLabel: language.to_key,
                  customRangeLabel: language.custom_range_key,
                  applyLabel: language.apply_key,
                  cancelLabel: language.cancel_key,
                  daysOfWeek: [
                    language.abbr_sunday_key,
                    language.abbr_monday_key,
                    language.abbr_tuesday_key,
                    language.abbr_wednesday_key,
                    language.abbr_thursday_key,
                    language.abbr_friday_key,
                    language.abbr_saturday_key,
                  ],
                  monthNames: [
                    language.january_key,
                    language.february_key,
                    language.march_key,
                    language.april_key,
                    language.may_key,
                    language.june_key,
                    language.july_key,
                    language.august_key,
                    language.september_key,
                    language.october_key,
                    language.november_key,
                    language.december_key,
                  ],
                }}
              />
            ) : (
              <DateWrapper
                value={start.toDate()}
                onChange={onDateChange}
                maxDate={maxDate}
                minDate={minDate}
                disabled={
                  disabled ||
                  viewMode === DateModeView.last90Day ||
                  viewMode === DateModeView.lastHour
                } // always disabled for last90Day, and lastHour mode
                showMonthYearPicker={viewMode === DateModeView.month}
                showYearPicker={viewMode === DateModeView.year}
                showTimeSelect={viewMode === DateModeView.datetime}
                textDate={textRender()}
                startDate={start.toDate()}
                endDate={end.toDate()}
                today={language.today_key}
              />
            )}
          </Styles>
          <Button
            variant="sensolus-greylight"
            disabled={limitRange.nextDisabled}
            onClick={onNextClick}
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          >
            <SntArrowRightIcon />
          </Button>
        </ButtonGroup>
      </div>
      {!disabled && (
        <div className="d-lg-inline-block mb-2">
          <ButtonGroup style={{ maxWidth: 384 }}>
            {buttons
              .filter((btn) => btn.display)
              .map((btn) => {
                return (
                  <Button
                    key={btn.key}
                    variant="sensolus-greylight"
                    className={viewMode === btn.key ? " active" : ""}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setButtonClicked(true);
                      if (viewMode !== btn.key) {
                        onButtonClick(btn.key);
                      }
                    }}
                  >
                    {btn.label}
                  </Button>
                );
              })}
          </ButtonGroup>
        </div>
      )}
    </div>
  );
};

export default DateRangeWidget;
