const convertMonitorComponent2RequestDto = (monitoredData, monitoredType) => {
  let data = {
    selectType: monitoredData.selectType,
    selectedIds: [],
    monitoredType: monitoredType || "DEVICE",
  };

  if (monitoredData.selectType === "TAG") {
    data.selectedIds = monitoredData.selectedTags?.map((item) => item.value);
  } else if (monitoredData.selectType === "NAME") {
    data.selectedIds = monitoredData.selectedIds?.map((item) => item.value);
  }

  return data;
};

const convertMonitorServerData2Component = (monitoredServerData) => {
  if (!monitoredServerData) return null;

  let data = {
    selectType: monitoredServerData.selectType,
    selectedIds: [],
    selectedTags: [],
  };

  if (monitoredServerData.selectType === "TAG") {
    data.selectedTags = monitoredServerData.selectedItems;
  } else if (monitoredServerData.selectType === "NAME") {
    data.selectedIds = monitoredServerData.selectedItems;
  }

  return data;
};

const CONDITION_MAPPING = {
  IN_PROCESS_STEP: {
    key: "IN_PROCESS_STEP",
    getLabel: (language) => {
      return language.process_inside_step;
    },
    getCell: (value, language) => {
      let data = value?.inProcessStepCondition;
      if (!data) return <></>;

      return (
        <>
          <div>Process: {data.processDefinitionId}</div>
          <div>Steps: {data.map((item) => item.id).join(", ")}</div>
        </>
      );
    },
    convertForm2RequestDto: (value) => {
      return {
        inProcessStepCondition: {
          processDefinitionId: value.selectedProcessId?.value,
          stepIds: value.stepId?.value ? [value.stepId.value] : [],
        },
      };
    },
    convertServerData2Component: (conditionData) => {
      let data = conditionData?.inProcessStepCondition;

      if (!data) return null;

      return {
        selectedProcessId: { value: data.processDefinitionId },
        stepId: data.stepIds[0] ? { value: data.stepIds[0] } : null,
      };
    },
  },
  IN_GEOZONE: {
    key: "IN_GEOZONE",
    getLabel: (language) => {
      return language.alert_ruletype_GeozoneInsideAlertType_label;
    },
    getCell: (value, language) => {
      let data = value?.inGeozoneCondition;
      if (!data) return <></>;

      return (
        <>
          <div>Type: {data.monitoredItem.selectType}</div>
          <div>
            Ids: {data.monitoredItem.selectedIds.map((id) => id).join(", ")}
          </div>
        </>
      );
    },
    convertForm2RequestDto: (value) => {
      return {
        inGeozoneCondition: {
          monitoredItem: convertMonitorComponent2RequestDto(value, "AREA"),
        },
      };
    },
    convertServerData2Component: (conditionData) => {
      let data = conditionData?.inGeozoneCondition;
      if (!data) return null;

      return convertMonitorServerData2Component(data.monitoredItem);
    },
  },
  OUT_GEOZONE: {
    key: "OUT_GEOZONE",
    getLabel: (language) => {
      return language.alert_ruletype_GeozoneOutsideAlertType_label;
    },
    getCell: (value, language) => {
      let data = value?.outGeozoneCondition;
      if (!data) return <></>;

      return (
        <>
          <div>Type: {data.monitoredItem.selectType}</div>
          <div>
            Ids: {data.monitoredItem.selectedIds.map((id) => id).join(", ")}
          </div>
        </>
      );
    },
    convertForm2RequestDto: (value) => {
      return {
        outGeozoneCondition: {
          monitoredItem: convertMonitorComponent2RequestDto(value, "AREA"),
        },
      };
    },
    convertServerData2Component: (conditionData) => {
      let data = conditionData?.outGeozoneCondition;
      if (!data) return null;

      return convertMonitorServerData2Component(data.monitoredItem);
    },
  },
  ALERT_RULE: {
    key: "ALERT_RULE",
    getLabel: (language) => {
      return language.alert_rule_condition_key;
    },
    getCell: (value, language) => {
      let data = value?.alertRuleCondition;
      if (!data) return <></>;

      return (
        <>
          <div>{data.alertRuleIds.map((id) => id).join(", ")}</div>
        </>
      );
    },
    convertForm2RequestDto: (value) => {
      return {
        alertRuleCondition: { alertRuleIds: [value.alertRuleId.value] },
      };
    },
    convertServerData2Component: (conditionData) => {
      let data = conditionData?.alertRuleCondition;
      if (!data) return null;
      return {
        alertRuleId: data.alertRuleIds[0]
          ? { value: data.alertRuleIds[0] }
          : null,
      };
    },
  },
};

const ACTION_MAPPING = {
  ASSIGN_ASSET_TAGS: {
    key: "ASSIGN_ASSET_TAGS",
    getLabel: (language) => {
      return language.asssign_tags_to_assets_key;
    },
    getCell: (value, language) => {
      let data = value?.assignTags || [];
      return <>{data.map((item) => item.name || item.id).join(", ")}</>;
    },
    convertForm2RequestDto: (value) => {
      return {
        assignTags: value.selectedIds?.map((item) => {
          return item.__isNew__
            ? { name: item.label }
            : { id: item.value, name: item.label };
        }),
      };
    },
    convertServerData2Component: (automationActionData) => {
      let data = automationActionData.assignTags;
      if (!data) return null;
      return {
        selectedIds: data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      };
    },
  },
  REMOVE_ASSET_TAGS: {
    key: "REMOVE_ASSET_TAGS",
    getLabel: (language) => {
      return language.remove_tags_from_assets_key;
    },
    getCell: (value, language) => {
      let data = value?.removeTags || [];
      return <>{data.map((item) => item.name || item.id).join(", ")}</>;
    },
    convertForm2RequestDto: (value) => {
      return {
        removeTags: value.selectedIds?.map((item) => ({
          id: item.value,
          name: item.label,
        })),
      };
    },
    convertServerData2Component: (automationActionData) => {
      let data = automationActionData.removeTags;
      if (!data) return null;
      return {
        selectedIds: data.map((item) => ({ value: item.id, label: item.name })),
      };
    },
  },
  SET_DECORATOR: {
    key: "SET_DECORATOR",
    getLabel: (language) => {
      return language.set_decorator_color_key;
    },
    getCell: (value, language) => {
      let data = value?.assignDecoratorColor || "";
      return <span style={{ backgroundColor: data }}>{data}</span>;
    },
    convertForm2RequestDto: (value) => {
      return { assignDecoratorColor: value.decorator };
    },
    convertServerData2Component: (automationActionData) => {
      let data = automationActionData.assignDecoratorColor;
      if (!data) return null;
      return { decorator: data };
    },
  },
  REMOVE_DECORATOR: {
    key: "REMOVE_DECORATOR",
    getLabel: (language) => {
      return language.remove_decorator_color_key;
    },
    getCell: (value, language) => {
      return <></>;
    },
    convertForm2RequestDto: (value) => {
      return { assignDecoratorColor: null };
    },
    convertServerData2Component: (automationActionData) => {
      return {};
    },
  },
  APPLY_VISIBILITY_FILTER: {
    key: "APPLY_VISIBILITY_FILTER",
    getLabel: (language) => {
      return language.apply_visibility_filter_key;
    },
    getCell: (value, language) => {
      let data = value?.assignVisibilityFilters || [];
      return <>{data.map((item) => item.name || item.id).join(", ")}</>;
    },
    convertForm2RequestDto: (value) => {
      return {
        assignVisibilityFilters: value.selectedIds?.map((item) => ({
          id: item.value,
          name: item.label,
        })),
      };
    },
    convertServerData2Component: (automationActionData) => {
      let data = automationActionData.assignVisibilityFilters;
      if (!data) return null;
      return {
        selectedIds: data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      };
    },
  },
  REMOVE_VISIBILITY_FILTER: {
    key: "REMOVE_VISIBILITY_FILTER",
    getLabel: (language) => {
      return language.remove_visibility_filter_key;
    },
    getCell: (value, language) => {
      let data = value?.removeVisibilityFilters || [];
      return <>{data.map((item) => item.name || item.id).join(", ")}</>;
    },
    convertForm2RequestDto: (value) => {
      return {
        removeVisibilityFilters: value.selectedIds?.map((item) => ({
          id: item.value,
          name: item.label,
        })),
      };
    },
    convertServerData2Component: (automationActionData) => {
      let data = automationActionData.removeVisibilityFilters;
      if (!data) return null;
      return {
        selectedIds: data.map((item) => ({ value: item.id, label: item.name })),
      };
    },
  },
  PUSH_USAGE_PROFILE: {
    key: "PUSH_USAGE_PROFILE",
    getLabel: (language) => {
      return language.push_tracker_usage_profile_key;
    },
    getCell: (value, language) => {
      let data = value?.pushProfileActions || [];
      return (
        <>
          {data
            ?.map((item) => {
              return item.profileName || item.profileId;
            })
            .join(", ")}
        </>
      );
    },
    convertForm2RequestDto: (value) => {
      return {
        pushProfileActions: value?.map((item) => {
          return {
            profileId: item.profile.id,
            profileName: item.profile.name,
            applyCategory: item.categories,
          };
        }),
      };
    },
    convertServerData2Component: (automationActionData) => {
      let data = automationActionData.pushProfileActions;
      if (!data) return null;
      return data?.map((item) => {
        return {
          profile: { id: item.profileId, name: item.profileName },
          categories: item.applyCategory,
        };
      });
    },
  },
};

export {
  CONDITION_MAPPING,
  ACTION_MAPPING,
  convertMonitorComponent2RequestDto,
  convertMonitorServerData2Component,
};
