import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import SntFormulaIcon from "@icons/SntFormulaIcon.js";
import SntIntegerSelectIcon from "@icons/SntIntegerSelectIcon.js";

const FunctionSelect = ({
  setting,
  defaultValue,
  language,
  onChange,
  numericVariables,
}) => {
  const [prevValue, setPrevValue] = useState("");
  const [isVariableMode, setIsVariableMode] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [variables, setVariables] = useState([
    "${rand(min,max,factor)}",
    "${organisation.uuid}",
    "${geobeaconSerial}",
  ]);

  useEffect(() => {
    if (isExpression(defaultValue.value)) {
      setIsVariableMode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  useEffect(() => {
    if (numericVariables) {
      let val = [];
      numericVariables.map((v) => {
        val.push(v.name);
        val.push("${" + v.name + "}");
      });
      val.push.apply(val, [...variables]);
      setVariables(val);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numericVariables]);

  const formulaClick = () => {
    setPrevValue(defaultValue.value);
    if (isVariableMode) {
      onChange({
        target: {
          name: setting.key,
          value: prevValue ? prevValue : setting.dataType.minValue + "",
        },
      });
    } else {
      onChange({
        target: {
          name: setting.key,
          value: "",
        },
      });
    }
    setIsVariableMode((pre) => !pre);
  };

  const onChangeFormula = (e) => {
    e.preventDefault();
    onChange(e);
  };

  const isExpression = (value) => {
    if (value && (value + "").includes("$")) {
      value = value.substring(value.indexOf("${") + 2, value.indexOf("}"));
    }
    return value && variables.indexOf(value) !== -1;
  };

  return (
    <div className={`col-xs-12 col-xl-12 d-flex align-self-center`}>
      {isVariableMode ? (
        <>
          <input
            placeholder={"Type $ to see your predefined variables"}
            className={"form-control mr-2"}
            name={setting.key}
            onChange={(e) => onChangeFormula(e)}
            list="variables"
            value={
              Number.isInteger(defaultValue.value) && isVariableMode
                ? ""
                : defaultValue.value
            }
          />
          <datalist id="variables">
            {variables.map((v) => (
              <option key={v} value={v} />
            ))}
          </datalist>
        </>
      ) : (
        <input
          name={setting.key}
          onChange={(e) => onChange(e)}
          className="form-control mr-2"
          type="number"
          min={setting.dataType.minValue}
          max={setting.dataType.maxValue}
          step={setting.bidirectionalSigfoxFormatInfo.factor}
          value={defaultValue.value}
        />
      )}
      <span style={{ marginLeft: 2 }}>
        <Button
          variant="sensolus-greylight"
          name={setting.key}
          onClick={formulaClick}
          title={
            isVariableMode
              ? language.remote_setting_use_numeric
              : language.remote_setting_use_formula
          }
        >
          {isVariableMode ? <SntIntegerSelectIcon /> : <SntFormulaIcon />}
        </Button>
      </span>
    </div>
  );
};

export default FunctionSelect;
