import SntIcon from "./SntIcon";

const SntSaveIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 0H12V5H4V0H0V16H16V3L13 0ZM11 1H9V4H11V1Z"
      />
    </SntIcon>
  );
};

export default SntSaveIcon;
