import { Formik, Form as Formk } from "formik";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useOrganisations } from "../../apis/organisation";
import { usePartners } from "../../apis/partner";
import { useProducts } from "../../apis/products";

import deviceUsageProfile from "../../apis/deviceUsageProfile";

import { SensolusDatetimeReact } from "../../constants/Config";

import TagMore, { DefaultTag } from "@/components/ReactBootstrap/TagMore";
import SelectorDialog from "@containers/dialog/SelectorDialog";
import _ from "lodash";
import { Button, Col, Row } from "react-bootstrap";
import SntPanel from "../../components/Panel/SntPanel";
import {
  FormFullScreenLayout,
  InputCheck,
  InputText,
  ItemSelector,
} from "../../components/ReactBootstrap/FormValidation";
import ObjectUtils from "../../utils/ObjectUtils";
import { FormikOnChange } from "./FormikOnChange";

const DeviceProfileGeneralInfo = ({
  profile,
  profileToUpdate,
  onChange,
  setShowUnChangeConfirm,
  type
}) => {
  const history = useHistory();
  const language = useSelector((state) => state.language);
  const loginInfo = useSelector((state) => state.user);
  let { permissionsMap } = loginInfo;
  const formikRef = useRef();
  const orgs = useOrganisations();
  const partners = usePartners();
  const products = useProducts();
  const [productsByReference, setProductsByReference] = useState([]);

  const [usageProfile, setUsageProfile] = useState({
    name: "",
    referenceProduct: null,
    supportedProducts: [],
    excludedProducts: [],
    accessibleByOrganizations: [],
    accessibleByPartners: [],
    description: "",
    deviceFwParameterSettings: [],
    profileServiceUpdates: [],
    numericVariables: [],
    categories: [],
    excludedRegexProducts: "",
    excludedProductsResult: [],
  });

  const { isMobile } = useSelector((state) => state.all);

  const [selectedReferenceProduct, setSelectedReferenceProduct] = useState(
    null
  );

  const [excludedProducts, setExcludedProducts] = useState([]);
  const [regexProductsNew, setRegexProductsNew] = useState({
    filter: {
      items: null,
    },
    allItems: null,
  });
  const [regexProductsText, setRegexProductsText] = useState();

  const originalProfile = useRef(JSON.parse(JSON.stringify(usageProfile)));

  const [excludedProductsData, setExcludedProductsData] = useState({
    filter: {
      items: null,
    },
    allItems: null,
  });

  const [referenceProductData, setReferenceProductData] = useState({
    filter: {
      items: null,
    },
    allItems: null,
  });

  const [supportedProductsData, setSupportedProductsData] = useState({
    filter: {
      items: null,
    },
    allItems: null,
  });

  const [isShowConfigDialog, setShowConfigDialog] = useState(false);
  const [hideAvailable, setHideAvailable] = useState(type === "view");

  const [
    isShowReferenceProductConfigDialog,
    setShowReferenceProductConfigDialog,
  ] = useState(false);
  const [
    hideAvailableReferenceProduct,
    setHideAvailableReferenceProduct,
  ] = useState(type === "view");

  const [
    isShowSupportedProductsConfigDialog,
    setShowSupportedProductsConfigDialog,
  ] = useState(false);

  const [
    hideAvailableSupportedProducts,
    setHideAvailableSupportedProducts,
  ] = useState(type === "view");

  const [
    isShowRegexResultConfigDialog,
    setShowRegexResultConfigDialog,
  ] = useState(false);
  const [hideAvailableRegexResult, setHideAvailableRegexResult] = useState(
    type === "view"
  );

  useEffect(() => {
    if (profile) {
      originalProfile.current = JSON.parse(JSON.stringify(profile));

      setSelectedReferenceProduct(profile.referenceProduct);

      setUsageProfile({ ...profile });

      let excludedProductList = getProductList(
        profile.excludedProducts,
        language
      );
      setExcludedProducts(excludedProductList);

      if (profile.supportedProducts) {
        let supportedProducts = getProductList(
          profile.supportedProducts,
          language
        );
        setSupportedProductsData({
          ...supportedProductsData,
          allItems: supportedProducts,
          filter: {
            items: supportedProducts,
          },
        });
      }
      setRegexProductsText(profile.excludedRegexProducts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    if (selectedReferenceProduct) {
      deviceUsageProfile
        .getAllRelativeProductsByReference(selectedReferenceProduct.key)
        .then(({ data }) => {
          setProductsByReference(data);
          if (type === "add") {
            let _allItems = getProductList(data, language);
            setSupportedProductsData({
              ...supportedProductsData,
              allItems: _allItems,
              filter: {
                items: _allItems,
              },
            });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludedProducts, selectedReferenceProduct]);

  useEffect(() => {
    if (products !== null) {
      let _allItems = getProductList(products, language);
      setReferenceProductData({
        ...referenceProductData,
        allItems: _allItems,
        filter: {
          items: selectedReferenceProduct ? [selectedReferenceProduct] : [],
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReferenceProduct, products]);

  useEffect(() => {
    let pattern;
    if (regexProductsText) {
      try {
        pattern = new RegExp("^" + regexProductsText + "$");
      } catch (ex) {
        console.error("Profile exclude regexp invalid", ex);
      }
    }
    let allProductsAfterExcludeRegexp = []; // this will be all products which excluded regexp
    let allProductsAfterRegexpAndExcludeSelection = []; // this will be final supported products at the end
    let _allExcludedProducts = []; // this will be all products which excluded by selections and regexp
    let _allProductsByReference = getProductList(productsByReference, language); // all relevant products by reference key

    // applying regexp
    _allProductsByReference.forEach((element) => {
      if (pattern === undefined) {
        allProductsAfterExcludeRegexp.push(element);
      } else {
        if (pattern.test(element.key)) {
          _allExcludedProducts.push(element);
        } else {
          allProductsAfterExcludeRegexp.push(element);
        }
      }
    });

    // applied to excluded product dialog
    let data = {
      ...excludedProductsData,
      allItems: allProductsAfterExcludeRegexp,
      filter: {
        items: excludedProducts,
      },
    };
    setExcludedProductsData(data);

    // if excluded product selections are present, update _allExcludedProducts and allProductsAfterRegexpAndExcludeSelection
    allProductsAfterRegexpAndExcludeSelection = allProductsAfterExcludeRegexp;
    if (excludedProducts && excludedProducts.length > 0) {
      excludedProducts.forEach((item) => {
        let product = getProduct(item.key, _allProductsByReference);
        if (product !== null) {
          _allExcludedProducts.push(product);
          allProductsAfterRegexpAndExcludeSelection = allProductsAfterRegexpAndExcludeSelection.filter(
            (p) => product.key !== p.key
          );
        }
      });
    }

    // applied to excluded product result
    setRegexProductsNew({
      filter: {
        items: _allExcludedProducts,
      },
      allItems: _allProductsByReference,
    });

    // applied to supported product
    type === "add" &&
      setSupportedProductsData({
        ...supportedProductsData,
        allItems: allProductsAfterRegexpAndExcludeSelection,
        filter: {
          items: allProductsAfterRegexpAndExcludeSelection,
        },
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regexProductsText, excludedProducts, productsByReference, language]);

  const referenceProductDataChanged = (data) => {
    setReferenceProductData(data);
    setSelectedReferenceProduct(data.filter.items[0]);
  };

  const excludedProductsDataChanged = (data) => {
    setExcludedProductsData(data);
    setExcludedProducts(data.filter.items);
  };

  function getProduct(key, allProducts) {
    for (const idx in allProducts) {
      if (allProducts[idx].key === key) {
        return allProducts[idx];
      }
    }

    return null;
  }

  function getProductList(data, language) {
    let productList = [];
    for (let i = 0; i < data.length; i++) {
      let productDtos = data[i].productDtos || [];

      for (let j = 0; j < productDtos.length; j++) {
        let productDto = productDtos[j];
        productList.push({
          key: productDto.key,
          columnKey: productDto.key,
          sortKey: productDto.key,
          // title: productDto.customerName,
          title: productDto.key,
          description: productDto.description,
          category: language[data[i].groupName],
          categoryId: language[data[i].groupName],
        });
      }
    }
    productList.sort(function (a, b) {
      return a.title.localeCompare(b.title);
    });

    return productList;
  }

  function onCancel() {
    let currentProfile = { ...profileToUpdate.current };
    currentProfile = ObjectUtils.deletePropertiesDeep(currentProfile, [
      "chosen",
      "selected",
    ]);
    currentProfile = ObjectUtils.sortDeep(currentProfile);
    originalProfile.current = ObjectUtils.sortDeep(originalProfile.current);

    if (
      JSON.stringify(originalProfile.current) !== JSON.stringify(currentProfile)
    ) {
      setShowUnChangeConfirm(true);
      return;
    }
    setTimeout(() => {
      history.push("/device_usage_profile");
    }, 500);
  }

  useEffect(() => {
    profileToUpdate.current = {
      ...profileToUpdate.current,
      referenceProduct: selectedReferenceProduct,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReferenceProduct]);

  useEffect(() => {
    profileToUpdate.current = {
      ...profileToUpdate.current,
      excludedProducts: excludedProductsData,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludedProductsData]);

  const handleChange = (values) => {
    let formData = {
      name: values.name,
      accessibleByOrganizations: values.accessibleByOrganizations,
      accessibleByPartners: values.accessibleByPartners,
      description: values.description,
      excludedRegexProducts: values.excludedRegexProducts,
      visibleToAll: values.visibleToAll,
    };
    profileToUpdate.current = {
      ...profileToUpdate.current,
      ...formData,
    };
  };

  const validationSchema = Yup.object().shape({});

  const onSaveClicked = () => {
    onChange && onChange();
  };

  return (
    <>
      {isShowConfigDialog &&
        excludedProductsData &&
        excludedProductsData.allItems != null && (
          <SelectorDialog
            title={language.excluded_products}
            data={excludedProductsData}
            setData={excludedProductsDataChanged}
            isShowConfigDialog={true}
            onDone={() => setShowConfigDialog(false)}
            onCancel={() => setShowConfigDialog(false)}
            hideAvailable={hideAvailable}
          />
        )}

      {isShowReferenceProductConfigDialog &&
        referenceProductData &&
        referenceProductData.allItems != null && (
          <SelectorDialog
            title={language.reference_product}
            data={referenceProductData}
            setData={referenceProductDataChanged}
            isShowConfigDialog={true}
            onDone={() => setShowReferenceProductConfigDialog(false)}
            onCancel={() => setShowReferenceProductConfigDialog(false)}
            hideAvailable={hideAvailableReferenceProduct}
            isSingle={true}
          />
        )}

      {isShowSupportedProductsConfigDialog &&
        supportedProductsData &&
        supportedProductsData.allItems != null && (
          <SelectorDialog
            title={language.supported_products_key}
            data={supportedProductsData}
            setData={setSupportedProductsData}
            isShowConfigDialog={true}
            onDone={() => setShowSupportedProductsConfigDialog(false)}
            onCancel={() => setShowSupportedProductsConfigDialog(false)}
            hideAvailable={hideAvailableSupportedProducts}
          />
        )}

      {isShowRegexResultConfigDialog &&
        regexProductsNew &&
        regexProductsNew.allItems != null && (
          <SelectorDialog
            title={language.excluded_products_result}
            data={regexProductsNew}
            setData={setRegexProductsNew}
            isShowConfigDialog={true}
            onDone={() => setShowRegexResultConfigDialog(false)}
            onCancel={() => setShowRegexResultConfigDialog(false)}
            hideAvailable={hideAvailableRegexResult}
          />
        )}

      <SntPanel title={language.v42_device_profile_title_edit_page_key}>
        <Row>
          <Col xs="12" lg="2" className="text-lg-end">
            <label>{language.created_by_key}</label>
          </Col>
          <Col xs="12" lg="10">
            {usageProfile.createdBy ? (
              <span>{usageProfile.createdBy.fullName}</span>
            ) : (
              loginInfo.fullName
            )}
          </Col>
        </Row>
        <Row>
          <Col xs="12" lg="2" className="text-lg-end">
            <label>{language.created_at_key}</label>
          </Col>
          <Col xs="12" lg="10">
            <span>
              {usageProfile.createdAt
                ? SensolusDatetimeReact.parse(
                    usageProfile.createdAt,
                    "server"
                  ).format("lll")
                : SensolusDatetimeReact.parse(new Date(), "server").format(
                    "lll"
                  )}
            </span>
          </Col>
        </Row>
        <Row>
          <Col xs="12" lg="2" className="text-lg-end">
            <label>{language.v42_device_profile_last_update_key}</label>
          </Col>
          <Col xs="12" lg="10">
            <span>
              {usageProfile.updatedAt
                ? SensolusDatetimeReact.parse(
                    usageProfile.updatedAt,
                    "server"
                  ).format("lll")
                : language.na_key}
            </span>
          </Col>
        </Row>
      </SntPanel>
      <SntPanel title={language.update_device_usage_profile_info_key}>
        <Formik
          initialValues={usageProfile}
          innerRef={formikRef}
          enableReinitialize={true}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <Formk>
              <FormikOnChange onChange={(data) => handleChange(data)} />
              <FormFullScreenLayout
                label={language.name_key}
                isRequired={true}
                rightRender={() => (
                  <InputText
                    name="name"
                    placeholder={language.name_key}
                    disabled={type === "view"}
                    isRequired={true}
                  />
                )}
              />
              <FormFullScreenLayout
                label={language.reference_product}
                rightRender={() => {
                  if (selectedReferenceProduct) {
                    return (
                      <DefaultTag
                        tagName={selectedReferenceProduct.key}
                        title={selectedReferenceProduct.description}
                        className="mb-2"
                        style={{ maxWidth: "300px" }}
                      />
                    );
                  }
                }}
              />
              {type === "add" && !selectedReferenceProduct && (
                <FormFullScreenLayout
                  label={""}
                  rightRender={() => {
                    return (
                      <div
                        style={{
                          marginTop:
                            !_.isEmpty(referenceProductData?.filter?.items) ||
                            isMobile
                              ? "-1rem"
                              : "-3rem",
                        }}
                      >
                        <Button
                          variant="sensolus-greylight"
                          onClick={() => {
                            setShowReferenceProductConfigDialog(true);
                            setHideAvailableReferenceProduct(false);
                          }}
                        >
                          {language.change_key}
                        </Button>
                      </div>
                    );
                  }}
                />
              )}
              <FormFullScreenLayout
                label={language.supported_products_key}
                rightRender={() => {
                  let _supportedProducts = supportedProductsData?.filter?.items?.map(
                    (obj) => {
                      return {
                        label: obj.title,
                        value: obj.key,
                        description: obj.description,
                      };
                    }
                  );

                  if (!_supportedProducts) {
                    _supportedProducts = [];
                  }

                  return (
                    <TagMore
                      onClickMoreLink={() => {
                        setShowSupportedProductsConfigDialog(true);
                        setHideAvailableSupportedProducts(true);
                      }}
                      visibleNumber={5}
                      tags={_supportedProducts}
                      render={(tag, index) => (
                        <DefaultTag
                          key={index}
                          tagName={tag.label}
                          title={tag.description}
                          className="mb-2 me-1"
                        />
                      )}
                    />
                  );
                }}
              />
              <FormFullScreenLayout
                label={language.excluded_products}
                rightRender={() => {
                  let _excludedProducts = excludedProductsData?.filter?.items?.map(
                    (obj) => {
                      return {
                        label: obj.title,
                        value: obj.key,
                        description: obj.description,
                      };
                    }
                  );

                  if (!_excludedProducts) {
                    _excludedProducts = [];
                  }

                  return (
                    <TagMore
                      onClickMoreLink={() => {
                        setShowConfigDialog(true);
                        setHideAvailable(true);
                      }}
                      visibleNumber={5}
                      tags={_excludedProducts}
                      render={(tag, index) => (
                        <DefaultTag
                          key={index}
                          tagName={tag.label}
                          title={tag.description}
                          className="mb-2 me-1"
                        />
                      )}
                    />
                  );
                }}
              />
              {(type === "add" || type === "edit") && (
                <FormFullScreenLayout
                  label={""}
                  rightRender={() => {
                    return (
                      <div
                        style={{
                          marginTop:
                            !_.isEmpty(excludedProductsData?.filter?.items) ||
                            isMobile
                              ? "-1rem"
                              : "-3rem",
                        }}
                      >
                        <Button
                          variant="sensolus-greylight"
                          onClick={() => {
                            setShowConfigDialog(true);
                            setHideAvailable(false);
                          }}
                        >
                          {language.change_key}
                        </Button>
                      </div>
                    );
                  }}
                />
              )}
              <FormFullScreenLayout
                label={language.excluded_products_regex}
                rightRender={() => (
                  <InputText
                    name="excludedRegexProducts"
                    placeholder={language.excluded_products_regex}
                    disabled={type === "view"}
                    handleChange={(regexp) => setRegexProductsText(regexp)}
                    isRequired={false}
                  />
                )}
              />
              <FormFullScreenLayout
                label={language.excluded_products_result}
                rightRender={() => {
                  let _regexProducts = regexProductsNew?.filter?.items?.map(
                    (obj) => {
                      return {
                        label: obj.title,
                        value: obj.key,
                        description: obj.description,
                      };
                    }
                  );

                  if (!_regexProducts) {
                    _regexProducts = [];
                  }

                  return _regexProducts.length ? (
                    <TagMore
                      onClickMoreLink={() => {
                        setShowRegexResultConfigDialog(true);
                        setHideAvailableRegexResult(true);
                      }}
                      visibleNumber={5}
                      tags={_regexProducts}
                      render={(tag, index) => (
                        <DefaultTag
                          key={index}
                          tagName={tag.label}
                          title={tag.description}
                          className="mb-2 me-1"
                        />
                      )}
                    />
                  ) : (
                    language.no_exclude_products
                  );
                }}
              />

              <FormFullScreenLayout
                label={language.visibility_key}
                rightRender={() => (
                  <InputCheck
                    name={"visibleToAll"}
                    disabled={
                      !permissionsMap["USAGE_PROFILE_MANAGE_VISIBLE_ALL"] ||
                      type === "view"
                    }
                    label={language.visible_to_all_key}
                    title={
                      language.do_not_have_permission_manage_visible_to_all
                    }
                  />
                )}
              />
              {!values.visibleToAll && (
                <FormFullScreenLayout
                  label={language.organizations_key}
                  rightRender={() => (
                    <ItemSelector
                      name="accessibleByOrganizations"
                      placeholder={language.organizations_key}
                      disabled={type === "view"}
                      isRequired={false}
                      multi={true}
                      options={orgs}
                    />
                  )}
                />
              )}
              {!values.visibleToAll && permissionsMap["SYSTEM_ADMIN_VIEW"] && (
                <FormFullScreenLayout
                  label={language.partner_partners_key}
                  rightRender={() => (
                    <ItemSelector
                      name="accessibleByPartners"
                      placeholder={language.partner_partners_key}
                      disabled={type === "view"}
                      isRequired={false}
                      multi={true}
                      options={partners}
                    />
                  )}
                />
              )}
              <FormFullScreenLayout
                label={language.description_key}
                rightRender={() => (
                  <InputText
                    name="description"
                    placeholder={language.description_key}
                    disabled={type === "view"}
                    isRequired={false}
                  />
                )}
              />
            </Formk>
          )}
        </Formik>
      </SntPanel>

      {type !== "view" && (
        <Col xs="12" className="mb-3">
          <Button
            variant="sensolus"
            onClick={() => onSaveClicked()}
            className="tmp me-1"
            id="save"
          >
            {language.save_key}
          </Button>
          <Button
            variant="outline-sensolus"
            onClick={() => onCancel()}
            id="cancel"
          >
            {language.cancel_key}
          </Button>
        </Col>
      )}
    </>
  );
};

export default DeviceProfileGeneralInfo;
