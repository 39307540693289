import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import searchUIClient from "@/apis/searchUIClient";
import metarWeatherLocationClient from "@apis/metarWeatherLocationClient.js";
import useDeleteDialog from "@widgets/dialog/useDeleteDialog";
import SntDeleteIcon from "../../components/Icons/SntDeleteIcon";
import SntPencilIcon from "../../components/Icons/SntPencilIcon";
import SntActionButton from "../../components/ReactBootstrap/SntActionButton";
import SntLink from "../../components/ReactBootstrap/SntLink";
import { SntAdvancedSearchTableViewCard } from "../../components/SntTableViewCard";
import { SensolusDatetimeReact } from "../../constants/Config";
import AdminLayout from "../../containers/AdminLayout/AdminLayout";
import { useOrg } from "../../contexts/OrgContext";

const pageName = "METAR_WEATHER_LOCATION";
const SHARED_KEY = "METAR_WEATHER_LOCATION";
const DEFAULT_OPTION = {
  pageName: pageName,
  queryObjectType: "METAR_WEATHER_LOCATION",
  filtersVisibleAtInit: ["name", "address"],
  optionsSettings: {},
};

const MetarWeatherLocation = ({
  reload,
  addFilter,
  isEmbeddedComponent = false,
}) => {
  //redux state
  const { orgId } = useOrg();
  const language = useSelector((state) => state.language);
  const loginInfo = useSelector((state) => state.user);
  const { permissionsMap, features } = loginInfo;
  const table = useRef();
  const [forceFilterAction, setForceFilterAction] = useState(null);
  const [filterMap, setFilterMap] = useState(null);

  const allColumns = useMemo(() => {
    return [
      {
        key: "name",
        title: language.name_key,
        Cell: ({ cell, value }) => {
          return (
            <div>
              <SntLink
                to={`/metar_weather_location_manage?type=view&id=${cell.row.original.id}`}
              >
                {value}
              </SntLink>
            </div>
          );
        },
      },
      {
        key: "address",
        title: language.v41_address_key,
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "latitude",
        title: language.latitude_key,
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "longitude",
        title: language.longitude_key,
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "radius",
        title: language.radius_new_key,
        Cell: ({ cell, value }) => {
          return value + " " + language.meters_key;
        },
      },
      {
        key: "createdBy",
        title: language.created_by_key,
        Cell: ({ cell, value }) => {
          return cell.row.original.userId ? (
            <div>
              <SntLink
                to={`/users_admin_update?type=view&id=${cell.row.original.userId}`}
              >
                {cell.row.original.userFullName}
              </SntLink>
            </div>
          ) : (
            ""
          );
        },
        cellCopyData: (data) => {
          return [data.userFullName];
        },
        cellFilterValue: (data) => {
          return [data.userFullName];
        },
      },
      {
        key: "createdAt",
        title: language.created_at_key,
        Cell: ({ cell, value }) => {
          return value
            ? SensolusDatetimeReact.parse(value, "server").format("lll")
            : "";
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const extraCols = useMemo(() => {
    if (permissionsMap["METAR_WEATHER_LOCATION_MANAGE"]) {
      return [
        {
          key: "actions",
          title: language.actions_key,
          immovable: true,
          disableSortBy: true,
          canResize: false,
          Cell: ({ cell }) => {
            var full = cell.row.original;
            return (
              <div>
                <SntActionButton
                  title={language.edit_key}
                  to={`/metar_weather_location_manage?type=edit&id=${cell.row.original.id}`}
                >
                  <SntPencilIcon />
                </SntActionButton>

                <SntActionButton
                  title={language.delete_key}
                  onClick={(e) => onDeleteClicked(e, full)}
                >
                  <SntDeleteIcon />
                </SntActionButton>
              </div>
            );
          },
        },
      ];
    } else {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionsMap]);

  const defaultColumnInit = useMemo(
    () => ["name", "address", "latitude", "longitude", "actions"],
    []
  );

  const onDataRequestChange = (data, callback) => {
    const { params, dataOptions } = data;
    if (table.current) {
      table.current.setLoading(true);
      searchUIClient.search(params).then(
        ({ data }) => {
          if (table.current) {
            table.current.updateDataTable({
              ...data,
              pageCount: data.countByFilter,
              totalItem: {
                countAll: data.countAll,
                countByFilter: data.countByFilter,
              },
            });
            table.current.setLoading(false);

            const dataCallback = table.current.getDataCallBack(
              params,
              dataOptions,
              data
            );
            callback(dataCallback[0], dataCallback[1]);
          }
        },
        (err) => {
          table.current.setLoading(false);
        }
      );
    }
  };

  const {
    handleOpen,
    handleClose,
    handleOpenError,
    DeleteDialog,
  } = useDeleteDialog({
    onDelete: (itemSelected) => {
      metarWeatherLocationClient
        .delete(itemSelected.id)
        .then(() => {
          handleClose();
          table.current.refresh();
        })
        .catch((error) => {
          handleClose();
          let message = "";
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            message = error.response.data.message;
          }
          handleOpenError(message);
        });
    },
    language,
  });

  const onDeleteClicked = (el, item) => {
    el.preventDefault();
    handleOpen(
      item,
      item.name &&
        language.delete_message_with_name_key.replace("{0}", item.name)
    );
  };

  return (
    <div>
      <DeleteDialog />
      <AdminLayout
        showPermissionWarning
        browserTabTitle={language.metar_weather_service_locations}
        headerTitle={language.metar_weather_service_locations}
        reload={reload}
        pageName={pageName}
        sharedKey={SHARED_KEY}
        defaultOption={DEFAULT_OPTION}
        isAdvanceFilter={true}
        isShowBackHistory={false}
        isHideTheSearchUI={isEmbeddedComponent}
        restrictedFilters={["organizations"]}
        newButton={{
          isShow:
            features.metar_weather_location &&
            permissionsMap["METAR_WEATHER_LOCATION_MANAGE"],
          link: "/metar_weather_location_manage?type=create",
          label: language.add_metar_weather_service,
        }}
        body={({ isDisabled, dataOptions, onChangeChildren }) =>
          dataOptions && (
            <SntAdvancedSearchTableViewCard
              shared={(child) => (table.current = child)}
              allColumns={allColumns}
              extraCols={extraCols}
              preventCols={[]}
              visibleCols={defaultColumnInit}
              disabled={isDisabled}
              onChange={(data) => {
                onDataRequestChange(data, onChangeChildren);
              }}
              dataOptions={dataOptions}
              addFilter={addFilter}
              key={orgId || loginInfo.organisation.id}
              orgId={orgId || loginInfo.organisation.id}
              useOrgInSidebar={true}
              isDisabledCopyData={false}
              isDisabbledAddFilter={false}
              onFilterClicked={(action, key, filterKeys, query) => {
                setForceFilterAction({ action, key, filterKeys, query });
              }}
              filterMap={filterMap}
            />
          )
        }
        forceFilterAction={forceFilterAction}
        setForceFilterAction={setForceFilterAction}
        onLoadFilter={(data) => setFilterMap(data)}
      ></AdminLayout>
    </div>
  );
};

export default MetarWeatherLocation;
