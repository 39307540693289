import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import SntDialog from "../../../components/SntDialog/SntDialog.js";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import ServiceInput from "../Utils/ServiceInput.js";
import { FormGroupLayout } from "../../../components/ReactBootstrap/FormValidation/index.js";

const BatteryServiceConfig = ({
  device,
  isShow,
  setIsShow,
  selectedService,
  onSave,
}) => {
  const language = useSelector((state) => state.language);
  const formikRefs = useRef();
  const [service, setService] = useState({
    type: "BATTERY",
    active: true,
    configuration: {
      estimationTimeWindowInDays: "90",
    },
  });

  useEffect(() => {
    if (selectedService && isShow) {
      let config = {};
      config.estimationTimeWindowInDays = selectedService.configuration
        ? selectedService.configuration.estimationTimeWindowInDays
        : "";
      setService({ ...service, configuration: { ...config } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService, isShow]);

  function onServiceSave() {
    let data = {
      ...service,
      configuration: {
        estimationTimeWindowInDays:
          formikRefs.current.values.estimationTimeWindowInDays,
      },
    };

    validationSchema.isValid(data.configuration).then(function (valid) {
      if (valid) {
        onSave(data);
        setIsShow(false);
      }
    });
  }

  const validationSchema = Yup.object().shape({
    estimationTimeWindowInDays: Yup.number().min(0).label("Day"),
  });

  return (
    <SntDialog
      isShow={isShow}
      onSave={() => onServiceSave(service)}
      onClose={() => setIsShow(false)}
      saveTxt={language.save_key}
      title={language.service_battery_description_name}
    >
      {() => (
        <Formik
          initialValues={service.configuration}
          innerRef={formikRefs}
          enableReinitialize={true}
          validationSchema={validationSchema}
        >
          <Form className="form-horizontal">
            <FormGroupLayout
              xl="6"
              label={language.battery_estimation_time_window}
              rightRender={() => (
                <ServiceInput
                  name="estimationTimeWindowInDays"
                  placeholder={language.battery_estimation_time_window}
                  min={0}
                  inputType={"number"}
                />
              )}
            />
          </Form>
        </Formik>
      )}
    </SntDialog>
  );
};

export default BatteryServiceConfig;
