import { useEffect, useState } from "react";

import {
  FormFullScreenLayout,
  ItemCreatable,
} from "@/components/ReactBootstrap/FormValidation";

import deviceTagClient from "@/apis/deviceTagClient";

const AssignDeviceTagsActionControl = ({ name, orgId, disabled, language }) => {
  const [deviceTagsOptions, setDeviceTagsOptions] = useState([]);

  useEffect(() => {
    deviceTagClient.getByOrg({ orgId: orgId }).then(
      ({ data }) => {
        setDeviceTagsOptions(
          data.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          })
        );
      },
      (err) => {}
    );
  }, [orgId]);

  return (
    <>
      <FormFullScreenLayout
        label={language.select_tags_key}
        isRequired={true}
        rightRender={() => (
          <ItemCreatable
            name={`${name}.selectedIds`}
            multi={true}
            options={deviceTagsOptions}
            disabled={disabled}
          />
        )}
      />
    </>
  );
};

export default AssignDeviceTagsActionControl;
