import { apiClient } from "./apiClient";

export default {
  getByOrg(params) {
    return apiClient.get("/rest/image_library/getByOrg", { params: params });
  },

  getByVisibility(params) {
    return apiClient.get("/rest/image_library/visibility", { params: params });
  },

  create(params) {
    return apiClient.post(`/rest/image_library`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  getById(id) {
    return apiClient.get("/rest/image_library/" + id);
  },

  update(id, params) {
    return apiClient.put(`/rest/image_library/` + id, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  delete(id) {
    return apiClient.delete(`/rest/image_library/${id}`);
  },
};

export const IMAGE_TYPE = {
  SENSOR: "SENSOR",
  ORIENTATION: "ORIENTATION",
  GEO_LAYER: "GEO_LAYER",
  NOTIFICATION: "NOTIFICATION",
  ASSET: "ASSET",
  ASSET_IMAGE: "ASSET_IMAGE",
  GEOZONE_ICON: "GEOZONE_ICON",
  GEOZONE_IMAGE: "GEOZONE_IMAGE",
};
