import { useEffect, useMemo, useRef, useState } from "react";

import { useSelector } from "react-redux";

import auditLogClient from "../../apis/auditLogClient";
import IcomoonIcon from "../../components/Icons/IcomoonIcon";
import SntActionButton from "../../components/ReactBootstrap/SntActionButton";
import SntLink from "../../components/ReactBootstrap/SntLink";
import AdminLayout from "../../containers/AdminLayout/AdminLayout";
import { useOrg } from "../../contexts/OrgContext";
import SntAdvancedSearchTableViewCard from "../../components/SntTableViewCard/SntAdvancedSearchTableViewCard";
import { Col, Row } from "react-bootstrap";
import moment from "../../constants/SensolusMoment";
import DateModeView from "../../cleanup/widgets/dates/DateModeView";
import DateRangeContainer from "../../cleanup/containers/dates/DateRangeContainer";
import SntDateView from "@/components/ReactBootstrap/SntDateView";
import useAuditLog from "../AuditLog/useAuditLog";
import queryString from "query-string";
import { useLocation } from "react-use";
import DeviceUsageProfileTabIds from "./DeviceUsageProfileTabIds";

const pageName = "DEVICE_PROFILE_AUDIT_LOG";
const AUDIT_LOG_DATE_RANGE = "AUDIT_LOG_RANGE";
const getSessionState = () => {
  let cachedDateTimePicker = {
    start: moment(),
    end: moment(),
    viewMode: DateModeView.week,
  };
  let cached = sessionStorage.getItem(AUDIT_LOG_DATE_RANGE);
  if (cached) {
    let json = JSON.parse(cached);
    cachedDateTimePicker = {
      start: moment(json.startDate),
      end: moment(json.endDate),
      viewMode: json.defaultViewMode,
    };
  }
  return cachedDateTimePicker;
};

const DEFAULT_OPTION = {
  pageName: pageName,
  queryObjectType: "AUDIT_LOG",
  filtersVisibleAtInit: ["authorName"],
  optionsSettings: {},
};

const DeviceProfileAuditLog = ({ profile }) => {
  //redux state
  const { orgId } = useOrg();
  const language = useSelector((state) => state.language);
  const loginInfo = useSelector((state) => state.user);
  const location = useLocation();
  const [tabActive, setTabActive] = useState("");

  const table = useRef();
  const [forceFilterAction, setForceFilterAction] = useState(null);
  const [filterMap, setFilterMap] = useState(null);

  const [filterCreateAt, setFilterCreateAt] = useState();
  const [reloadInside, setReloadInside] = useState(false);
  const [initialDefaultValue, setInitialDefaultValue] = useState(
    () => getSessionState(),
    []
  );
  const { getAuditLogActionLabel } = useAuditLog();

  useEffect(() => {
    let params = queryString.parse(location.search);
    setTabActive(params["tabActive"]);
  }, [location]);

  const allColumns = useMemo(() => {
    const allColumns = [
      {
        key: "createdAt",
        desc: true,
        title: language.date_key,
        Cell: ({ cell, value }) => {
          return <SntDateView value={value} format={"lll"} />;
        },
      },
      {
        key: "authorName",
        title: language.author_name_key,
        Cell: ({ cell, value }) => {
          let result = "";
          if (cell.row.original.authorId && cell.row.original.authorType) {
            let link = "";

            if (cell.row.original.authorType === "USER") {
              link = `/users_admin_update?type=view&id=${cell.row.original.authorId}`;
            } else {
              link = "/api-access?org=" + orgId;
            }

            result = (
              <div>
                <SntLink to={link}>{value}</SntLink>
              </div>
            );
          } else {
            result = value || "";
          }
          return result;
        },
      },
      {
        key: "auditAction",
        title: language.audit_action_key,
        Cell: ({ cell, value }) => {
          return value ? getAuditLogActionLabel(value) : "";
        },
      },
    ];
    return allColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSessionState = (data) => {
    let _data = {
      ...data,
      defaultDates: { startDate: data.startDate, endDate: data.endDate },
    };
    sessionStorage.setItem(AUDIT_LOG_DATE_RANGE, JSON.stringify(_data));
  };

  const extraCols = useMemo(() => {
    return [
      {
        key: "actions",
        title: language.actions_key,
        immovable: true,
        disableSortBy: true,
        canResize: false,
        Cell: ({ cell }) => {
          return (
            <div>
              <SntActionButton
                title={language.v43_view_key}
                to={`/audit_log_view?id=${cell.row.original.id}`}
                className="float-end"
              >
                <IcomoonIcon icon="eye"></IcomoonIcon>
              </SntActionButton>
            </div>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const preventCols = [
    "organizations",
    "entityId",
    "entityName",
    "auditCategory",
  ];

  const restrictedFilters = [
    "createdAt",
    "auditCategory",
    "entityName",
    "entityId",
    "entity",
    "organizations",
  ];

  const defaultColumnInit = useMemo(
    () => ["createdAt", "authorName", "auditAction", "actions"],
    []
  );

  const onDataRequestChange = (data, callback) => {
    const { params, dataOptions } = data;

    if (params.query) {
      let currentQueries = [...params.query];
      currentQueries.push(filterCreateAt);
      let categoryQuery = {
        categoryId: "log",
        filterKey: "auditCategory",
        filterType: "MULTI_SELECT_CHECKBOX",
        searchType: "STATIC",
        notFilter: false,
        filterValue: { selectedValues: ["DEVICE_USAGE_PROFILE"] },
      };
      currentQueries.push(categoryQuery);

      let entityIdQuery = {
        categoryId: "log",
        filterKey: "entity",
        filterType: "MULTI_STRING",
        searchType: "STATIC",
        notFilter: false,
        filterValue: {
          searchText: profile.id,
        },
      };
      currentQueries.push(entityIdQuery);

      params.query = currentQueries;
    }

    if (table.current) {
      table.current.setLoading(true);
      auditLogClient.search(params).then(
        ({ data }) => {
          if (table.current) {
            table.current.updateDataTable({
              ...data,
              pageCount: data.countByFilter,
              totalItem: {
                countByFilter: data.countByFilter,
              },
            });
            table.current.setLoading(false);

            const dataCallback = table.current.getDataCallBack(
              params,
              dataOptions,
              data
            );
            callback(dataCallback[0], dataCallback[1]);
          }
        },
        (err) => {
          table.current.setLoading(false);
        }
      );
    }
  };

  const handleTimeRangeChange = (dates) => {
    setFilterCreateAt({
      categoryId: "log",
      filterKey: "createdAt",
      filterType: "DATE",
      notFilter: false,
      filterValue: {
        absoluteStartDate: dates.start.format(),
        absoluteEndDate: dates.end.format(),
        dateFilterType: "BETWEEN_ABSOLUTE",
      },
      searchType: "STATIC",
    });

    setSessionState({
      startDate: dates.start,
      endDate: dates.end,
      defaultViewMode: dates.viewMode,
    });
    setReloadInside(!reloadInside);
  };

  return tabActive === DeviceUsageProfileTabIds.audit_log ? (
    <div>
      <Row className="">
        <Col sm="9">
          <DateRangeContainer
            value={initialDefaultValue}
            onChange={(value) => {
              setInitialDefaultValue({ ...initialDefaultValue, ...value });
              setTimeout(() => {
                handleTimeRangeChange(value);
              }, 300);
            }}
            overrideDisplays={{ month: false, year: false }}
          />
        </Col>
      </Row>
      {filterCreateAt && (
        <AdminLayout
          showPermissionWarning
          reload={reloadInside}
          isShowHeader={false}
          pageName={pageName}
          sharedKey={pageName}
          defaultOption={DEFAULT_OPTION}
          isAdvanceFilter={true}
          isShowBackHistory={false}
          restrictedFilters={restrictedFilters} //use org filter in the Sidebar
          totalLabel={language.audit_logs_key}
          body={({ isDisabled, dataOptions, onChangeChildren }) =>
            dataOptions && (
              <SntAdvancedSearchTableViewCard
                shared={(child) => (table.current = child)}
                allColumns={allColumns}
                extraCols={extraCols}
                visibleCols={defaultColumnInit}
                preventCols={preventCols}
                disabled={isDisabled}
                onChange={(data) => {
                  onDataRequestChange(data, onChangeChildren);
                }}
                dataOptions={dataOptions}
                key={orgId || loginInfo.organisation.id}
                orgId={orgId || loginInfo.organisation.id}
                useOrgInSidebar={true}
                restrictedFilters={restrictedFilters} //use org filter in the Sidebar
                isDisabledCopyData={false}
                isDisabbledAddFilter={false}
                onFilterClicked={(action, key, filterKeys, query) => {
                  setForceFilterAction({ action, key, filterKeys, query });
                }}
                filterMap={filterMap}
              />
            )
          }
          forceFilterAction={forceFilterAction}
          setForceFilterAction={setForceFilterAction}
          onLoadFilter={(data) => setFilterMap(data)}
        ></AdminLayout>
      )}
    </div>
  ) : (
    ""
  );
};

export default DeviceProfileAuditLog;
