import { useFormikContext } from "formik";
import { useEffect } from "react";

export const FormikOnChange = ({ onChange }) => {
  const { values } = useFormikContext();

  useEffect(() => {
    onChange(values);
  }, [values, onChange]);
  return null;
};
