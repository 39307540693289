import SntIcon from "./SntIcon";

const SntDropdownListIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path d="M5.3225 6L8 8.47439L10.6775 6L11.5 6.7655L8 10L4.5 6.7655L5.3225 6Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 2H3C2.44772 2 2 2.44772 2 3V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V3C14 2.44772 13.5523 2 13 2ZM3 1C1.89543 1 1 1.89543 1 3V13C1 14.1046 1.89543 15 3 15H13C14.1046 15 15 14.1046 15 13V3C15 1.89543 14.1046 1 13 1H3Z"
      />
    </SntIcon>
  );
};

export default SntDropdownListIcon;
