import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// eslint-disable-next-line react/display-name
const _SntLink = React.forwardRef((props, ref) => {
  const { children, to, onClick, ...rest } = props;
  return to ? (
    <Link
      ref={ref}
      {...rest}
      to={to}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          e.stopPropagation();
          onClick(e);
        }
      }}
    >
      {children}
    </Link>
  ) : (
    <a
      ref={ref}
      {...rest}
      href="##"
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          e.stopPropagation();
          onClick(e);
        }
      }}
    >
      {children}
    </a>
  );
});

const SntLink = styled(_SntLink)`
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

export default SntLink;
