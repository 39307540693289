import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import searchUIClient from "@/apis/searchUIClient";
import SntLink from "@/components/ReactBootstrap/SntLink.js";
import { SensolusDatetimeReact } from "@/constants/Config.js";
import CheckView from "@widgets/views/CheckView.js";
import { SntAdvancedSearchTableViewCard } from "../../components/SntTableViewCard";
import AdminLayout from "../../containers/AdminLayout/AdminLayout";

const pageName = "CELLULAR_SIM";
const SHARED_KEY = "CELLULAR_SIM";
const DEFAULT_OPTION = {
  pageName: pageName,
  queryObjectType: "CELLULAR_SIM",
  filtersVisibleAtInit: [
    "autoDetectedImei",
    "cellularAccount",
    "deviceIdentifier",
  ],
  optionsSettings: {},
};

const CellularSim = ({ reload, addFilter, isEmbeddedComponent = false }) => {
  //redux state
  const language = useSelector((state) => state.language);
  const table = useRef();
  const [forceFilterAction, setForceFilterAction] = useState(null);
  const [filterMap, setFilterMap] = useState(null);

  const allColumns = useMemo(() => {
    return [
      {
        key: "iccid",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "imsi",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "autoDetectedImei",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "enabledImeiLock",
        Cell: ({ cell, value }) => {
          return <CheckView value={value} />;
        },
      },
      {
        key: "enforceImeiLock",
        Cell: ({ cell, value }) => {
          return <CheckView value={value} />;
        },
      },
      {
        key: "imeiMatching",
        Cell: ({ cell, value }) => {
          return <CheckView value={value} />;
        },
      },
      {
        key: "cellularState",
        Cell: ({ cell, value }) => {
          const cellularState = value;
          return cellularState ? cellularState : "";
        },
        cellCopyData: (data) => {
          return [data.cellularState || ""];
        },
        cellFilterValue: (data) => {
          return [`${data.cellularState || ""}`];
        },
      },
      {
        key: "customerServiceProfile",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "ragStatus",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "rogueUsageAlertPresent",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "highUsageAlertPresent",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "onceVolume",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "onceTotalVolume",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "onceExpiryDate",
        Cell: ({ cell, value }) => {
          return value
            ? SensolusDatetimeReact.parse(value, "server").format("lll")
            : "";
        },
      },
      {
        key: "dtSubscriptionPackageName",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "dtSubscriptionPackageDescription",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "dtSimSpecificationId",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "epsilonModel",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "epsilonFirstActivationDate",
        Cell: ({ cell, value }) => {
          return value
            ? SensolusDatetimeReact.parse(value, "server").format("lll")
            : "";
        },
      },
      {
        key: "epsilonContractExpirationDate",
        Cell: ({ cell, value }) => {
          return value
            ? SensolusDatetimeReact.parse(value, "server").format("lll")
            : "";
        },
      },
      {
        key: "epsilonContractDeactivationDate",
        Cell: ({ cell, value }) => {
          return value
            ? SensolusDatetimeReact.parse(value, "server").format("lll")
            : "";
        },
      },
      {
        key: "epsilonGidName",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "emnifyModelId",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "emnifyModelDescription",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "emnifyMemorySize",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "emnifySimId",
        Cell: ({ cell, value }) => {
          return value || "";
        },
      },
      {
        key: "emnifyProductionDate",
        Cell: ({ cell, value }) => {
          return value
            ? SensolusDatetimeReact.parse(value, "server").format("lll")
            : "";
        },
      },
      {
        key: "deutschetelekomConsumption",
        Cell: ({ cell, value }) => {
          return value !== undefined
            ? value + " " + cell.row.original.deutschetelekomUnit
            : "";
        },
      },
      {
        key: "device",
        Cell: ({ cell, value }) => {
          return (
            <SntLink
              to={`/tracked_assets_update?type=view&id=${cell.row.original.deviceId}`}
            >
              {cell.row.original.deviceName}
            </SntLink>
          );
        },
        cellCopyData: (data) => {
          return [data.deviceName];
        },
        cellFilterValue: (data) => {
          return [data.deviceName];
        },
      },
      {
        key: "cellularAccount",
        Cell: ({ cell, value }) => {
          return (
            <SntLink
              to={`/cellular_account_manage?type=view&id=${cell.row.original.cellularAccountId}`}
            >
              {cell.row.original.cellularAccountName}
            </SntLink>
          );
        },
        cellCopyData: (data) => {
          return [data.cellularAccountName];
        },
        cellFilterValue: (data) => {
          return [`${data.cellularAccountId || ""}`];
        },
      },
      {
        key: "insertedDate",
        Cell: ({ cell, value }) => {
          return value
            ? SensolusDatetimeReact.parse(value, "server").format("lll")
            : "";
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultColumnInit = useMemo(
    () => ["iccid", "imsi", "device", "cellularState", "cellularAccount"],
    []
  );

  const onDataRequestChange = (data, callback) => {
    const { params, dataOptions } = data;
    if (table.current) {
      table.current.setLoading(true);
      searchUIClient.search(params).then(
        ({ data }) => {
          if (table.current) {
            table.current.updateDataTable({
              ...data,
              pageCount: data.countByFilter,
              totalItem: {
                countAll: data.countAll,
                countByFilter: data.countByFilter,
              },
            });
            table.current.setLoading(false);

            const dataCallback = table.current.getDataCallBack(
              params,
              dataOptions,
              data
            );
            callback(dataCallback[0], dataCallback[1]);
          }
        },
        (err) => {
          table.current.setLoading(false);
        }
      );
    }
  };

  return (
    <div>
      <AdminLayout
        showPermissionWarning
        browserTabTitle={
          language.UserPermission_MENU_ADMIN_CELLULAR_SIM_description_key
        }
        headerTitle={
          language.UserPermission_MENU_ADMIN_CELLULAR_SIM_description_key
        }
        reload={reload}
        pageName={pageName}
        sharedKey={SHARED_KEY}
        defaultOption={DEFAULT_OPTION}
        isAdvanceFilter={true}
        isShowBackHistory={false}
        isHideTheSearchUI={isEmbeddedComponent}
        totalLabel={language.cellular_sims_lower_key}
        body={({ isDisabled, dataOptions, onChangeChildren }) =>
          dataOptions && (
            <SntAdvancedSearchTableViewCard
              shared={(child) => (table.current = child)}
              allColumns={allColumns}
              preventCols={[]}
              visibleCols={defaultColumnInit}
              disabled={isDisabled}
              onChange={(data) => {
                onDataRequestChange(data, onChangeChildren);
              }}
              dataOptions={dataOptions}
              addFilter={addFilter}
              isDisabledCopyData={false}
              isDisabbledAddFilter={false}
              onFilterClicked={(action, key, filterKeys, query) => {
                setForceFilterAction({ action, key, filterKeys, query });
              }}
              filterMap={filterMap}
            />
          )
        }
        forceFilterAction={forceFilterAction}
        setForceFilterAction={setForceFilterAction}
        onLoadFilter={(data) => setFilterMap(data)}
      ></AdminLayout>
    </div>
  );
};

export default CellularSim;
