import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import SntDialog from "../../../components/SntDialog/SntDialog.js";
import SensorConfig from "./SensorConfig.js";

const BleSensorServiceConfig = ({
  isShow,
  setIsShow,
  selectedService,
  onSave,
}) => {
  const language = useSelector((state) => state.language);
  const form0 = useRef();
  const form1 = useRef();
  const form2 = useRef();
  const form3 = useRef();
  const [sensor0, setSensor0] = useState({
    name: "",
    description: "",
    unit: "",
    lowerBound: null,
    upperBound: null,
    useRawValue: true,
    handleAsDeviceOrientation: false,
    valueVisibility: "ALL",
    stateVisibility: "ALL",
  });
  const [sensor1, setSensor1] = useState({
    name: "",
    description: "",
    unit: "",
    lowerBound: null,
    upperBound: null,
    useRawValue: true,
    handleAsDeviceOrientation: false,
    valueVisibility: "ALL",
    stateVisibility: "ALL",
  });
  const [sensor2, setSensor2] = useState({
    name: "",
    description: "",
    unit: "",
    lowerBound: null,
    upperBound: null,
    useRawValue: true,
    handleAsDeviceOrientation: false,
    valueVisibility: "ALL",
    stateVisibility: "ALL",
  });
  const [sensor3, setSensor3] = useState({
    name: "",
    description: "",
    unit: "",
    lowerBound: null,
    upperBound: null,
    useRawValue: true,
    handleAsDeviceOrientation: false,
    valueVisibility: "ALL",
    stateVisibility: "ALL",
  });

  const [service, setService] = useState({
    type: "BLE_SENSOR",
    active: true,
    configuration: {
      sensor0: {
        name: "",
        description: "",
        unit: "",
        lowerBound: null,
        upperBound: null,
      },
      sensor1: {
        name: "",
        description: "",
        unit: "",
        lowerBound: null,
        upperBound: null,
      },
    },
  });

  useEffect(() => {
    if (selectedService) {
      selectedService &&
        isShow &&
        setService({
          ...service,
          ...selectedService,
          configuration: {
            ...service.configuration,
            ...selectedService.configuration,
          },
        });
      setSensor0({
        ...selectedService?.configuration?.sensor0,
      });
      setSensor1({
        ...selectedService?.configuration?.sensor1,
      });
      setSensor2({
        ...selectedService?.configuration?.sensor2,
      });
      setSensor3({
        ...selectedService?.configuration?.sensor3,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService]);

  function onServiceSave() {
    let configuration = {
      sensor0,
      sensor1,
      sensor2,
      sensor3,
    };

    configuration.sensor0 = form0.current?.getFormData();
    configuration.sensor1 = form1.current?.getFormData();
    configuration.sensor2 = form2.current?.getFormData();
    configuration.sensor3 = form3.current?.getFormData();

    onSave({ ...service, configuration: configuration });
    setIsShow(false);
  }

  return (
    <SntDialog
      isShow={isShow}
      onSave={() => onServiceSave(service)}
      onClose={() => setIsShow(false)}
      saveTxt={language.save_key}
      title={language.v40_define_services_to_enable_disable_key}
    >
      {() => (
        <>
          <SensorConfig
            title={language.ble_sensor_settings_1_key}
            sensorConfig={sensor0}
            shared={(child) => (form0.current = child)}
          />
          <SensorConfig
            title={language.ble_sensor_settings_2_key}
            sensorConfig={sensor1}
            shared={(child) => (form1.current = child)}
          />
          <SensorConfig
            title={language.ble_sensor_settings_3_key}
            sensorConfig={sensor2}
            shared={(child) => (form2.current = child)}
          />
          <SensorConfig
            title={language.ble_sensor_settings_4_key}
            sensorConfig={sensor3}
            shared={(child) => (form3.current = child)}
          />
        </>
      )}
    </SntDialog>
  );
};

export default BleSensorServiceConfig;
