import SntDeleteIcon from "@/components/Icons/SntDeleteIcon";
import SntPencilIcon from "@/components/Icons/SntPencilIcon";
import SntReOrderIcon from "@/components/Icons/SntReOrderIcon";
import SntActionButton from "@/components/ReactBootstrap/SntActionButton";
import { ReactSortable } from "react-sortablejs";
import styled from "styled-components";

const TransitionStyle = styled.div`
  ul {
    list-style: disc !important;
  }
`;

const SntSortList = ({ value, setValue, disabled, onEdit, onRemove }) => {
  return (
    <>
      <TransitionStyle className="sortable">
        <ReactSortable
          animation={150}
          list={value}
          setList={setValue}
          disabled={disabled}
          tag="ul"
          className="list-group ui-sortable mb-2"
        >
          {/* <ul className="search-filter no-li-marker list-group ui-sortable mb-2"> */}
          {value.map((item, idx) => {
            const label = item.label;

            return (
              <li className="list-group-item" key={idx}>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="flex-grow-1 fs-5">{label}</div>
                  <div
                    style={{
                      minWidth: "85px",
                      justifyContent: "space-between",
                    }}
                  >
                    {!disabled && (
                      <>
                        <SntActionButton
                          onClick={(e) => {
                            onEdit && onEdit(item, idx);
                          }}
                        >
                          <SntPencilIcon />
                        </SntActionButton>
                        <SntReOrderIcon style={{ color: "#b9c0ca" }} />
                        <SntActionButton
                          onClick={(e) => {
                            onRemove && onRemove(item, idx);
                          }}
                        >
                          <SntDeleteIcon />
                        </SntActionButton>
                      </>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
          {/* </ul> */}
        </ReactSortable>
      </TransitionStyle>
    </>
  );
};

export default SntSortList;
