import { apiClient } from "./apiClient";

export default {
  findAll(deviceId, params) {
    return apiClient.post(`/rest/orientationstates/${deviceId}`, params, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    });
  },
  test(params) {
    return apiClient.post(`/rest/orientationstates/test`, params, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    });
  },

  getOrientationStates() {
    return apiClient.get(`/rest/orientationstates/orientationStates`);
  },

  getOrientationStateAll() {
    return apiClient.get(`/rest/orientationstates/all/orientationStates`);
  },

  getOrientationForChart(deviceId, params) {
    return apiClient.post(`/rest/orientationstates/chart/${deviceId}`, params, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    });
  },
};
