import { Col, Row } from "react-bootstrap";

const SntDetailItem = ({
  label,
  rightRender,
  isSideBar,
  className,
  labelClass,
}) => {
  return (
    <Row className={className || ""}>
      <Col xs={6} md={isSideBar ? 6 : 4} className={labelClass}>
        {label}
      </Col>
      <Col xs={6} md={isSideBar ? 6 : 8} className="align-self-center">
        {rightRender && rightRender()}
      </Col>
    </Row>
  );
};

export default SntDetailItem;
