import { useEffect, useImperativeHandle, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { Form, Formik } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import { createFilter } from "react-select";
import { ReactSortable } from "react-sortablejs";
import * as Yup from "yup";

import SntDeleteIcon from "@icons/SntDeleteIcon.js";
import SntReOrderIcon from "@icons/SntReOrderIcon.js";
import ErrorLabel from "@/components/ReactBootstrap/FormValidation/ErrorLabel.js";
import SntActionButton from "@/components/ReactBootstrap/SntActionButton.js";
import SntInfoPanel from "@/components/SntInfo/SntInfoPanel.js";
import config from "@/constants/Config.js";
import { ItemSelector } from "@/components/ReactBootstrap/FormValidation/index.js";
import SntTooltip from "@/components/Tooltip/SntTooltip.js";

import ScriptInput from "../Utils/ScriptInput.js";
import ServiceConfigPanel from "../Utils/ServiceConfigPanel.js";
import ServiceInput from "../Utils/ServiceInput.js";
import BLESensorMappedState from "./BLESensorMappedState.js";
import TestConfigSensorInputLayout from "../Utils/TestConfigSensorInputLayout.js";

import bleSensorClient from "@apis/bleSensorClient.js";
import imageLibraryClient, { IMAGE_TYPE } from "@apis/imageLibraryClient.js";
import SntSelect from "@wrappers/ReactSelect/SntSelect.js";
import { useOrg } from "@/contexts/OrgContext.js";
import _ from "lodash";

const ConfigMappingSensor = ({
  getSensorInfo,
  serviceConfiguration = {},
  shared,
}) => {
  const language = useSelector((state) => state.language);
  const formikRefs = useRef();
  const loginInfo = useSelector((state) => state.user);
  const { orgId } = useOrg();

  const [testData, setTestData] = useState();

  const [images, setImages] = useState([]);
  const [error, setError] = useState("");
  const [isShowExample, setShowExample] = useState(false);
  const [customStateList, setCustomStateList] = useState([]);
  const [customState, setCustomState] = useState([]);

  const [configuration, setConfiguration] = useState({
    script: "",
    customStates: [],
  });

  useEffect(() => {
    if (_.isEmpty(configuration.customStates)) {
      setCustomStateList([]);
    } else {
      setCustomStateList(configuration.customStates);
    }
  }, [configuration]);

  useEffect(() => {
    if (customStateList.length > 0) {
      setCustomState(
        customStateList.map((s) => ({ label: s.key, value: s.key }))
      );
    }
  }, [customStateList]);

  const exampleScript =
    " if(prevValue < 25 && value < 25) {\n" +
    '    "25,250x";\n' +
    "  } else {\n" +
    '    if(prevState == "500" && value < 50) {\n' +
    '      "50,500x";\n' +
    "    } else {\n" +
    "    if (value < 25) {\n" +
    '     "25,250"\n' +
    "    } else if (value < 50) {\n" +
    '     "50,500";\n' +
    "    } else if(value<75) {\n" +
    '        "75,750";\n' +
    "      } else {\n" +
    "        if(value<100){\n" +
    '          "100,1000";\n' +
    "        } else {\n" +
    "         null;\n" +
    "        }\n" +
    "      }\n" +
    "    }\n" +
    "  }";

  useEffect(() => {
    setConfiguration({ ...configuration, ...serviceConfiguration });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceConfiguration]);

  useEffect(() => {
    imageLibraryClient
      .getByVisibility({
        orgId: orgId,
        imageLibraryType: IMAGE_TYPE.SENSOR,
      })
      .then(({ data }) => {
        let images = [];
        for (var i = 0; i < data.length; i++) {
          var full = data[i];
          var _system = "";
          let isSystem = false;
          if (loginInfo.organisation.organisationType === "SYSTEM") {
            if (full.organisation.organisationType === "SYSTEM") {
              _system = " (SYSTEM)";
              isSystem = true;
            } else {
              _system = " (" + full.organisation.name + ")";
            }
          } else if (full.organisation.organisationType === "SYSTEM") {
            _system = " (SYSTEM)";
            isSystem = true;
          }
          images.push({
            value: full.pathName,
            label: (
              <span>
                <img
                  className="tmp me-2"
                  alt={full.name}
                  src={config.API_URL + full.pathName}
                  width="24px"
                  height="24px"
                />{" "}
                {full.name + "." + full.type + _system}{" "}
              </span>
            ),
            isSystem: isSystem,
            name: full.name + _system,
          });
        }
        const sortedList = images.sort((a, b) => {
          // Sort by isSystem (true comes first)
          if (a.isSystem !== b.isSystem) {
            return a.isSystem ? -1 : 1;
          }

          // If isSystem is the same, sort by label alphabetically
          return a.name.localeCompare(b.name);
        });
        setImages(sortedList);
      })
      .then((error) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function renderCustomStates() {
    if (!configuration.customStates || configuration.customStates.length === 0)
      return;

    return (
      <>
        <li
          key="kk"
          style={{
            display: "flex",
            listStyle: "none",
            marginBottom: 5,
          }}
        >
          <Col sm="3" className="d-flex align-items-stretch me-1">
            {language.key_key}
          </Col>
          <Col sm="3" className="d-flex align-items-stretch me-1">
            {language.name_key}
          </Col>
          <Col sm="3" className="d-flex align-items-stretch me-1">
            {language.image_key}
          </Col>
        </li>
        <ReactSortable
          animation={150}
          list={customStateList}
          setList={setCustomStateList}
          id="sortable"
        >
          {customStateList.map((state, idx) => (
            <li
              key={idx}
              style={{
                display: "flex",
                listStyle: "none",
                marginBottom: 5,
              }}
            >
              <Col sm="3" className="d-flex align-items-stretch me-1">
                <input
                  name="key"
                  type="text"
                  className="form-control"
                  value={state.key}
                  onChange={(el) => onCustomKeyChanged(el, idx)}
                  placeholder={language.key_key}
                />
              </Col>
              <Col sm="3" className="d-flex align-items-stretch me-1">
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  value={state.name}
                  onChange={(el) => onCustomNameChanged(el, idx)}
                  placeholder={language.name_key}
                />
              </Col>
              <Col sm="4">
                <SntSelect
                  name="image"
                  options={images}
                  filterOption={createFilter({
                    matchFrom: "any",
                    stringify: (option) => `${option.data?.name}`,
                  })}
                  onChange={(el) => onImageChanged(el, idx)}
                  value={
                    images.filter((i) => i.value === state.image) || images[0]
                  }
                  placeholder={language.select_image_key}
                />
              </Col>
              <Col sm="2" className="text-center align-self-center">
                <SntActionButton
                  onClick={removeCustomStateClicked.bind(idx)}
                  title={language.delete_key}
                >
                  <SntDeleteIcon />
                </SntActionButton>

                <SntActionButton
                  title={language.drag_and_drop_to_change_order_key}
                  onClick={(e) => e.preventDefault()}
                >
                  <SntReOrderIcon />
                </SntActionButton>
              </Col>
            </li>
          ))}
        </ReactSortable>
      </>
    );
  }

  function onCustomKeyChanged(el, idx) {
    let states = [...configuration.customStates];
    states[idx] = { ...states[idx], key: el.target.value };
    let configData = { ...getFormData(), customStates: states };
    setConfiguration({ ...configuration, ...configData });
  }

  function onCustomNameChanged(el, idx) {
    let states = [...configuration.customStates];
    states[idx] = { ...states[idx], name: el.target.value };
    let configData = { ...getFormData(), customStates: states };
    setConfiguration({ ...configuration, ...configData });
  }

  function onImageChanged(el, idx) {
    let states = [...configuration.customStates];
    states[idx] = { ...states[idx], image: el.value };
    let configData = { ...getFormData(), customStates: states };
    setConfiguration({ ...configuration, ...configData });
  }

  function addCustomStateClicked(el) {
    let states = [
      ...configuration.customStates,
      { key: "", name: "", image: "" },
    ];
    let configData = { ...getFormData(), customStates: states };
    setConfiguration({ ...configuration, ...configData });
    el.preventDefault();
  }

  function removeCustomStateClicked() {
    let states = [...configuration.customStates];
    states.splice(this, 1);
    let configData = { ...getFormData(), customStates: states };
    setConfiguration({ ...configuration, ...configData });
  }

  function getFormData() {
    let params = formikRefs.current.values;
    let configuration = {
      script: params.script,
      customStates: customStateList,
    };
    return configuration;
  }

  function testButtonClicked() {
    let params = formikRefs.current.values;
    let sensorInfo = getSensorInfo && getSensorInfo();
    var testData = {
      measurementValue: params.measurementValue,
      script: params.script,
      prevValue: params.prevValue || null,
      prevState: params.prevState?.value || null,
    };

    validationSchema.isValid(testData).then(function (valid) {
      if (valid) {
        bleSensorClient
          .test(testData)
          .then(({ data }) => {
            setTestData(null);
            setError(null);
            if (data.result) {
              let parts = data.result.split(",");
              let _configuration = {
                ...configuration,
                customStates: params.customStates,
                name: sensorInfo.name,
                unit: sensorInfo.unit,
                mappedValue: parts[0]
                  ? Math.round((Number(parts[0]) + Number.EPSILON) * 100) / 100
                  : "unknown",
                sensorState: parts[1] || "unknown",
                measurementValue: params.measurementValue,
              };
              setTestData(_configuration);
              setError("");
            } else if (data.error) {
              setError(data.error);
            }
          })
          .catch((error) => {
            setError(error.response.data.message);
          });
      }
    });
  }

  const validationSchema = Yup.object({
    measurementValue: Yup.number().label("Sensor value"),
  });

  useImperativeHandle(shared, () => ({
    getFormData,
    // formRef,
  }));

  return (
    <div>
      <Formik
        initialValues={configuration}
        innerRef={formikRefs}
        enableReinitialize={true}
        validationSchema={validationSchema}
      >
        <Form className="form-horizontal">
          <ServiceConfigPanel
            title={language.definition_custom_sensor_states_key}
            tooltip={language.definition_custom_sensor_states_key}
          >
            {() => (
              <div>
                {renderCustomStates()}
                <Button
                  variant="sensolus-greylight"
                  onClick={addCustomStateClicked}
                >
                  {language.add_other_state_key}
                </Button>
              </div>
            )}
          </ServiceConfigPanel>

          {
            <ServiceConfigPanel title={language.script_key}>
              {() => (
                <>
                  <Row>
                    <SntInfoPanel>
                      {language.external_ble_sensor_script_warning}
                    </SntInfoPanel>

                    <ScriptInput
                      name="script"
                      rows={5}
                      value={configuration.script}
                    >
                      {" "}
                    </ScriptInput>
                  </Row>

                  <Row>
                    <div className="form-group">
                      <a
                        href="##"
                        onClick={() =>
                          setShowExample((prevState) => !prevState)
                        }
                      >
                        {isShowExample
                          ? language.hide_example_script
                          : language.show_example_script}
                      </a>
                      {isShowExample && (
                        <div
                          className=" form-control"
                          style={{
                            height: "200px",
                            overflowY: "auto",
                            marginTop: "5px",
                          }}
                        >
                          <pre style={{ overflow: "unset" }}>
                            {exampleScript}
                          </pre>
                        </div>
                      )}
                    </div>
                  </Row>
                </>
              )}
            </ServiceConfigPanel>
          }

          <ServiceConfigPanel title={language.test_key}>
            {() => (
              <Row>
                <Col md="5">
                  <TestConfigSensorInputLayout
                    leftRender={() => (
                      <div className="d-flex">
                        {"value"}
                        <span className="ms-2">
                          <SntTooltip text={language.raw_sensor_value} />
                        </span>
                      </div>
                    )}
                    rightRender={() => (
                      <ServiceInput
                        name="measurementValue"
                        inputType="number"
                      />
                    )}
                  />

                  <TestConfigSensorInputLayout
                    leftRender={() => (
                      <div className="d-flex">
                        {"prevValue"}
                        <span className="ms-2">
                          <SntTooltip text={language.pre_raw_sensor_value} />
                        </span>
                      </div>
                    )}
                    rightRender={() => (
                      <ServiceInput name="prevValue" inputType="number" />
                    )}
                  />

                  <TestConfigSensorInputLayout
                    leftRender={() => (
                      <div className="d-flex">
                        {"prevState"}
                        <span className="ms-2">
                          <SntTooltip text={language.pre_state} />
                        </span>
                      </div>
                    )}
                    rightRender={() => (
                      <ItemSelector
                        name="prevState"
                        options={customState}
                        placeholder={language.pre_state}
                      />
                    )}
                  />

                  <TestConfigSensorInputLayout
                    rightRender={() => (
                      <Button
                        variant="sensolus-greylight"
                        onClick={testButtonClicked}
                      >
                        {language.test_key}
                      </Button>
                    )}
                  />
                  <ErrorLabel error={error} />
                </Col>
                <Col md="7">
                  {testData ? (
                    <BLESensorMappedState
                      dataMapped={testData}
                    ></BLESensorMappedState>
                  ) : (
                    <div className="text-center">
                      {language.no_data_display_key}
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </ServiceConfigPanel>
        </Form>
      </Formik>
    </div>
  );
};

export default ConfigMappingSensor;
