import SntDeleteIcon from "@/components/Icons/SntDeleteIcon";
import SntPencilIcon from "@/components/Icons/SntPencilIcon";
import SntPanel from "@/components/Panel/SntPanel";
import SntActionButton from "@/components/ReactBootstrap/SntActionButton";
import SntInfoPanel from "@/components/SntInfo/SntInfoPanel";
import { useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import AddNumericVariablePopup from "./AddNumericVariablePopup";

const NumericVariable = ({ variables, onSave, mode }) => {
  const language = useSelector((state) => state.language);
  const [showAddVariablePopup, setShowAddVariablePopup] = useState(false);
  const [selectedVariable, setSelectedVariable] = useState({
    name: "",
    max: 0,
    min: 0,
    step: 1,
  });

  function renderRow(variable) {
    return (
      variable && (
        <tr key={variable.name}>
          <td>{variable.name}</td>
          <td>{variable.min}</td>
          <td>{variable.max}</td>
          <td>{variable.step}</td>
          {mode !== "view" && (
            <td className="text-center">
              <SntActionButton
                name={variable.name}
                onClick={(e) => onEdit(e, variable)}
                title={language.edit_key}
              >
                <SntPencilIcon />
              </SntActionButton>
              <SntActionButton
                name={variable.name}
                onClick={(e) => deleteVariable(e, variable)}
                title={language.delete_key}
              >
                <SntDeleteIcon />
              </SntActionButton>
            </td>
          )}
        </tr>
      )
    );
  }

  function onEdit(el, variable) {
    el.preventDefault();
    let idx = variables.findIndex((obj) => {
      return obj.name === variable.name;
    });
    setSelectedVariable({ ...variable, index: idx });
    setShowAddVariablePopup(true);
  }

  function deleteVariable(el, variable) {
    el.preventDefault();
    let vals = [...variables];
    vals.splice(
      vals.findIndex((v) => v.name === variable.name),
      1
    );
    onSave(vals);
  }

  function onSaveVariable(data) {
    if (data.index >= 0) {
      //edit case
      variables[data.index] = data;
    } else {
      //create new
      variables.push(data);
    }

    let val = variables.map((v) => {
      delete v.index;
      return v;
    });
    onSave(val);
  }

  function onAdd(e) {
    e.preventDefault();
    setSelectedVariable({
      name: "",
      max: 0,
      min: 0,
      step: 1,
    });
    setShowAddVariablePopup(true);
  }

  return (
    <>
      <SntPanel title={language.define_numeric_variables}>
        <SntInfoPanel>{language.numeric_variable_description}</SntInfoPanel>

        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>{language.name_key}</th>
              <th>{language.min_key}</th>
              <th>{language.max_key}</th>
              <th>{language.step_key}</th>
              {mode !== "view" && <th>{language.action_key}</th>}
            </tr>
          </thead>
          <tbody>{variables.map((variable) => renderRow(variable))}</tbody>
        </Table>

        {mode !== "view" && (
          <Row>
            <Col xs="12" sm="5">
              <Button variant="sensolus-greylight" onClick={onAdd}>
                {language.add_variable}
              </Button>
            </Col>
          </Row>
        )}
      </SntPanel>

      <AddNumericVariablePopup
        selectedVariable={selectedVariable}
        isShow={showAddVariablePopup}
        setIsShow={setShowAddVariablePopup}
        onSave={(data) => onSaveVariable(data)}
      ></AddNumericVariablePopup>
    </>
  );
};
export default NumericVariable;
