import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { FormGroupLayout } from "../../../../components/ReactBootstrap/FormValidation";
import SntDialog from "../../../../components/SntDialog/SntDialog";
import SntRadio from "../../../../components/SntRadio/SntRadio";
import ProfileConfig from "./../ProfileConfig/ProfileConfig";

const SelectUsageProfile = ({
  profiles = [],
  deviceSettings,
  onSave,
  isShow,
  setIsShow,
}) => {
  const language = useSelector((state) => state.language);

  const [selectedProfile, setSelectedProfile] = useState();
  const [showOptions, setShowOptions] = useState(false);

  const [results, setResults] = useState([]);
  const [searchProfile, onSearchProfile] = useState("");

  useEffect(() => {
    if (deviceSettings && deviceSettings.appliedDeviceUsageProfile) {
      let profile = profiles.find(
        (p) => p.id === deviceSettings.appliedDeviceUsageProfile.id
      );
      setSelectedProfile(profile);
    } else {
      setSelectedProfile(null);
    }
    setShowOptions(false);
    onSearchProfile("");
  }, [isShow, profiles, deviceSettings]);

  const renderProfiles = () => {
    return profiles.length
      ? profiles.map((p) => {
          let _isShow =
            p.name.toLowerCase().indexOf(searchProfile.toLowerCase()) > -1
              ? "block"
              : "none";
          return (
            <li key={p.name} style={{ display: _isShow }}>
              <SntRadio
                className=" me-2"
                value={p.id.toString()}
                selectedValue={
                  selectedProfile ? selectedProfile.id.toString() : ""
                }
                onChange={onProfileChange}
                label={p.name}
              />
              <p style={{ fontSize: 13 }}>{p.description}</p>
            </li>
          );
        })
      : "";
  };

  const onProfileChange = (roleId) => {
    let profile = profiles.find((p) => p.id.toString() === roleId);
    setSelectedProfile(profile);
  };

  useEffect(() => {
    let mapAppliedCategoryValue = {};
    if (deviceSettings && deviceSettings.appliedProfileCategories) {
      const categoryValues = deviceSettings.appliedProfileCategories.split(";");
      for (let i = 0; i < categoryValues.length; i++) {
        const nameValue = categoryValues[i].split(":");
        mapAppliedCategoryValue[nameValue[0]] = nameValue[1];
      }
    }

    let results =
      selectedProfile &&
      selectedProfile.categories &&
      selectedProfile.categories.map((category) => {
        let option = [];
        if (
          deviceSettings &&
          deviceSettings.appliedDeviceUsageProfile &&
          selectedProfile.id === deviceSettings.appliedDeviceUsageProfile.id &&
          mapAppliedCategoryValue[category.name]
        ) {
          option =
            category.options.find((option) => {
              return option.name === mapAppliedCategoryValue[category.name];
            }) || [];
        } else {
          option =
            category.options.find((option) => {
              return option.isDefault;
            }) || [];
        }
        return {
          name: category.name,
          description: category.description,
          options: [
            {
              description: option.description,
              deviceFwParameterSettings: option.deviceFwParameterSettings,
              isDefault: option.isDefault,
              name: option.name,
              profileServiceUpdates: option.profileServiceUpdates,
              uiLabel: option.uiLabel,
            },
          ],
        };
      });
    results = results && results.filter((r) => r.options[0].length !== 0);
    setResults(results);
  }, [selectedProfile, deviceSettings]);

  const onOptionChange = (category, option) => {
    let newResults = [];
    if (results.length > 0) {
      for (let i = 0; i < results.length; i++) {
        if (results[i].name === category.name) {
          newResults[i] = {
            name: category.name,
            description: category.description,
            options: [
              {
                description: option.description,
                deviceFwParameterSettings: option.deviceFwParameterSettings,
                isDefault: option.isDefault,
                name: option.name,
                profileServiceUpdates: option.profileServiceUpdates,
                uiLabel: option.uiLabel,
              },
            ],
          };
        } else {
          newResults[i] = results[i];
        }
      }
    }

    // newResults = newResults.filter((r) => r.options[0].name);
    setResults(newResults);
  };

  const onSaveClicked = () => {
    let categories = results.filter((r) => r.options[0].name);
    if (showOptions) {
      onSave({
        profile: {
          id: selectedProfile.id,
          name: selectedProfile.name,
        },
        categories: categories,
      });
      setShowOptions(false);
      setIsShow(false);
    } else {
      if (!selectedProfile || !selectedProfile.id) {
        setIsShow(false);
      } else if (
        !selectedProfile.categories ||
        selectedProfile.categories.length === 0
      ) {
        onSave({
          profile: {
            id: selectedProfile.id,
            name: selectedProfile.name,
          },
          categories: categories,
        });
        setIsShow(false);
      } else {
        setShowOptions(true);
      }
    }
  };

  const onCloseClicked = () => {
    setIsShow(false);
    setSelectedProfile(null);
  };

  return (
    <>
      <SntDialog
        isShow={isShow}
        onSave={onSaveClicked}
        onClose={onCloseClicked}
        saveTxt={showOptions ? language.ok_key : language.next_key}
        closeTxt={language.cancel_key}
        title={
          showOptions ? selectedProfile?.name : language.select_profile_key
        }
        customModalClassname={
          "snt-config-column-modal snt-config-column-modal-max-width"
        }
      >
        {() =>
          showOptions ? (
            <ProfileConfig
              profile={selectedProfile}
              results={results}
              onChange={onOptionChange}
            />
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Row>
                <FormGroupLayout
                  label={language.choose_a_profile_key}
                  rightRender={() => (
                    <input
                      name="search-profile"
                      className="form-control"
                      placeholder={language.search_profile_key}
                      onChange={(e) => onSearchProfile(e.target.value)}
                    />
                  )}
                />
                <FormGroupLayout
                  rightRender={() => (
                    <ul className="p-0">{renderProfiles()}</ul>
                  )}
                />
              </Row>
            </div>
          )
        }
      </SntDialog>
    </>
  );
};

export default SelectUsageProfile;
