import { useEffect, useState } from "react";
import Switch from "react-switch";

const AlertSwitch = ({ active, id, onSwitchChange, disabled }) => {
  const [checked, setChecked] = useState(active);

  const handleChange = (checked) => {
    setChecked(checked);
    onSwitchChange && onSwitchChange(checked, id);
  };

  useEffect(() => {
    setChecked(active);
  }, [active]);

  return (
    <label>
      <Switch onChange={handleChange} checked={checked} disabled={disabled} />
    </label>
  );
};

export default AlertSwitch;
