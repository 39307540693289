import { useCallback, useState } from "react";
import { Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  arrayMove,
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import SntDeleteIcon from "../../components/Icons/SntDeleteIcon";
import SntPencilIcon from "../../components/Icons/SntPencilIcon";
import SntReOrderIcon from "../../components/Icons/SntReOrderIcon";
import SntPanel from "../../components/Panel/SntPanel";
import SntActionButton from "../../components/ReactBootstrap/SntActionButton";
import AddCategory from "./AddCategory";
import AddOptionDetails from "./AddOptionDetails";
import SntClipboardIcon from "@/components/Icons/SntClipboardIcon";

const UsageProfileCategory = ({
  usageProfile,
  services,
  fwParams,
  categories,
  setCategories,
  selectedCategory,
  setSelectedCategory,
  isShowAddCategory,
  setShowAddCategory,
  numericVariables,
  type,
}) => {
  const language = useSelector((state) => state.language);

  const [categoryToEditOption, setCategoryToEditOption] = useState({
    name: "",
    uiLabel: "",
    description: "",
    options: [],
    visualization: "RADIO_BUTTON",
    dependOnCategories: [
      { operator: "EQUAL", categoryName: "", optionName: "" },
    ],
  });

  const [selectedOption, setSelectedOption] = useState();
  const [isShowOptionDetails, setIsShowOptionDetails] = useState(false);

  function onCategorySave(category) {
    if (categories) {
      let vals = [...categories];
      if (category.index >= 0) {
        vals[category.index] = category;
      } else {
        vals.push(category);
      }
      let cate = vals.map((c) => {
        delete c.index;
        return c;
      });
      setCategories(cate);
    } else {
      setCategories([category]);
    }
    setShowAddCategory(false);
  }

  function editCategory(el) {
    let vals = [...categories];
    let idx = vals.findIndex((obj) => {
      return obj.name === el.currentTarget.name;
    });
    setSelectedCategory({ ...vals[idx], index: idx });
    setShowAddCategory(true);
    el.preventDefault();
  }

  function removeCategoryClicked(el) {
    let vals = [...categories];
    vals.splice(
      vals.findIndex((v) => v.name === el.currentTarget.name),
      1
    );
    setCategories(vals);
    el.preventDefault();
  }

  function editOptionClicked(categoryName, optionName, el) {
    let category = categories.find((obj) => obj.name === categoryName);
    let option = {
      ...category.options.find((o) => o.name === optionName),
      index: category.options.findIndex((o) => o.name === optionName),
    };

    setCategoryToEditOption(
      categories.find((obj) => obj.name === categoryName)
    );
    setSelectedOption(option);
    setIsShowOptionDetails(true);
    el.preventDefault();
  }

  function onOptionDetailsSave(categoryName, option) {
    if (option.index >= 0) {
      let category = categories.find((obj) => obj.name === categoryName);
      let options = [...category.options];
      options[option.index] = option;
      options = options.map((o) => {
        return o.isDefault && o.name !== option.name && option.isDefault
          ? { ...o, isDefault: false }
          : o;
      });
      category = {
        ...category,
        options: options.map((o) => {
          delete o.index;
          return o;
        }),
      };
      let cate = categories.map((obj) =>
        obj.name === categoryName ? category : obj
      );
      setCategories(cate);
    }
    setIsShowOptionDetails(false);
  }

  function removeOptionClicked(categoryName, optionName, el) {
    let vals = [...categories];
    let options = vals.find((v) => v.name === categoryName).options;

    vals
      .find((v) => v.name === categoryName)
      .options.splice(
        options.findIndex((obj) => obj.name === optionName),
        1
      );
    setCategories(vals);
    el.preventDefault();
  }

  const getServiceFullNameByName = useCallback(
    (name) => {
      let finalName = language.na_key;
      if (services && services.length > 0) {
        let selectedService = services.find(
          (service) => service.serviceKey.name === name
        );
        finalName = selectedService
          ? selectedService.serviceKey.fullName
          : language.na_key;
      }
      return language[finalName] ? language[finalName] : language.na_key;
    },
    [services, language]
  );

  const DragHandle = sortableHandle(() => (
    <SntActionButton
      title={language.drag_and_drop_to_change_order_key}
      onClick={(e) => e.preventDefault()}
    >
      <SntReOrderIcon />
    </SntActionButton>
  ));

  function onOptionSortEnd(category, oldIndex, newIndex) {
    let lstCategory = [...categories];
    let idx = lstCategory.findIndex((c) => c.name === category.name);
    lstCategory[idx].options = arrayMove(
      lstCategory[idx].options,
      oldIndex,
      newIndex
    );
    setCategories(lstCategory);
  }

  const OptionSortableContainer = sortableContainer(({ children }) => {
    return <tbody className="option-details">{children}</tbody>;
  });

  const OptionSortableItem = sortableElement(({ category, option }) => (
    <tr key={option.name}>
      <td>
        <label>
          {option.isDefault
            ? option.uiLabel + " (" + language.default_key + ")"
            : option.uiLabel}
        </label>
        <p>{option.description}</p>
      </td>
      <td>
        {option.deviceFwParameterSettings &&
          option.deviceFwParameterSettings.map((setting) => (
            <p key={setting.fwParameterKey}>
              {setting.fwParameterKey + ": " + setting.value}
            </p>
          ))}
      </td>
      <td style={{ width: "10%" }}>
        <p> {option.batteryImpact}</p>
      </td>
      <td>
        {option.profileServiceUpdates &&
          option.profileServiceUpdates.map((service) => (
            <p key={service.type}>
              {getServiceFullNameByName(service.type) + ": " + service.active}
            </p>
          ))}
      </td>
      {type !== "view" && (
        <td>
          <>
            <SntActionButton
              name={option.name}
              onClick={editOptionClicked.bind(this, category.name, option.name)}
              title={language.edit_key}
            >
              <SntPencilIcon />
            </SntActionButton>
            <SntActionButton
              name={option.name}
              onClick={removeOptionClicked.bind(
                this,
                category.name,
                option.name
              )}
              title={language.delete_key}
            >
              <SntDeleteIcon />
            </SntActionButton>

            <DragHandle />
          </>
        </td>
      )}
    </tr>
  ));

  const onCopyToClipboardClicked = (el, item) => {
    el.preventDefault();
    navigator.clipboard.writeText(
      JSON.stringify({ ...item, name: "" }, null, 2)
    );
  };

  function onCategorySortEnd({ oldIndex, newIndex }) {
    setCategories((o) => arrayMove(o, oldIndex, newIndex));
  }

  const CategorySortableItem = sortableElement(({ category }) => (
    <SntPanel
      title={category.uiLabel}
      key={category.name}
      className={category.name}
      rightTitle={
        <>
          {type === "view" ? (
            ""
          ) : (
            <>
              <SntActionButton
                name={category.name}
                onClick={editCategory.bind(this)}
                title={language.edit_key}
              >
                <SntPencilIcon />
              </SntActionButton>
              <SntActionButton
                title={"Click to copy category as JSON to clipboard"}
                onClick={(e) => onCopyToClipboardClicked(e, category)}
              >
                <SntClipboardIcon />
              </SntActionButton>
              <SntActionButton
                name={category.name}
                onClick={removeCategoryClicked.bind(this)}
                title={language.delete_key}
              >
                <SntDeleteIcon />
              </SntActionButton>

              <DragHandle />
            </>
          )}
        </>
      }
    >
      <Row>
        <p className="ps-3">{category.description}</p>
      </Row>
      {category.dependOnCategories?.length ? (
        <div className="d-flex mb-3">
          <div className="col-1 fw-bold">{language.hide_when}</div>
          <div className="col-11">
            {category.dependOnCategories.map((dependOn) => (
              <div className="d-flex" key={dependOn.categoryName}>
                <div className="col-2">
                  {
                    categories.find((cat) => dependOn.categoryName === cat.name)
                      ?.uiLabel
                  }
                </div>
                <div className="col-2 fw-bold">
                  {dependOn.operator === "NOT_EQUAL"
                    ? language.not_equals
                    : language.equals}
                </div>
                <div className="col-2">
                  {
                    categories
                      .find((cat) => dependOn.categoryName === cat.name)
                      ?.options.find((o) => o.name === dependOn.optionName)
                      ?.uiLabel
                  }
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th>{language.option_name_key}</th>
            <th>{language.remote_settings_key}</th>
            <th>{language.battery_impact_key}</th>
            <th>{language.service_key}</th>
            {type !== "view" && (
              <th className="remote-config-action">{language.action_key}</th>
            )}
          </tr>
        </thead>
        {category.options && (
          <OptionSortableContainer
            helperClass="sortableHelper"
            onSortEnd={({ oldIndex, newIndex }) =>
              onOptionSortEnd(category, oldIndex, newIndex)
            }
            useDragHandle
          >
            {category.options.map((value, index) => (
              <OptionSortableItem
                key={`item-${value.name + type}`}
                index={index}
                category={category}
                option={value}
              />
            ))}
          </OptionSortableContainer>
        )}
      </Table>
    </SntPanel>
  ));

  const CategorySortableContainer = sortableContainer(({ children }) => {
    return <div>{children}</div>;
  });

  return (
    <>
      <AddCategory
        selectedProfile={usageProfile}
        selectedCategory={selectedCategory}
        fwParams={fwParams}
        mode={type}
        services={services}
        isShowCategory={isShowAddCategory}
        setShowAddCategory={setShowAddCategory}
        onCategorySave={(category) => onCategorySave(category)}
        numericVariables={numericVariables}
      />
      <AddOptionDetails
        category={categoryToEditOption}
        selectedOption={selectedOption}
        fwParams={fwParams}
        mode={type}
        services={services}
        isShowOptionDetails={isShowOptionDetails}
        onSave={(categoryName, option) =>
          onOptionDetailsSave(categoryName, option)
        }
        onCancel={() => setIsShowOptionDetails(false)}
        numericVariables={numericVariables}
      />
      {categories && categories.length ? (
        <CategorySortableContainer
          helperClass="sortableHelper"
          onSortEnd={onCategorySortEnd}
          useDragHandle
        >
          {categories.map((value, index) => (
            <CategorySortableItem
              key={`item-${value.name}`}
              index={index}
              category={value}
            />
          ))}
        </CategorySortableContainer>
      ) : (
        ""
      )}
    </>
  );
};

export default UsageProfileCategory;
