import { apiClient } from "./apiClient";
import apiUltils from "./apiUltils";

export default {
  getAllServiceByTechKeys(techKeys) {
    return apiClient.post(
      "/rest/sntservice/getAllServicesByTechKeys",
      techKeys,
      {
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
      }
    );
  },

  getActivatedServices(id) {
    return apiClient.get("/rest/sntservice/" + id + "/activatedServices");
  },

  deactivateService(serviceID) {
    return apiClient.delete("/rest/sntservice/" + serviceID);
  },

  activateServices(sigfoxDeviceID, params) {
    return apiClient.post(
      "/rest/sntservice/activateServices/" + sigfoxDeviceID,
      params
    );
  },

  activateService(sigfoxDeviceID, serviceType) {
    return apiClient.post(
      "/rest/sntservice/" + sigfoxDeviceID,
      JSON.stringify(serviceType),
      {
        headers: { "content-type": "application/json" },
      }
    );
  },

  updateConfiguration(deviceId, serviceKey, params) {
    let uri = apiUltils.buildParamsListURI(
      "/rest/sntservice/updateConfiguration",
      {
        deviceId: deviceId,
        serviceKey: serviceKey,
      }
    );
    return apiClient.post(uri, params);
  },

  disengageTimeSync(deviceId) {
    let uri = apiUltils.buildParamsListURI(
      "/rest/sntservice/disengageTimeSync",
      {
        deviceId: deviceId,
      }
    );
    return apiClient.post(uri);
  },

  findServices(params) {
    return apiClient.post(
      "/rest/sntservice/" +
        params.deviceId +
        "/activatedServices/applyProfile/" +
        params.id,
      params.categories,
      {
        headers: { "content-type": "application/json" },
      }
    );
  },
};
