import SntPanel from "../../../components/Panel/SntPanel.js";
import SntInfoPanel from "../../../components/SntInfo/SntInfoPanel.js";
import SntTooltip from "../../../components/Tooltip/SntTooltip.js";

const ServiceConfigPanel = ({
  title = "",
  description = "",
  children,
  tooltip = "",
}) => {
  return (
    <SntPanel
      title={title}
      rightTitle={
        tooltip && (
          <span className="float-end">
            <SntTooltip text={tooltip} />
          </span>
        )
      }
    >
      {description ? <SntInfoPanel>{description}</SntInfoPanel> : ""}
      {children && children()}
    </SntPanel>
  );
};
export default ServiceConfigPanel;
