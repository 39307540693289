import {
  DangerTag,
  SuccessTag,
  WarningTag,
} from "../../components/ReactBootstrap/TagMore";

const useAuditLog = () => {
  const getAuditLogActionLabel = (action) => {
    if (action === "CREATED") {
      return <SuccessTag tagName="CREATED" />;
    } else if (action === "UPDATED") {
      return <WarningTag tagName="UPDATED" />;
    } else if (action === "DELETED") {
      return <DangerTag tagName="DELETED" />;
    } else {
      return "";
    }
  };

  const getAuditLogEntityLink = (category, entityId) => {
    if (entityId) {
      switch (category) {
        case ("TRACKER", "CHANNEL_DATA"):
          return `/trackers_admin_update?type=edit&id=${entityId}`;
        case "USER":
          return `/users_admin_update?type=edit&id=${entityId}`;
        case "GEOZONE":
          return `/areas_update?type=edit&id=${entityId}`;
        case "ORGANIZATION":
          return `/organization_update?type=edit&id=${entityId}`;
        case "PRICE_MODEL":
          return `/pricemodel_update?type=edit&id=${entityId}`;
        case "GEOLAYER":
          return `/geolayer_update?type=edit&id=${entityId}`;
        case "LOCATION_INFRASTRUCTURE":
          return `/infrastructures_update?type=edit&id=${entityId}`;
        case "EXTERNAL_BLE_SENSOR":
          return `/ble_sensor_update?type=edit&id=${entityId}`;
        case "DEVICE_TAG":
          return `/update_device_tag?type=edit&id=${entityId}`;
        case "GEOZONE_TAG":
          return `/update_geozone_tag?type=edit&id=${entityId}`;
        case "ALERT_RULE":
          return `/manage_alerts_update?type=edit&id=${entityId}`;
        case "UTILIZATION_SCHEME":
          return `/utilization_scheme_update?type=edit&id=${entityId}`;
        case "FLOW_DEFINITION":
          return `/flow_settings?type=edit&id=${entityId}`;
        case "MAINTENANCE_PROGRAM":
          return `/maintenance_programs_manage?type=edit&id=${entityId}`;
        case "DASHBOARD":
          return `/bi_dashboard_manage_partner?type=edit&id=${entityId}`;
        case "REPORT_TEMPLATE":
          return `/report_template_library_manage?type=edit&id=${entityId}`;
        case "LANDING_PAGE":
          return `/custom_branding`;
        case "IMAGE_LIBRARY":
          return `/image_library_manage?type=edit&id=${entityId}`;
        case "CUSTOM_I18_MESSAGE":
          return `/custom_i18n_messages_manage?type=edit&id=${entityId}`;
        case "CUSTOM_BRANDING":
          return `/custom_branding?type=edit&id=${entityId}`;
        case "WIFI":
          return `/wifi?tabActive=private_mac`;
        case "DEVICE_USAGE_PROFILE":
          return `/device_usage_profile_manage?type=edit&id=${entityId}`;
        case "REMOTE_SETTINGS":
          return `/trackers_admin_update?type=edit&id=${entityId}`;
        case "WEBHOOK":
          return `/callbacks_update?type=edit&id=${entityId}`;
        case "DEVEVLOPER_ACCOUNT":
          return `/dev_account_update?type=edit&id=${entityId}`;
        case "SIGFOX_ACCOUNT":
          return `/sigfox_account_update?type=edit&id=${entityId}`;
        case "CHANNEL":
          return `/channel_update?type=edit&id=${entityId}`;
        case "PROFILE":
          return `/role_manager_admin_update?type=edit&id=${entityId}`;
        case "VISIBILITY_FILTER":
          return `/manage_visibility_filters_update?type=edit&id=${entityId}`;
        case "PARTNER":
          return `/partners_update?type=edit&id=${entityId}`;
        case "MAINTENANCE_STATE":
          return `/tracked_assets_update?tabActive=maintenance_tab&id=${entityId}`;
        default:
          break;
      }
    }
  };

  return {
    getAuditLogActionLabel,
    getAuditLogEntityLink,
  };
};

export default useAuditLog;
