import { useHistory } from "react-router-dom";

const SntDetailTabButton = ({ tablink }) => {
  const history = useHistory();

  return tablink ? (
    <a
      href=" "
      onClick={(e) => {
        e.preventDefault();
        history.push({
          pathname: tablink.pathname,
          search: tablink.search,
        });
      }}
      style={{
        fontSize: 13,
        cursor: "pointer",
        fontWeight: "normal",
      }}
    >
      {tablink.title}
      {` >`}
    </a>
  ) : (
    <></>
  );
};

export default SntDetailTabButton;
