import SntSensorIcon from "@icons/SntSensorIcon.js";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import config, { SensolusDatetimeReact } from "../../../constants/Config.js";
import moment from "../../../constants/SensolusMoment.js";

import {
  SntDetailItem,
  SntDetailPanel,
  SntDetailTabButton,
} from "@widgets/DetailInfo/index.js";
import { IMAGE_TYPE } from "@apis/imageLibraryClient.js";

const BLESensorMappedState = ({ device, dataMapped, isSideBar }) => {
  const language = useSelector((state) => state.language);
  const loginInfo = useSelector((state) => state.user);
  const { organisation } = loginInfo;
  const [data, setData] = useState({
    sensorState: "",
    name: language.unknown_key,
    customState: [],
  });

  useEffect(() => {
    if (dataMapped) {
      let translatedState = getCustomState(dataMapped);
      let dataUpdatedAt = dataMapped.dataUpdatedAt
        ? SensolusDatetimeReact.fromNow(
            SensolusDatetimeReact.parse(
              SensolusDatetimeReact.formatServer(dataMapped.dataUpdatedAt),
              "server"
            )
          )
        : SensolusDatetimeReact.fromNow(moment());
      setData({
        ...dataMapped,
        dataUpdatedAt: dataUpdatedAt,
        translatedState: translatedState,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMapped]);

  function getCustomState(configuration) {
    let customStates = configuration.customStates || [];
    for (let i = 0; i < customStates.length; i++) {
      if (customStates[i].key === configuration.sensorState) {
        return {
          key: customStates[i].key,
          name: customStates[i].name || language.unknown_key,
          image: customStates[i].image,
        };
      }
    }

    return {
      key: "unknown",
      name: language["state_unknown_key"],
      image:
        config.API_URL +
        "/rest/image_library/system/unknown?type=" +
        IMAGE_TYPE.SENSOR,
    };
  }

  return (
    <SntDetailPanel
      icon={<SntSensorIcon />}
      title={data.name || language.external_sensor_data_details_key}
      rightTitle={
        <SntDetailTabButton
          tablink={
            device && {
              title: language.external_sensor_data_details_key,
              pathname: "/tracked_assets_update",
              search: `?type=edit&id=${device.id}&tabActive=external_sensor_tab`,
            }
          }
        />
      }
    >
      <Row>
        <Col xs={6} md={isSideBar ? 6 : 8}>
          {data?.translatedState?.name && (
            <Row>
              <Col xs={6}>{language.state_key}</Col>
              <Col xs={6}>{data.translatedState.name}</Col>
            </Row>
          )}

          {dataMapped.valueVisibility &&
          (dataMapped.valueVisibility === "ALL" ||
            organisation.organisationType === "SYSTEM" ||
            organisation.organisationType === dataMapped.valueVisibility) &&
          dataMapped.mappedValue !== undefined &&
          dataMapped.mappedValue !== null ? (
            <Row>
              <Col xs={6}>{language.value_key}</Col>
              <Col xs={6}>{dataMapped.mappedValue + " " + dataMapped.unit}</Col>
            </Row>
          ) : (
            ""
          )}
        </Col>
        <Col xs={6} md={isSideBar ? 6 : 4} className="align-self-center">
          {data.translatedState &&
            data.customStates &&
            data.customStates.length > 0 && (
              <img
                src={
                  config.API_URL + "/" + data.translatedState.image
                    ? data.translatedState.image
                    : ""
                }
                alt="customState"
                style={{ width: 48, height: 48, maxWidth: "100%" }}
              />
            )}
        </Col>
      </Row>

      {/* has sensorSerial -> external sensor(do not show for internal sensor) */}
      {/* {dataMapped.sensorSerial && (
        <SntDetailItem
          label={language.sensor_battery_state}
          rightRender={() => (
            <>
              {dataMapped.batteryOk !== 0 ? (
                <SuccessTag tagName={language.ok_key} />
              ) : (
                <DangerTag tagName={language.almost_empty} />
              )}
            </>
          )}
          isSideBar={isSideBar}
        />
      )} */}
      <SntDetailItem
        label={language.last_data_updated_at}
        rightRender={() => <>{data.dataUpdatedAt}</>}
        isSideBar={isSideBar}
      />
    </SntDetailPanel>
  );
};
export default BLESensorMappedState;
