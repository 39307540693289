import { apiClient } from "./apiClient";
import { useEffect, useState } from "react";

export const partner = {
  getBasicPartner() {
    return apiClient.get("/rest/partner/basicPartner");
  },
  findOne(id) {
    return apiClient.get(`/rest/partner/${id}`);
  },
};

export const usePartners = () => {
  const [partners, setPartner] = useState([]);

  useEffect(() => {
    partner
      .getBasicPartner()
      .then(({ data }) => {
        let partners = [];
        for (let i = 0; i < data.length; i++) {
          partners.push({
            label: data[i].name,
            value: data[i].id,
          });
        }
        setPartner(partners);
      })
      .catch(() => {});
  }, []);

  return partners;
};
