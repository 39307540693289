/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import SntCloneIcon from "@/components/Icons/SntCloneIcon";
import SntInfoField from "@/components/SntInfo/SntInfoField";
import SntRadio from "@/components/SntRadio/SntRadio";
import GlobalUtils from "@/utils/GlobalUtils";
import { Form, Formik } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import {
  arrayMove,
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import SntDeleteIcon from "../../components/Icons/SntDeleteIcon";
import SntPencilIcon from "../../components/Icons/SntPencilIcon";
import SntReOrderIcon from "../../components/Icons/SntReOrderIcon";
import {
  FormGroupLayout,
  InputText,
  VerticalLayout,
} from "../../components/ReactBootstrap/FormValidation";
import ErrorLabel from "../../components/ReactBootstrap/FormValidation/ErrorLabel";
import SntActionButton from "../../components/ReactBootstrap/SntActionButton";
import SntDialog from "../../components/SntDialog/SntDialog";
import SntInfoPanel from "../../components/SntInfo/SntInfoPanel";
import AddOptionDetails from "./AddOptionDetails";
import ProfileConfig from "../TrackersAdminUpdate/TrackerConfiguration/ProfileConfig/ProfileConfig";
import IcomoonIcon from "@/components/Icons/IcomoonIcon";
import SntSelect from "@/cleanup/wrappers/ReactSelect/SntSelect";

const AddCategory = ({
  selectedProfile,
  selectedCategory,
  fwParams,
  mode,
  services,
  isShowCategory,
  setShowAddCategory,
  onCategorySave,
  numericVariables,
}) => {
  const language = useSelector((state) => state.language);

  const formikRef = useRef();

  const [category, setCategory] = useState(selectedCategory);
  const [valuesSave, setValuesSave] = useState(selectedCategory);

  const [selectedOption, setSelectedOption] = useState();

  const [options, setOptions] = useState([]);

  const [isShowOptionDetails, setIsShowOptionDetails] = useState(false);

  const [isShowConfirm, setShowConfirm] = useState(false);
  const [nameEditable, setNameEditable] = useState(false);
  const [isCloneAction, setIsCloneAction] = useState(false);
  const [allowEditName, setAllowEditName] = useState(false);

  const [error, setError] = useState("");

  const [dependOnCategories, setDependOnCategories] = useState([
    { operator: "EQUAL", categoryName: "", optionName: "" },
  ]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (selectedCategory.dependOnCategories?.length) {
      setDependOnCategories(selectedCategory.dependOnCategories);
    } else {
      setDependOnCategories([
        { operator: "EQUAL", categoryName: "", optionName: "" },
      ]);
    }
  }, [selectedCategory]);

  useEffect(() => {
    let selected = selectedProfile.categories.filter((cate) => {
      let ignore = dependOnCategories.find(
        (item) =>
          item.categoryName === cate.name || selectedCategory.name === cate.name
      );
      return !ignore;
    });

    setSelectedCategories(
      selected.map((item) => {
        return { label: item.uiLabel, value: item.name };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProfile, dependOnCategories]);

  const disableSave = useMemo(() => {
    if (options.length === 0) {
      return true;
    }
    setError("");
    return false;
  }, [options]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().test({
      test: function (name) {
        if (!name) {
          return this.createError({
            message: language.valid_required_key,
            path: "name",
          });
        } else if (name.includes(";") || name.includes(":")) {
          return this.createError({
            message: `Not allowed special character(";" or ":")`,
            path: "name",
          });
        } else if (
          selectedProfile?.categories.filter((c) => c.name === name).length &&
          selectedCategory.name !== name
        ) {
          return this.createError({
            message: `Duplicate ID in the profile`,
            path: "name",
          });
        }
        return true;
      },
    }),
    uiLabel: Yup.string().test({
      test: function (uiLabel) {
        if (!uiLabel) {
          return this.createError({
            message: language.valid_required_key,
            path: "uiLabel",
          });
        } else if (uiLabel.includes(";") || uiLabel.includes(":")) {
          return this.createError({
            message: `Not allowed special character(";" or ":")`,
            path: "uiLabel",
          });
        } else if (
          selectedProfile?.categories.filter((c) => c.uiLabel === uiLabel)
            .length &&
          selectedCategory.uiLabel !== uiLabel
        ) {
          return this.createError({
            message: `Duplicate UI label in the profile`,
            path: "uiLabel",
          });
        }
        return true;
      },
    }),
  });

  useEffect(() => {
    if (!isShowCategory) return;
    if (selectedCategory.name === "") {
      setNameEditable(true);
    } else {
      setNameEditable(false);
    }
    setCategory(selectedCategory);
    selectedCategory.options && setOptions(selectedCategory.options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  function onSaveClicked() {
    if (disableSave) {
      setError(language.please_select_an_option_first_key);
      return;
    }
    let formData = getFormData();
    validationSchema.isValid(formData).then(function (valid) {
      if (valid) {
        setCategory({
          ...category,
          dependOnCategories: dependOnCategories.filter(
            (item) => item.categoryName !== ""
          ),
        });

        options.map((o) => {
          delete o.index;
          return o;
        });

        onCategorySave(formData);
        setIsShowOptionDetails(false);
        setShowAddCategory(false);
        setNameEditable(false);
      }
    });
  }

  function getFormData() {
    return {
      ...category,
      options: options,
      dependOnCategories: dependOnCategories.filter(
        (item) => item.categoryName !== ""
      ),
    };
  }

  function onOptionDetailsSave(categoryName, option) {
    let ops = [...options];
    if (option.index >= 0) {
      ops[option.index] = option;
    } else {
      ops.push(option);
    }

    ops = ops.map((o) => {
      return o.isDefault && o.name !== option.name && option.isDefault
        ? { ...o, isDefault: false }
        : o;
    });

    setCategory({ ...getFormData(), options: ops });
    setOptions(ops);
    setIsShowOptionDetails(false);
  }

  function onAddOptionClicked(el) {
    setSelectedOption({
      name: "",
      uiLabel: "",
      description: "",
      batteryImpact: "NEUTRAL",
      deviceFwParameterSettings: [],
      profileServiceUpdates: [],
    });
    setIsShowOptionDetails(true);
    el.preventDefault();
  }

  function editOptionClick(el, optionName) {
    setIsCloneAction(false);
    setSelectedOption({
      ...options.find((o) => o.name === optionName),
      index: options.findIndex((o) => o.name === optionName),
    });
    setIsShowOptionDetails(true);
    el.preventDefault();
  }

  function onCloneOptionClicked(el, option) {
    setSelectedOption(option);
    setIsShowOptionDetails(true);
    setIsCloneAction(true);
    el.preventDefault();
  }

  function removeOptionClick(el, optionName) {
    let ops = [...options];
    ops.splice(
      ops.findIndex((o) => o.name === optionName),
      1
    );
    setOptions(ops);
  }

  function onVisualizationChanged(value) {
    let cate = { ...getFormData(), visualization: value };
    if (value === "NUMERIC_VALUE") {
      let option = {
        name: "Default options",
        uiLabel: "Default options",
        description: "description",
        isDefault: true,
      };
      cate.options = [option];
      setOptions([option]);
      setAllowEditName(true);
    } else {
      setAllowEditName(false);
      setOptions([]);
    }
    setCategory(cate);
  }

  function onClose() {
    let formData = getFormData();
    validationSchema.isValid(formData).then(function (valid) {
      if (valid) {
        if (JSON.stringify(selectedCategory) !== JSON.stringify(formData)) {
          setValuesSave(getFormData());
          setShowConfirm(true);
        }
      }
      setShowAddCategory(false);
    });
  }

  function onSortEnd({ oldIndex, newIndex }) {
    setOptions((o) => arrayMove(o, oldIndex, newIndex));
  }

  const DragHandle = sortableHandle(() => (
    <SntActionButton
      title={language.drag_and_drop_to_change_order_key}
      onClick={(e) => e.preventDefault()}
    >
      <SntReOrderIcon />
    </SntActionButton>
  ));

  const SortableItem = sortableElement(({ option }) => (
    <Row>
      <Col xs={12} lg={9}>
        <li className="list-group-item center d-flex">
          <div className="col-9 col-xs-12" style={{ marginTop: 5 }}>
            <Row>
              <label
                className="panel-title col-7"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  fontSize: 14,
                  color: "rgb(66, 75, 89)",
                }}
              >
                {option.isDefault
                  ? option.uiLabel + " (" + language.default_key + ")"
                  : option.uiLabel}
              </label>
              <span className="col-5">
                {GlobalUtils.renderBatteryImpact(option, option.batteryImpact)}
              </span>
            </Row>
            {option.description && (
              <SntInfoField>{option.description}</SntInfoField>
            )}
          </div>

          <div className="col-3 col-xs-12">
            <SntActionButton
              onClick={(el) => editOptionClick(el, option.name)}
              title={language.edit_key}
            >
              <SntPencilIcon />
            </SntActionButton>
            {category.visualization !== "NUMERIC_VALUE" ? (
              <>
                <SntActionButton
                  title={language.clone_key}
                  onClick={(e) => onCloneOptionClicked(e, option)}
                >
                  <SntCloneIcon />
                </SntActionButton>
                <SntActionButton
                  onClick={(el) => removeOptionClick(el, option.name)}
                  title={language.delete_key}
                >
                  <SntDeleteIcon />
                </SntActionButton>
                <DragHandle />
              </>
            ) : (
              ""
            )}
          </div>
        </li>
      </Col>
    </Row>
  ));

  const SortableContainer = sortableContainer(({ children }) => {
    return <ul className="list-group pt-1">{children}</ul>;
  });

  function renderOptions() {
    return (
      <SortableContainer
        helperClass="sortableHelper"
        onSortEnd={onSortEnd}
        useDragHandle
      >
        {options.map((value, index) => (
          <SortableItem
            key={`item-${value.name}`}
            index={index}
            option={value}
          />
        ))}
      </SortableContainer>
    );
  }

  function onConfirmYes(value) {
    onCategorySave(value);
    setShowConfirm(false);
  }

  function onConfirmNo() {
    setShowConfirm(false);
  }

  const onSelectCategoryChange = (item, idx) => {
    let category = selectedProfile.categories.find(
      (cate) => cate.name === item.value
    );
    let list = [...dependOnCategories];
    list[idx] = {
      categoryName: category.name,
      optionName: "",
      operator: "EQUAL",
    };
    setDependOnCategories(list);

    setSelectedOptions(category.options);
  };

  const onSelectOperatorChange = (item, idx) => {
    let list = [...dependOnCategories];
    list[idx].operator = item.value;
    setDependOnCategories(list);
  };

  const onSelectOptionChange = (item, idx) => {
    let list = [...dependOnCategories];
    list[idx].optionName = item.value;
    setDependOnCategories(list);
  };

  const onAddDependOnItem = () => {
    let list = [...dependOnCategories];
    list[dependOnCategories.length] = {
      operator: "EQUAL",
      categoryName: "",
      optionName: "",
    };
    setDependOnCategories(list);
  };

  const onRemoveDependOnItem = (index) => {
    let list = [...dependOnCategories];
    list.splice(index, 1);
    list.length === 0
      ? (list = [{ operator: "EQUAL", categoryName: "", optionName: "" }])
      : list;
    setDependOnCategories(list);
  };

  return (
    <>
      <SntDialog
        isShow={isShowConfirm}
        onSave={() => onConfirmYes(valuesSave)}
        onClose={() => onConfirmNo()}
        saveTxt={language.yes_key}
        closeTxt={language.no_key}
        title={language.add_category_key}
      >
        {() => language.you_have_unsaved_changes_key}
      </SntDialog>

      <SntDialog
        isShow={isShowCategory}
        onSave={onSaveClicked}
        onClose={() => onClose()}
        saveTxt={language.save_key}
        title={language.add_category_key}
        customModalClassname={"snt-config-column-modal"}
      >
        {() => (
          <>
            <FormGroupLayout
              rightRender={() => (
                <SntInfoPanel>
                  {language.add_category_description_key}
                </SntInfoPanel>
              )}
            />
            <Formik
              initialValues={category}
              enableReinitialize={true}
              innerRef={formikRef}
              validationSchema={validationSchema}
            >
              {() => (
                <Form className="form-horizontal">
                  <FormGroupLayout
                    label={language.id_upper}
                    isRequired={true}
                    rightRender={() => (
                      <InputText
                        name="name"
                        required
                        disabled={mode !== "add" && !nameEditable}
                        handleChange={(name) => {
                          setCategory({
                            ...category,
                            name: name,
                          });
                        }}
                      />
                    )}
                  />
                  <FormGroupLayout
                    label={language.ui_label}
                    isRequired={true}
                    rightRender={() => (
                      <InputText
                        name="uiLabel"
                        required
                        handleChange={(uiLabel) => {
                          setCategory({
                            ...category,
                            uiLabel: uiLabel,
                          });
                        }}
                      />
                    )}
                  />
                  <FormGroupLayout
                    label={language.description_key}
                    rightRender={() => (
                      <InputText
                        name="description"
                        placeholder={language.description_key}
                        handleChange={(description) => {
                          setCategory({
                            ...category,
                            description: description,
                          });
                        }}
                      />
                    )}
                  />
                  <FormGroupLayout
                    label={language.visualization}
                    rightRender={() => (
                      <VerticalLayout>
                        <SntRadio
                          value="RADIO_BUTTON"
                          selectedValue={
                            category.visualization !== "NUMERIC_VALUE"
                              ? "RADIO_BUTTON"
                              : "NUMERIC_VALUE"
                          }
                          onChange={(e) => onVisualizationChanged(e)}
                          label={language.choose_from_list_key}
                        />

                        <SntRadio
                          value="NUMERIC_VALUE"
                          selectedValue={category.visualization}
                          onChange={(e) => onVisualizationChanged(e)}
                          label={language.numeric_value}
                        />
                      </VerticalLayout>
                    )}
                  />
                  <FormGroupLayout
                    label={language.hide_when}
                    rightRender={() => (
                      <>
                        {dependOnCategories.map((item, idx) => {
                          return (
                            <>
                              <Row className="d-flex" key={idx}>
                                <Col xs={12} lg={3} className="mb-2 me-2">
                                  <SntSelect
                                    value={{
                                      label: selectedProfile.categories.find(
                                        (d) => item.categoryName === d.name
                                      )?.uiLabel,
                                      value: item.categoryName,
                                    }}
                                    onChange={(item) => {
                                      onSelectCategoryChange(item, idx);
                                    }}
                                    options={selectedCategories}
                                  />
                                </Col>
                                <Col xs={4} lg={2} className="mb-2 me-2">
                                  <SntSelect
                                    value={{
                                      label:
                                        item.operator === "NOT_EQUAL"
                                          ? language.not_equals
                                          : language.equals,
                                      value: item.operator,
                                    }}
                                    onChange={(item) => {
                                      onSelectOperatorChange(item, idx);
                                    }}
                                    options={[
                                      {
                                        label: language.equals,
                                        value: "EQUAL",
                                      },
                                      {
                                        label: language.not_equals,
                                        value: "NOT_EQUAL",
                                      },
                                    ]}
                                  />
                                </Col>
                                <Col xs={12} lg={3} className="mb-2 me-2">
                                  <SntSelect
                                    value={{
                                      label: selectedProfile.categories
                                        .find(
                                          (d) => item.categoryName === d.name
                                        )
                                        ?.options.find(
                                          (d) => item.optionName === d.name
                                        )?.uiLabel,
                                      value: item.optionName,
                                    }}
                                    onChange={(item) => {
                                      onSelectOptionChange(item, idx);
                                    }}
                                    options={selectedOptions.map((option) => {
                                      return {
                                        label: selectedOptions.find(
                                          (d) => option.name === d.name
                                        )?.uiLabel,
                                        value: option.name,
                                      };
                                    })}
                                  />
                                </Col>
                                <Col xs={12} lg={2}>
                                  {idx === 0 && (
                                    <SntActionButton
                                      onClick={() => {
                                        onAddDependOnItem();
                                      }}
                                    >
                                      <IcomoonIcon icon="plus"></IcomoonIcon>
                                    </SntActionButton>
                                  )}

                                  <SntActionButton
                                    onClick={() => onRemoveDependOnItem(idx)}
                                  >
                                    <SntDeleteIcon />
                                  </SntActionButton>
                                </Col>
                              </Row>
                              {idx !== dependOnCategories.length - 1 && (
                                <Row className="mb-2">
                                  <Col>{language.and_key}</Col>
                                </Row>
                              )}
                            </>
                          );
                        })}
                      </>
                    )}
                  />
                </Form>
              )}
            </Formik>

            <FormGroupLayout
              label={language.options}
              rightRender={() => renderOptions()}
            />

            {category.visualization !== "NUMERIC_VALUE" ? (
              <form className="form-inline">
                <FormGroupLayout
                  rightRender={() => (
                    <>
                      <Button
                        className="mt-2"
                        variant="sensolus"
                        onClick={(el) => onAddOptionClicked(el)}
                      >
                        {language.add_option_key}
                      </Button>
                      <ErrorLabel error={error} />
                    </>
                  )}
                />
              </form>
            ) : (
              ""
            )}
          </>
        )}
      </SntDialog>

      <AddOptionDetails
        category={category}
        selectedOption={selectedOption}
        fwParams={fwParams}
        mode={mode}
        services={services}
        isShowOptionDetails={isShowOptionDetails}
        onSave={(categoryName, option) =>
          onOptionDetailsSave(categoryName, option)
        }
        onCancel={() => setIsShowOptionDetails(false)}
        numericVariables={numericVariables}
        isCloneAction={isCloneAction}
        allowEditName={allowEditName}
      />
    </>
  );
};

export default AddCategory;
