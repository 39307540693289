import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import config from "@/constants/Config";

function OrientationStateBody({
  data,
  configuration,
  customState,
  dataUpdatedAt,
}) {
  const language = useSelector((state) => state.language);

  return (
    <Row>
      <Col xs={8}>
        {data.currentOrientation && (
          <Row>
            <Col xs={6}>{language.orientation_key}</Col>
            <Col xs={6}>{customState.name}</Col>
          </Row>
        )}

        {configuration.showYaw && (
          <Row>
            <Col xs={6}>{language.yaw_key}</Col>
            <Col xs={6}>
              {data.currentYaw ? (
                <>
                  {data.currentYaw}
                  <sup style={{ fontSize: "50%", top: "-1em" }}>o</sup>
                </>
              ) : (
                language.na_key
              )}
            </Col>
          </Row>
        )}

        {configuration.showRoll && (
          <Row>
            <Col xs={6}>{language.roll_key}</Col>
            <Col xs={6}>
              {" "}
              {data.currentRoll ? (
                <>
                  {data.currentRoll}
                  <sup style={{ fontSize: "50%", top: "-1em" }}>o</sup>
                </>
              ) : (
                language.na_key
              )}
            </Col>
          </Row>
        )}

        {configuration.showPitch && (
          <Row>
            <Col xs={6}>{language.pitch_key}</Col>
            <Col xs={6}>
              {data.currentPitch ? (
                <>
                  {data.currentPitch}
                  <sup style={{ fontSize: "50%", top: "-1em" }}>o</sup>
                </>
              ) : (
                language.na_key
              )}
            </Col>
          </Row>
        )}

        {dataUpdatedAt && (
          <Row>
            <Col xs={6}>{language.last_detected_key}</Col>
            <Col xs={6}>{dataUpdatedAt}</Col>
          </Row>
        )}
      </Col>

      <Col xs={4} className="align-self-center">
        {customState.image && (
          <img
            alt={customState.name}
            src={config.API_URL + customState.image}
            style={{ width: "56px", maxWidth: "100%", height: "56px" }}
          />
        )}
      </Col>
    </Row>
  );
}

export default OrientationStateBody;
