import { useHistory } from "react-router-dom";

import automationRuleClient from "@/apis/automationRuleClient";
import { AutomationRuleRoute } from "@/cleanup/utils/routesUtil/routesUtil";

import AutomationRuleForm from "../components/AutomationRuleForm";

const InfoTab = ({ orgId, id, type, data }) => {
  const history = useHistory();

  const onSubmit = (values) => {
    automationRuleClient
      .updateAutomationRule(id, values)
      .then((result) => {
        history.push(AutomationRuleRoute.getPage({ orgId: orgId }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <AutomationRuleForm
      disabled={type === "view"}
      onSubmit={onSubmit}
      orgId={orgId}
      data={data}
    />
  );
};

export default InfoTab;
