import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import IcomoonIcon from "@icons/IcomoonIcon.js";
import config, { SensolusDatetimeReact } from "../../../constants/Config.js";
import OrientationStateBody from "../../AssetDetail/AssetStatus/OrientationStateBody.js";
import moment from "../../../constants/SensolusMoment.js";

const OrientationStateService = ({ testData }) => {
  const language = useSelector((state) => state.language);
  const [data, setData] = useState({
    name: "",
    currentOrientation: "unknown",
    currentYaw: false,
    currentRoll: false,
    currentPitch: false,
    configuration: {},
    customState: {},
  });

  useEffect(() => {
    if (testData) {
      let customState = getCustomState(
        testData.currentOrientation,
        testData.configuration
      );
      let dataUpdatedAt = testData.dataUpdatedAt
        ? SensolusDatetimeReact.fromNow(
            SensolusDatetimeReact.parse(
              SensolusDatetimeReact.formatServer(testData.dataUpdatedAt),
              "server"
            )
          )
        : SensolusDatetimeReact.fromNow(moment());
      setData({
        ...testData,
        dataUpdatedAt: dataUpdatedAt,
        customState: customState,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testData]);

  function getCustomState(currentOrientation, configuration) {
    configuration = configuration || {};
    if (configuration.allowCustomMapping) {
      var customStates = configuration.customStates || [];
      for (var i = 0; i < customStates.length; i++) {
        if (customStates[i].key === currentOrientation) {
          return {
            key: currentOrientation,
            name: customStates[i].name + " (" + customStates[i].key + ")",
            image: customStates[i].image,
          };
        }
      }
    }

    var possibleList = [
        "top_up",
        "bottom_up",
        "left_up",
        "right_up",
        "back_up",
        "front_up",
        "state1",
        "state2",
      ],
      key = currentOrientation;
    if (possibleList.indexOf(currentOrientation) === -1) {
      key = "unknown";
    }

    return {
      key: key,
      name: language["state_" + key + "_key"] + " (" + key + ")",
      image: config.API_URL + "/rest/image_library/system/" + key,
    };
  }

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <span>
            {data.configuration.name || language.orientation_state_key}
          </span>
          <a href={" "} onClick={(e) => e.preventDefault}>
            <IcomoonIcon
              icon="question"
              className="ms-2"
              color="#212851"
            ></IcomoonIcon>
          </a>
        </Card.Title>
        <OrientationStateBody
          data={data}
          configuration={data.configuration}
          customState={data.customState}
          dataUpdatedAt={data.UpdatedAt}
        />
      </Card.Body>
    </Card>
  );
};
export default OrientationStateService;
