import { apiClient } from "./apiClient";
import UTILS from "./apiUltils";
import { saveAs } from "file-saver";

export default {
  addArea(data, orgId) {
    return apiClient.post(`/rest/area?orgId=${orgId}`, data, {
      headers: { "content-type": "application/json" },
    });
  },

  validate(data, orgId) {
    return apiClient.post(`/rest/area/validate?orgId=${orgId}`, data, {
      headers: { "content-type": "application/json" },
    });
  },

  updateArea(data, id) {
    return apiClient.put(`/rest/area/${id}`, data, {
      headers: { "content-type": "application/json" },
    });
  },

  updateBinsConfigForArea(id, data) {
    return apiClient.put(`/rest/area/bins/config/${id}`, data, {
      headers: { "content-type": "application/json" },
    });
  },
  search(data, cancelToken) {
    return apiClient.post("/rest/area/search", data, {
      headers: { "content-type": "application/json" },
      cancelToken: cancelToken,
    });
  },
  doubleSearch(data, cancelToken) {
    return apiClient.post("/rest/area/doublesearch", data, {
      headers: { "content-type": "application/json" },
      cancelToken: cancelToken,
    });
  },
  getStatisticsByGeozone(data, cancelToken) {
    return apiClient.post("/rest/area/statistics/bygeozone", data, {
      headers: { "content-type": "application/json" },
      cancelToken: cancelToken,
    });
  },
  getStatisticsByGeozoneTag(data, cancelToken) {
    return apiClient.post("/rest/area/statistics/bygeozonetag", data, {
      headers: { "content-type": "application/json" },
      cancelToken: cancelToken,
    });
  },
  findAll() {
    return apiClient.get(`/rest/area/`);
  },
  getAreaById(id) {
    return apiClient.get(`/rest/area/${id}`);
  },
  getAreaByOrgs(orgId) {
    return apiClient.get(
      `/rest/area/getAreaByOrgs${orgId ? "?orgIds=" + orgId : ""}`
    );
  },

  countGeozonesByTagIds(params) {
    let uri = encodeURI(
      UTILS.buildParamsListURI("/rest/area/countGeozonesByTagIds", params)
    );
    return apiClient.get(uri);
  },

  getArea(areaId) {
    return apiClient.get(`/rest/area/${areaId}`);
  },

  downloadCsvTemplate() {
    return apiClient
      .post("/rest/area/download/csvTemplateFile", "", {
        responseType: "arraybuffer",
      })
      .then((response) => {
        var blob = new Blob([response.data]);
        saveAs(blob, `Geozones_Template.csv`);
      });
  },

  downloadXlsTemplate() {
    return apiClient
      .post("/rest/area/download/xlsTemplateFile", "", {
        responseType: "arraybuffer",
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, `Geozones_Template.xlsx`);
      });
  },

  exportAsCsvFile(orgId, data) {
    return apiClient
      .post(`/rest/area/download/csvTemplateFile?orgId=${orgId}`, data, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        var blob = new Blob([response.data]);
        saveAs(blob, `Geozones_By_Org_${orgId}.csv`);
      });
  },

  exportAsXlsFile(orgId, data) {
    return apiClient
      .post(`/rest/area/download/xlsTemplateFile?orgId=${orgId}`, data, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, `Geozones_By_Org_${orgId}.xlsx`);
      });
  },

  importGeozonesByFile(orgId, params) {
    return apiClient.post(`/rest/area/import?orgId=${orgId}`, params, {
      headers: { "content-type": "multipart/form-data" },
    });
  },

  getImportResult(requestId) {
    return apiClient.get(`/rest/area/import/${requestId}/result`, {});
  },

  getCountries() {
    return apiClient.get(`/rest/area/countries`, {});
  },

  getCountryByIsoCode(isoCode) {
    return apiClient.get(`/rest/area/country/byIsoCode/${isoCode}`, {});
  },
};
