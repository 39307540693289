import { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";

import { useField } from "formik";
import { AsyncPaginate } from "react-select-async-paginate";
import { Form, Row } from "react-bootstrap";
import HtmlParser from "react-html-parser/lib/HtmlParser";

import GlobalUtils from "@/utils/GlobalUtils";
import SntHideLongText from "@/components/ReactBootstrap/SntHideLongText";
import IcomoonIcon from "@/components/Icons/IcomoonIcon";
import { VerticalLayout } from "@/components/ReactBootstrap/FormValidation";
import SntRadio from "@/components/SntRadio/SntRadio";
import SntInfoField from "@/components/SntInfo/SntInfoField";

const SntNameAndTagSelectorField = ({
  name,
  disabled = false,
  allowSelectTypes = ["NAME", "TAG", "ALL"],
  onChange = (e) => {},
  language,
  loadDataByIds,
  loadDataByTags,
  getLengthByType,
  lengthText = "",
  isCustomLabel,
}) => {
  const [field, meta, helper] = useField(name);

  const [allOptions, setAllOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");

  async function loadDevicesOptions(search, loadedOptions) {
    const data = await loadDataByIds(search, loadedOptions);
    if (!isCustomLabel) return data;
    return customResults(data, search, loadedOptions);
  }

  async function loadDeviceTagsOptions(search, loadedOptions) {
    const data = await loadDataByTags(search, loadedOptions);
    return data;
  }

  const onTypeChange = (type) => {
    helper.setValue({ ...field.value, selectType: type });
  };

  const onNameOptionsChange = (options) => {
    if (options && options.filter((opt) => opt.value === -1).length > 0) {
      let selectedAll = allOptions.map((opt) => {
        return {
          label: opt.name || opt.label,
          value: opt.value,
        };
      });

      let alreadySelected = options.filter((opt) => opt.value !== -1);
      let ids = new Set(alreadySelected.map((v) => v.value));
      let merged = [
        ...alreadySelected,
        ...selectedAll.filter((opt) => !ids.has(opt.value)),
      ];
      helper.setValue({ ...field.value, selectedIds: merged });

      setInputValue("");
      setAllOptions([]);
    } else {
      let selectedOptions = options?.map((opt) => {
        return {
          label: opt.name || opt.label,
          value: opt.value,
        };
      });
      helper.setValue({ ...field.value, selectedIds: selectedOptions });
    }
  };

  const onTagOptionsChange = (options) => {
    helper.setValue({ ...field.value, selectedTags: options });
  };

  const getNameValues = () => {
    let itemSelected = [];
    if (field.value && field.value.selectedIds) {
      itemSelected = field.value.selectedIds;
    }
    return itemSelected;
  };

  const getTagValues = () => {
    let itemSelected = [];
    if (field.value && field.value.selectedTags) {
      itemSelected = field.value.selectedTags;
    }
    return itemSelected;
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      onChange && onChange(field.value);
      updateLength(field.value);
    }, 100);
    return () => {
      timeout && clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, [field.value]);

  const [size, setSize] = useState(0);
  const updateLength = async (value) => {
    let size = 0;
    if (value && getLengthByType) {
      let selectedIds = [];
      if (value.selectType === "NAME") {
        selectedIds = value.selectedIds;
      } else if (value.selectType === "TAG") {
        selectedIds = value.selectedTags;
      }
      let monitorItems = {
        selectedIds: selectedIds.map((item) => item.value),
        selectType: value.selectType,
      };
      size = await getLengthByType(monitorItems);
    }

    setSize(size);
  };

  const selectType = field.value.selectType || "ALL";
  let formGroupClass = "";
  if (meta && meta.touched) {
    if (meta.error) {
      formGroupClass = "is-invalid";
    } else {
      formGroupClass = "is-valid";
    }
  }

  function customResults(data, search, loadedOptions) {
    let customResults = data.results.map((opt) => {
      return {
        ...opt,
        value: opt.value,
        label: getLabel(opt, search),
        name: opt.label,
      };
    });
    if (loadedOptions.length === 0) {
      setAllOptions([...customResults]);
      if (customResults.length > 1) {
        let selectAllOption = {
          value: -1,
          label: HtmlParser(`<b> ${language.select_all}</b>`),
          name: language.select_all,
          serial: -1,
        };
        customResults.unshift(selectAllOption);
      }
    } else {
      setAllOptions([...allOptions, ...customResults]);
    }
    return {
      options: customResults,
      hasMore: data.hasMore,
    };
  }

  function getLabel(device, search) {
    if (search === "") {
      return (
        <div style={{ overflow: "hidden" }}>
          {HtmlParser(getImage(device.id, device.image))}
          <div>
            <SntHideLongText> {device.label}</SntHideLongText>
            <p
              style={{ margin: 0, fontStyle: "13px" }}
              className="subtitle-text"
            >
              {device.serial}
            </p>
          </div>
        </div>
      );
    }
    // highlight the matched part with search text
    let reg = new RegExp(`(${search})`, "gi");
    let label = device.label.split(reg);
    let moreInfo = device.serial;
    if (
      device.imei &&
      device.imei.toLowerCase().indexOf(search.toLowerCase()) !== -1
    ) {
      moreInfo += ", " + device.imei;
    }
    if (
      device.mac &&
      device.mac.toLowerCase().indexOf(search.toLowerCase()) !== -1
    ) {
      moreInfo += ", " + device.mac;
    }
    moreInfo = moreInfo.split(reg);
    return (
      <div style={{ overflow: "hidden" }}>
        {HtmlParser(getImage(device.id, device.image))}
        <div>
          <SntHideLongText>
            {label.map((part, i) => {
              return (
                <span
                  key={i}
                  style={
                    part.toLowerCase() === search.toLowerCase()
                      ? { fontWeight: "bold" }
                      : {}
                  }
                >
                  {part}
                </span>
              );
            })}
          </SntHideLongText>
          <p style={{ margin: 0, fontStyle: "13px" }} className="subtitle-text">
            {moreInfo.map((part, i) => {
              return (
                <span
                  key={i}
                  style={
                    part.toLowerCase() === search.toLowerCase()
                      ? { fontWeight: "bold" }
                      : {}
                  }
                >
                  {part}
                </span>
              );
            })}
          </p>
        </div>
      </div>
    );
  }

  function getImage(entityId, iconPath) {
    let img = "";
    if (iconPath) {
      img =
        '<img style="float: left;width: 30px;height: 30px;margin-right: 10px;" src="' +
        GlobalUtils.getFile("device", entityId, "image", iconPath, "small") +
        '">';
    } else {
      img = ReactDOMServer.renderToString(
        <IcomoonIcon
          icon="podcast"
          className="text-dark"
          size="30"
          style={{ marginRight: 10, paddingTop: 5, float: "left" }}
        ></IcomoonIcon>
      );
    }
    return img;
  }

  return (
    <Row>
      <VerticalLayout>
        {allowSelectTypes.includes("ALL") && (
          <SntRadio
            value="ALL"
            disabled={disabled}
            selectedValue={selectType}
            onChange={onTypeChange}
            label={language.all_key}
          />
        )}
        {allowSelectTypes.includes("TAG") && (
          <SntRadio
            value="TAG"
            disabled={disabled}
            selectedValue={selectType}
            onChange={onTypeChange}
            label={language.by_tag_key}
          />
        )}
        {allowSelectTypes.includes("NAME") && (
          <SntRadio
            value="NAME"
            disabled={disabled}
            selectedValue={selectType}
            onChange={onTypeChange}
            label={language.by_identifiers}
          />
        )}
      </VerticalLayout>

      {selectType === "NAME" ? (
        <div>
          <AsyncPaginate
            name={name}
            key={selectType}
            value={getNameValues()}
            isMulti
            isDisabled={disabled}
            loadOptions={loadDevicesOptions}
            onChange={onNameOptionsChange}
            inputValue={inputValue}
            onInputChange={setInputValue}
            styles={{ menu: (styles) => ({ ...styles, zIndex: 100 }) }}
            menuPlacement="auto"
          />
          <Form.Control.Feedback
            type="invalid"
            className={`${
              formGroupClass === "is-invalid" ? "d-block" : "d-none"
            }`}
          >
            {meta.error && meta.error.selectedIds}
          </Form.Control.Feedback>
        </div>
      ) : selectType === "TAG" ? (
        <div>
          <AsyncPaginate
            name={name}
            key={selectType}
            value={getTagValues()}
            isMulti
            isDisabled={disabled}
            loadOptions={loadDeviceTagsOptions}
            onChange={onTagOptionsChange}
            styles={{ menu: (styles) => ({ ...styles, zIndex: 100 }) }}
            menuPlacement="auto"
          />
          <Form.Control.Feedback
            type="invalid"
            className={`${
              formGroupClass === "is-invalid" ? "d-block" : "d-none"
            }`}
          >
            {meta.error && meta.error.selectedTags}
          </Form.Control.Feedback>
        </div>
      ) : (
        <></>
      )}

      <SntInfoField>
        {`${language.selected_key}: ${size} ${lengthText}`}
      </SntInfoField>
    </Row>
  );
};

export default SntNameAndTagSelectorField;

// async function loadDeviceTagsOptions(search, loadedOptions) {
//   if (deviceTagOptionsCustom) {
//     let _data = [];
//     let _hasMore = false;
//     if (search === "") {
//       _data = deviceTagOptionsCustom.slice(
//         loadedOptions.length,
//         loadedOptions.length + 50
//       );
//     } else {
//       let filtered = deviceTagOptionsCustom.filter(
//         (option) =>
//           option.label.toLowerCase().indexOf(search.toLowerCase()) !== -1
//       );
//       _data = filtered.slice(loadedOptions.length, loadedOptions.length + 50);
//     }
//     _hasMore = loadedOptions.length + 50 < deviceTagOptionsCustom.length;
//     return {
//       options: _data,
//       hasMore: _hasMore,
//     };
//   } else {
//     const { data } = await deviceTagClient.getBasicInfo({
//       search: search,
//       offset: loadedOptions.length,
//       limit: 50,
//       orgId: orgId,
//     });
//     return {
//       options: data.results,
//       hasMore: data.hasMore,
//     };
//   }
// }
