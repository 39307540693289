import { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useSelector } from "react-redux";
import SntRadio from "../SntRadio/SntRadio";
import { useField } from "formik";
import sigfoxDeviceClient from "../../apis/sigfoxDeviceClient";
import deviceTagClient from "../../apis/deviceTagClient";

import { AsyncPaginate } from "react-select-async-paginate";
import { Form, Row } from "react-bootstrap";
import SntHideLongText from "../ReactBootstrap/SntHideLongText";
import HtmlParser from "react-html-parser/lib/HtmlParser";
import GlobalUtils from "../../utils/GlobalUtils";
import IcomoonIcon from "../Icons/IcomoonIcon";
import { VerticalLayout } from "../ReactBootstrap/FormValidation";

/**
 * @deprecated This component is deprecated. Use `MonitoredAssetContainer` instead.
 */
function MonitoredAssets({
  name,
  disabled = false,
  allowSelectTypes = ["NAME", "TAG", "ALL"],
  onChange = (e) => {
    console.log(e);
  },
  completedLoadingData = false,
  orgId,
  deviceTagOptionsCustom,
  serviceTypes = [],
  useChildOrg = false,
  forReport = false, // report use serial as value
  deviceCategories = ["TRACKER"],
  alertRuleType,
}) {
  const language = useSelector((state) => state.language);
  const [field, meta] = useField(name);

  let formGroupClass = "";
  if (meta && meta.touched) {
    if (meta.error) {
      formGroupClass = "is-invalid";
    } else {
      formGroupClass = "is-valid";
    }
  }

  const [selectType, setSelectType] = useState(field.value.selectType);
  const [selectedNameOptions, setSelectedNameOptions] = useState([]);
  const [selectedTagOptions, setSelectedTagOptions] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [monitoredItems, setMonitoredItems] = useState({});
  const [allOptions, setAllOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (completedLoadingData) {
      setSelectType(field.value.selectType);
      setSelectedIds(field.value.selectedIds);
      if (field.value.selectType === "NAME" && field.value.selectedItems) {
        setSelectedNameOptions(field.value.selectedItems);
      }

      if (field.value.selectType === "TAG" && field.value.selectedItems) {
        setSelectedTagOptions(field.value.selectedItems);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedLoadingData]);

  useEffect(() => {
    setMonitoredItems({
      ...monitoredItems,
      selectType: selectType,
      selectedIds: selectedIds,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectType, selectedIds]);

  useEffect(() => {
    onChange({
      selectType: monitoredItems.selectType,
      selectedIds: monitoredItems.selectedIds,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitoredItems]);

  useEffect(() => {
    if (selectedNameOptions) {
      setSelectedIds(selectedNameOptions.map((item) => item.value));
    } else {
      setSelectedIds([]);
    }
  }, [selectedNameOptions]);

  useEffect(() => {
    if (selectedTagOptions) {
      setSelectedIds(selectedTagOptions.map((item) => item.value));
    } else {
      setSelectedIds([]);
    }
  }, [selectedTagOptions]);

  async function loadDevicesOptions(search, loadedOptions) {
    if (forReport) {
      const { data } = await sigfoxDeviceClient.getAllForReport({
        search: search,
        offset: loadedOptions.length,
        limit: 50,
        serviceTypes: serviceTypes || [],
        deviceCategories: deviceCategories,
      });
      return customResults(data, search, loadedOptions);
    } else {
      const { data } = await sigfoxDeviceClient.getBasicInfo({
        search: search,
        offset: loadedOptions.length,
        limit: 50,
        orgId: orgId,
        serviceTypes: serviceTypes || [],
        useChildOrg: useChildOrg,
        deviceCategories: deviceCategories,
        alertRuleType: alertRuleType || "",
      });
      return customResults(data, search, loadedOptions);
    }
  }

  function customResults(data, search, loadedOptions) {
    let customResults = data.results.map((opt) => {
      return {
        ...opt,
        value: opt.value,
        label: getLabel(opt, search),
        name: opt.label,
      };
    });
    if (loadedOptions.length === 0) {
      setAllOptions([...customResults]);
      if (customResults.length > 1) {
        let selectAllOption = {
          value: -1,
          label: HtmlParser(`<b> ${language.select_all}</b>`),
          name: language.select_all,
          serial: -1,
        };
        customResults.unshift(selectAllOption);
      }
    } else {
      setAllOptions([...allOptions, ...customResults]);
    }
    return {
      options: customResults,
      hasMore: data.hasMore,
    };
  }

  function getLabel(device, search) {
    if (search === "") {
      return (
        <div style={{ overflow: "hidden" }}>
          {HtmlParser(getImage(device.id, device.image))}
          <div>
            <SntHideLongText> {device.label}</SntHideLongText>
            <p
              style={{ margin: 0, fontStyle: "13px" }}
              className="subtitle-text"
            >
              {device.serial}
            </p>
          </div>
        </div>
      );
    }
    // highlight the matched part with search text
    let reg = new RegExp(`(${search})`, "gi");
    let label = device.label.split(reg);
    let moreInfo = device.serial;
    if (
      device.imei &&
      device.imei.toLowerCase().indexOf(search.toLowerCase()) !== -1
    ) {
      moreInfo += ", " + device.imei;
    }
    if (
      device.mac &&
      device.mac.toLowerCase().indexOf(search.toLowerCase()) !== -1
    ) {
      moreInfo += ", " + device.mac;
    }
    moreInfo = moreInfo.split(reg);
    return (
      <div style={{ overflow: "hidden" }}>
        {HtmlParser(getImage(device.id, device.image))}
        <div>
          <SntHideLongText>
            {label.map((part, i) => {
              return (
                <span
                  key={i}
                  style={
                    part.toLowerCase() === search.toLowerCase()
                      ? { fontWeight: "bold" }
                      : {}
                  }
                >
                  {part}
                </span>
              );
            })}
          </SntHideLongText>
          <p style={{ margin: 0, fontStyle: "13px" }} className="subtitle-text">
            {moreInfo.map((part, i) => {
              return (
                <span
                  key={i}
                  style={
                    part.toLowerCase() === search.toLowerCase()
                      ? { fontWeight: "bold" }
                      : {}
                  }
                >
                  {part}
                </span>
              );
            })}
          </p>
        </div>
      </div>
    );
  }

  function getImage(entityId, iconPath) {
    let img = "";
    if (iconPath) {
      img =
        '<img style="float: left;width: 30px;height: 30px;margin-right: 10px;" src="' +
        GlobalUtils.getFile("device", entityId, "image", iconPath, "small") +
        '">';
    } else {
      img = ReactDOMServer.renderToString(
        <IcomoonIcon
          icon="podcast"
          className="text-dark"
          size="30"
          style={{ marginRight: 10, paddingTop: 5, float: "left" }}
        ></IcomoonIcon>
      );
    }
    return img;
  }

  async function loadDeviceTagsOptions(search, loadedOptions) {
    if (deviceTagOptionsCustom) {
      let _data = [];
      let _hasMore = false;
      if (search === "") {
        _data = deviceTagOptionsCustom.slice(
          loadedOptions.length,
          loadedOptions.length + 50
        );
      } else {
        let filtered = deviceTagOptionsCustom.filter(
          (option) =>
            option.label.toLowerCase().indexOf(search.toLowerCase()) !== -1
        );
        _data = filtered.slice(loadedOptions.length, loadedOptions.length + 50);
      }
      _hasMore = loadedOptions.length + 50 < deviceTagOptionsCustom.length;
      return {
        options: _data,
        hasMore: _hasMore,
      };
    } else {
      const { data } = await deviceTagClient.getBasicInfo({
        search: search,
        offset: loadedOptions.length,
        limit: 50,
        orgId: orgId,
      });
      return {
        options: data.results,
        hasMore: data.hasMore,
      };
    }
  }

  const changeSelectType = (type) => {
    if (type === "NAME" && selectedNameOptions) {
      setSelectedIds(selectedNameOptions.map((item) => item.value));
    } else if (type === "TAG" && selectedTagOptions) {
      setSelectedIds(selectedTagOptions.map((item) => item.value));
    } else {
      setSelectedIds([]);
    }
    setSelectType(type);
  };

  const updateSelectedNameOptions = (options) => {
    if (options && options.filter((opt) => opt.value === -1).length > 0) {
      let selectedAll = allOptions.map((opt) => {
        return {
          label: opt.name || opt.label,
          value: opt.value,
        };
      });

      let alreadySelected = options.filter((opt) => opt.value !== -1);
      let ids = new Set(alreadySelected.map((v) => v.value));
      let merged = [
        ...alreadySelected,
        ...selectedAll.filter((opt) => !ids.has(opt.value)),
      ];
      setSelectedNameOptions(merged);
      setInputValue("");
      setAllOptions([]);
    } else {
      let selectedOptions = options?.map((opt) => {
        return {
          label: opt.name || opt.label,
          value: opt.value,
        };
      });
      setSelectedNameOptions(selectedOptions);
    }
  };

  return (
    <Row>
      <VerticalLayout>
        {allowSelectTypes.includes("ALL") && (
          <SntRadio
            value="ALL"
            disabled={disabled}
            selectedValue={selectType}
            onChange={changeSelectType}
            label={language.all_key}
          />
        )}
        {allowSelectTypes.includes("TAG") && (
          <SntRadio
            value="TAG"
            disabled={disabled}
            selectedValue={selectType}
            onChange={changeSelectType}
            label={language.by_tag_key}
          />
        )}
        {allowSelectTypes.includes("NAME") && (
          <SntRadio
            value="NAME"
            disabled={disabled}
            selectedValue={selectType}
            onChange={changeSelectType}
            label={language.by_identifiers}
          />
        )}
      </VerticalLayout>

      {(selectType === "NAME" || selectType === "TAG") && (
        <div>
          {selectType === "NAME" ? (
            <AsyncPaginate
              name={name}
              key={selectType + "_" + orgId + JSON.stringify(serviceTypes)}
              value={selectedNameOptions}
              isMulti
              isDisabled={disabled}
              loadOptions={loadDevicesOptions}
              onChange={updateSelectedNameOptions}
              inputValue={inputValue}
              onInputChange={setInputValue}
              styles={{ menu: (styles) => ({ ...styles, zIndex: 100 }) }}
              menuPlacement="auto"
            />
          ) : null}
          {selectType === "TAG" ? (
            <AsyncPaginate
              name={name}
              key={selectType + "_" + orgId}
              value={selectedTagOptions}
              isMulti
              isDisabled={disabled}
              loadOptions={loadDeviceTagsOptions}
              onChange={setSelectedTagOptions}
              styles={{ menu: (styles) => ({ ...styles, zIndex: 100 }) }}
              menuPlacement="auto"
            />
          ) : null}
          <Form.Control.Feedback
            type="invalid"
            className={`${
              formGroupClass === "is-invalid" ? "d-block" : "d-none"
            }`}
          >
            {meta.error && meta.error.selectedIds}
          </Form.Control.Feedback>
        </div>
      )}
    </Row>
  );
}

export default MonitoredAssets;
