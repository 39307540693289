import { useField } from "formik";
import { useSelector } from "react-redux";
import { Form, Row } from "react-bootstrap";
import SntTooltip from "../../../components/Tooltip/SntTooltip.js";
import AwesomeIcon from "@icons/AwesomeIcon.js";

const DiagnosticServiceInput = ({
  name,
  minorName,
  majorName,
  label,
  placeholder = "",
  onChange,
  tooltip = "",
  isHightIsBetter = true,
}) => {
  const language = useSelector((state) => state.language);
  const [field, meta] = useField(name);

  let formGroupClass = "";
  if (meta && meta.touched) {
    if (meta.error) {
      formGroupClass = "is-invalid";
    } else {
      formGroupClass = "is-valid";
    }
  }

  return (
    <Row className={`form-group ${formGroupClass} mt-2`}>
      <label
        className={`col-xs-12 col-xl-6 align-self-center`}
        style={{ textAlign: "left" }}
      >
        {isHightIsBetter ? (
          <AwesomeIcon
            icon="arrow-up"
            color="green"
            title={language.higher_is_better_key}
          ></AwesomeIcon>
        ) : (
          <AwesomeIcon
            icon="arrow-down"
            color="red"
            title={language.lower_is_better_key}
          ></AwesomeIcon>
        )}

        {label}
      </label>

      <div className={`col-xs-12 col-xl-6 d-flex align-self-center`}>
        <span
          className="align-self-center"
          style={{ fontSize: 12, margin: "0px 10px" }}
        >
          {language.minor_key}
        </span>

        <Form.Control
          type="text"
          placeholder={placeholder}
          value={field.value.minor || ""}
          onChange={(e) => {
            onChange && onChange({ ...field.value, minor: e.target.value });
          }}
          name={minorName}
        />

        <span
          className="align-self-center"
          style={{ fontSize: 12, margin: "0px 10px" }}
        >
          {language.major_key}
        </span>

        <Form.Control
          type="text"
          placeholder={placeholder}
          value={field.value.major || ""}
          onChange={(e) => {
            onChange && onChange({ ...field.value, major: e.target.value });
          }}
          name={majorName}
        />
        <span className="align-self-center ps-2">
          <SntTooltip text={tooltip} />
        </span>
      </div>

      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </Row>
  );
};
export default DiagnosticServiceInput;
