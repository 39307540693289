import SntIcon from "./SntIcon";

const SntRadioListIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path d="M4 3C4 4.10457 3.10457 5 2 5C0.895431 5 0 4.10457 0 3C0 1.89543 0.895431 1 2 1C3.10457 1 4 1.89543 4 3Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4C2.55228 4 3 3.55228 3 3C3 2.44772 2.55228 2 2 2C1.44772 2 1 2.44772 1 3C1 3.55228 1.44772 4 2 4ZM2 5C3.10457 5 4 4.10457 4 3C4 1.89543 3.10457 1 2 1C0.895431 1 0 1.89543 0 3C0 4.10457 0.895431 5 2 5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 9C2.55228 9 3 8.55228 3 8C3 7.44772 2.55228 7 2 7C1.44772 7 1 7.44772 1 8C1 8.55228 1.44772 9 2 9ZM2 10C3.10457 10 4 9.10457 4 8C4 6.89543 3.10457 6 2 6C0.895431 6 0 6.89543 0 8C0 9.10457 0.895431 10 2 10Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 14C2.55228 14 3 13.5523 3 13C3 12.4477 2.55228 12 2 12C1.44772 12 1 12.4477 1 13C1 13.5523 1.44772 14 2 14ZM2 15C3.10457 15 4 14.1046 4 13C4 11.8954 3.10457 11 2 11C0.895431 11 0 11.8954 0 13C0 14.1046 0.895431 15 2 15Z"
      />
      <rect x="6" y="2" width="10" height="2" />
      <rect x="6" y="7" width="10" height="2" />
      <rect x="6" y="12" width="10" height="2" />
    </SntIcon>
  );
};

export default SntRadioListIcon;
