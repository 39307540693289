import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import SntInfoPanel from "../../components/SntInfo/SntInfoPanel";
import ServiceConfigTable from "../DeviceUsageProfile/ServiceConfigTable";
import ServiceConfigPopup from "./ServiceConfigPopup";

const ServiceConfig = ({
  mode,
  services,
  selectedService,
  onSave,
  description,
  parentEl,
}) => {
  const language = useSelector((state) => state.language);
  const [allService, setAllService] = useState([]);

  const [servicesSetting, setServiceSetting] = useState([]);
  const [chooseService, setChooseService] = useState();
  const [serviceConfig, setServiceConfig] = useState();

  useEffect(() => {
    let s = [];
    let selectedServiceKey =
      servicesSetting && servicesSetting.length
        ? servicesSetting.map((v) => {
            return v.type;
          })
        : [];
    services &&
      services.length &&
      services.forEach((e) => {
        selectedServiceKey.indexOf(e.serviceKey.name) < 0 &&
          s.push({
            value: e.serviceKey.name,
            label: language[e.serviceKey.fullName],
          });
      });
    setAllService(s);
  }, [services, servicesSetting, language]);

  useEffect(() => {
    setChooseService(allService[0]);
  }, [allService]);

  useEffect(() => {
    setServiceSetting([]);
    selectedService &&
      selectedService.length &&
      setServiceSetting(selectedService);
  }, [selectedService]);

  function onCheckBoxChange(el, serviceKey) {
    let vals = [...servicesSetting].map((v) =>
      v.type === serviceKey ? { ...v, active: el } : v
    );
    setServiceSetting(vals);
    onSave(vals);
  }

  function removeClick(el) {
    let vals = [...servicesSetting];
    vals.splice(
      vals.findIndex((v) => v.type === el.currentTarget.name),
      1
    );
    setServiceSetting(vals);
    onSave(vals);
    el.preventDefault();
  }

  function configClicked(el) {
    el.preventDefault();
    el.stopPropagation();
    let serviceKey = el.currentTarget.name;
    let vals = [...servicesSetting];
    let service = vals.find((v) => v.type === serviceKey);
    setServiceConfig({ ...service });
  }

  function onServiceConfigSave(serviceConfig) {
    let vals = [...servicesSetting];
    let idx = vals.findIndex((v) => v.type === serviceConfig.type);
    vals[idx] = serviceConfig;
    setServiceSetting(vals);
    onSave(vals);
  }

  const addClick = (event) => {
    if (!chooseService) return;
    let service = services.find(
      (p) => p.serviceKey.name === chooseService.value
    );
    let vals = [
      ...servicesSetting,
      { type: service.serviceKey.name, active: true },
    ];
    setServiceSetting(vals);
    onSave(vals);
    event.preventDefault();
  };

  return (
    <>
      {description && <SntInfoPanel>{description}</SntInfoPanel>}
      <ServiceConfigTable
        mode={mode}
        allService={services}
        services={servicesSetting}
        onActivate={onCheckBoxChange}
        onEdit={configClicked}
        onDelete={removeClick}
      ></ServiceConfigTable>

      {mode !== "view" && (
        <Row>
          <Col className="m-1" xs="12" sm="6">
            <Select
              value={chooseService}
              options={allService}
              onChange={(chooseService) => {
                setChooseService(chooseService);
              }}
              menuPlacement="auto"
            />
          </Col>
          <Col className="m-1" xs="12" sm="5">
            <Button variant="sensolus-greylight" onClick={addClick}>
              {language.add_this_service_key}
            </Button>
          </Col>
        </Row>
      )}
      <ServiceConfigPopup
        selectedService={serviceConfig}
        onServiceConfigSave={onServiceConfigSave}
        parentEl={parentEl}
      ></ServiceConfigPopup>
    </>
  );
};

export default ServiceConfig;
