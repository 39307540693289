import processDefinitionClient from "@/apis/processDefinitionClient";
import SntItemSelectorField from "@/cleanup/widgets/formiks/SntItemSelectorField";
import { FormFullScreenLayout } from "@/components/ReactBootstrap/FormValidation";
import { useEffect, useState } from "react";

const ProcessStepConditionControl = ({
  name,
  value,
  orgId,
  disabled,
  language,
}) => {
  const [processDefinitions, setProcessDefinitions] = useState({
    data: [],
    map: {},
  });

  useEffect(() => {
    processDefinitionClient
      .getByOrg(orgId)
      .then(({ data }) => {
        let processDefinitions = data.map((pro) => ({
          value: pro.id,
          label: pro.name,
        }));
        let processStepsMapDTO = {};
        for (let idx in data) {
          let processDefinition = data[idx];
          if (
            processDefinition.descriptor &&
            processDefinition.descriptor.steps &&
            processDefinition.descriptor.steps.length > 0
          ) {
            processStepsMapDTO[
              processDefinition.id
            ] = processDefinition.descriptor.steps.map((step) => ({
              value: step.id,
              label: step.label,
            }));
          }
        }

        setProcessDefinitions({
          data: processDefinitions,
          map: processStepsMapDTO,
        });
      })
      .catch((e) => {
        console.error(e);
      });
  }, [orgId]);

  return (
    <>
      <FormFullScreenLayout
        label={language.process_key}
        isRequired={true}
        rightRender={() => (
          <>
            <SntItemSelectorField
              options={processDefinitions.data}
              name={`${name}.selectedProcessId`}
              disabled={disabled}
            />
          </>
        )}
      />

      {value.selectedProcessId &&
        processDefinitions.map[value.selectedProcessId.value] && (
          <FormFullScreenLayout
            label={language.step_key}
            isRequired={true}
            rightRender={() => (
              <>
                <SntItemSelectorField
                  options={
                    processDefinitions.map[value.selectedProcessId.value]
                  }
                  name={`${name}.stepId`}
                  disabled={disabled}
                />
              </>
            )}
          />
        )}
    </>
  );
};

export default ProcessStepConditionControl;
