export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const LANGUAGE = "LANGUAGE";
export const ADD_HISTORY = "ADD_HISTORY";
export const BACK_HISTORY = "BACK_HISTORY";
export const SET_IS_MOBILE = "SET_IS_MOBILE";
export const INTERFACE = "INTERFACE";
export const MESSAGE = "MESSAGE";
export const STORE_LAYERS = "STORE_LAYERS";
export const SHOW_CANVAS_MENU = "SHOW_CANVAS_MENU";
export const LOGO_PATH = "LOGO_PATH";
export const MENU_TYPE = "MENU_TYPE";
export const RELOAD_SIDEBAR = "RELOAD_SIDEBAR";
export const GLOBAL_NOTIFICATION = "GLOBAL_NOTIFICATION";
export const GLOBAL_NOTIFICATION_COUNT = "GLOBAL_NOTIFICATION_COUNT";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const DASHBOARD_NOTIFICATION = "DASHBOARD_NOTIFICATION";
export const PREVENT_SIDEBAR = "PREVENT_SIDEBAR";
