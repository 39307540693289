import { apiClient } from "./apiClient";

export default {
  getByOrg(orgId, cancelToken) {
    return apiClient.get(`/rest/auditlog/byOrg/${orgId}`, {
      cancelToken: cancelToken,
    });
  },

  get(id) {
    return apiClient.get(`/rest/auditlog/${id}`);
  },

  search(params) {
    return apiClient.post(`/rest/auditlog/search`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
  getByPriceModel(priceModelId, cancelToken) {
    return apiClient.get(`/rest/auditlog/byPriceModel/${priceModelId}`, {
      cancelToken: cancelToken,
    });
  },
};
