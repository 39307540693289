import MonitoredGeozoneContainer from "@/cleanup/containers/formiks/MonitoredGeozoneContainer/MonitoredGeozoneContainer";
import { FormFullScreenLayout } from "@/components/ReactBootstrap/FormValidation";

const GeozoneConditionControl = ({ name, orgId, disabled, language }) => {
  return (
    <>
      <FormFullScreenLayout
        label={language.alert_generic_config_geozones_field_label}
        isRequired={true}
        rightRender={() => (
          <MonitoredGeozoneContainer
            language={language}
            name={name}
            disabled={disabled}
            orgId={orgId}
          />
        )}
      />
    </>
  );
};

export default GeozoneConditionControl;
