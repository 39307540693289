import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import SntDialog from "../../../components/SntDialog/SntDialog.js";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import ServiceConfigPanel from "../Utils/ServiceConfigPanel.js";
import ServiceInput from "../Utils/ServiceInput.js";
import { FormGroupLayout } from "../../../components/ReactBootstrap/FormValidation/index.js";

const OrientationEventServiceConfig = ({
  isShow,
  setIsShow,
  selectedService,
  onSave,
}) => {
  const language = useSelector((state) => state.language);
  const formikRefs = useRef();
  const [service, setService] = useState({
    type: "ORIENTATION_EVENT",
    active: true,
    configuration: {
      customEventName: "",
      description: "",
    },
  });

  useEffect(() => {
    selectedService && isShow && setService({ ...service, ...selectedService });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService, isShow]);

  function onServiceSave() {
    onSave({
      ...service,
      configuration: {
        customEventName: formikRefs.current.values.customEventName,
        description: formikRefs.current.values.description,
      },
    });
    setIsShow(false);
  }

  const validationSchema = Yup.object().shape({
    //TODO
  });

  return (
    <SntDialog
      isShow={isShow}
      onSave={() => onServiceSave(service)}
      onClose={() => setIsShow(false)}
      saveTxt={language.save_key}
      title={language.v40_define_services_to_enable_disable_key}
    >
      {() => (
        <ServiceConfigPanel title={language.orientation_settings_key}>
          {() => (
            <Formik
              initialValues={service.configuration}
              innerRef={formikRefs}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              <Form className="form-horizontal">
                <FormGroupLayout
                  label={language.name_key}
                  rightRender={() => (
                    <ServiceInput
                      name="customEventName"
                      placeholder={language.name_key}
                      tooltip={language.orientation_name_tooltip_key}
                    />
                  )}
                />
                <FormGroupLayout
                  label={language.description_key}
                  rightRender={() => (
                    <ServiceInput
                      name="description"
                      placeholder={language.description_key}
                      tooltip={language.orientation_description_tooltip_key}
                    />
                  )}
                />
              </Form>
            </Formik>
          )}
        </ServiceConfigPanel>
      )}
    </SntDialog>
  );
};

export default OrientationEventServiceConfig;
