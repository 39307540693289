import { useRoutes } from "@/components/SntTab/useRoutes";
import { useEffect, useMemo, useRef, useState } from "react";
import { Container, Nav } from "react-bootstrap";

const InternalTabs = ({ allTabs, tabGroupInfo }) => {
  const tabWidget = useRef(null);
  const { routeParams, updateRoute } = useRoutes(tabGroupInfo);

  const [selectedTabId, setSelectedTabId] = useState(tabGroupInfo["tabActive"]);

  const getSelectedTabId = (i) => {
    let selectedTab = allTabs.find((c) => c.id === i);
    return selectedTab ? selectedTab.id : "";
  };

  useEffect(() => {
    setSelectedTabId(getSelectedTabId(routeParams["tabActive"]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeParams["tabActive"]]);

  useEffect(() => {
    let route = {};
    route["tabActive"] = selectedTabId;

    selectedTabId && updateRoute(route);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabId]);

  const visibleItemRender = useMemo(() => {
    return allTabs.map((item) => (
      <a
        key={item.id}
        className={selectedTabId === item.id ? "nav-link active" : "nav-link"}
        onClick={(e) => {
          e.preventDefault();
          setSelectedTabId(item.id);
        }}
        href=" "
      >
        {item.label}
      </a>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTabs, selectedTabId]);

  const content = useMemo(() => {
    return allTabs.map((item) =>
      item.render ? (
        <div
          className="tab-pane"
          key={item.id}
          style={{ display: selectedTabId === item.id ? "block" : "none" }}
        >
          {item.component()}
        </div>
      ) : (
        ""
      )
    );
  }, [allTabs, selectedTabId]);

  return (
    <>
      <div className="tabs" style={{ position: "relative" }}>
        <Container className="nav-tabs p-0" fluid>
          <Nav ref={tabWidget} style={{ width: 10000, height: 43 }}>
            {visibleItemRender}
          </Nav>
        </Container>

        <div className="tab-content">{content}</div>
      </div>
    </>
  );
};

export default InternalTabs;
