import { apiClient } from "./apiClient";
import { saveAs } from "file-saver";

export default {
  getExternalBlesByTracker(id) {
    return apiClient.get(`/rest/sensor/tracker/${id}`);
  },

  isDeviceSupportSensorTypeName(id, sensorTypeName) {
    return apiClient.get(`/rest/sensor/tracker/${id}/sensor/${sensorTypeName}`);
  },

  getSensorInfoByIdentifier(params) {
    return apiClient.get(`/rest/sensor/byIdentifier`, {
      params: params,
    });
  },

  getSupportedTrackers(orgId) {
    return apiClient.get(`/rest/sensor/tracker/org/${orgId}`);
  },

  updateBleSensor(sensorId, body) {
    return apiClient.put(`/rest/sensor/${sensorId}`, body, {
      headers: { "content-type": "application/json" },
    });
  },

  auditRemoteSettings(sensorId) {
    return apiClient.get(`/rest/sensor/audit/${sensorId}`);
  },

  fixNotMatchedConfig(sensorId) {
    return apiClient.get(`/rest/sensor/fix/${sensorId}`);
  },

  search(params) {
    return apiClient.post(`/rest/sensor/search`, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  downloadBLESensorTemplateFile() {
    return apiClient
      .get("/rest/sensor/download/bleSensorsTemplateFile", {
        responseType: "arraybuffer",
      })
      .then((response) => {
        var blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, `BLE_Sensor_Template.xlsx`);
      });
  },

  uploadBLESensorFile(params) {
    return apiClient.post("/rest/sensor/efento/import", params, {
      headers: { "content-type": "multipart/form-data" },
    });
  },

  byDeviceIdentifier(deviceIdentifier) {
    return apiClient.post("/rest/sensor/byDeviceIdentifier", deviceIdentifier, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  test(params) {
    return apiClient.post(`/rest/sensor/test`, params, {
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    });
  },

  getSensorStates() {
    return apiClient.get(`/rest/sensor/sensorStates`);
  },

  getBleSensorIndex() {
    return apiClient.get(`/rest/sensor/bleSensorIndex`);
  },

  getOperatorType() {
    return apiClient.get(`/rest/sensor/operatorType`);
  },

  getForChart: function (deviceId, body) {
    return apiClient.post(`/rest/sensor/chart/` + deviceId, body, {
      headers: { "content-type": "application/json" },
    });
  },

  getAllExternalBleSensorTypes() {
    return apiClient.get(`/rest/sensor/types`);
  },

  getForStatsForMultiPeriods(deviceId, params, success, error) {
    return apiClient.post(
      "/rest/sensor/stats/multiPeriods/" + deviceId,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  },
  queueRemoteSetting(serial, body) {
    return apiClient.post(
      `/rest/sigfoxdevices/fwSetting/${serial}?description=`,
      body,
      {
        headers: { "content-type": "application/json" },
      }
    );
  },
  convertSensorToTracker(sensorId) {
    return apiClient.put(`/rest/sensor/convert/${sensorId}/to/tracker`, null, {
      headers: { "content-type": "application/json" },
    });
  },
};
