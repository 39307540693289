import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import deviceUsageProfile from "@/apis/deviceUsageProfile";
import sntServiceClient from "@/apis/sntServiceClient";
import { Button, Col, Row, Table } from "react-bootstrap";
import SntDeleteIcon from "../../components/Icons/SntDeleteIcon";
import SntPencilIcon from "../../components/Icons/SntPencilIcon";
import SntPanel from "../../components/Panel/SntPanel";
import { FormGroupLayout } from "../../components/ReactBootstrap/FormValidation";
import SntActionButton from "../../components/ReactBootstrap/SntActionButton";
import SntDialog from "../../components/SntDialog/SntDialog";
import ObjectUtils from "../../utils/ObjectUtils";
import ServiceConfig from "../ServicesConfiguration/ServiceConfig";
import DeviceProfileConfigurationAnchorMenu from "./DeviceProfileConfigurationAnchorMenu";
import NumericVariable from "./NumericVariable";
import RemoteSettings from "./RemoteSettings";
import UsageProfileCategory from "./UsageProfileCategory";

const DeviceProfileConfiguration = ({
  profile,
  profileToUpdate,
  onChange,
  setShowUnChangeConfirm,
  type,
}) => {
  const history = useHistory();
  const language = useSelector((state) => state.language);
  const parentServiceRef = useRef();
  const ref = useRef();

  const [numericVariables, setNumericVariables] = useState([]);
  const [categories, setCategories] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState({
    name: "",
    description: "",
    options: [],
    visualization: "RADIO_BUTTON",
    dependOnCategories: [{ categoryName: "", optionName: "" }],
  });

  const [fwParams, setFWParams] = useState({});
  const [services, setServices] = useState({});
  const [selectedService, setSelectedService] = useState({});
  const [remoteSettings, setRemoteSettings] = useState(
    profileToUpdate.current?.deviceFwParameterSettings || []
  );
  const [isShowRemoteSettings, setIsShowRemoteSettings] = useState(false);
  const [isRequiredProductKey, setIsRequiredProductKey] = useState(false);

  const [isShowAddCategory, setShowAddCategory] = useState(false);

  const [isShowImportDialog, setShowImportDialog] = useState(false);
  const [categoryInJSON, setCategoryInJSON] = useState();

  const originalProfile = useRef(
    JSON.parse(JSON.stringify(profileToUpdate.current))
  );

  useEffect(() => {
    if (profile) {
      originalProfile.current = JSON.parse(JSON.stringify(profile));

      profileToUpdate.current = { ...profile };

      setSelectedService(profile.profileServiceUpdates);
      setCategories(profile.categories);
      setNumericVariables(profile.numericVariables || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    profileToUpdate.current = {
      ...profileToUpdate.current,
      categories: [...categories],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  useEffect(() => {
    profileToUpdate.current = {
      ...profileToUpdate.current,
      numericVariables: [...numericVariables],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numericVariables]);

  useEffect(() => {
    if (
      profileToUpdate.current.referenceProduct &&
      profileToUpdate.current.referenceProduct.key
    ) {
      deviceUsageProfile
        .getFwParamByProductKeys([profileToUpdate.current.referenceProduct.key])
        .then(({ data }) => {
          setFWParams(data);
        });

      sntServiceClient
        .getAllServiceByTechKeys([profileToUpdate.current.referenceProduct.key])
        .then(({ data }) => {
          setServices(data);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileToUpdate.current.referenceProduct]);

  function onCancel() {
    let currentProfile = profileToUpdate.current;
    currentProfile = ObjectUtils.deletePropertiesDeep(currentProfile, [
      "chosen",
      "selected",
    ]);
    currentProfile = ObjectUtils.sortDeep(currentProfile);
    originalProfile.current = ObjectUtils.sortDeep(originalProfile.current);

    if (
      JSON.stringify(originalProfile.current) !== JSON.stringify(currentProfile)
    ) {
      setShowUnChangeConfirm(true);
      return;
    }
    setTimeout(() => {
      history.push("/device_usage_profile");
    }, 500);
  }

  function onServiceConfigSave(serviceConfig) {
    profileToUpdate.current = {
      ...profileToUpdate.current,
      profileServiceUpdates: serviceConfig,
    };
  }

  function checkRequiredProductKey() {
    if (!profileToUpdate.current.referenceProduct?.key) {
      setIsRequiredProductKey(true);
      return false;
    }
    setIsRequiredProductKey(false);
    return true;
  }

  function onAddRemoteSettingClicked(el) {
    checkRequiredProductKey() && setIsShowRemoteSettings(true);
    setRemoteSettings(profileToUpdate.current.deviceFwParameterSettings);
    el.preventDefault();
  }

  function onRemoteSettingSave(settings) {
    profileToUpdate.current = {
      ...profileToUpdate.current,
      deviceFwParameterSettings: settings,
    };
    setIsShowRemoteSettings(false);
  }

  function editSettingClicked(el) {
    setRemoteSettings(profileToUpdate.current.deviceFwParameterSettings);
    setIsShowRemoteSettings(true);
    el.preventDefault();
  }

  function removeSettingClicked(el) {
    profileToUpdate.current = {
      ...profileToUpdate.current,
      deviceFwParameterSettings: [],
    };

    el.preventDefault();
  }

  function renderRemoteSetting() {
    if (!profileToUpdate.current.deviceFwParameterSettings.length) return;
    let settings = profileToUpdate.current.deviceFwParameterSettings
      .sort((a, b) => {
        return a.fwParameterKey.localeCompare(b.fwParameterKey);
      })
      .sort((a, b) => {
        return +b.fwParameterKey.localeCompare("COMMAND_LOAD_PROFILE_PRESET");
      });
    return (
      <tr>
        <td>
          {settings.map((s) => (
            <p key={s.fwParameterKey}> {s.fwParameterKey + ": " + s.value} </p>
          ))}
        </td>
        {type !== "view" && (
          <td>
            <>
              <SntActionButton
                onClick={editSettingClicked.bind(this)}
                title={language.edit_key}
              >
                <SntPencilIcon />
              </SntActionButton>
              <SntActionButton
                onClick={removeSettingClicked.bind(this)}
                title={language.delete_key}
              >
                <SntDeleteIcon />
              </SntActionButton>
            </>
          </td>
        )}
      </tr>
    );
  }

  function addCategoryClicked(el) {
    el.preventDefault();
    checkRequiredProductKey() && setShowAddCategory(true);
    setSelectedCategory({
      name: "",
      description: "",
      options: [],
      visualization: "RADIO_BUTTON",
      dependOnCategories: [{ categoryName: "", optionName: "" }],
    });
  }

  const onImportPopupSave = () => {
    if (categoryInJSON !== "") {
      checkRequiredProductKey() && setShowAddCategory(true);
      setSelectedCategory(JSON.parse(categoryInJSON));
    }
    setShowImportDialog(false);
  };

  const onSaveClicked = () => {
    profileToUpdate.current = {
      ...profileToUpdate.current,
      ...profileToUpdate.current,
    };
    onChange && onChange();
  };

  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    let items = [
      {
        id: "base_parameters",
        name: language.base_parameters,
        isHeader: true,
      },
      {
        id: "service",
        name: language.v40_enable_disable_services_key,
      },
      {
        id: "fwSetting",
        name: language.remote_settings_key,
      },
      {
        id: "categories",
        name: language.categories_key,
        isHeader: true,
      },
    ];
    let categories = profileToUpdate.current.categories.map((cat) => {
      return { id: cat.name, name: cat.uiLabel };
    });
    items = items.concat(categories);
    setMenuItems(items);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileToUpdate.current, categories]);

  const onMenuItemClicked = (item) => {
    let element = ref.current.getElementsByClassName(item.id)[0];
    element &&
      element.scrollIntoView({
        behavior: "instant",
        block: "start",
        inline: "nearest",
      });
  };

  return (
    <>
      <SntDialog
        isShow={isShowImportDialog}
        onSave={() => onImportPopupSave()}
        onClose={() => setShowImportDialog(false)}
        saveTxt={language.save_key}
        closeTxt={language.cancel_key}
        customModalClassname={"snt-config-column-modal"}
        title={language.import_category_json}
      >
        {() => (
          <>
            <FormGroupLayout
              label={"JSON"}
              rightRender={() => (
                <textarea
                  name="categoryInJSON"
                  className="form-control"
                  rows="20"
                  value={categoryInJSON}
                  onChange={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setCategoryInJSON(e.target.value);
                  }}
                />
              )}
            />
          </>
        )}
      </SntDialog>

      <SntDialog
        isShow={isRequiredProductKey}
        onSave={() => setIsRequiredProductKey(false)}
        saveTxt={language.ok_key}
        isShowCloseButton={false}
        title={language.information_key}
        width="30%"
        onClose={() => {
          setIsRequiredProductKey(false);
        }}
      >
        {() => language.select_reference_product_key_first}
      </SntDialog>

      <RemoteSettings
        fwParams={fwParams}
        isShow={isShowRemoteSettings}
        settings={remoteSettings}
        onSave={(settings) => onRemoteSettingSave(settings)}
        onCancel={() => setIsShowRemoteSettings(false)}
      />

      <Row ref={ref} className="mb-3">
        <Col xs="2">
          <DeviceProfileConfigurationAnchorMenu
            items={menuItems}
            onItemClick={(item) => onMenuItemClicked(item)}
          />
        </Col>

        <Col
          xs="10"
          style={{ height: "75vh", overflowX: "hidden", overflowY: "auto" }}
        >
          <h4 className={"base_parameters"}>{language.base_parameters}</h4>

          <SntPanel
            className={"service"}
            title={language.v40_define_enable_disable_services_key}
          >
            <div ref={parentServiceRef}>
              <ServiceConfig
                parentEl={parentServiceRef}
                mode={type}
                services={services}
                selectedService={selectedService}
                onSave={(serviceConfig) => onServiceConfigSave(serviceConfig)}
                description={language.v40_define_services_note_title_key}
              />
            </div>
          </SntPanel>

          <SntPanel
            className={"fwSetting"}
            title={language.remote_settings_key}
          >
            <Row>
              <Col xs="12">
                <Table bordered striped responsive>
                  <thead>
                    <tr>
                      <th>{language.bidir_payload_key}</th>
                      {type !== "view" && <th>{language.action_key}</th>}
                    </tr>
                  </thead>
                  <tbody id="remote-setting-body">
                    {renderRemoteSetting()}
                  </tbody>
                </Table>
                {type !== "view" && (
                  <div className="text-left mb-3">
                    <Button
                      variant="sensolus-greylight"
                      id="add"
                      onClick={(e) => onAddRemoteSettingClicked(e)}
                    >
                      {language.push_generic_remote_settings_key}
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </SntPanel>

          <NumericVariable
            variables={numericVariables}
            onSave={(value) => setNumericVariables(value)}
            mode={type === "edit" && type}
          />

          <h4 className="pb-2 categories">{language.categories_key}</h4>
          <Row>
            <Col xs="12">
              <UsageProfileCategory
                usageProfile={profileToUpdate.current}
                services={services}
                fwParams={fwParams}
                categories={categories}
                setCategories={setCategories}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                isShowAddCategory={isShowAddCategory}
                setShowAddCategory={setShowAddCategory}
                numericVariables={numericVariables}
                type={type}
              />

              <div className="text-left mb-3 mt-2">
                {type === "view" ? (
                  ""
                ) : (
                  <>
                    <Button
                      className="me-2"
                      variant="sensolus-greylight"
                      id="addCategory"
                      onClick={(e) => addCategoryClicked(e)}
                    >
                      {language.add_category_key}
                    </Button>
                    <a
                      className="mt-2"
                      dataplacement="bottom"
                      title={language.import_category_json}
                      href=""
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setCategoryInJSON("");
                        setShowImportDialog(true);
                      }}
                    >
                      {language.import_category_json}
                    </a>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {type !== "view" && (
        <Row>
          <Col xs="2"></Col>
          <Col xs="10" className="mb-3">
            <Button
              variant="sensolus"
              onClick={() => onSaveClicked()}
              className="tmp me-1"
              id="save"
            >
              {language.save_key}
            </Button>
            <Button
              variant="outline-sensolus"
              onClick={() => onCancel()}
              id="cancel"
            >
              {language.cancel_key}
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

export default DeviceProfileConfiguration;
